// TODO: Need to figure out how to properly type this.

interface MappedActions<S> {
    // eslint-disable-next-line no-unused-vars
    [actionType: string]: (state: S, action: any, payload?: any) => S;
}

const reduxUtils = {
    createReducer: <S>(mappedActions: MappedActions<S>, initState: S) => {
        return (state: S = initState, action: any = { type: '' }): S => {
            if (action.type && mappedActions[action.type]) {
                return mappedActions[action.type](state, action);
            }
            return state;
        };
    }
};

export default reduxUtils;
