// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as storageUtils from 'app/shared/utils/storageUtils';

const LOCAL_STORAGE_KEY = 'fireAdPixels';
const ONE_DAY = 24 * 60 * 60 * 1000;
const TIME_TO_WAIT_TO_FIRE_PIXELS = 3 * ONE_DAY;

class TrackingPixels extends Component {
    constructor(props) {
        super(props);
        let waitTime = 0;
        this.shouldRenderPixels = false;

        if (storageUtils.storageAvailable('localStorage')) {
            waitTime = Number(localStorage.getItem(LOCAL_STORAGE_KEY)) || 0;
            let newWaitTime = Date.now() + TIME_TO_WAIT_TO_FIRE_PIXELS;
            if (waitTime === 0 || Date.now() >= waitTime) {
                this.shouldRenderPixels = true;
                localStorage.setItem(LOCAL_STORAGE_KEY, newWaitTime);
            }
        }
    }
    render() {
        const salesforcePixel = `https://beacon.krxd.net/usermatch.gif?partner=hotpads&partner_uid=${this.props.userToken
            }`;

        if (!this.shouldRenderPixels) {
            return null;
        }

        return (
            <div id="ad-pixels" style={{ display: 'none' }}>
                <img src={salesforcePixel} />
                <img src="//usermatch.krxd.net/um/v2?partner=google" />
                <img src="//kr.ixiaa.com/C726AB29-0470-440B-B8D2-D552CED3A3DC/a.gif" />
                <img src="https://usermatch.krxd.net/um/v2?partner=liveramp" />
                <img src="//aa.agkn.com/adscores/g.js?sid=9212244187&_kdpid=2111c0af-fc3a-446f-ab07-63aa74fbde8e" />
                <img src="//match.adsrvr.org/track/cmf/generic?ttd_pid=krux&ttd_tpi=1" />
                <img src="https://pixel.mathtag.com/sync/img?redir=https:%2F%2Fbeacon.krxd.net%2Fusermatch.gif%3Fpartner%3Dmediamath%26mmuuid%3D%5BMM_UUID%5D" />
                <img src="https://usermatch.krxd.net/um/v2?partner=adobe" />
                <img src="//usermatch.krxd.net/um/v2?partner=adnxs" />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userToken: state.user.userToken
    };
};

export default connect(mapStateToProps)(TrackingPixels);
