// @ts-nocheck
/* eslint-enable */
import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import * as S from './styles';

class Row extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        size: PropTypes.string,
        flex: PropTypes.bool
    };

    static defaultProps = {
        className: '',
        size: '',
        flex: false
    };

    constructor(props) {
        super(props);
    }

    render() {
        const { className, flex, size } = this.props;
        const rest = omit(this.props, ['className', 'flex', 'size']);

        return (
            <S.Row
                size={size}
                flex={flex}
                className={className}
                {...rest}
            >
                {this.props.children}
            </S.Row>
        );
    }
}

export default Row;
