// @ts-nocheck
/* eslint-enable */
/* eslint-disable camelcase */
// Lodash
import assign from 'lodash/assign';
import forEach from 'lodash/forEach';
import includes from 'lodash/includes';
import intersection from 'lodash/intersection';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import last from 'lodash/last';
import omit from 'lodash/omit';
import values from 'lodash/values';
import reduce from 'lodash/reduce';

// Models
import { DEFAULT } from 'app/shared/models/Filter';
import DEPRECATED_Filter from 'app/shared/models/DEPRECATED_Filter';

// Misc / utils
import formatter from 'app/shared/utils/formatter';
import dateUtils from 'app/shared/utils/dateUtils';
import genericUtils from 'app/shared/utils/genericUtils';
import numberUtils from 'app/shared/utils/numberUtils';

// Label makers
import LabelMaker from 'app/shared/utils/labelMaker';

const logger = getLogger('utils/filter');
const { createRangeArray } = genericUtils;

const ANY_BEDROOMS = createRangeArray(1, 0, 7, '8plus');
const NEARBY_AREAS_TO_GET = 3;

export const filterUtils_defaultFilter = DEPRECATED_Filter.create();

export const filterUtils_suggestions = {
    ANY_BEDROOMS,
    // by hours
    maxCreated: [
        {
            title: 'Any',
            value: [],
            filterKey: 'maxCreated'
        },
        {
            title: '1 hour',
            value: [1],
            filterKey: 'maxCreated'
        },
        {
            title: '1 day',
            value: [24],
            filterKey: 'maxCreated'
        },
        {
            title: '7 days',
            value: [168],
            filterKey: 'maxCreated'
        },
        {
            title: '30 days',
            value: [720],
            filterKey: 'maxCreated'
        }
    ],
    rentPropertyTypes: ({ resourceId }) => {
        return [
            {
                title: 'Any',
                value: DEPRECATED_Filter.allowedConstants.allowedPropertyTypes
            },
            {
                title: 'Apartment',
                value: ['medium', 'large', 'garden'],
                longTailLink: `https://hotpads.com/${resourceId}/apartments-for-rent`
            },
            {
                title: 'Condo',
                value: ['condo'],
                longTailLink: `https://hotpads.com/${resourceId}/condos-for-rent`
            },
            {
                title: 'Duplex',
                value: ['divided']
            },
            {
                title: 'House',
                value: ['house'],
                longTailLink: `https://hotpads.com/${resourceId}/houses-for-rent`
            },
            {
                title: 'Townhouse',
                value: ['townhouse'],
                longTailLink: `https://hotpads.com/${resourceId}/townhomes-for-rent`
            }
        ].map((type) => assign({}, type, { filterKey: 'propertyTypes' }));
    },
    sliderPriceRange: [
        0, 300, 350, 400, 450, 500, 550, 600, 650, 700, 750, 800, 850, 900, 950, 1000, 1050, 1100, 1150, 1200, 1250,
        1300, 1350, 1400, 1450, 1500, 1550, 1600, 1650, 1700, 1750, 1800, 1850, 1900, 1950, 2000, 2100, 2200, 2300,
        2400, 2500, 2600, 2700, 2800, 2900, 3000, 3250, 3500, 3750, 4000, 4250, 4500, 4750, 5000, 5500, 6000, 6500,
        7000, 7500, 8000
    ],
    sliderWithHistogramPriceRange: [
        0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900,
        2000, 2100, 2200, 2300, 2400, 2500, 2600, 2700, 2800, 2900, 3000, 3100, 3200, 3300, 3400, 3500, 3600, 3700,
        3800, 3900, 4000, 4100, 4200, 4300, 4400, 4500, 4600, 4700, 4800, 4900, 5000
    ],
    sqftRange: createRangeArray(200, 0, 3800).map((s) => Number(s))
};

export const filterUtils_presets = {
    delimiter: '-',
    legend: {
        freshness: 'maxCreated',
        maxCreated: 'maxCreated', // accept both for now?
        orderBy: 'orderBy',
        page: 'currentPage',
        photos: 'minPhotos',
        radius: 'radius',
        searchSlug: 'searchSlug'
    },

    // NOTE: This section is used for display options inside the AdvancedFilter component and doesn't affect
    // what gets passed to the API.
    rentalListingTypeOptions: ['rental', 'sublet', 'room', 'corporate'],

    // Display types for properties inside AdvancedFilter and filterToNiceSummary.
    // Key relates to what's displayed to users on the front-end.
    // Values relate to which propertyTypes are passed onto the API.
    mapDisplayTypesToPropertyTypes: {
        apartment: ['garden', 'large', 'medium'],
        condo: ['condo'],
        duplex: ['divided'],
        house: ['house'],
        townhouse: ['townhouse']
    },
    mapUrlQueryToFilter: {
        searchSlug: 'searchSlug',
        ascending: 'ascending',
        orderBy: 'orderBy',
        page: 'currentPage',

        // visible desktop filters
        price: ['lowPrice', 'highPrice'],
        beds: 'bedrooms',
        baths: 'bathrooms',
        pets: 'pets',

        // availability
        avail: ['startOfAvailabilityDate', 'endOfAvailabilityDate'],
        hideUnkAvail: 'hideUnknownAvailabilityDate',

        // more filters
        amenities: 'amenities',
        laundry: 'laundry',
        furnished: 'furnished',
        propertyTypes: 'propertyTypes',
        sqft: ['minSqft', 'maxSqft'],
        maxCreated: 'maxCreated',
        maxUpdated: 'maxUpdated',

        // special restrictions
        incomeRestricted: 'incomeRestricted',
        seniorHousing: 'seniorHousing',
        studentHousing: 'studentHousing',
        militaryHousing: 'militaryHousing',

        // additional options
        keywords: 'keywords',
        includeVaguePricing: 'includeVaguePricing',
        photos: 'minPhotos',
        promo: 'hasSpecialOffers',
        isListedByOwner: 'isListedByOwner',
        visible: 'visible',
        applicationsOk: 'isAcceptingRentalApplications',

        // Note: feeds is only used by rep,
        feeds: 'feeds'
    }
};

export const filterUtils_isTabSelected = ({ filterValue, filterKey, dataValue, dataTitle }) => {
    if (filterKey === 'pets' || filterKey === 'maxCreated') {
        return filterUtils_isGenericSelection({ filterValue, dataValue, dataTitle });
    } else if (filterKey === 'propertyTypes') {
        return filterUtils_isPropertyTypeSelected({ filterValue, dataValue, dataTitle });
    }
};
export const filterUtils_isGenericSelection = ({ filterValue, dataValue, dataTitle }) => {
    if (dataTitle.toUpperCase() === 'ANY' && !filterValue) {
        return true;
    } else if (includes(filterValue, dataValue[0]) || filterValue === dataValue[0]) {
        return true;
    }
    return false;
};
export const filterUtils_isPropertyTypeSelected = ({ filterValue, dataValue, dataTitle }) => {
    let allSelected = DEPRECATED_Filter.allowedConstants.allowedPropertyTypes;

    if (dataTitle.toUpperCase() === 'ANY') {
        if (isEqual(filterValue, allSelected)) {
            return true;
        }
    } else if (includes(filterValue, dataValue[0]) && !isEqual(filterValue, allSelected)) {
        return true;
    }

    return false;
};
export const filterUtils_getNumberOfAppliedFilters = (appliedFilters) => {
    /**
     * Function counts the number of keys in the object that is passed in.
     * If a nested object is detected, it'll count the keys of the nested
     * object and continue unless it's a sliding filter. In that
     * case, it will only be counted once.
     *
     *  i.e. { pets: ['dogs'], amenities: { cooling: true, doorman: true}, sqft: { minSqft: 800, maxSqft: 3000 } }
     *  will yield numberOfAppliedFilters === 4
     *
     */
    const appliedFilterKeys = Object.keys(appliedFilters);
    const isFilterObject = (filter) => isPlainObject(filter);
    const isFilterSliderOrPicker = (filter) => {
        const isPriceSlider = filter.lowPrice || filter.highPrice;
        const isSqftSlider = filter.minSqft || filter.maxSqft;
        const isDatePicker = filter.startOfAvailabilityDate || filter.endOfAvailabilityDate;

        return isPriceSlider || isSqftSlider || isDatePicker;
    };
    const numberOfAppliedFilters = appliedFilterKeys.reduce((filtersApplied, elem) => {
        const currentFilter = appliedFilters[elem];
        if (isFilterObject(currentFilter)) {
            if (isFilterSliderOrPicker(currentFilter)) {
                filtersApplied += 1;
            } else {
                filtersApplied += Object.keys(currentFilter).length;
            }
        } else {
            filtersApplied += 1;
        }
        return filtersApplied;
    }, 0);

    return numberOfAppliedFilters;
};
export const filterUtils_filterToNiceSummary = (searchFilter) => {
    let bathrooms = [];
    let bedrooms = [];
    let bathroomSummary;
    let bedroomSummary;
    let filterSummaryString = [];
    let priceSummary;
    let propertyTypes = [];
    let checkPropertyTypes = filterUtils_presets.mapDisplayTypesToPropertyTypes;

    // Check if the propertyTypes within the search filter match the consolidated display property types
    forEach(checkPropertyTypes, (propertyValuesArray, key) => {
        if (intersection(searchFilter.propertyTypes, propertyValuesArray).length > 0) {
            propertyTypes.push(key);
        }
    });
    // Handle pluralizing property types:
    if (propertyTypes.length > 0 && propertyTypes.length !== 5) {
        propertyTypes = propertyTypes.join(', ');
        filterSummaryString.push(propertyTypes);
    }

    // Format price summary
    if (searchFilter.lowPrice && searchFilter.highPrice) {
        priceSummary = '$' + numberUtils.compact(searchFilter.lowPrice);
        priceSummary += ' to $' + numberUtils.compact(searchFilter.highPrice);
    } else if (searchFilter.lowPrice) {
        priceSummary = '$' + numberUtils.compact(searchFilter.lowPrice) + '+';
    } else if (searchFilter.highPrice) {
        priceSummary = 'Up to $' + numberUtils.compact(searchFilter.highPrice);
    } else {
        //priceSummary = 'Any price';
        priceSummary = '';
    }

    if (priceSummary) {
        filterSummaryString.push(priceSummary);
    }

    if (isArray(searchFilter.bedrooms) && searchFilter.bedrooms.length > 0) {
        bedrooms = searchFilter.bedrooms;
    }
    // Format bedroom summary
    if (bedrooms.length === 1 && bedrooms[0] === '0') {
        bedroomSummary = 'Studios';
    } else if (bedrooms.length === 1 && bedrooms[0] !== '0') {
        bedroomSummary = bedrooms[0] + (bedrooms[0] === '1' ? ' bed' : ' beds');
    } else if (bedrooms[0] && bedrooms[0] === '0' && bedrooms[bedrooms.length - 1] !== '8plus') {
        bedroomSummary = 'Up to ' + bedrooms[bedrooms.length - 1] + ' beds';
    } else if (bedrooms[0] && bedrooms[0] !== '0' && bedrooms[bedrooms.length - 1] !== '8plus') {
        bedroomSummary = bedrooms[0] + ' to ' + bedrooms[bedrooms.length - 1] + ' beds';
    } else if (bedrooms[0] && bedrooms[0] !== '0' && bedrooms[bedrooms.length - 1] === '8plus') {
        bedroomSummary = bedrooms[0] + '+ beds';
    }

    if (bedroomSummary) {
        filterSummaryString.push(bedroomSummary);
    }

    if (isArray(searchFilter.bathrooms) && searchFilter.bathrooms.length > 0) {
        bathrooms = searchFilter.bathrooms;
    }
    // Format bathroom summarybathrooms
    if (bathrooms.length === 2) {
        // When a user selects a number of bathrooms, such as 2, we get an array of two values back:
        // [2, 2.5]
        // In this case, we can simplify our filter to just say, "2 baths"
        bathroomSummary = bathrooms[0] + (bathrooms[0] === '1' ? ' bath' : ' baths');
    } else if (bathrooms[0] && bathrooms[0] === '0' && bathrooms[bathrooms.length - 1] !== '8plus') {
        bathroomSummary = 'Up to ' + bathrooms[bathrooms.length - 1] + ' baths';
    } else if (bathrooms[0] && bathrooms[0] !== '0' && bathrooms[bathrooms.length - 1] !== '8plus') {
        bathroomSummary = bathrooms[0] + ' to ' + bathrooms[bathrooms.length - 1] + ' baths';
    } else if (bathrooms[0] && bathrooms[0] !== '0' && bathrooms[bathrooms.length - 1] === '8plus') {
        bathroomSummary = bathrooms[0] + '+ baths';
    }

    if (bathroomSummary) {
        filterSummaryString.push(bathroomSummary);
    }

    // Format pets
    if (searchFilter.pets) {
        if (!includes(searchFilter.pets, 'any')) {
            searchFilter.pets.forEach((element) => {
                filterSummaryString.push(element);
            });
        }
    }

    // Format number of photos
    if (searchFilter.minPhotos > 0) {
        filterSummaryString.push('+' + searchFilter.minPhotos + ' photos');
    }

    // Format listing freshness
    if (searchFilter.maxCreated) {
        let freshness;

        switch (searchFilter.maxCreated) {
            case 720:
                freshness = 'last 30 days';
                break;
            case 168:
                freshness = 'last 7 days';
                break;
            case 72:
                freshness = 'last 3 days';
                break;
            case 24:
                freshness = 'last 24 hours';
                break;
            case 12:
                freshness = 'last 12 hours';
                break;
            default:
                freshness = 'last hour';
                break;
        }
        filterSummaryString.push(freshness);
    }

    // Handle special restrictions
    if (searchFilter.incomeRestricted) {
        filterSummaryString.push('income restricted');
    }
    if (searchFilter.seniorHousing) {
        filterSummaryString.push('senior housing');
    }
    if (searchFilter.studentHousing) {
        filterSummaryString.push('student housing');
    }
    if (searchFilter.militaryHousing) {
        filterSummaryString.push('military housing');
    }

    // Handle square footage
    if (searchFilter.minSqft && !searchFilter.maxSqft) {
        filterSummaryString.push(searchFilter.minSqft + '+ sq ft');
    } else if (!searchFilter.minSqft && searchFilter.maxSqft) {
        filterSummaryString.push('up to ' + searchFilter.maxSqft + ' sq ft');
    } else if (searchFilter.minSqft && searchFilter.maxSqft) {
        filterSummaryString.push(searchFilter.minSqft + '-' + searchFilter.maxSqft + ' sq ft');
    }

    // Format keywords
    if (searchFilter.keywords) {
        filterSummaryString.push('contains: ' + searchFilter.keywords);
    }

    // Format Rental types
    let listingTypes = searchFilter.listingTypes;
    let listingTypesString = listingTypes.join(', ');
    if (listingTypes.length > 1) {
        let lastType = listingTypes[listingTypes.length - 1];
        listingTypesString = listingTypesString.replace(', ' + lastType, ' and ' + lastType);
    }
    listingTypesString = listingTypesString.replace('rental', 'regular');
    listingTypesString = listingTypesString.replace('room', 'room for rent');
    listingTypesString = listingTypesString.replace('newHome', 'new home');
    filterSummaryString.push(listingTypesString + ' listing types');

    if (searchFilter.amenities) {
        if (isString(searchFilter.amenities)) {
            // from api as a comma separated string)
            searchFilter.amenities.split(',').forEach((amenity) => {
                filterSummaryString.push(filterUtils_getRenamedAmenities(amenity, true));
            });
        } else {
            forEach(searchFilter.amenities, (v, amenity) => {
                filterSummaryString.push(filterUtils_getRenamedAmenities(amenity, true));
            });
        }
    }

    // Format furnished listings
    if (searchFilter.furnished) {
        filterSummaryString.push('furnished');
    }

    // Format offers
    if (searchFilter.hasSpecialOffers) {
        filterSummaryString.push('offers');
    }

    if (filterSummaryString.length === 0) {
        return 'For rent. ';
    } else {
        return formatter.string.capitalizeFirstLetter(filterSummaryString.join(' / ')) + '. ';
    }
};
export const filterUtils_filterToNiceSummaryV2 = (searchFilter) => {
    let filterSummaryString = [];
    let bathrooms = [];
    let bedrooms = [];
    let bathroomSummary;
    let bedroomSummary;
    let priceSummary;
    let propertyTypes = [];
    let checkPropertyTypes = filterUtils_presets.mapDisplayTypesToPropertyTypes;

    // Format price summary
    if (searchFilter.lowPrice && searchFilter.highPrice) {
        priceSummary = '$' + numberUtils.compact(searchFilter.lowPrice);
        priceSummary += ' – $' + numberUtils.compact(searchFilter.highPrice);
    } else if (searchFilter.lowPrice) {
        priceSummary = 'more than $' + numberUtils.compact(searchFilter.lowPrice);
    } else if (searchFilter.highPrice) {
        priceSummary = 'less than $' + numberUtils.compact(searchFilter.highPrice);
    } else {
        priceSummary = '';
    }
    if (priceSummary) {
        filterSummaryString.push(priceSummary);
    }

    // Format bedroom summary
    if (isArray(searchFilter.bedrooms) && searchFilter.bedrooms.length > 0) {
        bedrooms = searchFilter.bedrooms;
    }
    if (bedrooms.length === 0) {
        bedroomSummary = '';
    } else if (bedrooms.length === 1 && bedrooms[0] === '0') {
        bedroomSummary = 'studio';
    } else if (bedrooms.length === 1 && bedrooms[0] !== '0') {
        bedroomSummary = bedrooms[0] + (bedrooms[0] === '1' ? ' bed' : ' beds');
    } else if (bedrooms[0] === '0' && bedrooms[bedrooms.length - 1] !== '8plus') {
        bedroomSummary = 'studio – ' + bedrooms[bedrooms.length - 1] + ' beds';
    } else if (bedrooms[0] !== '0' && bedrooms[bedrooms.length - 1] !== '8plus') {
        bedroomSummary = bedrooms[0] + ' – ' + bedrooms[bedrooms.length - 1] + ' beds';
    } else if (bedrooms[0] !== '0' && bedrooms[bedrooms.length - 1] === '8plus') {
        bedroomSummary = bedrooms[0] + '+ beds';
    }
    if (bedroomSummary) {
        filterSummaryString.push(bedroomSummary);
    }

    // Format bathroom summary
    if (isArray(searchFilter.bathrooms) && searchFilter.bathrooms.length > 0) {
        bathrooms = searchFilter.bathrooms;
    }
    if (bathrooms.length >= 2 && bathrooms[0] !== '0') {
        // Users can only select a number and up, ex: 3+
        // ["3", "3.5", "4", "4.5", "5", "5.5", "6", "6.5", "7", "7.5", "8plus"]
        // In this case, we can simplify our filter to just say, "3+ baths"
        bathroomSummary = bathrooms[0] + '+ baths';
        filterSummaryString.push(bathroomSummary);
    }

    // Check if the propertyTypes within the search filter match the consolidated display property types
    forEach(checkPropertyTypes, (propertyValuesArray, key) => {
        if (intersection(searchFilter.propertyTypes, propertyValuesArray).length > 0) {
            propertyTypes.push(key.toLowerCase());
        }
    });
    // Handle pluralizing property types:
    if (propertyTypes.length === 1) {
        filterSummaryString.push(propertyTypes[0]);
    } else if (propertyTypes.length > 0 && propertyTypes.length !== 5) {
        filterSummaryString.push('property type: ' + propertyTypes.length + ' selected');
    }

    // Format pets (possible values: 'any' or ('dogs' and/or 'cats'))
    if (searchFilter.pets && !includes(searchFilter.pets, 'any')) {
        if (searchFilter.pets.length === 1) {
            filterSummaryString.push(searchFilter.pets[0]);
        } else if (searchFilter.pets.length === 2) {
            filterSummaryString.push('pets: 2 selected');
        }
    }

    // Format laundry
    if (searchFilter.laundry) {
        if (isString(searchFilter.laundry)) {
            // from api as a comma separated string)
            if (searchFilter.laundry.split(',').length === 2) {
                filterSummaryString.push('laundry: 2 selected');
            } else if (searchFilter.laundry.split(',').length === 1) {
                filterSummaryString.push(
                    'laundry: ' + filterUtils_getRenamedLaundryOptions(searchFilter.laundry).toLowerCase()
                );
            }
        } else {
            if (searchFilter.laundry.includes('inUnit') && searchFilter.laundry.includes('shared')) {
                filterSummaryString.push('laundry: 2 selected');
            } else if (searchFilter.laundry.includes('inUnit')) {
                filterSummaryString.push('laundry: in-unit');
            } else if (searchFilter.laundry.includes('shared')) {
                filterSummaryString.push('laundry: shared');
            }
        }
    }

    if (searchFilter.amenities) {
        if (isString(searchFilter.amenities)) {
            // from api as a comma separated string)
            searchFilter.amenities.split(',').forEach((amenity) => {
                filterSummaryString.push(filterUtils_getRenamedAmenities(amenity, true));
            });
        } else {
            forEach(searchFilter.amenities, (v, amenity) => {
                filterSummaryString.push(filterUtils_getRenamedAmenities(amenity, true));
            });
        }
    }

    // Format furnished listings
    if (searchFilter.furnished) {
        filterSummaryString.push('furnished');
    }

    // Format availability dates
    if (searchFilter.startOfAvailabilityDate && searchFilter.endOfAvailabilityDate) {
        const { startOfAvailabilityDate, endOfAvailabilityDate } = searchFilter;
        const dates = filterUtils_getRenamedAvailabilityDates({ startOfAvailabilityDate, endOfAvailabilityDate });
        // available from to
        filterSummaryString.push(dates);
    }

    // Format hiding unknown availability dates option
    if (searchFilter.hideUnknownAvailabilityDate) {
        filterSummaryString.push('Hide unknown dates');
    }

    // Handle square footage
    if (searchFilter.minSqft && !searchFilter.maxSqft) {
        filterSummaryString.push('>' + searchFilter.minSqft + ' sq ft');
    } else if (!searchFilter.minSqft && searchFilter.maxSqft) {
        filterSummaryString.push('<' + searchFilter.maxSqft + ' sq ft');
    } else if (searchFilter.minSqft && searchFilter.maxSqft) {
        filterSummaryString.push(searchFilter.minSqft + ' – ' + searchFilter.maxSqft + ' sq ft');
    }

    // Format listing freshness
    if (searchFilter.maxCreated) {
        let freshness;

        switch (searchFilter.maxCreated) {
            case 720:
                freshness = 'last 30 days';
                break;
            case 168:
                freshness = 'last 7 days';
                break;
            case 72:
                freshness = 'last 3 days';
                break;
            case 24:
                freshness = 'last 24 hours';
                break;
            case 12:
                freshness = 'last 12 hours';
                break;
            default:
                freshness = 'last hour';
                break;
        }
        filterSummaryString.push(freshness);
    }

    // Format listing types
    let listingTypes = searchFilter.listingTypes;
    if (isEqual(listingTypes.sort(), DEPRECATED_Filter.allowedConstants.defaultListingTypes)) {
        listingTypes = '';
    } else if (listingTypes.length === 1) {
        filterSummaryString.push(listingTypes[0]);
    } else {
        filterSummaryString.push('listing type: ' + listingTypes.length + ' selected');
    }

    // Handle special restrictions
    if (searchFilter.incomeRestricted) {
        filterSummaryString.push('income restricted');
    }
    if (searchFilter.seniorHousing) {
        filterSummaryString.push('senior housing');
    }
    if (searchFilter.studentHousing) {
        filterSummaryString.push('student housing');
    }
    if (searchFilter.militaryHousing) {
        filterSummaryString.push('military housing');
    }

    // Format keywords
    if (searchFilter.keywords) {
        filterSummaryString.push('contains: ' + searchFilter.keywords);
    }

    // Format photos required
    if (searchFilter.minPhotos > 0) {
        filterSummaryString.push('photos');
    }

    // Format promo
    if (searchFilter.hasSpecialOffers) {
        filterSummaryString.push('offers');
    }

    return filterSummaryString.join(' • ');
};

export const filterUtils_getChangedKeys = (oldObj, newObj, prefix = '') => {
    const changedKeys = [];

    for (const key in oldObj) {
        if (Object.prototype.hasOwnProperty.call(oldObj, key)) {
            const oldValue = oldObj[key];
            const newValue = newObj[key];
            const newPrefix = prefix ? `${prefix}.${key}` : key;

            if (typeof oldValue === 'object' && oldValue !== null && !Array.isArray(oldValue)) {
                // Recursively compare nested objects
                changedKeys.push(...filterUtils_getChangedKeys(oldValue, newValue || {}, newPrefix));
            } else if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
                changedKeys.push(newPrefix);
            }
        }
    }

    for (const key in newObj) {
        if (Object.prototype.hasOwnProperty.call(newObj, key) && !(key in oldObj)) {
            changedKeys.push(prefix ? `${prefix}.${key}` : key);
        }
    }

    return changedKeys;
};

export const filterUtils_sanitizeQueryForFilter = (query = {}) => {
    // return query without map params
    const mapParams = ['lat', 'lon', 'z', 'border'];
    const sanitizedQuery = assign({}, query);
    mapParams.forEach((mapParam) => delete sanitizedQuery[mapParam]);

    return sanitizedQuery;
};
export const filterUtils_getPropertyTypeFromFilterDescription = (description = '') => {
    if (!description) {
        return 'apartments';
    }

    const propertyType = description.split(' for ');
    return propertyType[0].toLowerCase();
};
export const filterUtils_getSearchSlugFromListing = ({ propertyType }) => {
    const propertyTypeToRentalSearchSlug = {
        house: 'houses-for-rent',
        condo: 'condos-for-rent',
        townhouse: 'houses-for-rent',
        corporate: 'apartments-for-rent'
    };

    if (propertyType && propertyTypeToRentalSearchSlug[propertyType]) {
        return propertyTypeToRentalSearchSlug[propertyType];
    }

    return 'apartments-for-rent';
};
export const filterUtils_isDefaultFilterValue = ({ filter, filterKeys }) => {
    const defaultFilter = filterUtils_defaultFilter;
    const filterDiff = filterUtils_getFilterDiff({ baseFilter: defaultFilter, editedFilter: filter });
    let isDefaultFilterValue = true;
    filterKeys.forEach((key) => {
        if (key in filterDiff) {
            isDefaultFilterValue = false;
        }
    });

    return isDefaultFilterValue;
};
export const filterUtils_isStudioApartment = (bedrooms = []) => {
    return bedrooms.length === 1 && bedrooms[0] === '0';
};
export const filterUtils_getRenamedPropertyTypes = (propertyTypes) => {
    let result = propertyTypes.map((item) => {
        if (item === 'divided') {
            return 'duplex';
        }
        return item;
    });

    const apartmentRemoved = result.filter((propertyType) => {
        return propertyType !== 'garden' && propertyType !== 'large' && propertyType !== 'medium';
    });
    const containsApartment = result.length !== apartmentRemoved.length;

    return containsApartment ? ['apartment'].concat(apartmentRemoved) : result;
};
export const filterUtils_getFilterDiff = ({ baseFilter, editedFilter, excludeFilters = [] }) => {
    let filterTabs = {};
    const filterKeys = omit(
        baseFilter,
        ['orderBy', 'ascending', 'currentPage', 'feeds', 'visible', 'searchSlug'],
        excludeFilters
    );
    for (let key in filterKeys) {
        if (!isEqual(baseFilter[key], editedFilter[key])) {
            if (key === 'minSqft' || key === 'maxSqft') {
                filterTabs.sqft = assign({}, filterTabs.sqft, { [key]: editedFilter[key] });
            } else if (key === 'lowPrice' || key === 'highPrice') {
                filterTabs.price = assign({}, filterTabs.price, { [key]: editedFilter[key] });
            } else if (key === 'startOfAvailabilityDate' || key === 'endOfAvailabilityDate') {
                filterTabs.avail = assign({}, filterTabs.avail, { [key]: editedFilter[key] });
            } else if (
                key === 'militaryHousing' ||
                key === 'studentHousing' ||
                key === 'incomeRestricted' ||
                key === 'seniorHousing'
            ) {
                const restrictions = filterTabs.restrictions || [];
                filterTabs.restrictions = restrictions.concat({ [key]: editedFilter[key] });
            } else {
                filterTabs[key] = editedFilter[key];
            }
        }
    }
    return filterTabs;
};
export const filterUtils_excludeFromFilterTabs = (key) => {
    const excludedFilters = ['commuteTime', 'commuteTimeMode', 'commuteMode', 'commuteLats', 'commuteLons'];

    if (excludedFilters.indexOf(key) > -1) {
        return true;
    }
};
export const DEPRECATED_filterUtils_getFilterLabels = (key, value = false) => {
    switch (key) {
        case 'amenities':
            return filterUtils_getAmenitiesLabel(value);
        case 'avail':
            return filterUtils_getRenamedAvailabilityDates(value);
        case 'bathrooms':
            return filterUtils_getBathsLabel(value);
        case 'bedrooms':
            return filterUtils_getBedsLabel(value);
        case 'furnished':
            return filterUtils_getRenamedFurnishedLabel(value);
        case 'hasSpecialOffers':
            return filterUtils_getHasSpecialOffersLabel(value);
        case 'hideUnknownAvailabilityDate':
            return filterUtils_getRenamedUnknownDates(value);
        case 'includeVaguePricing':
            return filterUtils_getIncludeVaguePricingLabel(value);
        case 'isAcceptingRentalApplications':
            return filterUtils_getIsAcceptingRentalApplicationsLabel(value);
        case 'isListedByOwner':
            return filterUtils_getIsListedByOwner(value);
        case 'keywords':
            return filterUtils_getKeywordsLabel(value);
        case 'laundry':
            return filterUtils_getLaundryLabel(value);
        case 'listingTypes':
            return filterUtils_getListingTypesLabel(value);
        case 'maxCreated':
            return filterUtils_getMaxCreatedLabel(value);
        case 'minPhotos':
            return filterUtils_getMinPhotosRequiredLabel(value);
        case 'pets':
            return filterUtils_getPetsLabel(value);
        case 'price':
            return filterUtils_getPriceLabel(value);
        case 'propertyTypes':
            return filterUtils_getPropertyLabelV2(value);
        case 'restrictions':
            return filterUtils_getRestrictionsLabel(value);
        case 'sqft':
            return filterUtils_getSqftLabel(value);
        default:
            return value;
    }
};
export const filterUtils_getFilterSummaryDiff = ({ baseFilter, editedFilter, excludeFilters = [] }) => {
    let filterTabs = {};
    const filterKeys = omit(
        baseFilter,
        ['orderBy', 'ascending', 'currentPage', 'feeds', 'visible', 'searchSlug'],
        excludeFilters
    );
    for (let key in filterKeys) {
        if (!isEqual(baseFilter[key], editedFilter[key])) {
            if (key === 'minSqft' || key === 'maxSqft') {
                filterTabs.sqft = assign({}, filterTabs.sqft, { [key]: editedFilter[key] });
            } else if (key === 'lowPrice' || key === 'highPrice') {
                filterTabs.price = assign({}, filterTabs.price, { [key]: editedFilter[key] });
            } else if (
                key === 'startOfAvailabilityDate' ||
                key === 'endOfAvailabilityDate' ||
                key === 'hideUnknownAvailabilityDate'
            ) {
                filterTabs.avail = assign({}, filterTabs.avail, { [key]: editedFilter[key] });
            } else if (
                key === 'militaryHousing' ||
                key === 'studentHousing' ||
                key === 'incomeRestricted' ||
                key === 'seniorHousing'
            ) {
                const restrictions = filterTabs.restrictions || [];
                filterTabs.restrictions = restrictions.concat({ [key]: editedFilter[key] });
            } else if (key === 'furnished') {
                filterTabs.amenities = assign({}, filterTabs.amenities, { furnished: editedFilter.furnished });
            } else if (key === 'amenities') {
                filterTabs.amenities = assign({}, filterTabs.amenities, editedFilter.amenities);
            } else if (
                key === 'includeVaguePricing' ||
                key === 'isAcceptingRentalApplications' ||
                key === 'hasSpecialOffers' ||
                key === 'minPhotos'
            ) {
                filterTabs.additionalOptions = assign({}, filterTabs.additionalOptions, {
                    [key]: editedFilter[key]
                });
            } else {
                filterTabs[key] = editedFilter[key];
            }
        }
    }
    return filterTabs;
};
export const filterUtils_getFilterSummaryVal = ({ key, value }) => {
    switch (key) {
        case 'amenities':
        case 'furnished':
            return { label: 'Amenities', filterVal: filterUtils_getAmenitiesSummary(value) };
        case 'additionalOptions':
            return {
                label: 'Additional Options',
                filterVal: filterUtils_getAdditionalOptionsSummary(value)
            };
        case 'avail':
            return { label: 'Move-in', filterVal: filterUtils_getAvailabilityDatesSummary(value) };
        case 'bathrooms':
            return { label: 'Baths', filterVal: filterUtils_getBathsLabel(value) };
        case 'bedrooms':
            return { label: 'Beds', filterVal: filterUtils_getBedsLabel(value) };
        case 'keywords':
            return { label: 'Keywords', filterVal: value };
        case 'laundry':
            return { label: 'Laundry', filterVal: filterUtils_getLaundrySummary(value) };
        case 'listingTypes':
            return {
                label: 'Rental type',
                filterVal: filterUtils_getListingTypesSummary(value)
            };
        case 'maxCreated':
            return { label: 'Created within', filterVal: filterUtils_getMaxCreatedLabel(value) };
        case 'pets':
            return { label: 'Pets', filterVal: filterUtils_getPetsLabel(value) };
        case 'price':
            return { label: 'Price', filterVal: filterUtils_getPriceSummary(value) };
        case 'propertyTypes':
            return { label: 'Looking for', filterVal: filterUtils_getPropertySummary(value) };
        case 'restrictions':
            return { label: 'Restrictions', filterVal: filterUtils_getRestrictionsSummary(value) };
        case 'sqft':
            return { label: 'Sqft', filterVal: filterUtils_getSqftLabel(value) };
        default:
            return { label: key, filterVal: value };
    }
};
export const filterUtils_getAdditionalOptionsSummary = (options) => {
    let additionalOptions = [];
    if (options.minPhotos > 0) {
        additionalOptions.push(filterUtils_getMinPhotosRequiredLabel(options.minPhotos));
    }

    if (options.includeVaguePricing) {
        additionalOptions.push(filterUtils_getIncludeVaguePricingLabel(options.includeVaguePricing));
    }

    if (options.hasSpecialOffers) {
        additionalOptions.push(filterUtils_getHasSpecialOffersLabel(options.hasSpecialOffers));
    }

    if (options.isAcceptingRentalApplications) {
        additionalOptions.push(
            filterUtils_getIsAcceptingRentalApplicationsLabel(options.isAcceptingRentalApplications)
        );
    }

    return additionalOptions.join(', ');
};
export const filterUtils_getPropertySummary = (propertyTypes) => {
    const renamedPropertyTypes = filterUtils_getRenamedPropertyTypes(propertyTypes);
    const propertyTypesList = renamedPropertyTypes.map((type) => {
        return formatter.string.firstCaps(type);
    });

    return propertyTypesList.join(', ');
};
export const filterUtils_getPriceSummary = ({ highPrice, lowPrice }) => {
    var priceLabel = 'Any price';
    var parsedLowPrice = lowPrice ? parseFloat(lowPrice) : 0;
    var parsedHighPrice = highPrice ? parseFloat(highPrice) : null;

    if (!parsedLowPrice && !parsedHighPrice) {
        priceLabel = 'Any price';
    } else if (parsedLowPrice > 0 && !parsedHighPrice) {
        priceLabel = 'Greater than $' + numberUtils.compact(parsedLowPrice);
    } else if (!parsedLowPrice && parsedHighPrice > 0) {
        priceLabel = 'Less than $' + numberUtils.compact(parsedHighPrice);
    } else if (parsedLowPrice > parsedHighPrice) {
        priceLabel = 'Price error!';
    } else {
        priceLabel = '$' + numberUtils.compact(parsedLowPrice) + ' – $' + numberUtils.compact(parsedHighPrice);
    }
    return priceLabel;
};
export const filterUtils_getAmenitiesSummary = (amenities) => {
    const amenitiesList = [];

    forEach(amenities, (amenityVal, amenityName) => {
        amenitiesList.push(filterUtils_getRenamedAmenities(amenityName));
    });

    return amenitiesList.join(', ');
};
export const filterUtils_getLaundrySummary = (laundryOptions) => {
    const laundryOptionsList = [];

    for (let option in laundryOptions) {
        if (laundryOptions[option]) {
            laundryOptionsList.push(
                filterUtils_getRenamedLaundryOptions(Array.isArray(laundryOptions) ? laundryOptions[option] : option)
            );
        }
    }

    return laundryOptionsList.join(', ');
};
export const filterUtils_getAvailabilityDatesSummary = ({ endOfAvailabilityDate, hideUnknownAvailabilityDate }) => {
    const avail = [];
    if (endOfAvailabilityDate) {
        avail.push(dateUtils.formatDateMmddyy(endOfAvailabilityDate));
    }
    if (hideUnknownAvailabilityDate) {
        avail.push(filterUtils_getRenamedUnknownDates(hideUnknownAvailabilityDate));
    }
    return avail.join(', ');
};
export const filterUtils_getRestrictionsSummary = (restrictions) => {
    const TOTAL_NUM_RESTRICTIONS = 4;
    const filterRestriction = restrictions.filter((restriction) => {
        return values(restriction)[0] === true;
    });
    const hideFilterRestriction = restrictions.filter((restriction) => {
        return values(restriction)[0] === false;
    });

    if (filterRestriction.length > 0) {
        const restrictedKey = Object.keys(filterRestriction[0])[0];
        const restrictionWords = formatter.string.splitCamelCaseToLowerCaseWords(restrictedKey);
        return formatter.string.upperFirstLowerRest(restrictionWords);
    } else if (hideFilterRestriction.length === TOTAL_NUM_RESTRICTIONS) {
        return 'Hide all restricted housing';
    }
};
export const filterUtils_getListingTypesSummary = (listingTypes) => {
    let listingTypesSummary = [];
    listingTypes.forEach((type) => {
        if (type === 'rental') {
            listingTypesSummary.push('Regular');
        } else if (type === 'room') {
            listingTypesSummary.push('Rooms for rent');
        } else {
            listingTypesSummary.push(`${formatter.string.firstCaps(type)}`);
        }
    });

    return listingTypesSummary.join(', ');
};
export const filterUtils_getIsAcceptingRentalApplicationsLabel = (isAcceptingRentalApplications) => {
    if (isAcceptingRentalApplications) {
        return 'Accepting HotPads applications';
    } else {
        return 'All properties';
    }
};
export const filterUtils_getHasSpecialOffersLabel = (promo) => {
    if (promo) {
        return 'Offers';
    } else {
        return 'Properties without offers';
    }
};
export const filterUtils_getIsListedByOwner = (isListedByOwner) => {
    return isListedByOwner ? 'Listed by owner' : 'Is not listed by owner';
};
export const filterUtils_getDefaultFilterVal = (key) => {
    const defaultValue = filterUtils_defaultFilter[key];
    // making an assumption here that lowPrice/highPrice, etc. exist
    if (key === 'price') {
        const { lowPrice, highPrice } = filterUtils_defaultFilter;
        return { lowPrice, highPrice };
    } else if (key === 'sqft') {
        const { minSqft, maxSqft } = filterUtils_defaultFilter;
        return { minSqft, maxSqft };
    } else if (key === 'restrictions') {
        const { incomeRestricted, militaryHousing, studentHousing, seniorHousing } = filterUtils_defaultFilter;
        return { incomeRestricted, militaryHousing, studentHousing, seniorHousing };
    } else if (key === 'avail') {
        const { startOfAvailabilityDate, endOfAvailabilityDate, hideUnknownAvailabilityDate } =
            filterUtils_defaultFilter;
        return { startOfAvailabilityDate, endOfAvailabilityDate, hideUnknownAvailabilityDate };
    } else if (!isUndefined(defaultValue)) {
        return { [key]: defaultValue };
    } else {
        logger.warn(`Unhandled key "${key}" passed into filterUtils#getDefaultFilterVal`);
        return false;
    }
};
export const filterUtils_getPriceLabel = ({ highPrice, lowPrice }) => {
    var priceLabel = 'Any price';
    var parsedLowPrice = lowPrice ? parseFloat(lowPrice) : 0;
    var parsedHighPrice = highPrice ? parseFloat(highPrice) : null;

    if (!parsedLowPrice && !parsedHighPrice) {
        priceLabel = 'Any price';
    } else if (parsedLowPrice > 0 && !parsedHighPrice) {
        priceLabel = '> $' + numberUtils.compact(parsedLowPrice);
    } else if (!parsedLowPrice && parsedHighPrice > 0) {
        priceLabel = '< $' + numberUtils.compact(parsedHighPrice);
    } else if (parsedLowPrice > parsedHighPrice) {
        priceLabel = 'Price error!';
    } else {
        priceLabel = '$' + numberUtils.compact(parsedLowPrice) + ' – $' + numberUtils.compact(parsedHighPrice);
    }
    return priceLabel;
};
export const filterUtils_getSqftLabel = ({ maxSqft, minSqft }) => {
    var sqftLabel = 'Any square footage';
    var parsedMinSqft = minSqft ? parseFloat(minSqft) : 0;
    var parsedMaxSqft = maxSqft ? parseFloat(maxSqft) : null;

    if (!parsedMinSqft && !parsedMaxSqft) {
        sqftLabel = 'Any square footage';
    } else if (parsedMinSqft > 0 && !parsedMaxSqft) {
        sqftLabel = 'More than ' + numberUtils.compact(parsedMinSqft) + ' sqft';
    } else if (!parsedMinSqft && parsedMaxSqft > 0) {
        sqftLabel = 'Less than ' + numberUtils.compact(parsedMaxSqft) + ' sqft';
    } else if (parsedMinSqft > parsedMaxSqft) {
        sqftLabel = 'Square footage error!';
    } else {
        sqftLabel = numberUtils.compact(parsedMinSqft) + ' – ' + numberUtils.compact(parsedMaxSqft) + ' sqft';
    }
    return sqftLabel;
};
export const filterUtils_getMaxCreatedLabel = (maxCreated) => {
    if (maxCreated <= 24) {
        return maxCreated <= 1 ? 'last hour' : `${maxCreated} hours`;
    } else {
        return `${maxCreated / 24} days`;
    }
};
export const filterUtils_getPropertyLabel = (propertyTypes) => {
    const renamedPropertyTypes = filterUtils_getRenamedPropertyTypes(propertyTypes);
    return renamedPropertyTypes.length < 2 ? formatter.string.firstCaps(renamedPropertyTypes[0]) : 'Property types';
};
export const filterUtils_getPropertyLabelV2 = (propertyTypes) => {
    const renamedPropertyTypes = filterUtils_getRenamedPropertyTypes(propertyTypes);
    if (renamedPropertyTypes.length < 2) {
        return formatter.string.firstCaps(renamedPropertyTypes[0]);
    } else {
        return `Property types • ${renamedPropertyTypes.length} selected`;
    }
};
export const filterUtils_getRenamedAvailabilityDates = ({ startOfAvailabilityDate, endOfAvailabilityDate }) => {
    const from = dateUtils.formatDateMmddyy(startOfAvailabilityDate);
    const to = dateUtils.formatDateMmddyy(endOfAvailabilityDate);
    return `Available ${from} to ${to}`;
};
export const filterUtils_getRenamedFurnishedLabel = () => {
    return 'Furnished';
};
export const filterUtils_getRenamedUnknownDates = (hideUnknownDates) => {
    if (hideUnknownDates) {
        return 'Hide unknown dates';
    }

    return;
};
export const filterUtils_getRenamedAmenities = (amenity, useLowercase = false) => {
    const AMENITY_NAMES = {
        heating: 'Heating',
        cooling: 'A/C',
        parking: 'Parking',
        doorman: 'Doorman',
        gatedEntry: 'Gated entry',
        swimmingPool: 'Pool',
        dishwasher: 'Dishwasher',
        fitnessCenter: 'Gym',
        furnished: 'Furnished'
    };

    let renamedAmenity = AMENITY_NAMES[amenity];
    if (useLowercase && amenity !== 'cooling') {
        renamedAmenity = renamedAmenity.toLowerCase();
    }
    return renamedAmenity;
};
export const filterUtils_getAmenitiesLabel = (amenities) => {
    const amenitiesLength = Object.keys(amenities).length;
    const amenitiesList = [];

    if (amenitiesLength >= 3) {
        return `Amenities • ${amenitiesLength} selected`;
    }

    for (let amenity in amenities) {
        if (amenities[amenity] && amenitiesLength < 3) {
            amenitiesList.push(filterUtils_getRenamedAmenities(amenity));
        }
    }

    return amenitiesList.join(', ');
};
export const filterUtils_getRenamedLaundryOptions = (option) => {
    const LAUNDRY_OPTION_NAMES = {
        shared: 'Shared',
        inUnit: 'In-unit'
    };

    return LAUNDRY_OPTION_NAMES[option];
};
export const filterUtils_getLaundryLabel = (laundryOptions) => {
    const laundryOptionsList = [];

    for (let option of laundryOptions) {
        laundryOptionsList.push(filterUtils_getRenamedLaundryOptions(option));
    }

    return `Laundry • ${laundryOptionsList.join(', ')}`;
};
export const filterUtils_getPetsLabel = (pets = []) => {
    var petsLabel = 'Pets';

    if (includes(pets, 'dogs') && includes(pets, 'cats')) {
        petsLabel = 'Dogs, Cats';
    } else if (includes(pets, 'dogs')) {
        petsLabel = 'Dogs';
    } else if (includes(pets, 'cats')) {
        petsLabel = 'Cats';
    }
    return petsLabel;
};
export const filterUtils_getBedsLabel = (bedrooms) => {
    var allBeds = 'All beds';
    var minBeds = parseInt(bedrooms[0]);
    var maxBeds = parseInt(last(bedrooms));
    var maxBedsDisplay = maxBeds === 8 ? maxBeds + '+' : maxBeds;

    if (minBeds === 0 && maxBeds === 8) {
        return allBeds;
    } else if (minBeds === maxBeds) {
        if (minBeds === 0) {
            return 'Studio';
        } else {
            return `${minBeds} bed${minBeds > 1 ? 's' : ''}`;
        }
    } else if (maxBeds === 8) {
        return `${minBeds}+ beds`;
    } else if (minBeds > maxBeds) {
        return 'Bed error!';
    } else {
        return `${minBeds === 0 ? 'Studio' : minBeds} – ${maxBedsDisplay} bed${maxBeds > 1 ? 's' : ''}`;
    }
};
export const filterUtils_getBathsLabel = (bathrooms) => {
    var allBaths = 'all baths';
    var minBaths = parseFloat(bathrooms[0]);
    var maxBaths = parseFloat(last(bathrooms));
    var maxBathsDisplay = maxBaths === 8 ? maxBaths + '+' : maxBaths;

    if (minBaths === 0 && maxBaths === 8) {
        return allBaths;
    } else if (minBaths === maxBaths) {
        return `${minBaths} bath${minBaths > 1 ? 's' : ''}`;
    } else if (maxBaths === 8) {
        return `${minBaths}+ baths`;
    } else if (minBaths > maxBaths) {
        return 'Bath error!';
    } else {
        return `${minBaths} – ${maxBathsDisplay} bath${maxBaths > 1 ? 's' : ''}`;
    }
};
export const filterUtils_getIncludeVaguePricingLabel = (includeVaguePricing) => {
    if (includeVaguePricing) {
        return 'Price not required';
    } else {
        return 'Price required';
    }
};
export const filterUtils_getMinPhotosRequiredLabel = (minPhotos: number) => {
    if (minPhotos > 0) {
        return 'Photos required';
    } else {
        return 'Photos not required';
    }
};
export const filterUtils_getKeywordsLabel = (keywords: string = '') => {
    if (!keywords) {
        return '';
    }

    const splitKeywords = keywords.split(',');
    if (splitKeywords.length > 1) {
        return `Keywords • ${splitKeywords.length} selected`;
    } else {
        return formatter.string.firstCaps(splitKeywords[0]);
    }
};
export const filterUtils_getListingTypesLabel = (listingTypes) => {
    if (listingTypes.length > 1) {
        return `Rental type • ${listingTypes.length} selected`;
    } else if (listingTypes[0] === 'rental') {
        return 'Regular rentals';
    } else if (listingTypes[0] === 'room') {
        return 'Rooms for rent';
    } else {
        return `${formatter.string.firstCaps(listingTypes[0])} rentals`;
    }
};
export const filterUtils_getRestrictionsLabel = (restrictions) => {
    const TOTAL_NUM_RESTRICTIONS = 4;
    const filterRestriction = restrictions.filter((restriction) => {
        return values(restriction)[0] === true;
    });
    const hideFilterRestriction = restrictions.filter((restriction) => {
        return values(restriction)[0] === false;
    });

    if (filterRestriction.length === 1) {
        const restrictedKey = Object.keys(filterRestriction[0])[0];
        const restrictionWords = formatter.string.splitCamelCaseToLowerCaseWords(restrictedKey);
        return formatter.string.upperFirstLowerRest(restrictionWords);
    } else if (hideFilterRestriction.length === TOTAL_NUM_RESTRICTIONS) {
        return 'Hide all restricted housing';
    } else {
        return `Restricted housing • ${hideFilterRestriction.length} hidden`;
    }
};
export const filterUtils_dedupeFilterValues = (filterValue) => {
    if (Array.isArray(filterValue)) {
        return filterValue[0];
    } else {
        return filterValue;
    }
};
export const filterUtils_getNearbyPropertyCounts = ({ nearby, area, filter }) => {
    let nearbyAreas = [];
    let nearbyArea = {};
    let areaType = area.type;
    let listingCountsForAreaType;
    let count = 0;
    let numAreasGathered = 0;
    let indexAt = 0;

    if (!nearby[areaType]) {
        return [];
    }

    while (numAreasGathered < NEARBY_AREAS_TO_GET && indexAt < nearby[areaType].length) {
        listingCountsForAreaType = nearby[areaType][indexAt].listingCounts;
        count = listingCountsForAreaType[filter.searchSlug];
        nearbyArea = {};

        if (count > 0) {
            nearbyArea.name = nearby[areaType][indexAt].name;
            nearbyArea.count = count;
            nearbyArea.uri = nearby[areaType][indexAt].uriV2;
            nearbyArea.area = nearby[areaType][indexAt];
            nearbyAreas.push(nearbyArea);
            numAreasGathered++;
        }

        indexAt++;
    }

    return nearbyAreas;
};
export const filterUtils_getPropertyForRent = (area) => {
    if (area) {
        return {
            apartmentsForRent: '/' + area.resourceId + '/apartments-for-rent',
            housesForRent: '/' + area.resourceId + '/houses-for-rent',
            condosForRent: '/' + area.resourceId + '/condos-for-rent',
            townhomesForRent: '/' + area.resourceId + '/townhomes-for-rent'
        };
    }

    return { apartmentsForRent: '#', housesForRent: '#' };
};
export const filterUtils_userSetFilter = (filter = {}) => {
    return !isEmpty(
        filterUtils_getFilterDiff({
            baseFilter: filterUtils_defaultFilter,
            editedFilter: filter
        })
    );
};
export const filterUtils_filterToQueryObj = (_filter) => {
    var filter = DEPRECATED_Filter.create(_filter);
    var queryObj = {};
    var defaultFilterObj = filterUtils_defaultFilter;
    var delimiter = '-';

    if (filter.amenities) {
        const amenities = filter.amenities;
        if (isString(amenities)) {
            queryObj.amenities = amenities.split(',').join(delimiter);
        } else {
            const isAmenityTriggered = (amenity) => amenities[amenity] === true;
            const amenityMap = Object.keys(amenities).filter((amenity) => isAmenityTriggered(amenity));

            queryObj.amenities = amenityMap.sort().join(delimiter);
        }
    }

    if (filter.lowPrice || filter.highPrice) {
        if (filter.lowPrice && !filter.highPrice) {
            queryObj.price = filter.lowPrice;
        } else if (filter.highPrice) {
            queryObj.price = [filter.lowPrice || 0, filter.highPrice].join(delimiter);
        }
    }

    // this supports min-max
    if (filter.bedrooms && !isEqual(filter.bedrooms, defaultFilterObj.bedrooms)) {
        if (filter.bedrooms.length < 2) {
            queryObj.beds = filter.bedrooms[0];
        } else {
            queryObj.beds = [filter.bedrooms[0], filter.bedrooms[filter.bedrooms.length - 1]].join(delimiter);
        }
    }

    if (filter.bathrooms && !isEqual(filter.bathrooms, defaultFilterObj.bathrooms)) {
        if (filter.bathrooms.length < 2) {
            queryObj.baths = filter.bathrooms[0];
        } else {
            queryObj.baths = [filter.bathrooms[0], filter.bathrooms[filter.bathrooms.length - 1]].join(delimiter);
        }
    }

    if (filter.propertyTypes) {
        if (!isEqual(filter.propertyTypes.sort(), defaultFilterObj.propertyTypes.sort())) {
            queryObj.propertyTypes = filter.propertyTypes.join(delimiter);
        }
    }

    if (filter.laundry) {
        if (!includes(filter.laundry, 'any')) {
            queryObj.laundry = filter.laundry.join(delimiter);
        }
    }

    if (filter.listingTypes) {
        if (!isEqual(filter.listingTypes.sort(), defaultFilterObj.listingTypes.sort())) {
            queryObj.listingTypes = filter.listingTypes.join(delimiter);
        }
    }

    if (filter.pets) {
        if (!includes(filter.pets, 'any')) {
            queryObj.pets = filter.pets.join(delimiter);
        }
    }

    if (filter.minSqft || filter.maxSqft) {
        if (filter.minSqft && !filter.maxSqft) {
            queryObj.sqft = filter.minSqft;
        } else if (filter.maxSqft) {
            queryObj.sqft = [filter.minSqft || 0, filter.maxSqft].join(delimiter);
        }
    }

    if (filter.minPhotos) {
        queryObj.photos = filter.minPhotos;
    }

    if (filter.keywords) {
        queryObj.keywords = filter.keywords;
    }

    if (filter.maxCreated) {
        queryObj.maxCreated = filter.maxCreated;
    }

    if (filter.orderBy && filter.orderBy !== defaultFilterObj.orderBy && filter.orderBy !== 'experimentScore') {
        queryObj.orderBy = filter.orderBy;
    }

    if (!filter.includeVaguePricing) {
        queryObj.includeVaguePricing = false;
    }

    if (filter.feeds) {
        queryObj.feeds = filter.feeds;
    }

    if (filter.visible && !isEqual(filter.visible.sort(), defaultFilterObj.visible.sort())) {
        queryObj.visible = filter.visible.join(delimiter);
    }

    if (filter.incomeRestricted || filter.incomeRestricted === false) {
        queryObj.incomeRestricted = filter.incomeRestricted;
    }

    if (filter.militaryHousing || filter.militaryHousing === false) {
        queryObj.militaryHousing = filter.militaryHousing;
    }

    if (filter.seniorHousing || filter.seniorHousing === false) {
        queryObj.seniorHousing = filter.seniorHousing;
    }

    if (filter.studentHousing || filter.studentHousing === false) {
        queryObj.studentHousing = filter.studentHousing;
    }

    if (filter.currentPage && filter.currentPage > 1) {
        queryObj.page = filter.currentPage;
    }

    if (filter.hasSpecialOffers) {
        queryObj.promo = filter.hasSpecialOffers;
    }

    if (filter.isAcceptingRentalApplications) {
        queryObj.applicationsOk = filter.isAcceptingRentalApplications;
    }

    if (filter.startOfAvailabilityDate || filter.endOfAvailabilityDate) {
        queryObj.avail = [filter.startOfAvailabilityDate, filter.endOfAvailabilityDate].join('to');
    }

    if (filter.furnished) {
        queryObj.furnished = filter.furnished;
    }

    if (filter.hideUnknownAvailabilityDate) {
        queryObj.hideUnkAvail = filter.hideUnknownAvailabilityDate;
    }

    if (filter.isListedByOwner) {
        queryObj.isListedByOwner = filter.isListedByOwner;
    }

    if (filter.acceptsSection8) {
        queryObj.acceptsSection8 = filter.acceptsSection8;
    }

    // HPWEB-5474: Search by Commute A/B test
    if (filter.commuteMode) {
        queryObj.commuteMode = filter.commuteMode;
    }

    if (filter.commuteLats) {
        queryObj.commuteLats = filter.commuteLats;
    }

    if (filter.commuteLons) {
        queryObj.commuteLons = filter.commuteLons;
    }

    if (filter.commuteTime) {
        queryObj.commuteTime = filter.commuteTime;
    }

    if (filter.commuteTimeMode) {
        queryObj.commuteTimeMode = filter.commuteTimeMode;
    }

    return queryObj;
};

// Used in mWeb SRPs
const _LABEL_MAP = {
    price: (v) => LabelMaker.price(v),
    bedrooms: (v) => LabelMaker.bedrooms(v),
    bathrooms: (v) => LabelMaker.bathrooms(v),
    commute: (v) => LabelMaker.commute(v),
    pets: (v) => LabelMaker.pets(v),
    availability: (v) => LabelMaker.availability(v),
    laundry: (v) => LabelMaker.laundry(v),
    amenities: (v) => LabelMaker.amenities(v),
    furnished: () => LabelMaker.furnished(),
    propertyTypes: (v) => LabelMaker.propertyTypes(v),
    sqft: (v) => LabelMaker.sqft(v),
    createdWithin: (v) => LabelMaker.createdWithin(v),
    rentalTypes: (v) => LabelMaker.rentalTypes(v),
    restrictions: (v) => LabelMaker.restrictions(v),
    keywords: (v) => LabelMaker.keywords(v),
    additionalOpts: (v) => LabelMaker.additionalOpts(v)
};

export const filterUtils_getActiveFilters = (currentFilter, excludedCategories = []) => {
    const omittedKeys = [...excludedCategories, 'orderBy', 'search', 'NON_USER_FACING'];
    const activeFilters = reduce(
        currentFilter,
        (result, value, key) => {
            // Ignore orderBy, NON_USER_FACING
            if (omittedKeys.includes(key)) {
                return result;
            }

            if (key === 'commute' && value.commuteTime === null) {
                return result;
            }

            return isEqual(value, DEFAULT[key]) ? result : result.concat(key);
        },
        []
    );

    return activeFilters;
};

export const filterUtils_hasActiveFilters = (currentFilter) => {
    const hasActiveFilters = Boolean(filterUtils_getActiveFilters(currentFilter).length > 0);

    return hasActiveFilters;
};

export const filterUtils_getNumberOfActiveFilters = (currentFilter, excludedCategories) => {
    const activeCategories = filterUtils_getActiveFilters(currentFilter, excludedCategories);
    const numberOfAppliedFilters =
        activeCategories &&
        activeCategories.reduce((filtersApplied, currentValue) => {
            const curr = currentFilter[currentValue];
            // Cases that require special filter number treatment: Commute, Furnished, Keywords
            const filterRequiresSpecialTreatment =
                (currentValue === 'furnished' && curr === true) ||
                (currentValue === 'keywords' && curr.length > 0) ||
                currentValue === 'commute';
            if (filterRequiresSpecialTreatment) {
                filtersApplied += 1;
                return filtersApplied;
            }
            const identifiers = Object.keys(curr);

            const active = identifiers.filter((subcat) => currentFilter[currentValue][subcat]);

            filtersApplied = filtersApplied + active.length;
            return filtersApplied;
        }, 0);

    return numberOfAppliedFilters;
};

export const filterUtils_getFilterLabels = (currentFilter) => {
    const activeFilters = filterUtils_getActiveFilters(currentFilter);

    const labels = activeFilters.map((label) => {
        const filterLabel = _LABEL_MAP[label] && _LABEL_MAP[label](currentFilter[label]);
        if (filterLabel) {
            return filterLabel;
        }

        return null;
    });
    const validLabels = labels.filter((label) => label);
    return validLabels.join(', ');
};

export const filterUtils_getFilterPillLabels = (currentFilter) => {
    const activeFilters = filterUtils_getActiveFilters(currentFilter);

    const labels = activeFilters.map((filterKey) => {
        const filterLabel = _LABEL_MAP[filterKey] && _LABEL_MAP[filterKey](currentFilter[filterKey]);

        if (filterLabel) {
            // Return tuple
            return [filterKey, filterLabel];
        }

        return null;
    });

    // Tuple is returned
    return labels.filter((label) => label);
};

export const filterUtils_mapDataIsDifferent = ({ mapData, query }) => {
    if (Number(mapData.zoom) !== Number(query.z)) {
        return true;
    } else if (Number(mapData.lat) !== Number(query.lat)) {
        return true;
    } else if (Number(mapData.lon) !== Number(query.lon)) {
        return true;
    } else if (mapData.border !== query.border) {
        return true;
    } else {
        return false;
    }
};

export const filterUtils_mergeMapDataIntoQuery = ({ mapData, query }) => {
    const newQueryObj = Object.assign({}, query);

    // HPWEB-2850: Map can unexpectedly revert to original zoom / location
    // if min/max lat/ on from query params are present in URL. After getting
    // new map data, delete them from newQueryObj as they aren't needed.
    delete newQueryObj.minLat;
    delete newQueryObj.minLon;
    delete newQueryObj.maxLat;
    delete newQueryObj.maxLon;

    // should use either MapData or MapDataQueryObj, or better consolidate those so we just have 1.....
    newQueryObj.z = Number(mapData.zoom);
    newQueryObj.lat = Number(mapData.lat).toFixed(4);
    newQueryObj.lon = Number(mapData.lon).toFixed(4);
    if (mapData.border === false) {
        newQueryObj.border = false;
    }
    return newQueryObj;
};

export const filterUtils_hasFilterChanged = (currentFilter, oldFilter = null) => {
    if (!oldFilter) {
        return !isEqual(currentFilter, DEFAULT);
    }

    return !isEqual(currentFilter, oldFilter);
};
