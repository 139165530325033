// @ts-nocheck
/* eslint-enable */
import React from 'react';
import loadable from '@loadable/component';

import PageLoadingIndicator from 'app/shared/modules/PageLoadingIndicator';

/* eslint-disable react/no-multi-comp */
export const HeaderFooterTemplate = loadable(
    () =>
        import(
            /* webpackChunkName: "HeaderFooterTemplate.loadable" */ 'app/shared/templates/HeaderFooterTemplate/index.tsx'
        ),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const UserDashboardTemplate = loadable(
    () =>
        import(
            /* webpackChunkName: "UserDashboardTemplate.loadable" */ 'app/shared/templates/UserDashboardTemplate/index.tsx'
        ),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const UserSettingsPage = loadable(
    () => import(/* webpackChunkName: "UserSettingsPage.loadable" */ 'app/shared/pages/UserSettingsPage/index.tsx'),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const UserRenterProfilePage = loadable(
    () =>
        import(
            /* webpackChunkName: "UserRenterProfilePage.loadable" */ 'app/shared/pages/UserRenterProfilePage/index.tsx'
        ),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const SearchResultsPage = loadable(
    () => import(/* webpackChunkName: "SearchResultsPage.loadable" */ 'app/shared/pages/SearchResultsPage/index.tsx'),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const SplitMapTemplate = loadable(
    () => import(/* webpackChunkName: "SplitMapTemplate.loadable" */ 'app/shared/templates/SplitMapTemplate/index.tsx'),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const LoginPage = loadable(
    () => import(/* webpackChunkName: "LoginPage.loadable" */ 'app/shared/pages/LoginPage/index.tsx'),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const LogoutPage = loadable(
    () => import(/* webpackChunkName: "LogoutPage.loadable" */ 'app/shared/pages/LogoutPage/index.tsx'),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const MobileAppsPage = loadable(
    () => import(/* webpackChunkName: "MobileAppsPage.loadable" */ 'app/shared/pages/MobileAppsPage/index.tsx'),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const UserDashboardPage = loadable(
    () => import(/* webpackChunkName: "UserDashboardPage.loadable" */ 'app/shared/pages/UserDashboardPage/index.tsx'),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const UserEditPage = loadable(
    () => import(/* webpackChunkName: "UserEditPage.loadable" */ 'app/shared/pages/UserEditPage/index.tsx'),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const UnsubscribePage = loadable(
    () => import(/* webpackChunkName: "UnsubscribePage.loadable" */ 'app/shared/pages/UnsubscribePage/index.tsx'),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const ReportListingAsUnavailablePage = loadable(
    () =>
        import(
            /* webpackChunkName: "ReportListingAsUnavailablePage.loadable" */ 'app/shared/pages/ReportListingAsUnavailablePage/index.tsx'
        ),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const CreatePasswordPage = loadable(
    () => import(/* webpackChunkName: "CreatePasswordPage.loadable" */ 'app/shared/pages/CreatePasswordPage/index.tsx'),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const AlertsView = loadable(
    () => import(/* webpackChunkName: "AlertsView.loadable" */ 'app/shared/modules/user-items/AlertsView/index.tsx'),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const MyHubPage = loadable(
    () => import(/* webpackChunkName: "MyHubPage.loadable" */ 'app/shared/pages/MyHubPage/index.tsx'),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const MyListPage = loadable(
    () => import(/* webpackChunkName: "MyListPage.loadable" */ 'app/shared/pages/MyListPage/index.tsx'),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const ForgotPasswordPage = loadable(
    () => import(/* webpackChunkName: "ForgotPasswordPage.loadable" */ 'app/shared/pages/ForgotPasswordPage/index.tsx'),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const AboutUsPage = loadable(
    () => import(/* webpackChunkName: "AboutUsPage.loadable" */ 'app/shared/pages/AboutUsPage/index.tsx'),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const ListYourRentalPage = loadable(
    () => import(/* webpackChunkName: "ListYourRentalPage.loadable" */ 'app/shared/pages/ListYourRentalPage/index.tsx'),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const StatePage = loadable(
    () => import(/* webpackChunkName: "StatePage.loadable" */ 'app/shared/pages/StatePage/index.tsx'),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const AnalyticsPage = loadable(
    () => import(/* webpackChunkName: "AnalyticsPage.loadable" */ 'app/shared/pages/AnalyticsPage/index.tsx'),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const FeedbackSuccessPage = loadable(
    () =>
        import(/* webpackChunkName: "FeedbackSuccessPage.loadable" */ 'app/shared/pages/FeedbackSuccessPage/index.tsx'),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const PropertyReviewGuidelinesPage = loadable(
    () =>
        import(
            /* webpackChunkName: "PropertyReviewGuidelinesPage.loadable" */ 'app/shared/pages/PropertyReviewGuidelinesPage/index.tsx'
        ),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const FetchAreaWrapper = loadable(
    () => import(/* webpackChunkName: "FetchAreaWrapper.loadable" */ 'app/shared/wrappers/FetchAreaWrapper/index.tsx'),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const FetchListingWrapper = loadable(
    () =>
        import(
            /* webpackChunkName: "FetchListingWrapper.loadable" */ 'app/shared/wrappers/FetchListingWrapper/index.tsx'
        ),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const SitemapPage = loadable(
    () => import(/* webpackChunkName: "SitemapPage.loadable" */ 'app/shared/pages/SitemapPage/index.tsx'),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const NotFoundPage = loadable(
    () => import(/* webpackChunkName: "NotFoundPage.loadable" */ 'app/shared/pages/NotFoundPage/index.tsx'),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const MobileChoicePage = loadable(
    () => import(/* webpackChunkName: "MobileChoicePage.loadable" */ 'app/shared/pages/MobileChoicePage/index.tsx'),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const FrontPage = loadable(
    () => import(/* webpackChunkName: "FrontPage.loadable" */ 'app/shared/pages/FrontPage/index.tsx'),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const FreepassRedirectPage = loadable(
    () =>
        import(
            /* webpackChunkName: "FreepassRedirectPage.loadable" */ 'app/shared/pages/FreepassRedirectPage/index.tsx'
        ),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const NetPromoterScorePage = loadable(
    () =>
        import(
            /* webpackChunkName: "NetPromoterScorePage.loadable" */ 'app/shared/pages/NetPromoterScorePage/index.tsx'
        ),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const CcpaOptOutPage = loadable(
    () => import(/* webpackChunkName: "CcpaOptOutPage.loadable" */ 'app/shared/pages/CcpaOptOutPage/index.tsx'),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const UnsubscribePropertyUpdatePage = loadable(
    () =>
        import(
            /* webpackChunkName: "UnsubscribePropertyUpdatePage.loadable" */ 'app/shared/pages/UnsubscribePropertyUpdatePage/index.tsx'
        ),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const UnsubscribeSearchesPage = loadable(
    () =>
        import(
            /* webpackChunkName: "UnsubscribeSearchesPage.loadable" */ 'app/shared/pages/UnsubscribeSearchesPage/index.tsx'
        ),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const ReactivateSearchesPage = loadable(
    () =>
        import(
            /* webpackChunkName: "ReactivateSearchesPage.loadable" */ 'app/shared/pages/ReactivateSearchesPage/index.tsx'
        ),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const EditSearchFrequencyPage = loadable(
    () =>
        import(
            /* webpackChunkName: "EditSearchFrequencyPage.loadable" */ 'app/shared/pages/EditSearchFrequencyPage/index.tsx'
        ),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const UpdatePasswordPage = loadable(
    () => import(/* webpackChunkName: "UpdatePasswordPage.loadable" */ 'app/shared/pages/UpdatePasswordPage/index.tsx'),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const UserItemsPage = loadable(
    () => import(/* webpackChunkName: "UserItemsPage.loadable" */ 'app/shared/pages/UserItemsPage/index.tsx'),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const SsrTracePage = loadable(
    () => import(/* webpackChunkName: "SsrTracePage.loadable" */ 'app/shared/pages/SsrTracePage/index.tsx'),
    {
        fallback: <PageLoadingIndicator />
    }
);

export const IpCheckPage = loadable(
    () => import(/* webpackChunkName: "IpCheckPage.loadable" */ 'app/shared/pages/IpCheckPage/index.tsx'),
    {
        fallback: <PageLoadingIndicator />
    }
);
