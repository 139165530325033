// @ts-nocheck
/* eslint-enable */
var forEach = require('lodash/forEach');

/***** Copy Pated Block *******/
/**
 * True if val contains an invalid field-vchar
 *  field-value    = *( field-content / obs-fold )
 *  field-content  = field-vchar [ 1*( SP / HTAB ) field-vchar ]
 *  field-vchar    = VCHAR / obs-text
 **/
function checkInvalidHeaderChar(val) {
    val = String(val);
    for (var i = 0; i < val.length; i++) {
        var ch = val.charCodeAt(i); //Changed const to var, since const didn't update in node v4.4
        if (ch === 9) {
            continue;
        }
        if (ch <= 31 || ch > 255 || ch === 127) {
            return true;
        }
    }
    return false;
}
/***** End of Copy Pated Block *******/

//Reverse the logic to keep interface consistent
function validHeaderValue(val) {
    return !checkInvalidHeaderChar(val);
}

//Same logic as checkInvalidHeaderChar, which removes all invalid chars
function cleanHeaderValue(val) {
    val = String(val);
    var cleanVal = '';
    for (var i = 0; i < val.length; i++) {
        var ch = val.charCodeAt(i);
        if (ch === 9 || ch <= 31 || ch > 255 || ch === 127) {
            continue;
        } else {
            cleanVal += val[i];
        }
    }
    return cleanVal;
}

export const createCookieString = (cookieObj = {}) => {
    let cookies = {};

    Object.keys(cookieObj).forEach((key) => {
        if (!validHeaderValue(cookieObj[key])) {
            cookies[key] = cleanHeaderValue(cookieObj[key]);
        } else {
            cookies[key] = cookieObj[key];
        }
    });

    let cookieString = '';

    forEach(cookies, (val, key) => {
        cookieString += `${key}=${val};`;
    });

    return cookieString;
};
