import numberUtils from 'app/shared/utils/numberUtils';

interface UserPointProps {
    id?: string;
    created?: number;
    name?: string;
    address?: string;
    lat?: number;
    lon?: number;
    active?: boolean;
}

class UserPoint {
    id: string;
    created: number;
    name: string;
    address: string;
    lat: number;
    lon: number;
    active: boolean;

    constructor({ id = '', created = 0, name = '', address = '', lat = 0, lon = 0, active = false }: UserPointProps = {}) {
        // API provides id, and created fields on request.
        // API requires address field when creating new user point.
        this.id = id;
        this.created = created;
        this.name = name;
        this.address = address ? address : name; // Duplicate name if address empty.
        this.lat = numberUtils.trimDecimal(lat);
        this.lon = numberUtils.trimDecimal(lon);
        this.active = active;
    }
}

export default UserPoint;