// @ts-nocheck
/* eslint-enable */
import { getFeatureFlagsFromURL } from 'app/shared/utils/featureFlagUtils';
import * as uaV1 from './conditionalImportsV1'; // Legacy GA
import * as uaV2 from './conditionalImportsV2'; // Clickstream SDK

// Temp wrapper until Clickstream is live
// Wrapper for conditional logic
function getAnalytics() {
  // Hard code for now to enable Clickstream UA on production!
  // If we need to rollback (or roll forward), set enableForProduction to false for legacy analytics behavior.
  const enableForProduction = true;
  let enableZgUa = false;

  // Once we validate that Clickstream dual tagging is working, we can remove these hacky conditional checks.
  if (enableForProduction) {
    enableZgUa = true;
  } else {
    enableZgUa = __DEV__ || getFeatureFlagsFromURL().zgUa;

    // hostname check is a temporary hack to enable data science testing on comet1 or in dev environments:
    // note: this runs on ssr, so we need to check that window exists, eventually this will be removed
    // once clickstream is live.
    if (typeof window !== 'undefined' && !enableZgUa) {
      enableZgUa = window.location.hostname === 'comet1.testpads.net';
    }
  }


  if (typeof window !== 'undefined' && enableZgUa) {
    window.zgUaVersion = 'v2: zg/universal-analytics (clickstream)';
    return uaV2;
  } else if (typeof window !== 'undefined' && !enableZgUa) {
    window.zgUaVersion = 'v1: zg/zanalytics (legacy)';
  }

  return uaV1;
}

// Export a consistent API
export const analyticsEvent = (...args) => getAnalytics().analyticsEvent(...args);
export const areaPageView = (...args) => getAnalytics().areaPageView(...args);
export const initialize = (...args) => getAnalytics().initialize(...args);
export const listingPageView = (...args) => getAnalytics().listingPageView(...args);
export const pageView = (...args) => getAnalytics().pageView(...args);
