// @ts-nocheck
/* eslint-enable */
import styled from 'styled-components';
import { viewports } from 'app/shared/styles/_breakpoints';
import { sidePadding16Px } from 'app/shared/styles/_mixins';

export const Container = styled.div`
    ${sidePadding16Px}
    margin: auto;
    position: relative;

    ${(props) =>
        props.size &&
        props.size === 'sm' &&
        `
        max-width: 320px;
    `}

    ${(props) =>
        !props.size ||
        (props.size === 'md' &&
            `
        max-width: 920px;
    `)}

    ${(props) =>
        props.size &&
        props.size === 'lg' &&
        `
        max-width: 1200px;
    `}

    ${(props) =>
        props.size &&
        props.size === 'full' &&
        `
        max-width: none;
    `}

    ${(props) =>
        props.slimPadding &&
        `
        @media ${viewports['xl-and-up']} {
            padding: 0px 8px;
        }
    `}
`;
