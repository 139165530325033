import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { viewports } from 'app/shared/styles/_breakpoints';
import React from 'react';
import { fontSize } from 'app/shared/styles/_fontsize';
import { ResponsiveProp } from 'app/types/index';

const TitleSm: FlattenSimpleInterpolation = css`
    font-size: ${fontSize['$font-size-sm']};
    font-weight: bold;
    line-height: ${fontSize['$line-height-tiny']};
`;

const TitleMd: FlattenSimpleInterpolation = css`
    font-size: ${fontSize['$font-size-md']};
    font-weight: bold;
    line-height: ${fontSize['$line-height-sm']};
`;

const TitleLg: FlattenSimpleInterpolation = css`
    font-size: ${fontSize['$font-size-lg']};
    font-weight: bold;
    line-height: ${fontSize['$line-height-lg']};
`;

const TitleXl: FlattenSimpleInterpolation = css`
    font-size: ${fontSize['$font-size-xl']};
    font-weight: bold;
    line-height: ${fontSize['$line-height-xl']};
`;

const TitleHero: FlattenSimpleInterpolation = css`
    font-size: ${fontSize['$font-size-xl']};
    font-weight: bold;
    line-height: ${fontSize['$line-height-xl']};

    @media ${viewports['md-and-up']} {
        font-size: ${fontSize['$font-size-hero']};
        line-height: ${fontSize['$line-height-hero']};
    }
`;

const TITLE_SIZE = {
    sm: TitleSm,
    md: TitleMd,
    lg: TitleLg,
    xl: TitleXl,
    hero: TitleHero
};

const MD_RESPONSIVE_SIZE = {
    sm: TitleSm,
    md: TitleMd,
    lg: TitleLg
};

const LG_RESPONSIVE_SIZE = {
    sm: TitleSm,
    md: TitleMd,
    lg: TitleLg,
    xl: TitleXl
};

interface TitleProps {
    className?: string;
    children: React.ReactNode;
    size?: keyof typeof TITLE_SIZE;
    responsive?: ResponsiveProp;
}

export const Title = styled.h1<TitleProps>`
    ${(props: TitleProps) => props.size && TITLE_SIZE[props.size]}
    ${(props: TitleProps) =>
        props.responsive &&
        props.responsive.mdAndUp &&
        `
        @media ${viewports['md-and-up']} {
            ${MD_RESPONSIVE_SIZE[props.responsive.mdAndUp as keyof typeof MD_RESPONSIVE_SIZE]};
        }
    `}
    ${(props: TitleProps) =>
        props.responsive &&
        props.responsive.lgAndUp &&
        `
        @media ${viewports['lg-and-up']} {
            ${LG_RESPONSIVE_SIZE[props.responsive.lgAndUp as keyof typeof MD_RESPONSIVE_SIZE]};
        }
    `}
`;
