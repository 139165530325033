// @ts-nocheck
/* eslint-enable */
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import isFinite from 'lodash/isFinite';
import lodashFilter from 'lodash/filter';
import map from 'lodash/map';
import maximum from 'lodash/max';
import minimum from 'lodash/min';
import reduce from 'lodash/reduce';
import uniq from 'lodash/uniq';

import SummaryV2 from 'app/shared/models/SummaryV2';
import numberUtils from 'app/shared/utils/numberUtils';
import formatter from 'app/shared/utils/formatter';

const logger = getLogger('utils/listing');

export const listingUtils_isApartmentBldg = (listingType, propertyType) => {
    return (
        listingType === 'rental' && (propertyType === 'large' || propertyType === 'garden' || propertyType === 'medium')
    );
};

export const listingUtils_formatPropertyType = (propertyType) => {
    if (propertyType === 'large' || propertyType === 'garden' || propertyType === 'medium') {
        return 'Apt building';
    } else if (propertyType === 'divided') {
        return 'Duplex';
    } else {
        return formatter.string.capitalizeFirstLetter(propertyType);
    }
};

export const listingUtils_getIconType = (propertyType) => {
    let iconType;
    if (propertyType === 'large' || propertyType === 'medium' || propertyType === 'garden') {
        iconType = 'building';
    } else if (propertyType === 'condo' || propertyType === 'divided') {
        iconType = 'condo';
    } else if (propertyType === 'house' || propertyType === 'townhouse' || propertyType === 'land') {
        iconType = 'house';
    } else {
        iconType = 'house';
    }
    return iconType;
};

export const listingUtils_getEffectivePropertyType = (propertyType) => {
    if (propertyType === 'large' || propertyType === 'medium' || propertyType === 'garden') {
        return 'Apartment building';
    } else if (propertyType === 'townhouse') {
        return 'Townhouse';
    } else if (propertyType === 'divided') {
        return 'Duplex';
    } else if (propertyType === 'condo') {
        return 'Condo';
    } else if (propertyType === 'house') {
        return 'House';
    } else if (propertyType === 'land') {
        return 'Land';
    } else if (propertyType === 'room') {
        return 'Room';
    } else {
        return propertyType;
    }
};

// Alternatively, this is a handy way to determine whether something is a longtail
// listing. const isLongtail = listingUtils_isMultifamilyType(propertyType) === false
export const listingUtils_isMultifamilyType = (propertyType) => {
    const MfTypes = ['garden', 'large', 'medium'];

    return includes(MfTypes, propertyType);
};

export const listingUtils_outOfRange = (min, max) => {
    if (min === Infinity || !(min >= 0)) {
        return true;
    }
    if (max === Infinity || !(max >= 0)) {
        return true;
    }

    return false;
};

export const listingUtils_sqftDisplay = (min, max) => {
    min = Number(min);
    max = Number(max);
    if (listingUtils_outOfRange(min, max)) {
        return '';
    }

    if (min === 0 || max === 0) {
        return '';
    }

    if (min > max) {
        let temp = min;
        min = max;
        max = temp;
    }

    if (min !== max) {
        return `${min} to ${max} sqft`;
    } else {
        return `${min} sqft`;
    }
};
export const listingUtils_bathDisplay = (min, max, type, customSeperator) => {
    min = Number(min);
    max = Number(max);
    if (listingUtils_outOfRange(min, max)) {
        return '';
    }

    if (min === 0 && max === 0) {
        return '';
    }

    const seperator = customSeperator ?? (type === 'compact' ? '-' : 'to');

    if (min !== max) {
        return type === 'compact' ? `${min}${seperator}${max} ba` : `${min} ${seperator} ${max} baths`;
    } else if (min === 1) {
        return type === 'compact' ? `${min} ba` : `${min} bath`;
    } else {
        return type === 'compact' ? `${min} ba` : `${min} baths`;
    }
};

export const listingUtils_bedDisplay = (min, max, type, customSeperator) => {
    min = Number(min);
    max = Number(max);

    const seperator = customSeperator ?? (type === 'compact' ? '-' : 'to');

    if (listingUtils_outOfRange(min, max)) {
        return '';
    }

    if (min === 0) {
        if (max === 0) {
            return 'Studio';
        }
        return type === 'compact' ? `Studio${seperator}${max} bd` : `Studio ${seperator} ${max} beds`;
    }

    if (min > max) {
        let temp = min;
        min = max;
        max = temp;
    }

    if (min !== max) {
        return type === 'compact' ? `${min}${seperator}${max} bd` : `${min} ${seperator} ${max} beds`;
    } else if (min === 1) {
        return type === 'compact' ? `${min} bd` : `${min} bed`;
    } else {
        return type === 'compact' ? `${min} bd` : `${min} beds`;
    }
};

// TODO: displayAsRange is for ListingCardV2 a/b testing - remove it once done.
export const listingUtils_getPriceSummaryDisplay = (minPrice, maxPrice, displayAsRange = false) => {
    let display = 'Call';

    if (minPrice > 0 && minPrice < Infinity) {
        // Use minPrice
        display = '$' + numberUtils.compact(minPrice);
        if (maxPrice < Infinity && minPrice < maxPrice) {
            display += displayAsRange ? ' - $' + numberUtils.compact(maxPrice) : '+';
        }
    } else if (maxPrice > 0 && maxPrice < Infinity) {
        // Use maxPrice
        display = '$' + numberUtils.compact(maxPrice);
    }

    return display;
};

export const listingUtils_modelBeds = (beds, listingType) => {
    if (listingType === 'land') {
        return '-- beds';
    } else if (beds === 0) {
        return 'Studio';
    } else if (beds === 1) {
        return '1 bed';
    } else if (beds === '8plus') {
        return '8+ beds';
    } else {
        return beds + ' beds';
    }
};

export const listingUtils_displayAllAvailableBeds = (models) => {
    if (!models || models.length === 0) {
        return '-- bedrooms';
    }

    let availableBedsSet = new Set();
    let isEightPlusBedsAvailable = false;

    models.forEach((model) => {
        if (model.beds === '8plus') {
            isEightPlusBedsAvailable = true;
            availableBedsSet.add(8);
        } else {
            availableBedsSet.add(model.beds);
        }
    });

    const sortedAvailableBedsArray = Array.from(availableBedsSet).sort();
    let index = 0;
    let availableBedsString = '';

    sortedAvailableBedsArray.forEach((beds) => {
        const isAtLastIndex = index === sortedAvailableBedsArray.length - 1;
        let bedsString = beds;

        if (beds === 0) {
            bedsString = 'Studio';
        } else if (beds === 8 && isEightPlusBedsAvailable) {
            bedsString = '8+';
        }

        if (sortedAvailableBedsArray.length === 1) {
            availableBedsString = `${bedsString} bedrooms`;
        } else if (sortedAvailableBedsArray.length === 2) {
            availableBedsString += index === 0 ? `${bedsString}` : ` and ${bedsString} bedrooms`;
        } else if (isAtLastIndex) {
            availableBedsString += `and ${bedsString} bedrooms`;
        } else {
            const isSecondToLastIndex = index === sortedAvailableBedsArray.length - 2;
            availableBedsString += `${bedsString}${isSecondToLastIndex ? ' ' : ', '}`;
        }

        index++;
        return availableBedsString;
    });

    return availableBedsString;
};

export const listingUtils_bedRange = (minBeds, maxBeds) => {
    const low = minBeds === 0 ? 'Studio' : minBeds;
    const high = maxBeds === 0 ? 'Studio' : maxBeds;
    const range = [low, high].filter((val) => Boolean(val));
    if (!range.length) {
        return 'Call';
    }
    if (range.length === 1) {
        return range[0];
    }
    if (low === high) {
        return low;
    }
    return `${low} - ${high}`;
};

export const listingUtils_bathSqFtRange = (min, max) => {
    const low = min ?? null;
    const high = max ?? null;
    const range = [low, high].filter((val) => val !== null);
    if (!range.length) {
        return 'Call';
    }
    if (range.length === 1) {
        return range[0];
    }
    if (low === high) {
        return low;
    }
    return `${low} - ${high}`;
};

export const listingUtils_modelBaths = (baths) => {
    if (!baths) {
        return '-- baths';
    } else if (baths === '8plus') {
        return '8+ baths';
    } else if (baths < 2) {
        return baths + ' bath';
    } else {
        return baths + ' baths';
    }
};
export const listingUtils_modelSqft = (modelSqft) => {
    if (!modelSqft) {
        return '-- sqft';
    } else {
        return numberUtils.compact(modelSqft) + ' sqft';
    }
};
export const listingUtils_checkFavorite = (listing) => {
    var favorited = false;

    if (listing.userItemTypes) {
        favorited = includes(listing.userItemTypes, 'favorite');
    }
    return favorited;
};
export const listingUtils_checkViewed = (userItemTypes) => {
    if (userItemTypes) {
        return userItemTypes.includes('viewed');
    }

    return false;
};
export const listingUtils_require = (obj, arr) => {
    var valid = true;

    forEach(arr, (a) => {
        if (!get(obj, a)) {
            valid = false;
        }
    });
    return valid;
};
export const listingUtils_sortModelsByBeds = (floorplans = []) => {
    const floorplansCopy = [...floorplans];
    floorplansCopy.sort((a, b) => {
        if (a.beds !== b.beds) {
            return a.beds - b.beds;
        } else {
            if (a.type === 'CALL' && b.type !== 'CALL') {
                // Sort 'CALL' types toward the end
                return 1;
            } else if (b.type === 'CALL' && a.type !== 'CALL') {
                return -1;
            } else if ((a.type === 'RANGE' || a.type === 'FLAT') && (b.type === 'RANGE' || b.type === 'FLAT')) {
                // 'RANGE' or 'FLAT', sort by price
                return a.low - b.low;
            } else {
                return 0;
            }
        }
    });

    return floorplansCopy;
};
export const listingUtils_areaTypeLegend = {
    zip: 'Zip Code',
    neighborhood: 'Neighborhood',
    city: 'City',
    county: 'County',
    state: 'State',
    primaryschool: 'Primary School',
    middleschool: 'Middle School',
    highschool: 'High School',
    mixedschool: 'Mixed School',
    unifschdist: 'Unified School District'
};
export const listingUtils_schoolTypeLegend = {
    primaryschool: 'Primary School',
    middleschool: 'Middle School',
    highschool: 'High School',
    mixedschool: 'Mixed School',
    university: 'University'
};
export const listingUtils_districtTypeLegend = {
    unifschdist: 'Unified School District',
    secschdist: 'Seconday School District',
    elemschdist: 'Elementary School District'
};
export const listingUtils_getAmenitiesForMetaDescription = (listing) => {
    // 160 chars MAX on meta description
    if (!listing) {
        return '';
    }

    const hasAmenityHighlights = listing?.amenities?.highlights?.length > 0;
    const MAX_NUM_OF_HIGHLIGHTS = 3;
    const AMENITY_META_DESCRIPTION = {
        pets: 'pet friendly',
        laundry: 'laundry',
        parking: 'parking',
        hvac: 'AC',
        gym: 'gym',
        pool: 'pool',
        security: 'security',
        appliances: 'appliances'
    };

    if (hasAmenityHighlights) {
        const amenityHighlights = listing.amenities.highlights;
        const slicedHighlights =
            amenityHighlights.length > MAX_NUM_OF_HIGHLIGHTS
                ? amenityHighlights.slice(0, MAX_NUM_OF_HIGHLIGHTS)
                : amenityHighlights;

        const amenitiesForMetaDescription = slicedHighlights.reduce((accumulator, currentAmenity, currentIdx) => {
            const isFirstAmenity = currentIdx === 0;
            const isParkingAmenity = currentAmenity.id === 'parking';
            const isApplianceAmenity = currentAmenity.id === 'appliances';

            if (isParkingAmenity || isApplianceAmenity) {
                const hasDetailedType = currentAmenity?.types?.length > 0;

                if (hasDetailedType) {
                    if (isParkingAmenity) {
                        const formattedParkingType = isFirstAmenity
                            ? `${formatter.string.capitalizeFirstLetter('parking')} ${currentAmenity.types[0]}`
                            : `parking ${currentAmenity.types[0].toLowerCase()}`;
                        accumulator.push(formattedParkingType);
                    } else if (isApplianceAmenity) {
                        const hasSecondApplianceType = Boolean(currentAmenity?.types?.[1]);
                        const formattedApplianceType = isFirstAmenity
                            ? `${formatter.string.capitalizeFirstLetter(currentAmenity.types[0])}${hasSecondApplianceType ? `, ${currentAmenity.types[1].toLowerCase()}` : ''
                            }`
                            : `${currentAmenity.types[0].toLowerCase()}${hasSecondApplianceType ? `, ${currentAmenity.types[1].toLowerCase()}` : ''
                            }`;
                        accumulator.push(formattedApplianceType);
                    }

                    return accumulator;
                }
            }
            const amenityMetaDescription =
                AMENITY_META_DESCRIPTION[currentAmenity.id] || currentAmenity.display.toLowerCase();
            const formattedAmenity = isFirstAmenity
                ? formatter.string.capitalizeFirstLetter(amenityMetaDescription)
                : amenityMetaDescription;

            accumulator.push(formattedAmenity);

            return accumulator;
        }, []);

        return `${amenitiesForMetaDescription.join(', ')}, & more.`;
    }

    return null;
};
export const listingUtils_getListingMetaDescription = (listing) => {
    if (!listing) {
        return '';
    }

    const { address, displayName, units, propertyType, trusted, active, building } = listing;
    const isApartmentBldg = listingUtils_isApartmentBldg('rental', propertyType);
    const capitalizedPropertyType =
        trusted || isApartmentBldg ? 'Apartment' : formatter.string.capitalizeFirstLetter(propertyType);
    let locationPortion = address.hideStreet ? `${address.city}, ${address.state} ${address.zip}` : address.street;
    let metaDescription = '';
    if (!active) {
        metaDescription =
            `${capitalizedPropertyType} at ` +
            `${locationPortion}${locationPortion[locationPortion.length - 1] === '.' ? '' : '.'} ` +
            `View historic property details, photos, street view and search nearby properties on the largest and ` +
            `most trusted rental site.`;
    } else if (trusted) {
        metaDescription = `See apartments for rent at ${displayName} ` + `located at ${locationPortion}.`;

        const orderedAmenities = listingUtils_getAmenitiesForMetaDescription(listing, metaDescription.length);

        // See apartments for rent at <Building Name> located at <street address>. <amenities list>.
        metaDescription =
            `See apartments for rent at ${displayName} ` +
            `located at ${locationPortion}.` +
            `${orderedAmenities
                ? ` ${orderedAmenities}`
                : ' View property details from the largest and most trusted rental site.'
            }`;
    } else if (building) {
        metaDescription =
            `Check out the ${units.length} rentals available at this multi-unit ` +
            `condo building located at ` +
            `${locationPortion}${locationPortion[locationPortion.length - 1] === '.' ? '' : '.'} ` +
            `View detailed property information and get real-time updates.`;
    } else {
        metaDescription =
            `${capitalizedPropertyType} for rent at ${locationPortion}` +
            `${locationPortion.length === '.' ? '' : '.'} View property details, photos, street view, and ` +
            `get real-time updates with the largest and most trusted rental site.`;
    }

    return metaDescription;
};
export const listingUtils_getContactEmail = (user, userInquiryDataCache) => {
    let email;

    email = userInquiryDataCache.email || user.info.email || '';

    if (typeof email === 'string' && includes(email, 'facebookuser+')) {
        email = '';
    }

    return email;
};
export const listingUtils_truncateListingUpdated = (timeElapsed) => {
    const time = timeElapsed.split(',');
    return time.length > 1 ? `${time[0]} ago` : time[0];
};
export const listingUtils_getResponseTime = (responseTime) => {
    const hours = responseTime / 3600000;
    if (hours <= 1) {
        return 'within an hour';
    } else if (hours < 8) {
        return 'within a few hours';
    } else if (hours < 120) {
        return 'within a few days';
    }

    return null;
};
export const listingUtils_getListingTimes = (listingHistory) => {
    const nonEmptyDates = reduce(
        listingHistory,
        (result, value, key) => {
            if (value.date && key !== 'lastSynced') {
                result[key] = value;
            }

            return result;
        },
        {}
    );

    return {
        postedTime: listingUtils_getPostedTime(nonEmptyDates),
        updatedTime: listingUtils_getUpdatedTime(nonEmptyDates)
    };
};
export const listingUtils_getPostedTime = (nonEmptyDates = {}) => {
    if (nonEmptyDates.activated && nonEmptyDates.created && nonEmptyDates.activated.date > nonEmptyDates.created.date) {
        return nonEmptyDates.activated;
    } else if (nonEmptyDates.created) {
        return nonEmptyDates.created;
    } else {
        return null;
    }
};
export const listingUtils_getUpdatedTime = (nonEmptyDates = {}) => {
    // not enough info
    if (!nonEmptyDates.lastUpdated) {
        return null;
    }

    if (
        nonEmptyDates.activated &&
        listingUtils_truncateListingUpdated(nonEmptyDates.lastUpdated.ago) ===
        listingUtils_truncateListingUpdated(nonEmptyDates.activated.ago)
    ) {
        return null;
    } else if (
        nonEmptyDates.created &&
        listingUtils_truncateListingUpdated(nonEmptyDates.lastUpdated.ago) ===
        listingUtils_truncateListingUpdated(nonEmptyDates.created.ago)
    ) {
        return null;
    } else {
        return nonEmptyDates.lastUpdated;
    }
};
export const listingUtils_pricingText = (priceObj, pricingType, numberDisplay) => {
    if (!priceObj.priceLow && !priceObj.priceHigh) {
        return 'Call';
    } else if (priceObj.priceLow === priceObj.priceHigh) {
        return `$${numberUtils[numberDisplay](priceObj.priceLow)}`;
    } else if (!priceObj.priceLow) {
        return `$${numberUtils[numberDisplay](priceObj.priceHigh)}`;
    } else {
        return `$${numberUtils[numberDisplay](priceObj.priceLow)}+`;
    }
};

export const listingUtils_summaryPriceText = (priceObj) => {
    if (!priceObj.priceLow && !priceObj.priceHigh) {
        return 'Call';
    }

    const prices = uniq(
        [priceObj.priceLow, priceObj.priceHigh].filter((price) => Boolean(price)),
        true
    );

    if (prices.length === 1) {
        return `$${numberUtils.compact(prices[0])}`;
    } else {
        return `$${numberUtils.compact(prices[0])} - $${numberUtils.compact(prices[1])}`;
    }
};
export const listingUtils_getAvailabilityDisplay = (listing = {}, floorplan = {}) => {
    if (!listing.active) {
        return 'No longer available';
    }
    if (!floorplan.availabilityDate) {
        return '';
    }

    const availDateArray = floorplan.availabilityDate.split('-');
    const month = parseInt(availDateArray[1]);
    const day = parseInt(availDateArray[2]);

    return `Available ${month}/${day}`;
};
export const listingUtils_hasBedPricing = (pricing, bed) => {
    return Boolean(pricing[bed]) && isFinite(pricing[bed].priceLow) && isFinite(pricing[bed].priceHigh);
};
export const listingUtils_validBeds = (pricing) => {
    return lodashFilter(pricing, (beds, key) => {
        if (key === 'summary') {
            return false;
        }

        return beds && isFinite(beds.priceLow) && isFinite(beds.priceHigh);
    });
};
export const listingUtils_createThreePlusSummary = (pricing) => {
    const ThreePlusBeds = {};
    const ThreePlusBedsSummary = {};

    forEach(pricing, (bedSummary, key) => {
        if (key >= 3) {
            ThreePlusBeds[key] = bedSummary;
        }
    });

    const maxBeds = maximum(
        map(Object.keys(ThreePlusBeds), (bedNum) => {
            return Number(bedNum);
        })
    );
    const minBeds = minimum(
        map(Object.keys(ThreePlusBeds), (bedNum) => {
            return Number(bedNum);
        })
    );

    ThreePlusBedsSummary.bedsLow = Number(minBeds);
    ThreePlusBedsSummary.bedsHigh = Number(maxBeds);
    ThreePlusBedsSummary.priceLow = ThreePlusBeds[minBeds].priceLow;
    ThreePlusBedsSummary.priceHigh = ThreePlusBeds[maxBeds].priceHigh;

    return ThreePlusBedsSummary;
};
export const listingUtils_shouldShowPpcLink = (listingDetails = {}, device) => {
    // Disable PPC links on mobile devices unless url contains mobileFriendly=true
    if (
        listingDetails.webSiteIsPayPerClick &&
        (device.deviceType !== 'mobile' || includes(listingDetails.webSite, 'mobileFriendly=true'))
    ) {
        return true;
    } else {
        return false;
    }
};
export const listingUtils_getCondensedModelsAndPricing = (floorplans = []) => {
    /**
     * Creates a condensed version of `floorplans` for the
     * SimilarListingsContainer component to consume
     *
     * modelsAndPricing: [
     *     {
     *         numBeds: number,
     *         lowPrice: number,
     *         highPrice: number
     *     }
     * ]
     */
    const tempMap = {};

    const floorplanMap = reduce(
        floorplans,
        (obj, plan) => {
            const currentNumBeds = Number(plan.beds);
            const currentLowPrice = Number(plan.low);
            const currentHighPrice = Number(plan.high);

            try {
                if (!obj[currentNumBeds]) {
                    obj[currentNumBeds] = {
                        numBeds: currentNumBeds,
                        lowPrice: currentLowPrice,
                        highPrice: currentHighPrice,
                        quantity: plan.units ? plan.units.length : 1
                    };
                } else {
                    obj[currentNumBeds].lowPrice = obj[currentNumBeds].lowPrice
                        ? Math.min(obj[currentNumBeds].lowPrice, currentLowPrice)
                        : currentLowPrice;
                    obj[currentNumBeds].highPrice = obj[currentNumBeds].highPrice
                        ? Math.max(obj[currentNumBeds].highPrice, currentHighPrice)
                        : currentHighPrice;
                    obj[currentNumBeds].quantity += plan.units ? plan.units.length : 1;
                }
            } catch (e) {
                logger.error({
                    errorLocation: 'listingUtils_getCondensedModelsAndPricing.floorplanMap',
                    message: `The following error was seen "${e}" on floorplan id ${plan.modelId}, ${plan.beds} bed, ${plan.baths} baths`
                });
            }

            return obj;
        },
        tempMap
    );

    return map(floorplanMap, (obj) => {
        return obj;
    });
};
export const listingUtils_getCondensedAmenities = (highlightedAmenities) => {
    /**
     * Creates a condensed version of `highlightedAmenities` for the
     * SimilarListingsContainer, PetPolicy, and Hdp R4R components to consume
     *
     * condensedAmenities: {
     *     pets: string,
     *     laundry: string,
     *     security: string,
     *     airConditioning: boolean || null,
     *     dishwasher: boolean || null,
     *     parking: boolean || null,
     *     gym: boolean || null,
     *     pool: boolean || null
     * }
     */
    return reduce(
        highlightedAmenities,
        (amenitiesMap, amenity) => {
            const { persisted, subtypes } = amenity;

            if (persisted === 'pets') {
                // Display what type of pets are allowed.
                amenitiesMap[persisted] = subtypes[0].display;
            }

            if (persisted === 'laundry') {
                // Display what type of laundry options there are.
                const laundryTypes = map(subtypes, (option) => {
                    // For cases when feed data lists "No Laundry" as an amenity
                    if (option.display.toLowerCase() === 'no laundry') {
                        return 'none';
                    }

                    return option.display;
                });

                amenitiesMap[persisted] = laundryTypes.join(', ');
            }

            if (persisted === 'security') {
                // Display what type of security options there are.
                const securityTypes = map(subtypes, (option) => {
                    return option.display;
                });

                amenitiesMap[persisted] = securityTypes.join(', ');
            }

            if (persisted === 'hvac') {
                /**
                 * If any of these A/C categories is detected, let the FE know.
                 *
                 * NOTE: If SimilarListings component passes initial A/B testing, API will refactor
                 * code to help make this less verbose...
                 */
                const airConditioningChoices = [
                    'Central A/C',
                    'Wall Mounted A/C',
                    'Window Mounted A/C',
                    'A/C',
                    'Electric A/C',
                    'Evaporative Cooling',
                    'Gas Cooling',
                    'Individual Cooling',
                    'Refrigeration Cooling',
                    'Solar Cooling',
                    'Zoned Air Conditioning'
                ];
                const shouldInclude = (subtype) => {
                    return airConditioningChoices.includes(subtype.display);
                };
                const hasAirConditioning = subtypes.some((subtype) => shouldInclude(subtype));

                if (hasAirConditioning) {
                    amenitiesMap.airConditioning = hasAirConditioning;
                }
            }

            if (persisted === 'appliances') {
                // If a dishwasher appliance is detected, let the FE know.
                const hasDishwasher = subtypes.some((type) => {
                    return type.persisted.includes('dishwasher');
                });

                if (hasDishwasher) {
                    amenitiesMap.dishwasher = hasDishwasher;
                }
            }

            if (persisted === 'parking') {
                // If a parking category is detected, let the FE know.
                const hasNoParkingAmenity = subtypes.some((subtype) => {
                    return subtype.persisted.includes('none');
                });

                if (!hasNoParkingAmenity) {
                    amenitiesMap[persisted] = hasNoParkingAmenity === false;
                }
            }

            if (persisted === 'gym') {
                // If the gym category is detected, let the FE know.
                amenitiesMap[persisted] = true;
            }

            if (persisted === 'pool') {
                // If the pool category is detected, let the FE know.
                amenitiesMap[persisted] = true;
            }

            return amenitiesMap;
        },
        {}
    );
};
// Calculates the straight-line distance between a listing and a user's chosen point-of-interest.
// Based on: http://www.geodatasource.com/developers/php
export const listingUtils_getDistanceToUserPoint = (locationObj = {}) => {
    let lat1 = locationObj.lat0;
    let lon1 = locationObj.lon0;
    let lat2 = locationObj.originLat;
    let lon2 = locationObj.originLon;
    let unit = locationObj.unit || 'M';

    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;

    // Default result is returned in regular old statue miles.
    // If result is needed in a different unit, provide it as an argument.

    // Convert to kilometers
    if (unit === 'K') {
        dist = dist * 1.609344;
    }

    // Convert to nautical miles
    if (unit === 'N') {
        dist = dist * 0.8684;
    }

    return dist;
};
export const listingUtils_userRecentlyContactedListing = (userItemTypes = [], userItemDates = {}) => {
    if (!includes(userItemTypes, 'inquiry')) {
        return false;
    }

    const MONTHS_SINCE_CONTACT_CUTOFF = 3;
    let recentlyContacted = true;
    let currentDate = new Date(Date.now());
    let contactedDate = new Date(userItemDates.inquiry.date);
    let monthsSinceContact =
        currentDate.getMonth() -
        contactedDate.getMonth() +
        12 * (currentDate.getFullYear() - contactedDate.getFullYear());

    if (monthsSinceContact >= MONTHS_SINCE_CONTACT_CUTOFF) {
        recentlyContacted = false;
    }

    if (userItemDates.inquiry.date === 0) {
        recentlyContacted = true;
    }

    return recentlyContacted;
};
export const listingUtils_userOnWaitlist = (userActivity) => {
    if (isEmpty(userActivity)) {
        return false;
    }

    const waitlistCreatedOn = new Date(userActivity.waitlistCreatedOn);
    const waitlistExpiresOn = new Date(userActivity.waitlistExpiresOn);
    return waitlistCreatedOn < waitlistExpiresOn;
};
/**
 * getListingImgTitleText
 * @param { currentIndex?: number, maxIndex?: number, nameOrAddress: string, addressDetails: object }
 */
export const listingUtils_getListingImgTitleText = ({
    currentIndex = -1,
    maxIndex = -1,
    nameOrAddress = '',
    addressDetails = {}
}) => {
    const listingCity = addressDetails.city;
    const listingState = addressDetails.state;

    // If we do not have index info, return a general title string
    if (currentIndex < 0 || maxIndex < 0) {
        return `Photo of ${nameOrAddress} - ${listingCity}, ${listingState}`;
    }

    // Else, title string is `Photo 1 of 20 - eaves Apartment Complex - Austin, TX`
    return `Photo ${currentIndex + 1} of ${maxIndex} - ${nameOrAddress} - ${listingCity}, ${listingState}`;
};
/**
 * getListingImgAltText
 * @param { photoCaption: string, nameOrAddress: string, isPrimaryPhoto?: boolean }
 */
export const listingUtils_getListingImgAltText = ({
    photoCaption = '',
    nameOrAddress = '',
    isPrimaryPhoto = false
}) => {
    // If photoCaption exists, prioritize and use it!
    if (photoCaption) {
        return `${photoCaption} - ${nameOrAddress}`;
    }

    // Else, tag it as a primary or building photo... "apartments" style
    return isPrimaryPhoto ? `Primary Photo - ${nameOrAddress}` : `Building Photo - ${nameOrAddress}`;
};

export const getScheduledTour = (aliasEncoded, scheduledToursForUser) => {
    for (let i = 0; i < scheduledToursForUser.length; i++) {
        const scheduledTour = scheduledToursForUser[i];
        if (aliasEncoded === scheduledTour.listingAlias) {
            return scheduledTour;
        }
    }

    return {};
};

export const listingHasTourUrls = (listing) => {
    const { details = {} } = listing;

    const has3dTour = listing.has3dTour || details.has3dTour;

    return Boolean(has3dTour);
};

export const get3dTourUrlFlag = (rawListingDataFromApi) => {
    let { hasZillow3dTourUrls, hasExternal3dTourUrls } = rawListingDataFromApi || {};

    return Boolean(hasZillow3dTourUrls || hasExternal3dTourUrls);
};

export const listingUtils_getResourceIdAndSlug = (url = '') => {
    url = url.split('?')[0];
    url = url.split('/');
    const lastIdx = url.length - 1;

    return {
        resourceId: url[lastIdx - 1],
        slug: url[lastIdx]
    };
};

export const listingUtils_apiLimitedBuildingArrayToSummaryArray = (
    apiLimitedBuildingsArray,
    filter = {},
    limitListings = false
) => {
    if (!apiLimitedBuildingsArray || !apiLimitedBuildingsArray.length) {
        return [];
    }

    const summaries = [];
    apiLimitedBuildingsArray.forEach((buildingObj) => {
        const summary = new SummaryV2(buildingObj, filter);
        if (summary && summary.maloneLotIdEncoded && summary.aliasEncoded) {
            summaries.push(summary);
        }
    });

    if (limitListings) {
        // Supports HP-5565: Limit listings for affordable- and luxury- slugs
        const halfLength = Math.ceil(summaries.length / 2);
        const leftSideSummaries = summaries.slice(0, halfLength);

        return leftSideSummaries;
    }

    return summaries;
};

export const listingUtils_makeThumbUrl = (url = '') => {
    // ex url: https://photos.zillowstatic.com/fp/b660438694db7493dc69367aa3d7acec-rentals_medium_500_500.webp
    const MED_URL = 'rentals_medium_500_500';
    const THUMB_URL = 'rentals_thumb_180_180';

    if (url.includes(MED_URL)) {
        return url.replace(MED_URL, THUMB_URL);
    }
    return url;
};
