import { bootstrap } from '@zg-rentals/rp-bootstrap-browser';

let logSampleRate = 0.2;
if (__CLIENT__) {
    try {
        const params = new URLSearchParams(window.location.search);
        const paramValue = params.get('logSampleRate');

        if (paramValue !== null) {
            logSampleRate = parseFloat(paramValue);
        }
    } catch (err) {
        console.error(`error setting the logSampleRate from query ${err}`);
    }
}

const rpBootstrap = bootstrap({
    appName: process.env.APP_NAME || 'hotpads-web',
    buildNumber: Number(__BUILD_NUMBER__ ?? -1),
    loggerOptions: {
        logPath: '/log',
        level: 'info',
        sampleRate: logSampleRate
    },
    monitorOptions: {
        proxyPath: '/pontoon-proxy/',
        datadogPluginConfig: {
            datadogOptions: {
                applicationId: 'b958ce70-ca7e-419b-ac17-7b82cb334431',
                clientToken: 'pub6bac9bcea69f2463eac0848093adf883'
            }
        }
    }
});

rpBootstrap.getMonitor().count({ name: 'browser.initialized' });

if (__CLIENT__) {
    // @ts-ignore
    window.getLogger = rpBootstrap.getLogger.bind(rpBootstrap);
}

export { rpBootstrap };
