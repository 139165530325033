import analyticsSnippet from './analyticsSnippet';
import stringHelper from 'app/shared/utils/stringHelper';
import { identify, initZanalytics } from '@zillow/universal-analytics';
import { getGuidForClickstream, setGuidForClickstream } from '../utils/browserCookieUtils';

export function initialize({ debug = false, trace = false } = {}) {
    let gaAddress;
    if (typeof window === 'undefined') {
        return false;
    }

    if (debug === true || __DEV__) {
        gaAddress = 'https://www.google-analytics.com/analytics_debug.js';
    }

    //Look for OneTrust cookie (OptanonConsent) to determine how to include GA
    //If no OneTrust cookie (user's first visit), load GA normally
    //If the OneTrust cookie does exist (user has been to HP before), load GA through OneTrust so we respect
    //the user's cookie settings. (by adding the appropriate classname and content type to the javascript include.)
    var onetrustEnabled;
    try {
        var cookie = stringHelper.getCookieByName('OptanonConsent');
        if (typeof cookie == 'undefined') {
            onetrustEnabled = false;
        } else {
            onetrustEnabled = true;
        }
    } catch (error) {
        onetrustEnabled = false;
    }

    analyticsSnippet({
        gaAddress,
        onetrustEnabled
    });

    if (typeof window.ga !== 'function') {
        return false;
    }

    // Initialize Zillow's Universal Analytics library
    const { store } = window;
    // @ts-expect-error TODO: Implement types for Redux store
    const { app } = store.getState() || {};
    // @ts-expect-error TODO: Implement types for Redux store
    // User token from Redux store (refreshes on login / logout)
    const { loggedIn, userToken } = store.getState().user;

    // Store user token as `guid` cookie if it doesn't exist
    setGuidForClickstream(userToken);

    if (loggedIn) {
        // Sets user_id for Clickstream, which will be different than stable guid
        identify(userToken);
    }

    const options = {
        hotpads: {
            apiHost: app.clickstreamApi,
            apiKey: app.clickstreamApiKey,
            writeKey: app.clickstreamWriteKey,
            secure: true,
            anonymousId: getGuidForClickstream() || '',
            hpUserToken: userToken
        },
        // Temporarily needed in order to trigger new lane events on staging.
        // We can remove once we get the okay from data engineering team.
        zillow: {
            apiHost: app.clickstreamApi,
            apiKey: app.clickstreamApiKey,
            writeKey: app.clickstreamWriteKey,
            secure: true,
            anonymousId: getGuidForClickstream() || ''
        }
    };

    initZanalytics(window, document, options);

    // Enabling trace debugging will output more verbose information to the console.
    if (trace) {
        window.ga_debug = { trace: true };
    }
}
