interface RestrictedIncomeByOccupants {
    [key: number]: string;
}

interface ContactProps {
    restricted?: boolean;
    defaultInquiryMessage?: string;
    requiresNumBeds?: boolean;
    requiresMoveDate?: boolean;
    requiresPhone?: boolean;
    requiresName?: boolean;
    requiresEmail?: boolean;
    requiresMessage?: boolean;
    displayContactBox?: boolean;
    message?: string;
    confirmationMessage?: string;
    restrictedIncomeByOccupants?: RestrictedIncomeByOccupants;
}

// Define the class with TypeScript
class Contact {
    restricted: boolean;
    defaultInquiryMessage: string;
    requiresNumBeds: boolean;
    requiresMoveDate: boolean;
    requiresPhone: boolean;
    requiresName: boolean;
    requiresEmail: boolean;
    requiresMessage: boolean;
    displayContactBox: boolean;
    message: string;
    confirmationMessage: string;
    restrictedIncomeByOccupants: RestrictedIncomeByOccupants;

    constructor({
        restricted = false,
        defaultInquiryMessage = '',
        requiresNumBeds = false,
        requiresMoveDate = false,
        requiresPhone = false,
        requiresName = false,
        requiresEmail = true,
        requiresMessage = false,
        displayContactBox = false,
        message = '',
        confirmationMessage = '',
        restrictedIncomeByOccupants = {}
    }: ContactProps = {}) {
        this.restricted = Boolean(restricted);
        this.defaultInquiryMessage = String(defaultInquiryMessage);
        this.requiresNumBeds = Boolean(requiresNumBeds);
        this.requiresMoveDate = Boolean(requiresMoveDate);
        this.requiresPhone = Boolean(requiresPhone);
        this.requiresName = Boolean(requiresName);
        this.requiresEmail = Boolean(requiresEmail);
        this.requiresMessage = Boolean(requiresMessage);
        this.displayContactBox = Boolean(displayContactBox);

        // why is low income details separate from senior/student/military?
        this.message = String(message); // "You must qualify for low income housing to live here."
        this.confirmationMessage = String(confirmationMessage); // "I qualify"
        this.restrictedIncomeByOccupants = {
            1: String(restrictedIncomeByOccupants[1] || ''), // "$31,980",
            2: String(restrictedIncomeByOccupants[2] || ''), // "$36,540",
            3: String(restrictedIncomeByOccupants[3] || ''), // "$41,100",
            4: String(restrictedIncomeByOccupants[4] || ''), // "$45,660",
            5: String(restrictedIncomeByOccupants[5] || ''), // "$49,320",
            6: String(restrictedIncomeByOccupants[6] || ''), // "$52,980",
            7: String(restrictedIncomeByOccupants[7] || ''), // "$56,640",
            8: String(restrictedIncomeByOccupants[8] || '') // "$60,300"
        };
    }
}

export default Contact;
