interface PhotoParams {
    url?: string;
    caption?: string;
    width?: number;
    height?: number;
    noPhoto?: boolean;
}

class Photo {
    url: string;
    caption: string;
    noPhoto?: boolean;

    constructor({ url = '', caption = '', width = 0, height = 0, noPhoto = false }: PhotoParams = {}) {
        this.url = String(url);
        this.caption = String(caption);

        if (noPhoto) {
            this.noPhoto = noPhoto;
        }
    }
}

export default Photo;
