import * as zanalytics from '@zillow/zanalytics';

// Utilizes same API as universal-analytics event API.
/**
    e.g., fieldObject will be an AnalyticsConstant object:
   {
        action: 'Search Box Open'
        category: 'Search'
        label: 'SAVED_SEARCH / SAVED_HOME / RECENT_SEARCH'
    }
 */
export function event(fieldObject = {}) {
    if (typeof window === 'undefined') {
        return;
    }

    zanalytics.track('event', fieldObject);
}
