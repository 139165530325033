// @ts-nocheck
/* eslint-enable */
import isArray from 'lodash/isArray';

var numberUtils = {
    _roundDecimal(type, value, exp) {
        /**
         * Decimal adjustment of a number.
         * Handy function to properly round decimal numbers.
         * Using this, we get 3.195 => 3.2
         * JavaScript's toFixed(2) method would return 3.19
         *
         * @param {String}  type  The type of adjustment.
         * @param {Number}  value The number.
         * @param {Integer} exp   The exponent (the 10 logarithm of the adjustment base).
         * @returns {Number} The adjusted value.
         */
        // If the exp is undefined or zero...
        if (typeof exp === 'undefined' || Number(exp) === 0) {
            return Math[type](value);
        }
        value = Number(value);
        exp = Number(exp);
        // If the value is not a number or the exp is not an integer...
        if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
            return NaN;
        }
        // Shift
        value = value.toString().split('e');
        value = Math[type](Number(value[0] + 'e' + (value[1] ? Number(value[1]) - exp : -exp)));
        // Shift back
        value = value.toString().split('e');

        return Number(value[0] + 'e' + (value[1] ? Number(value[1]) + exp : exp));
    },
    _stringToNumber(rawNum = '') {
        return Number(rawNum?.toString().split(',').join('') ?? '');
    },
    _classifyDecimal(rawNum, forPageTitle = false) {
        /**
         * There are subtle changes when the `forPageTitle` flag is set to true.
         * Abbreviations are capitalized and added to values >= 1000
         * See HPWEB-3248 for additional context.
         */
        const currencySymbol = '$';
        let abbrev = '';
        let number;

        if (rawNum >= 1000000) {
            // Result: 2,350,000 / 10,000 => 2.35m
            number = rawNum / 1000000;
            abbrev = forPageTitle ? 'M' : 'm';
            number = numberUtils._roundDecimal('round', number, -2);
        } else if (rawNum >= 100000) {
            // Result: 235,500 / 1,000 => 235k
            number = Math.floor(rawNum / 1000).toFixed(0);
            abbrev = forPageTitle ? 'K' : 'k';
        } else if (forPageTitle ? rawNum >= 1000 : rawNum >= 10000) {
            // Result: 10,500 / 1,000 => 10.5k
            number = (rawNum / 1000).toFixed(1);
            if (number[number.length - 1] === '0') {
                number = number.slice(0, -2);
            }
            abbrev = forPageTitle ? 'K' : 'k';
        } else if (rawNum >= 0) {
            // 1234 => 1,234
            number = numberUtils.prettyPrintNumber(rawNum);
        } else {
            return (number = '--');
        }

        return forPageTitle ? currencySymbol + number + abbrev : number + abbrev;
    },
    compact(rawNum, forPageTitle = false) {
        if (isArray(rawNum)) {
            rawNum = rawNum[0];
        }

        // Make sure we convert input to number and remove any commas and such.
        rawNum = numberUtils._stringToNumber(rawNum);

        return numberUtils._classifyDecimal(rawNum, forPageTitle);
    },
    fullNumber(rawNum) {
        if (rawNum && rawNum >= 0) {
            return numberUtils.prettyPrintNumber(rawNum);
        }

        return '--';
    },
    asPercent(value) {
        const isWholeNumber = value % 1 === 0;
        const numOfDecimalPlaces = !isWholeNumber ? value.toString().split('.')[1].length : 0;

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'percent',
            minimumFractionDigits: numOfDecimalPlaces,
            maximumFractionDigits: 2
        });

        return formatter.format(value / 100);
    },
    asCurrency(value) {
        const isWholeNumber = value % 1 === 0;
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: isWholeNumber ? 0 : 2 // Whole numbers shouldn't have decimal when converted to currency
        });

        return formatter.format(value);
    },
    asWord(num) {
        const number = Number(num);

        if (number < 0) {
            return 'less than zero';
        }

        const NUM_TO_WORD = {
            0: 'zero',
            1: 'one',
            2: 'two',
            3: 'three',
            4: 'four',
            5: 'five',
            6: 'six',
            7: 'seven',
            8: 'eight',
            9: 'nine',
            10: 'ten',
            other: 'more than ten'
        };

        return number <= 10 ? NUM_TO_WORD[number] : NUM_TO_WORD.other;
    },
    currencyStringToInt(str) {
        if (str.length < 2) {
            return 0;
        }
        return parseInt(str.substr(1).replace(/,/g, ''));
    },
    prettyPrintNumber(int) {
        var parts = int.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
    },
    cleanPhoneNumber(phone) {
        // Strip any user formatting from phone numbers because we
        // handle how to display this on the front-end.
        // e.g., "(123) 456-7890" -> "1234567890"
        phone = phone.replace(/[^\d]/g, '');

        if (phone.length > 10) {
            phone = phone.substring(0, 10);
        }

        return phone;
    },
    formatPhoneNumber(phone) {
        // receives user input and returns formatted number to display in format: (123) 123-4567
        if (typeof phone === 'undefined') {
            return '';
        }

        let phoneNumber = numberUtils.cleanPhoneNumber(phone);
        let phoneLength = phoneNumber.length;

        // Check the length of the phone number and add appropriate masking based on length.
        if (phoneLength === 10) {
            phoneNumber =
                '(' +
                phoneNumber.substring(0, 3) +
                ') ' +
                phoneNumber.substring(3, 6) +
                '-' +
                phoneNumber.substring(6, 10);
        } else if (phoneLength > 6) {
            phoneNumber =
                '(' +
                phoneNumber.substring(0, 3) +
                ') ' +
                phoneNumber.substring(3, 6) +
                '-' +
                phoneNumber.substring(6, phoneLength);
        } else if (phoneLength > 3) {
            phoneNumber = '(' + phoneNumber.substring(0, 3) + ') ' + phoneNumber.substring(3, phoneLength);
        } else if (phoneLength > 0) {
            phoneNumber = '(' + phoneNumber.substring(0, phoneLength);
        }

        return phoneNumber;
    },
    getDisplayPhoneNumber(phone) {
        // receieves valid, full phone number (see link below) and returns number in format: (123) 123-4567
        if (typeof phone === 'undefined') {
            return '';
        }

        if (!/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(phone)) {
            // matches many forms of phone numbers https://stackoverflow.com/a/16702965/7669090
            return '';
        }

        // strip phone formatting and return numbers only
        let _phone = phone.replace(/[^\d]/g, '');

        // strip country code (assumes US numbers only)
        if (_phone.length === 11 && _phone.startsWith('1')) {
            _phone = _phone.substring(1, _phone.length);
        }

        return (_phone = '(' + _phone.substring(0, 3) + ') ' + _phone.substring(3, 6) + '-' + _phone.substring(6, 10));
    },
    getOrdinalSuffix(i) {
        let j = i % 10;
        let k = i % 100;

        if (j === 1 && k !== 11) {
            return i + 'st';
        }

        if (j === 2 && k !== 12) {
            return i + 'nd';
        }

        if (j === 3 && k !== 13) {
            return i + 'rd';
        }

        return i + 'th';
    },
    trimDecimal(int, limit = 7) {
        var s = '1';
        while (s.length <= limit) {
            s = s + '0';
        }

        return Math.round((Number(int) || 0) * Number(s)) / Number(s);
    },
    truncateCompactedNumber(numberString, places = 2) {
        if (!numberString || numberString === '--') {
            return numberString;
        }

        const splitOnDecimal = numberString.split('.');

        // no decimal, no need to compact
        if (splitOnDecimal.length === 1) {
            return numberString;
        }

        const preDecimal = splitOnDecimal[0];

        // capture group to include split delim (non digit)
        const postDecimalAbbrev = splitOnDecimal[1].split(/(\D)/);

        const truncatedDecimalPlaces = postDecimalAbbrev[0].slice(0, places);
        const stringPostDecimalAbbrev = postDecimalAbbrev[1] || '';

        // don't show decimal point if we are truncating to 0 places
        if (places === 0) {
            return `${preDecimal}${stringPostDecimalAbbrev}`;
        }

        return `${preDecimal}.${truncatedDecimalPlaces}${stringPostDecimalAbbrev}`;
    }
};

export default numberUtils;
