import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';
import { zindex } from 'app/shared/styles/_zindex';

export const PageLoadingIndicator = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${zindex['$z-index-max']};
    -webkit-transition: opacity 0.4s linear 3s;
    transition: opacity 0.4s linear 3s;
    opacity: 1;
`;

export const PageLoadingIndicatorProgress = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 2px;
    background: ${colors['$hpx-teal-500']};
    box-shadow: 0 0 10px rgba(131, 232, 218, 0.7);
    -webkit-transition: width 2.5s ease;
    transition: width 2.5s ease;
`;
