// @ts-nocheck
/* eslint-enable */
// App
import pathsManifest from 'app/shared/utils/pathsManifest';

// Misc / Utils
import dateUtils from 'app/shared/utils/dateUtils';
import omit from 'lodash/omit';
import queryUtils from 'app/shared/utils/queryUtils';
import stringHelper from 'app/shared/utils/stringHelper';

var userUtils = {
    mapRenterProfileApiToRedux(data = {}) {
        const renterProfile = {};

        if (data?.moveInDate && Array.isArray(data.moveInDate)) {
            const [dateYear, dateMonth, dateDay] = data.moveInDate;
            renterProfile.moveInDate = new Date(`${dateYear}/${dateMonth}/${dateDay}`);
        } else if (data?.moveInDate) {
            renterProfile.moveInDate = data.moveInDate;
        }

        // Handle issue translating between data from Redux vs data from API
        let leaseLength = data.leaseLengthMonths || data.leaseDuration;

        renterProfile.moveInTimeFrame = data.moveInTimeFrame ? data.moveInTimeFrame.toLowerCase() : '';
        renterProfile.leaseDuration = leaseLength ? Number(leaseLength) : null;
        renterProfile.incomeYearly = data.incomeYearly;

        const numBedrooms = !isNaN(data.numBedrooms) && data.numBedrooms >= 0 ? data.numBedrooms : data.numBeds;
        renterProfile.numBeds = numBedrooms;
        renterProfile.numOccupants = data.numOccupants;

        // TODO: delete petDetails related code when petDetails field is replcaed by renterPetDetails field on prod.
        const petDetails = data?.petDetails;
        const renterPetDetails = data?.renterPetDetails;
        if ((petDetails && petDetails?.length >= 1 && petDetails[0]?.type !== 'NONE') || data.desiredPets) {
            renterProfile.desiredPets = 'true';
        } else if (renterPetDetails && renterPetDetails?.petType && renterPetDetails?.petType !== 'NONE') {
            renterProfile.desiredPets = 'true';
        } else {
            renterProfile.desiredPets = 'false';
        }

        // Funky stuff to handle credit score API:
        if (data?.creditScoreMin && data?.creditScoreMax) {
            const creditRange = `${data.creditScoreMin}-${data.creditScoreMax}`;
            renterProfile.creditScore = creditRange;
            renterProfile.creditScoreMin = data.creditScoreMin;
            renterProfile.creditScoreMax = data.creditScoreMax;
        }

        renterProfile.desiredParking = data.desiredParking;

        return renterProfile;
    },
    mapRenterProfileDataToApi(data = {}) {
        const {
            creditScoreMin,
            creditScoreMax,
            desiredParking,
            desiredPets,
            incomeYearly,
            leaseDuration,
            moveInDate,
            moveInTimeFrame,
            numBeds,
            numOccupants
        } = data;
        const queryObj = {};

        const shouldAddLeadingZeroForDate = (value) => {
            if (value < 10) {
                return `0${value}`;
            }

            return value;
        };

        if (moveInDate) {
            if (dateUtils.dateIsValid(moveInDate)) {
                const formatDate = [
                    moveInDate.getFullYear(),
                    shouldAddLeadingZeroForDate(moveInDate.getMonth() + 1),
                    shouldAddLeadingZeroForDate(moveInDate.getDate())
                ].join('-');
                queryObj.moveInDate = formatDate;
            }
        }

        if (moveInTimeFrame) {
            queryObj.moveInTimeFrame = 'flexible';
        }

        if (leaseDuration) {
            queryObj.leaseLengthMonths = Number(leaseDuration);
        }

        if (numOccupants) {
            queryObj.numOccupants = Number(numOccupants);
        }

        if (incomeYearly) {
            queryObj.incomeYearly = Number(incomeYearly);
        }

        if (creditScoreMin) {
            queryObj.creditScoreMin = Number(creditScoreMin);
        }

        if (creditScoreMax) {
            queryObj.creditScoreMax = Number(creditScoreMax);
        }

        if (!isNaN(numBeds) && numBeds >= 0) {
            queryObj.numBedrooms = Number(numBeds);
        }

        if (desiredPets) {
            queryObj.desiredPets = desiredPets;
        }

        if (desiredParking) {
            queryObj.desiredParking = desiredParking;
        }

        return queryObj;
    },

    isRep(roles) {
        return roles.indexOf('rep') > -1;
    },
    isAdmin(roles) {
        return roles.indexOf('admin') > -1;
    },
    hasActiveSubscriptions(subscriptions) {
        if (subscriptions.stopListings && subscriptions.stopOther) {
            return false;
        }

        return true;
    },
    successLoginRedirect(transitionPath) {
        var queryParams = stringHelper.getUrlQueryParams();

        transitionPath = transitionPath || queryParams.redirect;
        if (transitionPath && transitionPath[0] !== '/') {
            transitionPath = '/';
        }
        var csr = queryParams.csr;
        var restOfQueryParams = omit(queryParams, 'redirect');

        if (transitionPath && csr) {
            const pathWithQuery = transitionPath + queryUtils.stringify(restOfQueryParams);
            return window.router.transitionTo(pathWithQuery);
        } else if (transitionPath) {
            const pathWithQuery = transitionPath + queryUtils.stringify(restOfQueryParams);
            window.location = window.location.origin + pathWithQuery;
        } else if (window.location.pathname === pathsManifest.LoginPage) {
            return window.router.transitionTo(pathsManifest.UserDashboardPage);
        } else if (window.location.pathname === pathsManifest.UpdatePasswordPage) {
            return window.router.transitionTo('/');
        } else {
            return false;
        }
    }
};

export default userUtils;
