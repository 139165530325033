import React from 'react';
import * as S from './styles';

const OfflineNotification: React.FC = () => {
    return (
        <S.OfflineNotification>
            <S.OfflineNotificationLeft>
                <S.OfflineNotificationTitle>You are browsing HotPads offline</S.OfflineNotificationTitle>
                <p>Please check your internet connection.</p>
            </S.OfflineNotificationLeft>
            <S.OfflineNotificationRight>
                <S.OfflineNotificationIcon />
            </S.OfflineNotificationRight>
        </S.OfflineNotification>
    );
};

export default OfflineNotification;