// @ts-nocheck
/* eslint-enable */
const notFoundUtils = {
    getHttpStatus(pathname) {
        if (/^\/search\/congressional\/*\/*/.test(pathname)) {
            // /search/congressional/il/1 ==> 410 GONE
            return 410;
        } else if (/.*\/(?:auction).*$/i.test(pathname)) {
            // /CA/auction
            return 410;
        } else if (/^\/auctions\/*/.test(pathname)) {
            // /auction
            return 410;
        } else if (/^\/profile\/*?/.test(pathname)) {
            // /profile
            return 410;
        } else if (/^\/[0-9]{3}\//.test(pathname)) {
            // area codes
            return 410;
        } else if (/^\/company\/*/.test(pathname)) {
            // // company pages
            return 410;
        } else if (/for-sale/.test(pathname)) {
            return 410;
        } else {
            return 404;
        }
    }
};

export default notFoundUtils;
