const size = {
    'screen-sm-min': '320px',
    'screen-sm-max': '767px',
    'screen-md-min': '768px',
    'screen-md-max': '991px',
    'screen-lg-min': '992px',
    'screen-lg-max': '1199px',
    'screen-xl-min': '1200px',
    'screen-xl-max': '1999px',
    'screen-xxl-min': '2000px'
};

export const viewports: { [key: string]: string } = {
    'sm-and-below': `(max-width: ${size['screen-sm-max']})`,
    'md-and-up': `(min-width: ${size['screen-md-min']})`,
    'lg-and-up': `(min-width: ${size['screen-lg-min']})`,
    'xl-and-up-bigger-listing-cards': `(min-width: 1280px)`, // HPWEB-6082 Big Listing Cards
    'xl-and-up': `(min-width: ${size['screen-xl-min']})`,
    'xxl-and-up': `(min-width: ${size['screen-xxl-min']})`,
    'md-to-lg': `(min-width: ${size['screen-md-min']}) and (max-width: ${size['screen-lg-max']})`,
    'md-only': `(min-width: ${size['screen-md-min']}) and (max-width: ${size['screen-md-max']})`
};
