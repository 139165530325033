/**
 * Example:
 *
 * export default [
 *   new Experiment({
 *     experimentName: constants.EXAMPLE_EXPERIMENT_NAME,
 *     jira: 'HPWEB-0000'
 *   })
 * ]
 */

/** Uncomment these imports when running experiments */
import { Experiment } from 'app/shared/models/Experiment';
import constants from 'app/shared/constants/ExperimentConstants';

export default [
    new Experiment({
        experimentName: constants.HOTPADS_WEB_HDP_AA_TEST,
        jira: 'HP-6197'
    })
];
