// @ts-nocheck
/* eslint-enable */
// App
import Promise from 'promise';
import api from 'app/shared/utils/api';

// Lodash
import isEmpty from 'lodash/isEmpty';

// Actions
import AuthActions from 'app/shared/flux/actions/AuthActions';

// Models
import MapData from 'app/shared/models/MapData';
import RecentSearch from 'app/shared/models/RecentSearch';
import SavedSearch from 'app/shared/models/SavedSearch';
import Filter from 'app/shared/models/Filter';

// Misc / utils
import constants from 'app/shared/constants/ConstantsBundle';
import adapterUtils from 'app/shared/utils/adapterUtils';
import gmapUtils from 'app/client/utils/map/gmapUtils';
import routeUtils from 'app/shared/utils/routeUtils';
import { adapt_reduxToQuery, adapt_reduxToJava } from 'app/shared/flux/actions/FilterActions/adapters';
import { areaUtils_validateMapForUpdatingRecentSearch } from 'app/shared/utils/areaUtils';

const logger = getLogger('actions/usersearch');

const UserSearchActions = {
    search: {
        saved: {
            create(name, email, frequency, useMapBounds, borderAreaId) {
                return function(dispatch, getState) {
                    let { filter, area, user, location } = getState();
                    const { minLat, minLon, maxLat, maxLon } = area.area;
                    let mapData = { minLat, minLon, maxLat, maxLon };

                    if (!email) {
                        logger.warn('UserSearchActions#search#saved No email specified.');
                        return false;
                    }

                    if (useMapBounds && window.map) {
                        mapData = gmapUtils.getMapData(window.map);
                    }

                    var savedSearchItem = adapterUtils.savedSearchItemForApi({
                        name,
                        email,
                        frequency,
                        filter,
                        mapData,
                        borderAreaId
                    });

                    if (!savedSearchItem) {
                        logger.error('Error in UserSearchActions#search.saved.create');
                        return false;
                    }

                    if (user.loggedIn) {
                        return dispatch(api.user.search.saved.create(savedSearchItem));
                    } else {
                        const url = routeUtils.buildFreepassPath(location.current);
                        return dispatch(api.user.login.freepass({ email, url }))
                            .then((result) => {
                                if (result.success && result.loggedIn) {
                                    dispatch(AuthActions.login.success(result));
                                    result.loggedIn = true;
                                    return result;
                                } else {
                                    result.loggedIn = false;
                                    return result;
                                }
                            })
                            .then((result) => {
                                if (result.loggedIn) {
                                    return dispatch(api.user.search.saved.create(savedSearchItem));
                                }
                                return false;
                            });
                    }
                };
            },
            update({ id, name, frequency, filter, mapData, borderAreaId }) {
                return function(dispatch) {
                    if (!id) {
                        logger.warn('UserSearchActions#search#update No id specified.');
                        return false;
                    }

                    var updatedSearchItem = adapterUtils.savedSearchItemForApi({
                        id,
                        name,
                        frequency,
                        filter,
                        mapData,
                        borderAreaId
                    });

                    if (!updatedSearchItem) {
                        logger.error('Error in UserSearchActions#search.saved.update');
                        return false;
                    }

                    return dispatch(api.user.search.saved.update(updatedSearchItem));
                };
            },
            delete(searchId) {
                return function(dispatch) {
                    if (!searchId) {
                        logger.warn('UserSearchActions#search#delete No searchId specified.');
                        return false;
                    }

                    return dispatch(api.user.search.saved.delete(searchId)).then((result) => {
                        if (result.success === true) {
                            dispatch(UserSearchActions.getSavedSearches());
                        }
                        return result;
                    });
                };
            },
            displaySearchPromptInquiry({ shouldUseAreaBoundary, useMapBounds }) {
                return function(dispatch, getState) {
                    let area = getState().area.area;
                    let mapData = {
                        minLat: area.minLat,
                        minLon: area.minLon,
                        maxLat: area.maxLat,
                        maxLon: area.maxLon
                    };

                    if (useMapBounds && window.map) {
                        mapData = gmapUtils.getMapData(window.map);
                    }

                    return dispatch(
                        api.user.search.saved.displaySearchPromptInquiry({
                            minLat: mapData.minLat,
                            minLon: mapData.minLon,
                            maxLat: mapData.maxLat,
                            maxLon: mapData.maxLon,
                            id: shouldUseAreaBoundary ? area.id : null
                        })
                    ).then((res) => {
                        return res;
                    });
                };
            }
        },
        recommended: {
            get() {
                return function(dispatch) {
                    return dispatch(api.user.search.recommended.get());
                };
            },
            reset(id) {
                return function(dispatch) {
                    return dispatch(api.user.search.recommended.reset(id));
                };
            },
            reactivate(email) {
                return function(dispatch) {
                    return dispatch(api.user.search.recommended.reactivate(email));
                };
            }
        }
    },
    getSavedSearches() {
        return function(dispatch) {
            return dispatch(api.user.search.saved.get()).then((result) => {
                const savedSearchesMap = result.data.map((data) => {
                    const {
                        sid = null,
                        filter = {},
                        resourceId = 'new-york-ny',
                        created,
                        name,
                        emailFrequency,
                        numberOfNewResults
                    } = data;
                    const mapData = new MapData(filter);
                    const createdFilter = new Filter(filter);
                    const queryObj = adapt_reduxToQuery({
                        filter: createdFilter,
                        mapData: filter.areas ? null : mapData,
                        border: filter.areas || false
                    });

                    return new SavedSearch({
                        sid,
                        filter: createdFilter,
                        resourceId,
                        created,
                        name,
                        emailFrequency,
                        numberOfNewResults,
                        queryObj,
                        mapData,
                        borderAreaId: filter.areas
                    });
                });

                dispatch({
                    type: constants.UPDATE_SAVED_SEARCHES,
                    payload: {
                        savedSearch: savedSearchesMap
                    }
                });

                return;
            });
        };
    },
    getRecentSearches() {
        return function(dispatch) {
            return dispatch(api.user.search.recent.get())
                .then((result = {}) => {
                    if (!result.data) {
                        return;
                    }

                    const { filter = {}, areaInfo = {} } = result.data;
                    const hasBoundary = Boolean(filter.areas);
                    const mapData = new MapData(filter);
                    const createdFilter = new Filter(filter);
                    const queryObj = adapt_reduxToQuery({
                        filter: createdFilter,
                        mapData: hasBoundary ? null : mapData,
                        border: hasBoundary
                    });

                    return new RecentSearch({
                        hasBoundary,
                        resourceId: areaInfo.resourceId,
                        filter: createdFilter,
                        queryObj,
                        mapData,
                        areaInfo
                    });
                })
                .then((recentSearch) => {
                    dispatch({
                        type: constants.UPDATE_RECENT_SEARCHES,
                        payload: {
                            recentSearch
                        }
                    });
                    return recentSearch;
                })
                .catch((err) => logger.error(err.stack, `Error getting recent searches`));
        };
    },
    updateRecentSearch({ mapDataInfo, newFilter } = {}) {
        return function(dispatch, getState) {
            if (__CLIENT__) {
                const { filter, area, location, app } = getState();
                const isMobile = app.device.isMobile;
                const shouldUseAreaBoundary = location.current.query.border !== false;
                const mapData = (app.gmapLoaded && gmapUtils.getMapData(window.map)) || mapDataInfo;

                if (isEmpty(mapData) && !isMobile) {
                    return Promise.resolve();
                }

                const areaId = shouldUseAreaBoundary && !isEmpty(area.area) ? area.area.id : null;
                const apiFilter = adapt_reduxToJava({
                    filter: newFilter ? newFilter : filter,
                    area: { id: areaId },
                    mapData
                });
                dispatch({
                    type: constants.SET_CURRENT_SEARCH,
                    payload: null
                });

                return dispatch(api.user.search.recent.update({ filter: apiFilter })).then(() => {
                    dispatch(UserSearchActions.getRecentSearches());
                });
            }
        };
    },
    setCurrentSearch({ mapData, waiting, resourceId, isMapFirstIdle = false }) {
        return function(dispatch, getState) {
            const { filter, area, location } = getState();
            let currentSearch = getState().user.currentSearch;
            const currentSearchReady = currentSearch && currentSearch.waiting;
            const currAreaMatches = area.area && currentSearch && currentSearch.resourceId === area.area.resourceId;

            if (waiting) {
                // case 1: user searched (no map data)
                return dispatch({
                    type: constants.SET_CURRENT_SEARCH,
                    payload: { waiting: true, resourceId }
                });
            } else if (currentSearchReady && currAreaMatches) {
                // case 2: map data came back - update recent search
                dispatch(UserSearchActions.updateRecentSearch({ mapDataInfo: mapData }));
            } else if (currentSearchReady && !currAreaMatches) {
                // do nothing, wait till area is done loading
            } else if (
                areaUtils_validateMapForUpdatingRecentSearch(mapData, location.current.query.border) &&
                !isMapFirstIdle
            ) {
                // case 3: save api filter info for when user qualifies search to be a recent search
                // ie clicks on a listing
                const shouldUseAreaBoundary = location.current.query.border !== false;
                const areaId = shouldUseAreaBoundary ? area.area.id : null;
                const apiFilter = adapt_reduxToJava({
                    filter,
                    area: { id: areaId },
                    mapData
                });
                return dispatch({
                    type: constants.SET_CURRENT_SEARCH,
                    payload: { apiFilter }
                });
            } else {
                // invalid search (ie too zoomed out)
                // set current search to null to avoid updating recent search
                return dispatch({
                    type: constants.SET_CURRENT_SEARCH,
                    payload: null
                });
            }
        };
    },
    listingOnCurrentSearchClicked() {
        return function(dispatch, getState) {
            const currentSearch = getState().user.currentSearch;
            if (currentSearch && currentSearch.apiFilter) {
                dispatch({
                    type: constants.SET_CURRENT_SEARCH,
                    payload: null
                });

                return dispatch(api.user.search.recent.update({ filter: currentSearch.apiFilter })).then(() => {
                    dispatch(UserSearchActions.getRecentSearches());
                });
            }
        };
    }
};

export default UserSearchActions;
