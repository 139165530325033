import Cookies from 'universal-cookie';

const cookies = new Cookies();

export function getPis() {
    return cookies.get('pis');
}

export function incrementPis() {
    let pis = cookies.get('pis') || 0;
    pis = Number(pis);
    pis += 1;
    cookies.set('pis', pis);
}

export function clearPis() {
    cookies.remove('pis');
}

export function getGuidForClickstream() {
    return cookies.get('guid');
}

/**
 * Set a stable GUID for the Clickstream SDK (HPWEB-6215).
 *
 * The Clickstream SDK requires a stable user token for analytics tracking.
 * Currently, when a user logs in or out of HotPads, their user token ('ut' cookie) is reset.
 * This function creates a stable guid cookie mapped to an initial user token received from the API.
 *
 * @param {string} guid - The GUID to set for the Clickstream SDK.
 * @returns {void}
 */
export function setGuidForClickstream(guid: string) {
    if (getGuidForClickstream()) {
        return;
    }

    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 10);
    cookies.set('guid', guid, { expires: expirationDate });
}
