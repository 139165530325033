import Area from './Area';

interface RelatedAreasProps {
    borough?: Area[];
    city?: Area[];
    county?: Area[];
    elemschdist?: Area[];
    highschool?: Area[];
    middleschool?: Area[];
    mixedschool?: Area[];
    neighborhood?: Area[];
    primaryschool?: Area[];
    secschdist?: Area[];
    state?: Area[];
    unifschdist?: Area[];
    university?: Area[];
    zip?: Area[];
}

// Define the class with TypeScript
class RelatedAreas {
    borough: Area[];
    city: Area[];
    county: Area[];
    elemschdist: Area[];
    highschool: Area[];
    middleschool: Area[];
    mixedschool: Area[];
    neighborhood: Area[];
    primaryschool: Area[];
    secschdist: Area[];
    state: Area[];
    unifschdist: Area[];
    university: Area[];
    zip: Area[];

    constructor({
        borough = [],
        city = [],
        county = [],
        elemschdist = [],
        highschool = [],
        middleschool = [],
        mixedschool = [],
        neighborhood = [],
        primaryschool = [],
        secschdist = [],
        state = [],
        unifschdist = [],
        university = [],
        zip = []
    }: RelatedAreasProps = {}) {
        this.borough = borough;
        this.city = city;
        this.county = county;
        this.elemschdist = elemschdist;
        this.highschool = highschool;
        this.middleschool = middleschool;
        this.mixedschool = mixedschool;
        this.neighborhood = neighborhood;
        this.primaryschool = primaryschool;
        this.secschdist = secschdist;
        this.state = state;
        this.unifschdist = unifschdist;
        this.university = university;
        this.zip = zip;
    }
}

export default RelatedAreas;
