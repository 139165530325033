import constants from 'app/shared/constants/ConstantsBundle';
import reduxUtils from 'app/shared/utils/reduxUtils';
import merge from 'lodash/merge';

interface State {
    searchExperience: string;
}

interface Action {
    type: string;
    payload: any;
}

const initState = (): State => ({
    searchExperience: 'list'
});

const mapActionsToReducer = {
    [constants.VIEW_SEARCH_EXPERIENCE]: (state: State, action: Action): State => {
        let searchExperience = 'list';
        if (action.payload === 'map') {
            searchExperience = action.payload;
        }

        return merge({}, state, { searchExperience });
    }
};

const reducer = reduxUtils.createReducer(mapActionsToReducer, initState());

export default reducer;