import * as zanalytics from '@zillow/zanalytics';

export function pageview(properties = {}, options = {}) {
    if (typeof window === 'undefined') {
        return;
    }

    // @ts-expect-error TODO: properties type do not match expected argument in page, but this is what we've been sending so far.
    zanalytics.page(properties, options);
}
