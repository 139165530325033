/**
 * GOOGLE ANALYTICS EVENT HOW TO:
 * Basic structure of a Google Analytics event object looks like this:
 *
 * EVENT_NAME_CONSTANT = {
 *     category: String (required),
 *     action: (required),
 *     label: (optional),
 *     value: (optional)
 * }
 */

// TO DO: make GaEvents its own file...

const gaEvents = {
    ADVANCED_FILTER_HANDLE_CHANGE: {
        category: 'FilterEvents',
        description: '?'
    },
    INLINE_FILTER_HANDLE_CHANGE: {
        category: 'InlineFilterEvents'
        // action
        // label
    },
    AREAPAGE_TOP_CITIES_CLICK: {
        category: 'AreaPage',
        action: 'ClickedTopCities',
        description: 'Track when a user clicks a Top Cities link in the Area Page sidebar.'
    },
    LISTINGS_SCROLL_ANYWHERE: {
        category: 'ListingsWrapper',
        action: 'ScrollAnywhere',
        nonInteraction: true,
        description: 'User scrolls (non-user interaction event).'
    },
    LISTINGS_SCROLL_BOTTOM: {
        category: 'ListingsWrapper',
        action: 'ScrollBottom',
        description: 'User has scrolled to bottom of page (interaction event).'
    },
    LISTINGS_CLICK_NEXT_PAGE: {
        category: 'ListingsWrapper',
        action: 'NextPage',
        description: 'User clicked Next page >'
    },
    LISTINGS_CLICK_PREV_PAGE: {
        category: 'ListingsWrapper',
        action: 'PrevPage',
        description: 'User clicked < Prev page'
    },
    LISTINGS_GO_TO_PAGE: {
        category: 'ListingsWrapper',
        action: 'GoToPage',
        description: 'User clicked on a specific page number in listings'
    },
    LIST_OR_MANAGE_RENTALS: {
        category: 'listYourRental',
        description: 'User clicked on "List your rental" or "Manage my properties" link'
    },
    EXPLORE_CLICK_NEXT_PAGE: {
        category: 'AreaPage',
        action: 'NextPageOfSearchResults',
        description: 'User clicked next page of search results at bottom of area explroe content'
    },
    COMMUTEV2_ADD_DEST_HDP: {
        category: 'CommuteTimeV2',
        action: 'AddCommuteDest',
        label: 'HDP',
        description: 'User clicks Add Destination button in Commute Time V2 module on HDP'
    },
    COMMUTEV2_CHANGE_DEST_HDP: {
        category: 'CommuteTimeV2',
        action: 'ChangeCommuteDest',
        label: 'HDP',
        description: 'User clicks Change Destination button in Commute Time V2 module on HDP'
    },
    COMMUTEV2_EDIT_DEST_HDP: {
        category: 'CommuteTimeV2',
        action: 'EditCommuteDest',
        label: 'HDP',
        description: 'User clicks Edit link in Commute Time V2 module on HDP'
    },
    COMMUTEV2_MAP_CLICK_HDP: {
        category: 'CommuteTimeV2',
        action: 'MapsOpenCommute',
        label: 'HDP',
        description: 'User clicks HDP map to open CommuteTimes modal.'
    },
    COMMUTEV2_DRIVING_CLICK_HDP: {
        category: 'CommuteTimeV2',
        action: 'DrivingModeOpenCommute',
        label: 'HDP',
        description: 'User clicks driving mode to open CommuteTimes modal.'
    },
    COMMUTEV2_TRANSIT_CLICK_HDP: {
        category: 'CommuteTimeV2',
        action: 'TransitModeOpenCommute',
        label: 'HDP',
        description: 'User clicks transit mode to open CommuteTimes modal.'
    },
    COMMUTEV2_FOOT_CLICK_HDP: {
        category: 'CommuteTimeV2',
        action: 'FootModeOpenCommute',
        label: 'HDP',
        description: 'User clicks walking mode to open CommuteTimes modal.'
    },
    COMMUTEV2_BIKE_CLICK_HDP: {
        category: 'CommuteTimeV2',
        action: 'BikeModeOpenCommute',
        label: 'HDP',
        description: 'User clicks bike mode to open CommuteTimes modal.'
    },
    COMMUTEV2_DEPART_NOW: {
        category: 'CommuteTimeV2',
        action: 'CommuteNow',
        label: 'CommuteModal',
        description: 'User clicks Depart Now.'
    },
    COMMUTEV2_DEPART_RUSH: {
        category: 'CommuteTimeV2',
        action: 'CommuteRushHour',
        label: 'CommuteModal',
        description: 'User clicks Rush Hour.'
    },
    COMMUTEV2_DEPART_OFFPEAK: {
        category: 'CommuteTimeV2',
        action: 'CommuteOffPeak',
        label: 'CommuteModal',
        description: 'User clicks Off Peak.'
    },
    COMMUTEV2_DRIVE_CLICK_MODAL: {
        category: 'CommuteTimeV2',
        action: 'GetDrivingModeTime',
        label: 'CommuteModal',
        description: 'User clicks drive directions in modal.'
    },
    COMMUTEV2_TRANSIT_CLICK_MODAL: {
        category: 'CommuteTimeV2',
        action: 'GetTransitModeTime',
        label: 'CommuteModal',
        description: 'User clicks transit directions in modal.'
    },
    COMMUTEV2_WALK_CLICK_MODAL: {
        category: 'CommuteTimeV2',
        action: 'GetFootModeTime',
        label: 'CommuteModal',
        description: 'User clicks walking directions in modal.'
    },
    COMMUTEV2_BIKE_CLICK_MODAL: {
        category: 'CommuteTimeV2',
        action: 'GetBikeModeTime',
        label: 'CommuteModal',
        description: 'User clicks bike directions in modal.'
    },
    COMMUTEV2_SEARCH_LOCATION: {
        category: 'CommuteTimeV2',
        action: 'SearchCommuteLocation',
        label: 'CommuteModal',
        description: 'User searches for a location.'
    },
    COMMUTE_ADD_DESTINATION: {
        category: 'CommuteTimes',
        action: 'AddDestination',
        description: 'User clicks Add Destination link in commute time module on the HDP'
    },
    COMMUTE_AUTOCOMPLETE_ADDRESS_CLICK: {
        category: 'CommuteTimes',
        action: 'AutoCompleteAddressClick',
        description: 'User clicks an address that appeared in the places autocomplete dropdown menu.'
    },
    COMMUTE_ERROR_FETCHING_DATA: {
        category: 'CommuteTimes',
        action: 'ErrorOccurred',
        description: 'Triggered when an error occurrs while trying to fetch commute times.'
    },
    COMMUTE_FILTER_SELECT_LOC: {
        category: 'SearchByCommute',
        action: 'FilterNav-SelectLocation',
        description: 'User clicks Edit link in SearchByCommute FilterNav'
    },
    COMMUTE_FILTER_MODE: {
        category: 'SearchByCommute',
        action: 'FilterNav-SelectMode',
        description: 'User selects a transit mode in SearchByCommute FilterNav'
    },
    COMMUTE_FILTER_DEPART: {
        category: 'SearchByCommute',
        action: 'FilterNav-SelectDepartureTime',
        description: 'User selects a departure time in SearchByCommute FilterNav'
    },
    COMMUTE_FILTER_TIME: {
        category: 'SearchByCommute',
        action: 'FilterNav-SelectMaxTime',
        description: 'User selects a maximum transit time in SearchByCommute FilterNav'
    },
    COMMUTE_FILTER_RESET: {
        category: 'SearchByCommute',
        action: 'FilterNav-Reset',
        description: 'User resets mode, departure time, and time in transit'
    },
    COMMUTE_PREV_ADDRESS_CLICK: {
        category: 'CommuteTimes',
        action: 'PreviouslyEnteredAddressClick',
        description: 'User clicks an address that they already saved.'
    },
    COMMUTE_REQ_FROM_LOGGED_OUT: {
        category: 'CommuteTimes',
        action: 'LoggedOutUserRequest',
        description: 'Triggered when a HotPads user tries to fetch commute times before logging in.'
    },
    CONTACT_FORM_INLINE: {
        category: 'Contact',
        action: 'Inline-Form-Contact',
        description: 'Track when a user makes a contact from an inline form.'
    },
    CONTACT_FORM_MODAL: {
        category: 'Contact',
        action: 'Modal-Form-Contact',
        description: 'Track when a user makes a contact from a modal form.'
    },
    CONTACT_FORM_FLOATING: {
        category: 'Contact',
        action: 'Floating-Form-Contact',
        description: 'Tracks when a user makes a contact from the new HDP experience'
    },
    CONTACT_SUCCESS: {
        category: 'PostLead',
        action: 'Contact-Success-Displayed',
        nonInteraction: true,
        description: 'Track when a message has successfully been sent to a landlord and we show modal to user.'
    },
    OPEN_SEARCH_ALERT_MODAL_SRP: {
        category: 'SearchAlerts',
        action: 'openSearchAlertModalSRP',
        description: 'Track when web or mWeb user opens the search alert modal from the SRP.'
    },
    OPEN_SEARCH_ALERT_MODAL_MAP: {
        category: 'SearchAlerts',
        action: 'openSearchAlertModalMap',
        description: 'Track when mWeb user opens search alert modal from map.'
    },
    OPEN_SEARCH_ALERT_MODAL_MOBILE_FILTER: {
        category: 'SearchAlerts',
        action: 'openSearchAlertModalMobileFilter',
        description: 'Track when mWeb user user opens the search alert modal from the mobile FilterSummaryBar.'
    },
    FILTER_REMOVED_FROM_SRP: {
        category: 'FilterLabels',
        action: 'FilterLabelRemoved',
        description: 'Track when user removes a filter label due to having < 5 listings results in a search'
    },
    FOOTER_NAV_CLICK_TO: {
        category: 'FooterNav',
        description: 'Track when a user clicks a link in the Footer Nav.'
    },
    GOOGLE_MAPS_TYPE: {
        category: 'MapSearch',
        description: 'Track when user toggles Google Map to a different type.'
    },
    GOOGLE_MAPS_ZOOM: {
        category: 'GoogleMap',
        action: 'MapZoom',
        description: 'User zoomed in or out on the map'
    },
    GOOGLE_MAPS_DRAG: {
        category: 'GoogleMap',
        action: 'MapDrag',
        description: 'User moved the map'
    },
    HAMBURGER_NAV_CLICK: {
        category: 'HamburgerNav',
        description: 'Track when a user clicks a link in the Hamburger Nav menu.'
    },
    HAMBURGER_NAV_OPEN: {
        category: 'HamburgerNav',
        action: 'HamburgerNav Opened',
        description: 'Track when a user opens the Hamburger Nav menu.'
    },
    HEADER_SIGN_IN: {
        category: 'Header',
        action: 'Sign in - Opened',
        description: 'Track when a user clicks the sign in link in the Header Nav.'
    },
    USER_ACTION_SIGN_IN: {
        category: 'UserAction',
        action: 'Sign in - Opened',
        description: 'Track when a user hits the reg wall trying to hide/favorite a listing.'
    },
    SRP_LISTING_WITH_3D_TOUR_CLICK: {
        category: 'listingWith3dTour',
        action: 'listingClick',
        description: 'Track when a user clicks on listing card that has the 3D tour badge'
    },
    HDP_SUMMARY_CALL_CLICK: {
        category: 'HDP',
        action: 'SummaryCallButtonClick',
        description: 'User clicks call button from HDP Summary phone icon'
    },
    HDP_CONTACT_CTA_APPLICATION_REQUEST: {
        action: 'requestedChecked',
        category: 'Application',
        label: 'inquiry'
    },
    HDP_CONTACT_CTA_APPLICATION_REQUEST_SUCCESS: {
        action: 'requested',
        category: 'Application',
        label: 'inquiry'
    },
    HDP_APPLICATION_CTA_APPLICATION_REQUEST: {
        action: 'requestedCtaClick',
        category: 'Application',
        label: 'hdp'
    },
    HDP_APPLICATION_CTA_APPLICATION_REQUEST_SUCCESS: {
        action: 'requested',
        category: 'Application',
        label: 'hdp'
    },
    HDP_APPLICATION_CTA_APPLY_NOW: {
        action: 'ctaClick',
        category: 'Application',
        label: 'hdp'
    },
    HDP_APPLICATION_INTERSTITIAL_CTA_APPLY_NOW_BY_ZILLOW: {
        action: 'ctaClick',
        category: 'ApplicationInterstitialByZillow',
        label: 'hdp'
    },
    HDP_PET_POLICY_CTA_CLICK: {
        action: 'ctaClick',
        category: 'petPolicy',
        label: 'hdp'
    },
    HDP_CONTACT_CTA_CLICK: {
        category: 'HDP',
        action: 'ContactCtaClick',
        description: `Track when user clicks 'Contact Now' CTA button on HDP.`
        // label: inline | fixed - ['defaultCTA | waitlistedCTA | firstToContactCTA | contactAgainCTA']
    },

    // HDP Navbar
    HDP_MOBILE_CLOSE: {
        category: 'MobileHeader',
        action: 'CloseHDP',
        description: 'Track when user closes an HDP on mWeb.'
    },
    HDP_NAVBAR_MENU_OPEN: {
        category: 'HdpNavBar',
        action: 'HdpNavBarOpened',
        description: 'Track when a user opens the menu on the HDP Nav Bar.'
    },
    HDP_NAVBAR_MENU_ACTION_CLICK: {
        category: 'HdpNavBar',
        action: 'HdpNavBarActionClick',
        description: 'Track when a user selects an action on the HDP Nav Bar menu.'
    },
    HDP_FAVORITE_ATTEMPT: {
        category: 'HDP',
        action: 'tryFavorited',
        description: 'HPWEB-5022: Track when user attempts to favorite a listing.'
    },
    HDP_FAVORITED: {
        category: 'HDP',
        action: 'Favorited',
        description: 'Track when a user favorites a listing from the HDP.'
    },
    HDP_UNFAVORITED: {
        category: 'HDP',
        action: 'Unfavorited',
        description: 'Track when a user unfavorites a listing from the HDP.'
    },
    HDP_UNFAVORITED_ATTEMPT: {
        category: 'HDP',
        action: 'tryUnfavorited',
        description: 'Track when a user attempts to unfavorite a listing from the HDP.'
    },
    HDP_HIDDEN: {
        category: 'HDP',
        action: 'Hidden',
        description: 'Track when a user hides a listing from the HDP.'
    },
    HDP_UNHIDDEN: {
        category: 'HDP',
        action: 'Hidden',
        description: 'Track when a user unhides a listing from the HDP.'
    },
    HDP_REPORTED: {
        category: 'HDP',
        action: 'Reported',
        description: 'Track when a user reports a listing from the HDP.'
    },
    HDP_NEXT_CLICK: {
        category: 'HDP',
        action: 'hdpToHdpNext',
        description: 'Track when a user clicks next on hdp to hdp navigation'
    },
    HDP_PREV_CLICK: {
        category: 'HDP',
        action: 'hdpToHdpNext',
        description: 'Track when a user clicks previous on hdp to hdp navigation'
    },
    HDP_SHARE: {
        category: 'HDP',
        action: 'Share',
        description: 'Track when a user clicks share button on the HDP Nav Bar.'
    },

    // HDP Sharing
    HDP_NATIVE_SHARE: {
        category: 'HDP',
        action: 'Native Share to Friend',
        description: 'Track when a user uses web share api to share a listing to a friend from the HDP.'
    },
    HDP_COPY_LINK: {
        category: 'HDP',
        action: 'Copy Link',
        description: 'Track when a user copies the link from the share tray on the HDP.'
    },
    HDP_SMS_FRIEND: {
        category: 'HDP',
        action: 'SMS Friend',
        description: 'Track when a user shares a listing by sending an SMS to a friend from the HDP.'
    },
    HDP_EMAIL_FRIEND: {
        category: 'HDP',
        action: 'Email Friend',
        description: 'Track when a user shares a listing by emailing a friend from the HDP.'
    },
    HDP_FACEBOOK_SHARE_FRIEND: {
        category: 'HDP',
        action: 'Facebook Share to Friend',
        description: 'Track when a user shares a listing via Facebook to a friend from the HDP.'
    },
    HDP_TWITTER_SHARE_FRIEND: {
        category: 'HDP',
        action: 'Twitter Share to Friend',
        description: 'Track when a user shares a listing via Twitter to a friend from the HDP.'
    },

    // HDP Other
    HDP_TOGGLE_SECTION: {
        category: 'HDP',
        action: 'Collapsable-Section',
        description: 'Track when a user toggles between hiding or showing a section on the HDP.'
    },
    HDP_EXPIRED_AREA_CLICK: {
        category: 'HDP',
        action: 'ExpiredListing-View-Area-Click',
        description: 'Track when a user clicks to view area page at bottom of an expired HDP.'
    },
    HDP_PPC_CLICKED_UPPER: {
        category: 'HDP',
        action: 'Upper PPC link clicked',
        description: 'Track when a user clicks the upper PPC link on the HDP.'
    },
    HDP_PPC_CLICKED_LOWER: {
        category: 'HDP',
        action: 'Lower PPC link clicked',
        description: 'Track when a user clicks the lower PPC link on the HDP.'
    },
    HDP_SCROLL_REVEALS_LEAD_FORM: {
        category: 'HDP',
        action: 'LeadFormRevealed',
        nonInteraction: true,
        description: 'User scrolls full lead form into view (user interaction event).'
    },
    HDP_OPEN_PHOTO_GALLERY_FULL: {
        category: 'HDP',
        label: 'PhotoPlayer',
        action: 'OpenPlayer',
        description: 'User clicked on image in HDP to open full photo gallery player (fires at most once per hdp)'
    },
    HOMEPAGE_HPPP_UPSELL: {
        category: 'PostAListing',
        action: 'ClickedUpsell',
        description: 'Track when a user clicks the HPPP upsell link on the HotPads homepage.'
    },
    HDP_RECOMMENDED_CLICK: {
        category: 'HDP',
        action: 'recommendedListing',
        description: 'Track when a user clicks on a recommended listing.'
    },
    HDP_EXPIRED_RECOMMENDATION_CLICK: {
        category: 'HDP',
        action: 'recommendedExpiredListing',
        description: 'Track when a user clicks on an expired recommended listing'
    },
    HDP_RECENTLY_VIEWED_RECOMMENDED_CLICK: {
        category: 'HDP',
        action: 'recommendedRecentlyViewedListing',
        description: 'Track when a user clicks on a recommended recently viewed listing.'
    },
    HDP_SIMILAR_LISTINGS_CLICK: {
        category: 'HDP',
        action: 'recommendedSimilarListing',
        description: 'Track when a user clicks on a similar listing from the similarListings component.'
    },

    // HP Instant Tours
    HPTOUR_BOOK_TOUR_NOW: {
        category: 'HDP',
        action: 'instantTourCtaClick',
        label: 'inline'
    },
    HPTOUR_BOOK_ANOTHER_TOUR: {
        category: 'HDP',
        action: 'bookAnotherTourCtaClick',
        label: 'inline'
    },
    HPTOUR_TOUR_TYPE_SELECT: {
        category: 'InstantTourModal',
        action: 'tourTypeRadioButton'
        // label: 'inPerson' | 'liveVideo' | 'selfGuided,
    },
    HPTOUR_TOUR_TYPE_CONTINUE: {
        category: 'InstantTourModal',
        action: 'tourTypeScreenContinue'
    },
    HPTOUR_CONTINUE_DATE_SELECTION: {
        category: 'InstantTourModal',
        action: 'dateSelectionContinue'
    },
    HPTOUR_GO_BACK_DATE_SELECTION: {
        category: 'InstantTourModal',
        action: 'dateSelectionGoBack'
    },
    HPTOUR_SCHEDULE_TOUR_SUBMIT: {
        category: 'InstantTourModal',
        action: 'scheduleTourSubmit'
    },
    HPTOUR_GO_BACK_SCHEDULE_TOUR: {
        category: 'InstantTourModal',
        action: 'scheduleTourGoBack'
    },
    HPTOUR_SCHEDULE_TOUR_ERROR: {
        category: 'InstantTourModal',
        action: 'scheduleTourFailed'
        // label: <possible error types>
    },
    HPTOUR_SCHEDULE_TOUR_ERROR_CHOOSE_NEW_TIME: {
        category: 'InstantTourModal',
        action: 'scheduleTourErrorChooseNewTimeCtaClick'
    },
    HPTOUR_SCHEDULE_TOUR_ERROR_GO_BACK: {
        category: 'InstantTourModal',
        action: 'scheduleTourErrorGoBackCtaClick'
    },
    HPTOUR_SCHEDULE_TOUR_SUCCESS: {
        category: 'InstantTourModal',
        action: 'scheduleTourSuccess'
    },
    HPTOUR_INSTANT_TOUR_CLOSE: {
        category: 'InstantTourModal',
        action: 'exitModalTop'
        // label: screen that was exited from.
    },
    HPTOUR_RESCHEDULE_TOUR_SUCCESS: {
        category: 'InstantTourModal',
        action: 'rescheduleTourClick',
        label: 'successScreen'
    },
    HPTOUR_RESCHEDULE_TOUR_HDP: {
        category: 'HDP',
        action: 'rescheduleTourClick',
        label: 'inline'
    },
    HPTOUR_CONTACT_SUCCESS: {
        category: 'InstantTourModal',
        action: 'contactPropertyCtaClick',
        label: 'successScreen'
    },
    HPTOUR_CONTACT_HDP: {
        category: 'HDP',
        action: 'contactPropertyCtaClick',
        label: 'inline'
    },
    HPTOUR_CANCEL_TOUR_SUBMIT: {
        category: 'InstantTourModal',
        action: 'cancelTourCtaClick'
    },
    HPTOUR_CANCEL_TOUR_SUCCESS: {
        category: 'HDP',
        action: 'cancelTourSuccess'
    },
    HPTOUR_CANCEL_TOUR_ERROR: {
        category: 'HDP',
        action: 'cancelTourError'
        // label: <possible error types>
    },
    HPTOUR_CANCEL_CHOOSE_NEW_TIME: {
        category: 'InstantTourModal',
        action: 'cancelChooseNewTimeCtaClick'
    },
    HPTOUR_CHANGE_TOUR_SUBMIT: {
        category: 'InstantTourModal',
        action: 'changeCtaClick'
    },
    HPTOUR_CHANGE_SUCCESS: {
        category: 'InstantTourModal',
        action: 'reschduleTourSuccess'
    },
    HPTOUR_CHANGE_ERROR: {
        category: 'InstantTourModal',
        action: 'reschduleTourFail'
        // label: <possible error types>
    },
    HPTOUR_SYSTEM_ERROR: {
        category: 'InstantTourModal',
        action: 'systemError'
        // label: <possible error types>
    },
    HPTOUR_LOGIN_PROMPTED: {
        category: 'InstantTourModal',
        action: 'LoginPrompted'
    },
    HPTOUR_LOGIN_SKIPPED: {
        category: 'InstantTourModal',
        action: 'LoginSkipped'
    },
    HPTOUR_LOGIN_SUCCESS: {
        category: 'InstantTourModal',
        action: 'LoginSuccess'
    },
    HPTOUR_LOGIN_FREEPASS: {
        category: 'InstantTourModal',
        action: 'LoginFreepass'
    },

    RENTER_PROFILE_SAVE: {
        category: 'UserDashboard', // one of 'UserDashboard' or 'HDP'
        label: 'SaveButtonClick',
        action: 'RenterProfile'
    },
    RENTER_PROFILE_POST_CONTACT_CLOSE: {
        category: 'HDP',
        label: 'PopupClosedByUser',
        action: 'RenterProfile'
    },
    RENTER_PROFILE_CANCEL: {
        category: 'HDP',
        label: 'CancelButtonClick',
        action: 'RenterProfile'
    },

    // ZRM Upsell CTAs
    ZRM_UPSELL_ZRM: {
        category: 'HDP_zrm_upsell',
        label: 'zrm_get_started',
        action: 'click_posting_path'
    },
    ZRM_UPSELL_POST_LISTING: {
        category: 'HDP_zrm_upsell',
        label: 'post_listing_get_started',
        action: 'click_posting_path'
    },
    ZRM_UPSELL_POST_LISTING_LEARN_MORE: {
        category: 'HDP_zrm_upsell',
        label: 'post_listing_learn_more',
        action: 'click'
    },
    ZRM_UPSELL_SCREEN_TENANTS: {
        category: 'HDP_zrm_upsell',
        label: 'screen_tenants_get_started',
        action: 'click_through_applications'
    },
    ZRM_UPSELL_SCREEN_TENANTS_LEARN_MORE: {
        category: 'HDP_zrm_upsell',
        label: 'screen_tenants_learn_more',
        action: 'click'
    },
    ZRM_UPSELL_SIGN_LEASE: {
        category: 'HDP_zrm_upsell',
        label: 'leases_create_lease',
        action: 'click_through_leases'
    },
    ZRM_UPSELL_SIGN_LEASE_LEARN_MORE: {
        category: 'HDP_zrm_upsell',
        label: 'leases_learn_more',
        action: 'click'
    },
    ZRM_UPSELL_COLLECT_RENT: {
        category: 'HDP_zrm_upsell',
        label: 'payments_get_paid',
        action: 'click_through_payments'
    },
    ZRM_UPSELL_COLLECT_RENT_LEARN_MORE: {
        category: 'HDP_zrm_upsell',
        label: 'payments_learn_more',
        action: 'click'
    },
    ZRM_UPSELL_FREE_LISTING: {
        category: 'HDP_zrm_upsell',
        label: 'free_listing_get_started',
        action: 'click_posting_path'
    },

    FDP_FLOOR_PLAN_CONTACT_CLICK: {
        category: 'FloorPlanFDP',
        action: 'ContactCtaClick',
        description: 'Track when a user clicks the contact button on the floor plan full detail page.'
    },
    FDP_CLICK: {
        category: 'HDP', // or PhotoGallery for floor plans
        // action: currently: FloorPlanFDPClick | future: AmenityFDPClick, PetFDPClick, etc
        description: 'Track when a user opens a full detail page.'
    },
    LEAD_EMAIL: {
        category: 'Lead',
        action: 'Email',
        description: 'Track when a user sends an inquiry to a paid listing.'
    },
    LEAD_PHONE: {
        category: 'Lead',
        action: 'Phone',
        description: 'Tracks when a user clicks a phone number or call icon.'
        // label: 'paid', 'paidDemoted', 'free'
    },
    SHOW_MORE_LINK_TOGGLE: {
        action: 'ShowMore',
        description: 'Track when a user clicks LinkToggle to show more/less.'
    },
    LISTING_CARD_IMAGE_NEXT: {
        category: 'AreaPage',
        action: 'SRP_ListingCard',
        label: 'Image_Gallery_Next_Click'
    },
    LISTING_CARD_IMAGE_PREV: {
        category: 'AreaPage',
        action: 'SRP_ListingCard',
        label: 'Image_Gallery_Prev_Click'
    },
    LISTING_CARD_SEE_MORE_UNITS: {
        action: 'AreaPage',
        category: 'SRP_ListingCard',
        label: 'See_More_Units_Click'
    },
    LISTING_NO_IMAGES: {
        category: 'ListingNoImages',
        description: 'Track when a listing card or HDP is shown that had zero images.'
    },
    LOGIN_FACEBOOK: {
        category: 'Login',
        action: 'Facebook',
        description: 'Track when a user is logged in through Facebook.'
    },
    LOGIN_FREEPASS: {
        category: 'Login',
        action: 'Freepass',
        description: 'Track when a user is logged in through Freepass.'
    },
    SIGN_IN_WITH_GOOGLE_SUCCESS: {
        category: 'Login',
        action: 'SignInWithGoogle',
        description: 'Track when a user logs in through Sign In With Google.'
    },
    MF_FLOORPLAN_CLICK: {
        category: 'MultiFamily',
        action: 'FloorplanClick',
        description: 'Track when a user clicks a floorplan on multifamily listing'
    },
    MOVE_IN_DATE_FILTER: {
        category: 'FilterEvents',
        action: 'moveInDate',
        description: 'Track when a user applies the move-in date filter in the filter tray'
    },
    MOBILE_MAP_ICON_CLICK: {
        category: 'MobileMapInteraction',
        action: 'MapClick'
    },
    NAV_BAR_CLICK: {
        category: 'NavBarClick',
        description: 'Track any links a user clicks on the NavBar.'
    },
    OPT_IN_REC_SEARCH: {
        category: 'InquirySuccess',
        action: 'SubscribeRecSearchPostInquiry',
        description: 'Track when user opts in rec search after sending an inquiry'
    },
    OPT_IN_SAVED_SEARCH: {
        category: 'SavedSearchAlertSuccess',
        action: 'OptInSearchAlerts',
        description: 'Track when user opts in saved searches after creating a saved search'
    },
    NEARBY_AREA_CLICKED: {
        category: 'NearbyAreasOnSpareAreas',
        action: 'ClickNearbyAreaLink',
        description: 'Track when user clicks on nearby area link when landing on a page with less than 5 listings'
    },
    NEARBY_HDP_CLICKED: {
        category: 'NearbyAreasOnSpareAreas',
        action: 'ClickHdpInNearbyArea',
        description:
            'Track when user clicks on an hdp in a nearby area when landing on a page with less than 5 listings'
    },
    BLOG_POSTS: {
        category: 'BlogPosts',
        label: 'AreaPage',
        description: 'Track when a user interacts with the blog posts carousel'
        // action: Click | Swipe
    },
    NOTIFICATION_ACCEPT: {
        category: 'Notification',
        action: 'Accept',
        description: 'Track when a user opts into notifications.'
    },
    NOTIFICATION_DISMISS: {
        category: 'Notification',
        action: 'Dismiss',
        description: 'Track when a user dismisses a notification'
    },
    NOTIFICATION_SHOW: {
        category: 'Notification',
        action: 'Show',
        nonInteraction: true,
        description: 'Track when we show a notification to a user.'
    },
    NOTIFICATION_CHANGE: {
        category: 'SearchAlerts',
        label: 'UserSettingsPage',
        description: 'Track when a user makes a change to their notifications setting on their user profile page.'
    },
    NOTIFICATION_EDIT_DEVICES: {
        category: 'SearchAlerts',
        label: 'UserSettingsPage',
        description: 'Track when user changes a device notification setting.'
    },
    NOTIFICATION_STOP_ALL: {
        category: 'SearchAlerts',
        label: 'UserSettingsPage',
        description: 'Track when a user chooses to stop all notifications from their user profile page.'
    },
    CCPA_EXPORT_OR_DELETE: {
        category: 'CCPA',
        label: 'UserSettingsPage',
        description: 'Track when a user clicks to go to the Zillow CCPA page.'
    },
    PPC_LINK_CLICKED: {
        category: 'PPC',
        action: 'PPC link clicked',
        description: 'Track when a user clicks a PpcLink component.'
    },
    PPC_LINK_DISPLAYED: {
        category: 'HDP',
        action: 'PPC link displayed',
        nonInteraction: true,
        description: 'Track if a PPC link is shown to a user on a valid device (e.g., desktop).'
    },
    HDP_GALLERY_CONTROLS_CLICK: {
        category: 'HDP',
        action: 'Click on hdp gallery control buttons',
        description: 'Track which gallery button controls are clicked on the hdp (street view, map view, etc.)'
    },
    PHOTO_GALLERY_CONTACT_CTA: {
        category: 'PhotoGallery',
        action: 'EndOfPhotosUpsellContactClick',
        description: 'Track when a user clicks the contact button at end of the Photo Gallery.'
    },
    PHOTO_GALLERY_MODAL_CONTACT_CTA: {
        category: 'PhotoGallery',
        action: 'PhotoGalleryModalContactClick',
        description: 'Track when a user clicks the contact button from the Photo Gallery modal.'
    },
    PHOTO_GALLERY_FULL_CONTACT_CTA: {
        category: 'PhotoGallery',
        action: 'PhotoGalleryFullContactClick',
        description: 'Track when a user clicks the contact button from the full Photo Gallery.'
    },
    SELECT_AREA_SUGGESTION: {
        category: 'Search',
        action: 'SelectAreaSuggestion',
        description: 'Track when a user selects a suggested area from AutocompleteSearchInput.'
    },
    RESUME_RECENT_SEARCH: {
        category: 'Search',
        action: 'Resume',
        description: 'Track when a user resumes a recent search from AutocompleteSearchInput.'
    },
    RESUME_SAVED_SEARCH: {
        category: 'Search',
        action: 'ResumeSavedSearch',
        description: 'Track when a user resumes a saved search from AutocompleteSearchInput.'
    },
    SELECT_GEOIP_SEARCH: {
        category: 'Search',
        action: 'SelectGeoipSearch',
        description: 'Track when a user selects geoip choice from AutocompleteSearchInput.'
    },
    SEARCH_ALERT_CREATE: {
        category: 'SearchAlerts',
        action: 'CreateNewSearchAlert',
        description: 'Track when a user creates a new search alert.'
    },
    SEARCH_ALERT_DELETE: {
        category: 'SearchAlerts',
        label: 'SavedSearchPage',
        action: 'Delete',
        description: 'Track when a user deletes a saved search from the Saved Search Page.'
    },
    SEARCH_ALERT_DELETE_CONFIRM: {
        category: 'SearchAlerts',
        action: 'Delete - Confirm',
        description: 'Track when a user confirms to delete a saved search.'
    },
    SEARCH_ALERT_EDIT: {
        category: 'SearchAlerts',
        label: 'SavedSearchPage',
        action: 'Edit',
        description: 'Track when a user edits a particular search alert from the Saved Search page.'
    },
    SEARCH_ALERT_LOG_IN: {
        category: 'SearchAlerts',
        action: 'Log in',
        description: 'Track when we display the log in prompt to a user trying to create a new search alert.'
    },
    SEARCH_ALERT_VIEW: {
        category: 'SearchAlerts',
        label: 'SavedSearchPage',
        action: 'View',
        description: 'Track when a user views a saved search from the Saved Search Page.'
    },
    SEARCH_ALERT_POST_CONTACT_DISPLAY: {
        category: 'SearchAlerts',
        label: 'postContact',
        action: 'formImpression',
        description: 'Track when we display the post contact saved search modal.'
    },
    SEARCH_ALERT_POST_CONTACT_CREATE: {
        category: 'SearchAlerts',
        label: 'postContact',
        action: 'CreateNewSearchAlert',
        description: 'Track when user creates a saved search through the post contact modal.'
    },
    SEARCH_CURRENT_GEOLOCATION: {
        category: 'Search',
        action: 'Current-Device-Location-GPS',
        description: 'Track when a user clicks the geolocation icon to go to their current GPS location.'
    },
    SEARCH_QUERY_INPUT: {
        category: 'Search',
        description: 'Capture query that user inputted into search field.'
    },
    SEARCH_FREEFORM: {
        category: 'Search',
        action: 'Freeform-Text',
        description: 'Track when user searches for an area using the free form text search.'
    },
    SEARCH_NO_RESULTS: {
        category: 'Search',
        action: 'noResults',
        description: 'Track when user lands on a page with no results.'
    },
    SIMILAR_LISTING_CLICK: {
        category: 'PostLead',
        action: 'Similar-Clicked',
        description: 'Track when a user clicks a similar listing.'
    },
    SIMILAR_LISTING_DISPLAYED: {
        category: 'PostLead',
        action: 'Similar-Displayed',
        nonInteraction: true,
        description: 'Track when a similar listing is displayed.'
    },
    SORT_NAV_CLICK: {
        category: 'Filter',
        description: 'Track when a user changes how the listings are sorted.'
    },
    SORT_USER_ITEMS: {
        category: 'SortOrder',
        // action: added | lowPrice | highPrice | updated
        // label: Favorited | Viewed | Contacted | Hidden
        description: 'Track when a user changes how the listings are sorted on User Items pages.'
    },
    ABLOCK_DETECTED: {
        category: 'Adblock',
        action: 'on',
        description: 'Track when a user has adblock enabled.'
    },
    ABLOCK_NOT_DETECTED: {
        category: 'Adblock',
        action: 'off',
        description: 'Track when a user does not have adblock enabled.'
    },
    USER_MILESTONE_35: {
        category: 'UserEngagement',
        action: 'Milestone35',
        description: 'Track when a user has stayed on the site for at least 35 seconds.',
        nonInteraction: true
    },
    USER_MILESTONE_60: {
        category: 'UserEngagement',
        action: 'Milestone60',
        description: 'Track when a user has stayed on the site for at least 60 seconds.',
        nonInteraction: true
    },
    USER_MILESTONE_90: {
        category: 'UserEngagement',
        action: 'Milestone90',
        description: 'Track when a user has stayed on the site for at least 90 seconds.',
        nonInteraction: true
    },
    UNSUBSCRIBE_FROM_EMAIL: {
        category: 'UnsubscribePage',
        description: 'Track when a user lands on the unsubscribe page after clicking link in email.'
    },
    EDIT_FREQUENCY_FROM_EMAIL: {
        category: 'EditSearchFrequencyPage',
        action: 'ModifySearchAlertFrequencyFromEmail',
        label: 'EditSearchFrequencyPage',
        description:
            'Track when user lands on the edit frequency page after clicking the link in email to change the frequency for their saved search.'
    },
    REACTIVATE_REC_SEARCH: {
        category: 'UnsubscribePage',
        label: 'UnsubscribePage',
        action: 'StaySubscribedRecSearch',
        description: 'Track when a user clicks the resubscribe button in breakup email'
    },
    RESUBSCRIBE_REC_SEARCH: {
        category: 'UnsubscribeSearchesPage',
        label: 'UnsubscribeSearchesPage',
        action: 'ResubscribeRecSearchAfterUnsubscribing',
        description:
            'Track when a user clicks on the link to resubscribe to rec search after clicking unsubscribe from email'
    },
    RESUBSCRIBE_SAVED_SEARCH: {
        category: 'UnsubscribeSearchesPage',
        label: 'UnsubscribeSearchesPage',
        action: 'ResubscribeSavedSearchAfterUnsubscribing',
        description:
            'Track when a user clicks on the link to resubscribe to saved search after clicking unsubscribe from email'
    },
    SATELLITE_CONTACT_VIEW_MSG_CLICK: {
        category: 'Satellite',
        action: 'View-Message',
        label: 'Contact-Box-Click'
    },
    SATELLITE_HDP_VIEW_MSG_CLICK: {
        category: 'Satellite',
        action: 'View-Message',
        label: 'Hdp-Click'
    },
    SATELLITE_NAV_LINK_CLICK: {
        category: 'Satellite',
        action: 'Messages',
        label: 'Header-Nav-Click'
    },
    SEARCH_FAB_FILTER: {
        category: 'Filter',
        action: 'ShowFilterSearchFab',
        label: 'ShowFilterSearchFab',
        description: 'Show filter when user clicks on Filter in search floating action bar'
    },
    SEARCH_FAB_MAPLIST: {
        category: 'Search',
        action: 'MapListToggleSearchFab',
        label: 'MapListToggleSearchFab',
        description: 'Toggle map/list when user clicks on map or list in search floating action bar.'
    },
    REMOVE_BOUNDARY: {
        category: 'Search',
        action: 'RemoveBoundary',
        label: 'RemoveBoundary',
        description: 'Remove border button floating on map.'
    },
    HOME_PAGE_MF_CLICK: {
        category: 'Homepage',
        action: 'MfListingClick',
        description: 'MultiFamily building click from the homepage'
    },
    PRICE_FILTER: {
        category: 'FilterEvents',
        description: 'Tracks when user sets price via input or slider'
    },
    BATHS_FILTER: {
        category: 'FilterEvents',
        action: 'baths',
        description: 'Tracks when user sets a bathrooms filter'
    },
    BED_FILTER: {
        category: 'FilterEvents',
        action: 'bedrooms',
        description: 'Tracks when user sets a bed filter'
    },
    PETS_FILTER: {
        category: 'FilterEvents',
        action: 'pets',
        description: 'Tracks when user sets a filter for pets'
    },
    LAUNDRY_AMENITIES_FILTER: {
        category: 'FilterEvents',
        action: 'laundry',
        label: 'FilterTray',
        description: 'Tracks when a user has selected the in-unit, shared, or "Any" laundry amenity'
    },
    AC_AMENITIES_FILTER: {
        category: 'FilterEvents',
        action: 'airConditioning',
        label: 'FilterTray',
        description: 'Tracks when a user has selected the Air Conditioning amenity'
    },
    HEATING_AMENITIES_FILTER: {
        category: 'FilterEvents',
        action: 'heating',
        label: 'FilterTray',
        description: 'Tracks when a user has selected the Heating amenity'
    },
    PARKING_AMENITIES_FILTER: {
        category: 'FilterEvents',
        action: 'offStreetParking',
        label: 'FilterTray',
        description: 'Tracks when a user has selected the Parking amenity'
    },
    GATED_ENTRY_AMENITIES_FILTER: {
        category: 'FilterEvents',
        action: 'gatedEntry',
        label: 'FilterTray',
        description: 'Tracks when a user has selected the Gated Entry amenity'
    },
    DOORMAN_AMENITIES_FILTER: {
        category: 'FilterEvents',
        action: 'doorman',
        label: 'FilterTray',
        description: 'Tracks when a user has selected the Doorman amenity'
    },
    GYM_AMENITIES_FILTER: {
        category: 'FilterEvents',
        action: 'gym',
        label: 'FilterTray',
        description: 'Tracks when a user has selected the Gym amenity'
    },
    POOL_AMENITIES_FILTER: {
        category: 'FilterEvents',
        action: 'pool',
        label: 'FilterTray',
        description: 'Tracks when a user has selected the Pool amenity'
    },
    DISHWASHER_AMENITIES_FILTER: {
        category: 'FilterEvents',
        action: 'dishwasher',
        label: 'FilterTray',
        description: 'Tracks when a user has selected the Dishwasher amenity'
    },
    FURNISHED_AMENITIES_FILTER: {
        category: 'FilterEvents',
        action: 'furnished',
        label: 'FilterTray',
        description: 'Tracks when a user has selected the Furnished amenity'
    },
    RESET_FILTER: {
        category: 'FilterEvents',
        action: 'Reset',
        description: 'Tracks when user resets filters'
    },
    SHOW_CHAT_WIDGET: {
        category: 'HDP',
        action: 'ShowChatWidget',
        description: 'Show RealPage Chat Widget'
    },
    SHOW_CHAT_BUTTON: {
        category: 'HDP',
        action: 'ShowChatButton',
        description: 'Chat Button Displayed on HDP',
        nonInteraction: true
    },
    INFO_CARDS_SET_FILTER: {
        category: 'FilterEvents',
        label: 'InfoCards',
        description: 'Tracks when user sets filter using info cards'
    },
    NET_PROMOTER_SCORE_SUBMIT: {
        category: 'NetPromoterScore',
        action: 'NetPromoterScore entered',
        description: 'Tracks when user submits a net promoter score'
    },
    HDP_PHOTOGALLERY_3DTOUR_CLICKED: {
        category: 'HDP',
        action: '3dTours',
        label: 'PhotoPlayer'
    },
    HDP_ADDITIONAL_PHOTO_VIEW: {
        category: 'HDP',
        label: 'gallery',
        action: 'photoImpression'
    },
    HDP_FLOORPLAN_3DTOUR_CLICKED: {
        category: 'HDP',
        action: '3dTours',
        label: 'FloorPlan'
    },
    HOMEHUB_NEW_FROM_SAVED_SEARCH: {
        category: 'savedSearch',
        label: 'userHub'
        // action: View | Click | Swipe
    },
    HOMEHUB_NEW_FROM_RECENT_SEARCH: {
        category: 'recentSearch',
        label: 'userHub'
        // action: View | Click | Swipe
    },
    HOMEHUB_SIMILAR_TO_CONTACTED: {
        category: 'similarToContacted',
        label: 'userHub'
        // action: View | Click | Swipe
    },
    HOMEHUB_SIMILAR_TO_FAVORITE: {
        category: 'similarToFavorite',
        label: 'userHub'
        // action: View | Click | Swipe
    },
    HOMEHUB_CONTINUE_WITH: {
        category: 'continueWith',
        label: 'userHub'
        // action: View | Click
    },
    HOMEHUB_EXPAND_SEARCH: {
        category: 'expandSearch',
        label: 'userHub'
        // action: View | Click
    },
    HOMEHUB_LUXURY_APARTMENTS: {
        category: 'luxuryApartments',
        label: 'userHub'
        // action: View | Click | Swipe
    },
    HOMEHUB_CHEAP_APARTMENTS: {
        category: 'cheapApartments',
        label: 'userHub'
        // action: View | Click | Swipe
    },
    HOMEHUB_RENTALS_WITH_PROMOS: {
        category: 'promoApartments',
        label: 'userHub'
        // action: View | Click | Swipe
    },
    HOMEHUB_POPULAR_APARTMENTS: {
        category: 'popularApartments',
        label: 'userHub'
        // action: View | Click | Swipe
    },
    HOMEHUB_RECOMMENDED_SEARCH: {
        category: 'recommendedSearch',
        label: 'userHub'
        // action: View | Click | Swipe
    },
    HOMEHUB_ACCEPTS_APPLICATION: {
        category: 'acceptsApplication',
        label: 'userHub'
        // action: View | Click | Swipe
    },
    FIRST_INPUT_DELAY: {
        category: 'performanceMetrics',
        action: 'firstInputDelay',
        nonInteraction: true,
        description: 'Tracks time it takes for page to respond after user interacts with it'
        // label = event type
        // value = time of delay
    },

    // My Hub Page
    MY_HUB_COLLECTION_IN_VIEWPORT: {
        category: 'MyHub',
        action: 'scroll'
    },
    MY_HUB_VIEW_MORE_CLICK: {
        category: 'MyHub',
        action: 'viewMoreCtaClick'
    },
    MY_HUB_LISTING_CARD_CLICK: {
        category: 'MyHub',
        action: 'listingCardCtaClick'
    },
    MY_HUB_SHOW_OFF_MARKET_CHECK: {
        category: 'MyHub',
        action: 'showOffMarketCheck'
    },
    MY_HUB_NEARBY_AREAS_CLICK: {
        category: 'MyHub',
        action: 'nearbyAreaClick',
        label: 'nearbyAreas'
    }
};

export { gaEvents };
