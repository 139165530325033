// @ts-nocheck
/* eslint-enable */
// App
import api from 'app/shared/utils/api';
import { batch } from 'react-redux';

// Lodash
import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';

// Actions
import AppActions from 'app/shared/flux/actions/AppActions';
import ListingEngineActions from 'app/shared/flux/actions/ListingEngineActions';
import UserSearchActions from 'app/shared/flux/actions/UserSearchActions';

// Models
import Filter, { DEFAULT } from 'app/shared/models/Filter';
import MapData from 'app/shared/models/MapData';

// Misc / Utils
import CONSTANTS from 'app/shared/constants/DispatchConstants';
import VALUE_CONSTANTS from 'app/shared/constants/ValueConstants';
import { PRICE_SLIDER_MAX } from 'app/shared/constants/FilterConstants';
import { listingUtils_getResourceIdAndSlug } from 'app/shared/utils/listingUtils';
import { adapt_queryToJava, adapt_reduxToQuery, adapt_reduxToJava } from './adapters';
import queryUtils from 'app/shared/utils/queryUtils';
import routeUtils from 'app/shared/utils/routeUtils';
import { filterUtils_mapDataIsDifferent, filterUtils_mergeMapDataIntoQuery } from 'app/shared/utils/filterUtils';
import searchSlugUtils from 'app/shared/utils/searchSlugUtils';
import gmapUtils from 'app/client/utils/map/gmapUtils';

const logger = getLogger('actions/mobilefilter');

/**
 * Gathers the proper orderBy depending on vec2Rec or bountyBrain score | experimentScore tests
 * @param {string} orderBy - one of [score | experimentScore | activated | weekViews | lowPrice | highPrice]
 * @param {Object} runningExperiments - Object containing current A/B tests that are running
 */
const _getOrderByData = (orderBy) => {
    if (!orderBy) {
        orderBy = 'score';
    }

    return { orderBy };
};

/**
 * Updates Redux filter reducer
 * @param {Object} filter - Filter object to be saved to Redux filter ;
 */
const _saveFilterToRedux = (filter) => {
    return (dispatch) => {
        try {
            return dispatch({
                type: CONSTANTS.UPDATE_MWEB_FILTER,
                payload: filter
            });
        } catch (error) {
            throw error;
        }
    };
};

/**
 * Rebuilds and saves the Redux Filter state
 * @param {Object} partialFilter - contains the changed portion of the filter
 */
const _createFilter = (partialFilter) => {
    return (dispatch, getState) => {
        try {
            const state = getState();
            const orderByData = _getOrderByData(partialFilter.orderBy, state.analytics.runningExperiments);
            Object.assign(partialFilter, orderByData); // NOTE: Polyfill or use Lodash for desktop migration
            const newFilter = new Filter(partialFilter);
            dispatch(_saveFilterToRedux(newFilter));

            return Promise.resolve({ success: true });
        } catch (error) {
            throw error;
        }
    };
};

/**
 * Generates a new path and query string for the browser url
 */
const _updateUrlWithFilterParams = () => {
    return (_dispatch, getState) => {
        try {
            const state = getState();
            const currentFilter = state.filter;
            const areaResourceId = state.area.area.resourceId;
            const currPathname = state.location.current.pathname;
            const isAreaUrl = routeUtils.isAreaUrl(currPathname);
            const isPadOrBuildingUrl = routeUtils.isPadOrBuildingUrl(currPathname);
            const isNearMeUrl = routeUtils.isNearMeUrl(currPathname);
            let pathname;

            if (isAreaUrl) {
                pathname = routeUtils.buildAreaPath({
                    areaResourceId,
                    searchSlug: currentFilter.search.slug
                });
            } else if (isPadOrBuildingUrl) {
                pathname = currPathname;
            } else if (isNearMeUrl) {
                pathname = routeUtils.buildAreaPath({
                    areaResourceId: state.geolocation.area.resourceId,
                    searchSlug: currentFilter.search.slug
                });
            } else {
                Promise.resolve({ success: false });
            }

            const mapData = window?.map ? new MapData(gmapUtils.getMapData(window.map)) : null;
            const analytics = {
                HPWEB_CONTROL: getState().location.current?.query?.HPWEB_CONTROL,
                HPWEB_EXP: getState().location.current?.query?.HPWEB_EXP
            };
            // Given path and query, build out the query string
            const newQueryString = queryUtils.stringify(
                adapt_reduxToQuery({ filter: currentFilter, mapData, analytics })
            );

            // Assemble new url
            const newUrl = pathname + newQueryString;

            // Somehow push this to the browser url!
            window.router.transitionTo(newUrl);

            Promise.resolve({ success: true });
        } catch (error) {
            throw error;
        }
    };
};

/**
 * Calls Search Slug API and saves it to Redux
 * @param {{
 *  javaFilter: Object - java-ized filter object
 *  hasFilterBeenSet: boolean - flag that resets search slug name if true
 * }}
 * @param {Object} AbortController - The AbortSignal object that allows you to abort one or more DOM requests as and when desired
 */
const _fetchAndSaveSearchSlugData = ({ javaFilter, hasFilterBeenSet = false }, abortControllerSignal) => {
    return (dispatch, getState) => {
        const isValidSearchSlug = getState().app.isValidSearchSlug;

        if (hasFilterBeenSet) {
            javaFilter.searchSlug = 'apartments-for-rent';
        }

        return dispatch(api.area.searchSlugToFilter(javaFilter, abortControllerSignal)).then((res) => {
            const { data = {}, status } = res;
            const { filter = {} } = data;

            if (status === 'INVALID_DATA') {
                // Throw an error
                logger.warn(
                    'MobileFilterActions_fetchFilterFromUrlAndApi invalid search slug: ',
                    javaFilter.searchSlug
                );

                // Show NotFoundPage
                dispatch(AppActions.setAppStoreBool('isValidSearchSlug', false));

                return { error: true };
            }

            if (isValidSearchSlug === false) {
                dispatch(AppActions.setAppStoreBool('isValidSearchSlug', true));
            }

            filter.searchSlug = data.searchSlug;
            // Supports HPWEB-5867 - "Cheap Apartments|Houses" are now "Affordable Apartments|Houses"
            filter.searchTitle = searchSlugUtils.prepareSlugDescription(data.searchSlug);

            // Create new filter!
            return dispatch(_createFilter(filter));
        });
    };
};

/**
 * Simply takes a filter change and spreads it over the current filter to generate an augmented
 * and "updated" filter in Redux filter format
 * @param {Object} partialFilter - Contains the changed portion of the filter
 * @returns {Object} augmentedfilter - Contains the changed filter spread over the current filter
 */
const _buildAugmentedFilter = (partialFilter) => {
    return (_dispatch, getState) => {
        const state = getState();
        const augmentedFilter = {};
        const currentFilter = state.filter;

        if (partialFilter) {
            // Use partial filter
            const orderByData = _getOrderByData(partialFilter.orderBy, state.analytics.runningExperiments);
            Object.assign(augmentedFilter, currentFilter, partialFilter, orderByData); // NOTE: Polyfill or use Lodash for desktop migration
        } else {
            // Filter reset
            const orderByData = _getOrderByData(null, state.analytics.runningExperiments);
            Object.assign(augmentedFilter, new Filter(), orderByData); // NOTE: Polyfill or use Lodash for desktop migration
        }

        return augmentedFilter;
    };
};

/**
 * Calls Price Histogram API and saves it to Redux
 * @param {Object} additionalParams - Additional parameters that get used in the priceHistogram API call
 * @param {Object} AbortController - The AbortSignal object that allows you to abort one or more DOM requests as and when desired
 */
const FilterActions_fetchPriceHistogramFilterData = (additionalParams = {}, abortControllerSignal) => {
    return (dispatch, getState) => {
        const state = getState();
        const { mapData = null, shouldUseAreaBoundary = true } = additionalParams;
        let area;

        // Rely on Map data & boundary info
        if (mapData && shouldUseAreaBoundary) {
            area = Object.assign({}, mapData, { id: get(state.area, 'area.id', '117776782') });
        } else if (mapData) {
            // If no boundary, rely on map data
            area = Object.assign({}, mapData);
        } else {
            // Else, rely on reudx area data
            area = Object.assign(
                {},
                {
                    id: get(state.area, 'area.id', '117776782'),
                    maxLat: get(state.area, 'area.maxLat', 40.917577),
                    maxLon: get(state.area, 'area.maxLon', -73.700272),
                    minLat: get(state.area, 'area.minLat', 40.477399),
                    minLon: get(state.area, 'area.minLon', -74.25909)
                }
            );
        }

        const apiParams = adapt_reduxToJava({
            filter: state.filter,
            area
        });

        // For filter price histogram
        const priceHistogramParams = Object.assign({}, apiParams, {
            numBuckets: PRICE_SLIDER_MAX,
            bucketSize: VALUE_CONSTANTS.PRICE_FILTER_INCREMENT,
            useCache: false,
            lowPrice: 0, // ignore price filters - histogram will gray-out prices out of range
            highPrice: null
        });
        return dispatch(api.listing.priceHistogram(priceHistogramParams, abortControllerSignal)).then((res) => {
            if (res.data) {
                const buckets = res.data.buckets;
                let maxCount = -Infinity;
                let medianBucket = { percent: 0 };

                if (buckets) {
                    const priceHistogramArray = map(buckets, (b, i) => {
                        const { min, count, percent } = b;

                        if (
                            count &&
                            Math.abs(50 - Math.floor(percent)) <= Math.abs(50 - Math.floor(medianBucket.percent))
                        ) {
                            // floor percentage to pick 2nd of 3 results (33.3333 is closer to 50 than 66.6667)
                            medianBucket = b;
                        }

                        if (i < buckets.length - 1) {
                            maxCount = Math.max(count, maxCount);
                            return {
                                min,
                                count
                            };
                        } else {
                            return {
                                min,
                                count: 0
                            };
                        }
                    });

                    dispatch({
                        type: CONSTANTS.LOAD_AREA_PRICE_HISTOGRAM,
                        payload: {
                            priceHistogram: {
                                data: priceHistogramArray,
                                maxCount,
                                median: medianBucket.min
                            }
                        }
                    });
                    return Promise.resolve({ success: true });
                }
            }
        });
    };
};

/**
 * Only updates the filter Redux reducer - no other processing or fetching occurs.
 * @param {Object} partialFilter - Contains the changed portion of the filter
 */
const FilterActions_handleReduxOnlyFilterChange = (partialFilter) => {
    return (dispatch) => {
        try {
            const augmentedFilter = dispatch(_buildAugmentedFilter(partialFilter));

            // Convert filter into java format in preparation to pass it along to filter model
            const javaFilter = adapt_reduxToJava({ filter: augmentedFilter });
            return dispatch(_createFilter(javaFilter));
        } catch (error) {
            throw error;
        }
    };
};

/**
 * Processes any filter changes in the UI and calls the search slug API as well as fetches new listings
 * @param {Object} partialFilter - Contains the changed portion of the filter
 * @param {Object} AbortController - The AbortSignal object that allows you to abort one or more DOM requests as and when desired
 */
const FilterActions_handleFilterChange = (partialFilter, abortControllerSignal) => {
    return async (dispatch, getState) => {
        try {
            const state = getState();
            const javaFilter = {};
            const augmentedFilter = dispatch(_buildAugmentedFilter(partialFilter));
            const query = state.location.current.query;
            const page = query.page ? Math.max(query.page, 0) : 0;

            // NOTE: Polyfill or use Lodash for desktop migration
            Object.assign(
                javaFilter,
                adapt_reduxToJava({
                    filter: augmentedFilter,
                    area: {
                        id: get(state.area, 'area.id', '117776782'),
                        maxLat: get(state.area, 'area.maxLat', 40.917577),
                        maxLon: get(state.area, 'area.maxLon', -73.700272),
                        minLat: get(state.area, 'area.minLat', 40.477399),
                        minLon: get(state.area, 'area.minLon', -74.25909)
                    }
                })
            );

            const byCoordsParams = {
                filter: augmentedFilter,
                page
            };

            return batch(async () => {
                // Call searchSlug
                await dispatch(
                    _fetchAndSaveSearchSlugData({ javaFilter, hasFilterBeenSet: true }, abortControllerSignal)
                );

                // Call byCoords
                dispatch(ListingEngineActions.fetchListingsOnlyByCoords(byCoordsParams, abortControllerSignal));

                // Send empty object and abort signal to fetchPriceHistogramFilterData
                dispatch(FilterActions_fetchPriceHistogramFilterData({}, abortControllerSignal));

                return dispatch(_updateUrlWithFilterParams());
            });

            // Update url with params
        } catch (error) {
            throw error;
        }
    };
};

/**
 * Processes pathanme and query and passes them along to the search slug API via _fetchAndSaveSearchSlugData
 * @param {string} pathname - pathname in browser url
 * @param {Object} query - query parameters object via location.current.query
 */
const FilterActions_fetchFilterFromUrlAndApi = (pathname, query) => {
    return (dispatch) => {
        const _query = Object.assign({}, query); // NOTE: Polyfill or use Lodash for desktop migration
        const isAreaUrl = routeUtils.isAreaUrl(pathname);
        const isNearMeUrl = routeUtils.isNearMeUrl(pathname);
        let slug = 'apartments-for-rent';
        const javaFilter = {};

        // is standard SRP page?
        if (isAreaUrl) {
            slug = listingUtils_getResourceIdAndSlug(pathname).slug;
        }

        // is near-me page?
        if (isNearMeUrl) {
            slug = searchSlugUtils.nearMeToSearchSlugMap(pathname);
        }

        // Check if query is empty - if not, that means there are filters
        if (!isEmpty(_query)) {
            Object.assign(javaFilter, adapt_queryToJava(_query)); // NOTE: Polyfill or use Lodash for desktop migration
        }

        // Add the search slug
        javaFilter.searchSlug = slug;

        // Send it off to the api
        return dispatch(_fetchAndSaveSearchSlugData({ javaFilter }));
    };
};

const FilterActions_updateUrlWithMapData = (mapData) => {
    return (dispatch, getState) => {
        const state = getState();
        const pathname = state.location.current.pathname;
        const currentResourceId = state.area.area.resourceId || 'new-york-ny';
        const currentSearchSlug = state.filter.search.slug || 'apartments-near-me';
        const currentArea = `/${currentResourceId}/${currentSearchSlug}`;
        const query = state.location.current.query;
        const isNearMeUrl = routeUtils.isNearMeUrl(pathname);

        delete query.page;

        if (filterUtils_mapDataIsDifferent({ mapData, query })) {
            const newQueryObj = filterUtils_mergeMapDataIntoQuery({ mapData, query });
            const newQueryStr = queryUtils.stringify(newQueryObj);
            const newUrl = isNearMeUrl ? currentArea + newQueryStr : pathname + newQueryStr;
            window.router.transitionTo(newUrl);
            return true;
        } else {
            return false;
        }
    };
};

/**
 * FilterActions_updateFilterDuringSSR updates the Redux filter store with new
 * filter parameters
 * @param {Object} partialFilterObj - Partial Redux filter object
 * @param {String} category - Which filter field to update
 */
const FilterActions_updateFilterDuringSSR = (partialFilterObj, category) => {
    return async (dispatch, getState) => {
        try {
            const state = getState();

            // Merge state together
            const mergedFilter = Object.assign({}, state.filter, { [category]: partialFilterObj });

            return await dispatch(_saveFilterToRedux(mergedFilter));
        } catch (error) {
            throw error;
        }
    };
};

const FilterActions_resetRecentSearchFilter = () => {
    return async (dispatch, getState) => {
        try {
            const state = getState();
            const user = state.user;
            const recentSearch = Object.assign({}, user.search.recent);
            if (isEmpty(recentSearch)) {
                return false;
            }

            const shouldUseAreaBoundary = !recentSearch.url.includes('border=false');
            const mapData = recentSearch.mapData || {};
            const areaId =
                shouldUseAreaBoundary && user.search.recent && !isEmpty(user.search.recent.areaInfo)
                    ? user.search.recent.areaInfo.id
                    : null;
            const apiFilter = adapt_reduxToJava({
                filter: DEFAULT,
                area: { id: areaId },
                mapData
            });

            dispatch({
                type: CONSTANTS.SET_CURRENT_SEARCH,
                payload: null
            });

            return dispatch(api.user.search.recent.update({ filter: apiFilter })).then(() => {
                dispatch(UserSearchActions.getRecentSearches());
            });
        } catch (error) {
            throw error;
        }
    };
};

export {
    FilterActions_handleReduxOnlyFilterChange,
    FilterActions_handleFilterChange,
    FilterActions_fetchFilterFromUrlAndApi,
    FilterActions_fetchPriceHistogramFilterData,
    FilterActions_updateUrlWithMapData,
    FilterActions_updateFilterDuringSSR,
    FilterActions_resetRecentSearchFilter
};
