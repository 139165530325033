import React from 'react';
import * as PropTypes from 'prop-types';
import * as S from './styles';
import { ResponsiveProp } from 'app/types/index';

type TagType = 'div' | 'h1' | 'h2' | 'h3' | 'span';

interface TitleProps {
    htmlTag: TagType;
    className?: string;
    children?: React.ReactNode;
    size?: 'sm' | 'md' | 'lg' | 'xl' | 'hero';
    responsive?: ResponsiveProp;
    id?: string;
}

class Title extends React.Component<TitleProps> {
    static propTypes = {
        children: PropTypes.any,
        htmlTag: PropTypes.string,
        className: PropTypes.string,
        size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'hero']),
        responsive: PropTypes.object,
        id: PropTypes.string
    };

    static defaultProps = {
        children: null,
        htmlTag: 'h1',
        className: null,
        size: null,
        responsive: {},
        id: null
    };

    constructor(props: TitleProps) {
        super(props);
    }

    render() {
        const { htmlTag: CustomTag, className, children, size, responsive, id } = this.props;

        return (
            <S.Title as={CustomTag} size={size} responsive={responsive} className={className} id={id}>
                {children}
            </S.Title>
        );
    }
}

export default Title;
