import React from 'react';
import loadable from '@loadable/component';

const AutocompleteSearchInput = loadable(
    () =>
        import(
            /* webpackChunkName: "AutocompleteSearchInput.loadable" */ 'app/shared/modules/location/AutocompleteSearchInput/component'
        )
);

interface Props {
    defaultItemTypes?: string[]
    full?: boolean
    includeCancel?: boolean
    includeClear?: boolean
    isHomepage?: boolean
    large?: boolean
    onSelect?: () => void
    showRecentSearchItem: boolean
    triggerLocation?: string
    triggerObject?: string
    useSmallScreenWidthDropdown?: boolean
}

const LoadableAutocompleteSearchInput = (props: Props) => {
    return <AutocompleteSearchInput {...props} />;
};

export default LoadableAutocompleteSearchInput;
