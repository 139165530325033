// @ts-nocheck
/* eslint-enable */
// App
import SummaryV2 from 'app/shared/models/SummaryV2';

// Misc / Utils
import formatter from 'app/shared/utils/formatter';
import { adapt_reduxToJava } from 'app/shared/flux/actions/FilterActions/adapters';

// Lodash
import assign from 'lodash/assign';
import isArray from 'lodash/isArray';
import isNull from 'lodash/isNull';
import isObject from 'lodash/isObject';
import transform from 'lodash/transform';

const logger = getLogger('utils/adapter');

const adapterUtils = {
    apiLimitedBuildingArrayToSummaryArray(apiLimitedBuildingsArray, filter = {}, limitListings = false) {
        if (!apiLimitedBuildingsArray || !apiLimitedBuildingsArray.length) {
            return [];
        }

        const summaries = [];
        apiLimitedBuildingsArray.forEach((buildingObj) => {
            const summary = new SummaryV2(buildingObj, filter);
            if (summary && summary.maloneLotIdEncoded && summary.aliasEncoded) {
                summaries.push(summary);
            } else {
                logger.warn(
                    { buildingObj, summary },
                    'SummaryV2 failed. maloneLotIdEncoded or aliasEncoded not defined.'
                );
            }
        });

        if (limitListings) {
            // Supports HP-5565: Limit listings for affordable- and luxury- slugs
            const halfLength = Math.ceil(summaries.length / 2);
            const leftSideSummaries = summaries.slice(0, halfLength);

            return leftSideSummaries;
        }

        return summaries;
    },
    getFilterForByCoordsApi({ filter, area, offset, limit = 20, channels, components, bbTestGroup }) {
        const flatFilter = adapterUtils.flattenFilterObj(assign({}, filter));
        if (flatFilter.maxCreated === 'null') {
            delete flatFilter.maxCreated;
        }

        if (!flatFilter.commuteTimeMode) {
            flatFilter.commuteTime = null;
            flatFilter.commuteMode = null;
            flatFilter.commuteLats = null;
            flatFilter.commuteLons = null;
        }

        // HPWEB-5750: School SRP not returning listings.
        // Model index does not return listings for university area IDs.
        // Passing null forces byCoords call to search by lat/long instead.
        const areas = area && area.type !== 'university' ? area.id : null;

        if (flatFilter.orderBy === 'score') {
            flatFilter.orderBy = bbTestGroup === 'EXPERIMENT' ? 'experimentScore' : 'score';
        }

        return {
            amenities: flatFilter.amenities,
            areas,
            bathrooms: flatFilter.bathrooms,
            bedrooms: flatFilter.bedrooms,
            channels: channels || '',
            components: components || 'basic,useritem,quality,model,photos',
            dupeGrouping: flatFilter.dupeGrouping,
            endOfAvailabilityDate: flatFilter.endOfAvailabilityDate,
            feeds: flatFilter.feeds, // Note: feeds is only used by reps
            furnished: flatFilter.furnished,
            hasSpecialOffers: flatFilter.hasSpecialOffers,
            highPrice: flatFilter.highPrice,
            hideUnknownAvailabilityDate: flatFilter.hideUnknownAvailabilityDate,
            includeVaguePricing: flatFilter.includeVaguePricing,
            incomeRestricted: flatFilter.incomeRestricted,
            isAcceptingRentalApplications: flatFilter.isAcceptingRentalApplications,
            isListedByOwner: flatFilter.isListedByOwner,
            acceptsSection8: flatFilter.acceptsSection8,
            keywords: flatFilter.keywords,
            laundry: flatFilter.laundry,
            limit,
            listingTypes: flatFilter.listingTypes,
            lowPrice: flatFilter.lowPrice,
            maxCreated: flatFilter.maxCreated,
            maxLat: area.maxLat,
            maxLon: area.maxLon,
            maxSqft: flatFilter.maxSqft,
            militaryHousing: flatFilter.militaryHousing,
            minLat: area.minLat,
            minLon: area.minLon,
            minPhotos: flatFilter.minPhotos,
            minSqft: flatFilter.minSqft,
            offset: offset || 0,
            orderBy: flatFilter.orderBy || 'activated',
            pets: flatFilter.pets,
            propertyTypes: flatFilter.propertyTypes,
            seniorHousing: flatFilter.seniorHousing,
            startOfAvailabilityDate: flatFilter.startOfAvailabilityDate,
            studentHousing: flatFilter.studentHousing,
            visible: flatFilter.visible,

            // TODO: Merge in alphabetical order with properties above.
            // HPWEB-5474: Search by Commute
            // Temporarily attaching these as the last items
            // that will be attached to the byCoords API call
            // for easier debugging.
            commuteTimeMode: flatFilter.commuteTimeMode,
            commuteTime: flatFilter.commuteTime,
            commuteMode: flatFilter.commuteMode,
            commuteLats: flatFilter.commuteLats,
            commuteLons: flatFilter.commuteLons
        };
    },
    savedSearchItemForApi({
        id = null, // Needed if updating a search, otherwise generated on server
        name = 'My search alert',
        email,
        frequency = 'DAILY',
        filter,
        mapData = {},
        borderAreaId = null
    }) {
        if (!mapData.maxLat || !mapData.minLat || !mapData.maxLon || !mapData.minLon) {
            logger.warn('CreateSavedSearch requires a complete bounding box!');
            return false;
        }
        if (
            mapData.maxLat === mapData.minLat ||
            mapData.maxLon === mapData.minLon ||
            mapData.maxLon === mapData.maxLat ||
            mapData.minLon === mapData.minLat ||
            mapData.minLon === mapData.maxLat
        ) {
            logger.warn('Error with bounding box in CreateSavedSearch.');
            return false;
        }

        if (mapData.maxLat > 90 || mapData.minLat > 90 || mapData.maxLon > 180 || mapData.minLon > 180) {
            logger.warn('Invalid bounding box in CreateSavedSearch');
        }
        return {
            filter: adapt_reduxToJava({ filter, mapData, area: { id: borderAreaId } }),
            toEmail: email,
            emailFrequency: frequency,
            sid: id,
            name
        };
    },
    flattenFilterObj(_params) {
        var params = assign({}, _params);

        return transform(params, (accumulator, value, key) => {
            if (!isNull(value)) {
                if (isArray(value)) {
                    accumulator[key] = value.join(',');
                } else if (isObject(value)) {
                    accumulator[key] = formatter.obj.toCommaDelimitedString(value);
                } else {
                    accumulator[key] = value;
                }
            }
        });
    },
    createJavaFilter({ filter: _filter, mapData, areaId }) {
        const flatFilter = adapterUtils.flattenFilterObj(assign({}, _filter));

        if (flatFilter.maxCreated === 'null') {
            delete flatFilter.maxCreated;
        }

        if (!flatFilter.commuteTimeMode) {
            flatFilter.commuteTime = null;
            flatFilter.commuteMode = null;
            flatFilter.commuteLats = null;
            flatFilter.commuteLons = null;
        }

        const additions = {};

        if (areaId) {
            additions.areas = areaId;
        }

        let filter = assign(
            {},
            {
                amenities: flatFilter.amenities,
                ascending: flatFilter.ascending,
                bathrooms: flatFilter.bathrooms,
                bedrooms: flatFilter.bedrooms,
                currentPage: flatFilter.currentPage,
                dupeGrouping: flatFilter.dupeGrouping,
                endOfAvailabilityDate: flatFilter.endOfAvailabilityDate,
                feeds: flatFilter.feeds, // Note: feeds is only used by reps
                furnished: flatFilter.furnished,
                hasSpecialOffers: flatFilter.hasSpecialOffers,
                highPrice: flatFilter.highPrice,
                hideUnknownAvailabilityDate: flatFilter.hideUnknownAvailabilityDate,
                includeVaguePricing: flatFilter.includeVaguePricing,
                incomeRestricted: flatFilter.incomeRestricted,
                isAcceptingRentalApplications: flatFilter.isAcceptingRentalApplications,
                isListedByOwner: flatFilter.isListedByOwner,
                acceptsSection8: flatFilter.acceptsSection8,
                keywords: flatFilter.keywords,
                laundry: flatFilter.laundry,
                listingTypes: flatFilter.listingTypes,
                lowPrice: flatFilter.lowPrice,
                maxCreated: flatFilter.maxCreated,
                maxSqft: flatFilter.maxSqft,
                maxUpdated: flatFilter.maxUpdated,
                militaryHousing: flatFilter.militaryHousing,
                minPhotos: flatFilter.minPhotos,
                minSqft: flatFilter.minSqft,
                offset: 0,
                orderBy: flatFilter.orderBy,
                pets: flatFilter.pets,
                propertyTypes: flatFilter.propertyTypes,
                searchSlug: flatFilter.searchSlug,
                seniorHousing: flatFilter.seniorHousing,
                startOfAvailabilityDate: flatFilter.startOfAvailabilityDate,
                studentHousing: flatFilter.studentHousing,

                // TODO: Merge in alphabetical order with properties above.
                // HPWEB-5474: Search by Commute
                // Temporarily attaching these as the last items
                // that will be attached to the byCoords API call
                // for easier debugging.
                commuteTimeMode: flatFilter.commuteTimeMode,
                commuteTime: flatFilter.commuteTime,
                commuteMode: flatFilter.commuteMode,
                commuteLats: flatFilter.commuteLats,
                commuteLons: flatFilter.commuteLons
            },
            additions
        );

        if (mapData) {
            filter.maxLat = mapData.maxLat;
            filter.maxLon = mapData.maxLon;
            filter.minLat = mapData.minLat;
            filter.minLon = mapData.minLon;
        }

        return filter;
    }
};

export default adapterUtils;
