import { ClickstreamEvent } from 'app/types';
import { Envelope } from './Envelope';

export const TrackFacebookLogin = (): ClickstreamEvent => {
    return {
        envelope: new Envelope({
            eventId: '5981',
            eventVersion: '1',
            templateId: '171',
            templateVersion: '1'
        }),
        clickstream_trigger: {
            trigger_type_nm: 'interaction',
            trigger_location_nm: 'login_screen',
            trigger_source_nm: 'button_to_facebook_login',
            trigger_object_nm: 'login_screen|auth_form'
        },
        semantic: {
            semantic_event_nm: 'facebook_login'
        }
    };
};

// type FreePassTriggerObject = 'home_details_component|inline_form' | 'home_details_component|modal_contact_form';

// export const TrackFreepassLogin = ({ triggerObject }: { triggerObject: FreePassTriggerObject }): ClickstreamEvent => {
//     return {
//         envelope: new Envelope({
//             eventId: '5982',
//             eventVersion: '1',
//             templateId: '4',
//             templateVersion: '1'
//         }),
//         clickstream_trigger: {
//             trigger_type_nm: 'interaction',
//             trigger_location_nm: 'home_details',
//             trigger_source_nm: 'button_to_complete_contact_form',
//             trigger_object_nm: triggerObject
//         },
//         semantic: {
//             semantic_event_nm: 'freepass_login'
//         }
//     };
// };

export const TrackGoogleLogin = (): ClickstreamEvent => {
    return {
        envelope: new Envelope({
            eventId: '5983',
            eventVersion: '1',
            templateId: '171',
            templateVersion: '1'
        }),
        clickstream_trigger: {
            trigger_type_nm: 'interaction',
            trigger_location_nm: 'login_screen',
            trigger_source_nm: 'button_to_google_login',
            trigger_object_nm: 'login_screen|auth_form'
        },
        semantic: {
            semantic_event_nm: 'google_login'
        }
    };
};

export const TrackHeaderLogin = (): ClickstreamEvent => {
    return {
        envelope: new Envelope({
            eventId: '5985',
            eventVersion: '1',
            templateId: '171',
            templateVersion: '1'
        }),
        clickstream_trigger: {
            trigger_type_nm: 'interaction',
            trigger_location_nm: 'hp_header',
            trigger_source_nm: 'link_to_sign_in',
            trigger_object_nm: 'hp_header|login_component'
        },
        semantic: {
            semantic_event_nm: 'header_sign_in_click'
        }
    };
};
