// @ts-nocheck
/* eslint-enable */
import { areaUtils_validType } from 'app/shared/utils/areaUtils';
import { listingUtils_districtTypeLegend, listingUtils_schoolTypeLegend } from 'app/shared/utils/listingUtils';
import Area from 'app/shared/models/Area';
import AreaPriceComparison from 'app/shared/models/AreaPriceComparison';

const ListingAreas = function({ searchSlug = '', areas = {}, pricingStats = {}, pricing = {} } = {}) {
    let breadcrumbs = areas.breadcrumbs || [];
    let links = areas.links || [];
    let parsedBreadcrumbs = [];
    let parsedLinks = [];
    let parsedDistricts = [];
    let parsedSchools = {
        primaryschool: [],
        middleschool: [],
        highschool: [],
        mixedschool: [],
        university: []
    };

    breadcrumbs.forEach((area) => {
        if (areaUtils_validType(area.type)) {
            parsedBreadcrumbs.push(new Area(area));
        }
    });

    links.forEach((area) => {
        if (areaUtils_validType(area.type)) {
            area.uriV2 = '/' + area.resourceId + '/' + searchSlug;
            parsedLinks.push(new Area(area));
        }
    });

    links.forEach((area) => {
        if (areaUtils_validType(area.type) && listingUtils_districtTypeLegend[area.type]) {
            parsedDistricts.push(new Area(area));
        }
    });

    links.forEach((area) => {
        if (areaUtils_validType(area.type) && listingUtils_schoolTypeLegend[area.type]) {
            parsedSchools[area.type].push(new Area(area));
        }
    });

    links = [].concat(parsedLinks);

    this.breadcrumbs = [].concat(parsedBreadcrumbs);
    this.surroundingAreaStats = new AreaPriceComparison({ links, pricingStats, pricing });
    this.links = links;
    this.districts = [].concat(parsedDistricts);
    this.schools = {
        primaryschool: [].concat(parsedSchools.primaryschool),
        middleschool: [].concat(parsedSchools.middleschool),
        highschool: [].concat(parsedSchools.highschool),
        mixedschool: [].concat(parsedSchools.mixedschool),
        university: [].concat(parsedSchools.university)
    };
};

export default ListingAreas;
