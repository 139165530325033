interface ReviewsBreakdownProps {
    totalNumberOfReviews?: number;
    totalOneStarReviews?: number;
    totalTwoStarReviews?: number;
    totalThreeStarReviews?: number;
    totalFourStarReviews?: number;
    totalFiveStarReviews?: number;
}

const logger = getLogger('models');

class ReviewsBreakdown {
    oneStar: number = 0;
    twoStar: number = 0;
    threeStar: number = 0;
    fourStar: number = 0;
    fiveStar: number = 0;

    constructor({
        totalNumberOfReviews = 0,
        totalOneStarReviews = 0,
        totalTwoStarReviews = 0,
        totalThreeStarReviews = 0,
        totalFourStarReviews = 0,
        totalFiveStarReviews = 0
    }: ReviewsBreakdownProps = {}) {
        try {
            if (totalNumberOfReviews === 0) {
                return;
            }

            this.oneStar = Math.round((totalOneStarReviews / totalNumberOfReviews) * 100);
            this.twoStar = Math.round((totalTwoStarReviews / totalNumberOfReviews) * 100);
            this.threeStar = Math.round((totalThreeStarReviews / totalNumberOfReviews) * 100);
            this.fourStar = Math.round((totalFourStarReviews / totalNumberOfReviews) * 100);
            this.fiveStar = Math.round((totalFiveStarReviews / totalNumberOfReviews) * 100);
        } catch (err) {
            logger.error(
                {
                    dataError: 'dataModel',
                    type: 'ReviewsBreakdown',
                    error: err
                },
                'ReviewsBreakdown'
            );
        }
    }
}

export default ReviewsBreakdown;
