import numberUtils from 'app/shared/utils/numberUtils';

interface PetPolicyProps {
    restriction?: string;
    comment?: string;
    maxNumberAllowed?: number;
    maxWeightAllowed?: number;
    deposit?: number;
    oneTimeFee?: number;
    monthlyFee?: number;
    allowed?: boolean;
}

const logger = getLogger('models');

class PetPolicy {
    allowed: boolean = false;
    deposit: string | null = null;
    oneTimeFee: string | null = null;
    monthlyFee: string | null = null;
    maxNumberAllowed: string | null = null;
    maxWeightAllowed: string | null = null;
    comment: string = '';
    restriction: string = '';

    constructor({
        restriction = '',
        comment = '',
        maxNumberAllowed = 0,
        maxWeightAllowed = 0,
        deposit = 0,
        oneTimeFee = 0,
        monthlyFee = 0,
        allowed = false
    }: PetPolicyProps = {}) {
        try {
            this.allowed = allowed;
            this.deposit = deposit ? `${numberUtils.asCurrency(deposit)} pet deposit` : null;
            this.oneTimeFee = oneTimeFee ? `${numberUtils.asCurrency(oneTimeFee)} one time fee` : null;
            this.monthlyFee = monthlyFee ? `${numberUtils.asCurrency(monthlyFee)} monthly pet fee` : null;
            this.maxNumberAllowed = maxNumberAllowed
                ? `${maxNumberAllowed} ${maxNumberAllowed > 1 ? `pets` : `pet`} max`
                : null;
            this.maxWeightAllowed = maxWeightAllowed
                ? `${maxWeightAllowed} ${maxWeightAllowed > 1 ? `lbs` : `lb`} max`
                : null;
            this.comment = comment;
            this.restriction = restriction;
        } catch (err) {
            logger.error(
                {
                    dataError: 'dataModel',
                    type: 'PetPolicy',
                    error: err
                },
                'PetPolicy'
            );
        }
    }
}

export default PetPolicy;
