// @ts-nocheck
/* eslint-enable */
import queryUtils from 'app/shared/utils/queryUtils';
import RequesterActions from 'app/shared/flux/actions/RequesterActions';


const logger = getLogger('actions/error');

// an axiosError, server responded with non-2xx status code
function isApiResponseError(err) {
    return Boolean(err.response);
}

// an axiosError, request was made but no response received
function isApiRequestError(err) {
    return Boolean(err.request);
}

const ErrorActions = {
    sendToSplunk(data, message) {
        return (_, getState) => {
            // can just log
            if (__SERVER__) {
                const xEids = getState().app.xEids;

                logger.error({
                    ...{ xEids },
                    data
                }, message);
            } else {
                // TODO: send to node log proxy
                logger.error(data, message);
            }
        };
    },
    errorHandler({ error, errorClass, errorLocation, req, criticalType = null }) {
        return (dispatch, getState) => {
            const state = getState();
            let traceId = __SERVER__ ? state.location.ssrEntry.traceId : null;
            const { response, message, config } = error;

            if (isApiResponseError(error)) {
                const { status, headers } = response;

                let xEidResponseHeaders;

                if (__SERVER__ && headers && headers['x-eid']) {
                    xEidResponseHeaders = headers['x-eid'];
                    dispatch(RequesterActions.pushxEid(xEidResponseHeaders));
                }

                dispatch(
                    ErrorActions.sendToSplunk(
                        {
                            type: 'axiosError',
                            message: 'server responded with status code out of 2xx range',
                            errorLocation,
                            errorClass,
                            traceId,
                            url: config.url,
                            params: config.params,
                            fullUrl: `${config.url}${queryUtils.stringify(config.params)}`,
                            criticalType,
                            status,
                            jsStackTrace: error.stack
                        },
                        message
                    )
                );
            } else if (isApiRequestError(error)) {
                // todo: we can use pontoon counters instead for req related failures
                if (message && message.toLowerCase() !== 'request aborted') {
                    dispatch(
                        ErrorActions.sendToSplunk(
                            {
                                type: 'axiosError',
                                message: 'request made, no response received',
                                errorLocation,
                                errorClass,
                                traceId,
                                url: config.url,
                                params: config.params,
                                fullUrl: `${config.url}${queryUtils.stringify(config.params)}`,
                                criticalType,
                                jsStackTrace: error.stack
                            },
                            message
                        )
                    );
                }
            } else {
                dispatch(
                    ErrorActions.sendToSplunk(
                        {
                            error: {
                                stack: error.stack,
                                message: error.message
                            },
                            errorLocation,
                            errorClass
                        },
                        message
                    )
                );
            }
        };
    }
};

export default ErrorActions;
