// Geo.ts
import numberUtils from 'app/shared/utils/numberUtils';

interface GeoParams {
    lat?: number;
    lon?: number;
    quad?: string;
}

class Geo {
    lat: number;
    lon: number;
    quad: string;

    constructor({ lat = 0, lon = 0, quad = '' }: GeoParams = {}) {
        this.lat = numberUtils.trimDecimal(lat);
        this.lon = numberUtils.trimDecimal(lon);

        this.quad = String(quad);
    }
}

export default Geo;
