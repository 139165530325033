var ValueConstants = {
    LEFT_ARROW_KEYCODE: 37,
    ARROW_LEFT_KEY: 'ArrowLeft',
    RIGHT_ARROW_KEYCODE: 39,
    ARROW_RIGHT_KEY: 'ArrowRight',
    ESC_KEYCODE: 27,
    ESC_KEY: 'Escape',
    ENTER_KEYCODE: 13,
    UP_ARROW_KEYCODE: 38,
    ARROW_UP_KEY: 'ArrowUp',
    DOWN_ARROW_KEYCODE: 40,
    ARROW_DOWN_KEY: 'ArrowDown',
    TAB_KEYCODE: 9,
    TAB_KEY: 'Tab',
    SHIFT_KEYCODE: 16,
    SPACE_KEYCODE: 32,
    FETCH_LISTINGS_LIMIT: 40,
    MAX_LISTINGS_PER_PAGE: 20,
    LISTINGS_PER_NEARBY_AREA_TO_FETCH: 4,
    LISTINGS_THRESHOLD: 400,
    ZOOM_CURRENT_LISTING: 15,
    MAX_COMMUTE_DISTANCE: 75, // miles
    MARKETING_BLURB_TEST_AREAS: [
        'chicago-il',
        'los-angeles-ca',
        'philadelphia-pa',
        'san-francisco-ca',
        'new-york-ny',
        'boston-ma',
        'san-diego-ca',
        'washington-dc',
        'seattle-wa',
        'denver-co',
        'charlottesville-va',
        'santa-clara-ca',
        'quincy-ma',
        'danbury-ct',
        'springfield-ma',
        'north-hollywood-los-angeles-ca',
        'clearwater-fl',
        'west-new-york-nj',
        'lake-worth-fl',
        'alexandria-va'
    ],
    NO_PREV_NEXT_TEST_STATES: 'NV|GA|FL|TX|OR',
    STUDIO_EFFICIENCY_TEST_STATES: 'FL|NJ|ME|PA|MD|GA|VA|TN|IL|WV|NY|OH|CT|OK|MN|KY|SC|NC',
    USER_MILESTONE_35: 35000, // milliseconds
    USER_MILESTONE_60: 60000, // milliseconds
    USER_MILESTONE_90: 90000, // milliseconds
    SRP_MAX_PAGE_LIMIT: 40,
    SRP_MAX_PAGE_LIMIT_KEYWORDS: /(affordable|luxury)-(apartments|houses)-for-rent/,
    STATE_PATTERN:
        /-(AL|AK|AR|AZ|CA|CO|CT|DC|DE|FL|GA|HI|IA|ID|IL|IN|KS|KY|LA|MA|MD|ME|MI|MN|MO|MS|MT|NC|ND|NE|NH|NJ|NM|NV|NY|OH|OK|OR|PA|RI|SC|SD|TN|TX|UT|VA|VT|WA|WI|WV|WY)-/i, //eslint-disable-line
    STATE_PATTERN_FROM_URL:
        /-(AL|AK|AR|AZ|CA|CO|CT|DC|DE|FL|GA|HI|IA|ID|IL|IN|KS|KY|LA|MA|MD|ME|MI|MN|MO|MS|MT|NC|ND|NE|NH|NJ|NM|NV|NY|OH|OK|OR|PA|RI|SC|SD|TN|TX|UT|VA|VT|WA|WI|WV|WY)/i, //eslint-disable-line
    ZIP_PATTERN: /-(\d{5})-/,
    TIME_TO_WAIT_AFTER_NOTIFICATION_DISMISS: 1 * (24 * 60 * 60 * 1000),
    MAX_TIME_TO_WAIT_AFTER_NOTIFICATION_DISMISS: 8 * (24 * 60 * 60 * 1000),
    MIN_MAP_ZOOM: 3,
    DOT_MAP_DISPLAY_LIMIT: 200, // max number of dots to display on map at one time (includes in and out of border),
    PRICE_FILTER_INCREMENT: 50 // These increments are dependent on the price filter slide having a max of $5000,
};

export default ValueConstants;
