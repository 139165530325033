// @ts-nocheck
/* eslint-enable */
import assign from 'lodash/assign';
import round from 'lodash/round';
import genericUtils from 'app/shared/utils/genericUtils';
import includes from 'lodash/includes';
import intersection from 'lodash/intersection';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';

/**
 * Filter
 * - Model: an object that corresponds to our filter state in Redux/Flux
 * - QueryObj: an object that corresponds to query params in url
 * - QueryString: a string version of query obj (what actually appears in the url)
 *
 * Notes:
 * - This does not support anything that should go in mapdata (zoom, bounding box)
 * - Saved search and Recent search are types of filter object
 * - See Filters.md for more info
 */

const allowedConstants = {
    allowedListingTypes: ['rental', 'sublet', 'room', 'corporate'].sort(),
    defaultListingTypes: ['rental', 'sublet', 'room', 'corporate'].sort(),

    allowedPropertyTypes: ['house', 'divided', 'condo', 'townhouse', 'medium', 'large', 'garden', 'land'].sort(),
    defaultPropertyTypes: ['house', 'divided', 'condo', 'townhouse', 'medium', 'large', 'garden', 'land'].sort(),

    allowedBeds: genericUtils.createRangeArray(1, 0, '8plus'),
    allowedBaths: genericUtils.createRangeArray(0.5, 0, '8plus'),
    allowedPets: ['cats', 'dogs'].sort(),
    allowedAmenities: [
        'cooling',
        'dishwasher',
        'doorman',
        'fitnessCenter',
        'gatedEntry',
        'heating',
        'parking',
        'swimmingPool'
    ],
    allowedLaundry: ['any', 'inUnit', 'shared'].sort(), // laundry filter defaults to `any`
    allowedVisible: ['new', 'viewed', 'favorite', 'inquiry', 'note', 'hidden', 'notified'].sort(),
    allowedOrderBy: [
        'activated',
        'priority',
        'weekViews',
        'photoCount',
        'lowPrice',
        'highPrice',
        'score',
        'experimentScore'
    ].sort(),
    allowedListingTags: ['seniorHousing', 'incomeRestricted', 'militaryHousing', 'studentHousing'],

    // HPWEB-5474: Search by Commute A/B test
    allowedCommuteTimeModes: ['now', 'offPeak', 'rushHour'],
    allowedTransitModes: ['DRIVING', 'CYCLING', 'WALKING', 'TRANSIT']
};

const checkValidity = (constants, value) => {
    if (includes(constants, value)) {
        return value;
    } else {
        return null;
    }
};

const getFilterOrDefault = ({ val, filterType }) => {
    /* eslint-disable no-undefined */
    if (val !== undefined) {
        return val;
    }

    // default values
    if (filterType === 'searchSlug') {
        return 'apartments-for-rent';
    } else if (filterType === 'includeVaguePricing') {
        return true;
    }

    return null;
};

const formatListingTypes = (listingTypes) => {
    const allowedListingTypes = allowedConstants.allowedListingTypes;
    const defaultListingTypes = allowedConstants.defaultListingTypes;

    let listingTypesArray;
    if (isArray(listingTypes)) {
        listingTypesArray = listingTypes;
    } else if (isString(listingTypes)) {
        listingTypesArray = listingTypes.split(new RegExp('[,-]', 'g'));
    } else if (isObject(listingTypes)) {
        listingTypesArray = keys(
            pickBy(listingTypes, (v) => {
                return v;
            })
        );
    }

    if (!listingTypes || isEmpty(listingTypes) || !intersection(allowedListingTypes, listingTypesArray).length) {
        return defaultListingTypes;
    }

    return listingTypesArray.filter((type) => {
        if (includes(allowedListingTypes, type)) {
            return type;
        }
    });
};

const formatPropertyTypes = (propertyTypes) => {
    const allowedPropertyTypes = allowedConstants.allowedPropertyTypes;
    const defaultPropertyTypes = allowedConstants.defaultPropertyTypes;

    if (!propertyTypes || isEmpty(propertyTypes)) {
        return defaultPropertyTypes;
    }

    let propertyTypesArray;
    if (isArray(propertyTypes)) {
        propertyTypesArray = propertyTypes;
    } else if (isString(propertyTypes)) {
        propertyTypesArray = propertyTypes.split(new RegExp('[,-]', 'g'));
    } else if (isObject(propertyTypes)) {
        propertyTypesArray = keys(
            pickBy(propertyTypes, (v) => {
                return v;
            })
        );
    }

    return propertyTypesArray.filter((type) => {
        if (includes(allowedPropertyTypes, type)) {
            return type;
        }
    });
};

const formatFilterValueArray = (val, type) => {
    /*
        possible val formats
        val = [1,2,3,4,5]
        val = '1,2,3,4,5'
        val = { pets: 1, dogs: 1 } // not supported
    */

    const allowedBeds = allowedConstants.allowedBeds;
    const allowedBaths = allowedConstants.allowedBaths;
    const allowedPets = allowedConstants.allowedPets;
    const allowedLaundry = allowedConstants.allowedLaundry;
    const allowedVisible = allowedConstants.allowedVisible;
    let selector;

    if (type === 'beds') {
        selector = allowedBeds;
    } else if (type === 'baths') {
        selector = allowedBaths;
        if (val && isString(val)) {
            val = val.replace(/\.0/g, '');
        }
    } else if (type === 'pets') {
        selector = allowedPets;

        // pets default: `any` (HPWEB-3826)
        if (!val) {
            return [];
        }
    } else if (type === 'laundry') {
        selector = allowedLaundry;

        // laundry default: `any` (HPWEB-3826)
        if (!val) {
            return ['any'];
        }
    } else if (type === 'visible') {
        selector = allowedVisible;

        if (!val) {
            return null;
        }
    }

    if (!val) {
        return selector;
    }

    if (isArray(val)) {
        val = val.join(',');
    } else if (!isString(val)) {
        return null;
    }

    return val
        .split(',')
        .sort()
        .filter((b) => {
            return selector.indexOf(b) > -1;
        });
};

const formatFilterValueObject = (val, type) => {
    /*
        possible val formats
        val = { gatedEntry: true, swimmingPool: true }
        val = 'gatedEntry,swimmingPool'
    */

    if (!val) {
        return null;
    }

    const allowedAmenities = allowedConstants.allowedAmenities;
    let selector;

    if (type === 'amenities') {
        selector = allowedAmenities;
    }

    if (isString(val)) {
        return val
            .split(',')
            .sort()
            .filter((b) => {
                return selector.indexOf(b) > -1;
            })
            .reduce((obj, el) => {
                obj[el] = true;
                return obj;
            }, {});
    } else if (isObject(val)) {
        return val;
    }

    return null;
};

// must support legacy and new filter
const createFilter = (_baseFilter) => {
    const baseFilter = assign({}, _baseFilter);
    let filter = {};

    filter.searchSlug = getFilterOrDefault({
        filterType: 'searchSlug',
        val: baseFilter.searchSlug
    });
    filter.ascending = baseFilter.ascending || false;
    filter.orderBy = baseFilter.orderBy ? checkValidity(allowedConstants.allowedOrderBy, baseFilter.orderBy) : 'score';
    filter.currentPage = Number(baseFilter.currentPage) || 1;

    // visible desktop filters
    filter.lowPrice = round(Number(baseFilter.lowPrice)) || 0;
    filter.highPrice = round(Number(baseFilter.highPrice)) || null;
    filter.bedrooms = formatFilterValueArray(baseFilter.bedrooms, 'beds');
    filter.bathrooms = formatFilterValueArray(baseFilter.bathrooms, 'baths');
    filter.pets = formatFilterValueArray(baseFilter.pets, 'pets');

    // availability
    filter.startOfAvailabilityDate = baseFilter.startOfAvailabilityDate || null;
    filter.endOfAvailabilityDate = baseFilter.endOfAvailabilityDate || null;
    filter.hideUnknownAvailabilityDate = Boolean(baseFilter.hideUnknownAvailabilityDate) || false;

    // more filters
    filter.amenities = formatFilterValueObject(baseFilter.amenities, 'amenities');
    filter.laundry = formatFilterValueArray(baseFilter.laundry, 'laundry');
    filter.furnished = Boolean(baseFilter.furnished) || null;
    filter.propertyTypes = formatPropertyTypes(baseFilter.propertyTypes).sort();
    filter.minSqft = round(Number(baseFilter.minSqft)) || 0;
    filter.maxSqft = round(Number(baseFilter.maxSqft)) || null;
    filter.maxCreated = Number(baseFilter.maxCreated) || null;
    filter.maxUpdated = Number(baseFilter.maxUpdated) || null;
    filter.listingTypes = formatListingTypes(baseFilter.listingTypes).sort();

    // special restrictions
    filter.incomeRestricted = genericUtils.getTrueFalseNull(baseFilter.incomeRestricted);
    filter.seniorHousing = genericUtils.getTrueFalseNull(baseFilter.seniorHousing);
    filter.studentHousing = genericUtils.getTrueFalseNull(baseFilter.studentHousing);
    filter.militaryHousing = genericUtils.getTrueFalseNull(baseFilter.militaryHousing);

    // additional options
    filter.keywords = baseFilter.keywords ? baseFilter.keywords : '';
    filter.includeVaguePricing = getFilterOrDefault({
        filterType: 'includeVaguePricing',
        val: baseFilter.includeVaguePricing
    });
    filter.minPhotos = Number(baseFilter.minPhotos) || 0;
    filter.hasSpecialOffers = Boolean(baseFilter.hasSpecialOffers) || null;
    filter.isListedByOwner = Boolean(baseFilter.isListedByOwner) || null;
    filter.acceptsSection8 = Boolean(baseFilter.acceptsSection8) || null;
    filter.visible =
        formatFilterValueArray(baseFilter.visible, 'visible') ||
        ['new', 'viewed', 'favorite', 'inquiry', 'note', 'notified'].sort();
    filter.isAcceptingRentalApplications = Boolean(baseFilter.isAcceptingRentalApplications) || null;

    // HPWEB-5474: Search by Commute A/B test
    filter.commuteTimeMode =
        checkValidity(allowedConstants.allowedCommuteTimeModes, baseFilter.commuteTimeMode) || null;
    filter.commuteTime = Number(baseFilter.commuteTime) || null;
    filter.commuteMode = checkValidity(allowedConstants.allowedTransitModes, baseFilter.commuteMode) || null;
    filter.commuteLats = Number(baseFilter.commuteLats) || null;
    filter.commuteLons = Number(baseFilter.commuteLons) || null;

    // Note: feeds is only used by reps
    filter.feeds = baseFilter.feeds || null; // csv list of feeds

    return filter;
};

export default {
    create: createFilter,
    allowedConstants
};
