// @ts-nocheck
/* eslint-enable */
import get from 'lodash/get';
import isFinite from 'lodash/isFinite';
import isEmpty from 'lodash/isEmpty';
import toString from 'lodash/toString';

import { filterUtils_hasActiveFilters } from 'app/shared/utils/filterUtils';
import googleAnalytics from './ga';
import phabletDetect from 'app/client/utils/phablet-detect';
import * as zgAnalytics from '../zg-analytics';
import { listingHasTourUrls } from 'app/shared/utils/listingUtils';

/**
 * @example: returns 'c'
 * propertyAsString({ a: {b: 'c'} }, 'a.b')
 */
function propertyAsString(object, path) {
    return toString(get(object, path));
}

const MAX_DIMENSIONS = 40;
const emptyDimensions = {};
for (let index = 1; index <= MAX_DIMENSIONS; index++) {
    emptyDimensions[`dimension${index}`] = '';
}

export function setListingDimensions(store) {
    let listing = get(store, 'currentListingDetails.currentListing');

    if (isEmpty(listing)) {
        return;
    }

    if (listing.units) {
        listing = listing.units[0];
    }

    let {
        active,
        address = {},
        aliasEncoded,
        details = {},
        featuredPropertyProductId = 0,
        listedBy,
        listingType = '',
        maloneLotIdEncoded: maloneLotId,
        propertyType = '',
        ratingsAndReviews,
        rentalApplicationStatus,
        specialOffers
    } = listing;

    let isPaid = toString(details.trusted);
    let listingActive = toString(active);
    let demoted = toString(details.demoted);
    let hasSpecialOffers = toString(specialOffers.length > 0);
    let listingRating, responseRateLevel, responseRate, responseTime, responseTimeLevel;
    const has3dTour = listingHasTourUrls(listing);
    if (ratingsAndReviews && ratingsAndReviews.reviews.length > 0 && ratingsAndReviews.reviewsSummary) {
        listingRating = toString(ratingsAndReviews.reviewsSummary.averageStarLevel);
    }
    if (isFinite(listedBy.responseRateLevel)) {
        responseRateLevel = toString(listedBy.responseRateLevel);
    }
    if (isFinite(listedBy.responseRate)) {
        responseRate = toString(Math.ceil(listedBy.responseRate));
    }
    if (isFinite(listedBy.responseTime)) {
        responseTime = toString(listedBy.responseTime / (60000 * 60)); // ms --> hrs
    }
    if (isFinite(listedBy.responseTimeLevel)) {
        responseTimeLevel = toString(listedBy.responseTimeLevel);
    }

    const dimensionsObject = {
        dimension1: 'true', // for-rent url GA dimension (typeof bool = string for GA)
        dimension2: 'false', // for-sale url GA dimension (typeof bool = string for GA)
        dimension3: listingType,
        dimension4: propertyType,
        dimension5: aliasEncoded,
        dimension6: isPaid,
        dimension7: listingActive,
        dimension8: maloneLotId,
        // TODO: Remove this if blank
        dimension12: address.neighborhood,
        dimension13: address.city,
        dimension14: address.state,
        dimension15: address.county,
        dimension16: address.zip,
        // If HDP is paid, send productId. If it's NOT paid, leave dimension18 BLANK
        dimension18: isPaid ? featuredPropertyProductId : '',
        dimension19: responseRateLevel,
        dimension20: responseRate,
        dimension21: responseTime,
        dimension22: responseTimeLevel,
        // Remove if not available
        dimension23: listingRating,
        dimension25: demoted,
        dimension27: rentalApplicationStatus,
        dimension32: hasSpecialOffers,
        dimension37: has3dTour
    };

    googleAnalytics.set(dimensionsObject);
    zgAnalytics.setDim(dimensionsObject);
}

export function setBaseDimensions(store) {
    if (typeof window === 'undefined') {
        return;
    }

    const filter = get(store, 'filter');
    const homeHub = get(store, 'homehub.googleAnalyticsPageView');
    const hasActiveFilter = filterUtils_hasActiveFilters(filter) ? 'true' : 'false';

    let fullURL = window.location.href;
    let mql = window.matchMedia('(orientation: portrait)');
    let deviceOrientation = mql.matches ? 'portrait' : 'landscape';
    let { deviceType: device, customVariableSiteType: siteType } = phabletDetect(window.navigator.userAgent);
    let { user } = store;

    let { info: userInfo, loggedIn, userToken } = user;

    let signedIn;
    const isAdminOrRep = userInfo.roles.indexOf('admin') > -1 || userInfo.roles.indexOf('rep') > -1;
    const isUser = userInfo.roles.indexOf('user') > -1;

    if (userInfo.roles) {
        if (isAdminOrRep) {
            signedIn = 'admin/rep';
        } else if (isUser) {
            signedIn = 'user';
        }
    }

    if (!loggedIn) {
        signedIn = 'anonymous';
    }

    const dimensionsObject = {
        ...emptyDimensions,
        dimension9: siteType,
        dimension10: device,
        dimension11: deviceOrientation,
        dimension17: 'false',
        dimension29: fullURL,
        dimension30: signedIn,
        dimension31: userToken,
        dimension34: hasActiveFilter,
        dimension35: homeHub
    };

    googleAnalytics.set(dimensionsObject);
    zgAnalytics.setDim(dimensionsObject);
}

export function setAreaDimensions(store) {
    if (isEmpty(store.area.area)) {
        return;
    }

    let searchExperience = propertyAsString(store, 'view.searchExperience');
    let ratingsAndReviews = propertyAsString(store, 'area.ratingsAndReviews');
    let totalListings = propertyAsString(store, 'listings.totalListings');

    let { neighborhood, city, state, county, zip } = store.area.area;

    // TODO: This is null
    let areaRating = propertyAsString(ratingsAndReviews, 'reviewsSummary.totalNumberOfReviews');

    const dimensionsObject = {
        dimension1: 'true', // for-rent url GA dimension (typeof bool = string for GA)
        dimension2: 'false', // for-sale url GA dimension (typeof bool = string for GA)
        dimension12: neighborhood,
        dimension13: city,
        dimension14: state,
        dimension15: county,
        dimension16: zip,
        dimension17: 'true',
        dimension24: totalListings,
        // Leave out if missing
        dimension26: areaRating,
        // This gets carried over to HDP or whereever
        dimension28: searchExperience
    };

    googleAnalytics.set(dimensionsObject);
    zgAnalytics.setDim(dimensionsObject);
}
