// @ts-nocheck
/* eslint-enable */
import assign from 'lodash/assign';
import buildingUtils from 'app/shared/utils/buildingUtils';
import {
    get3dTourUrlFlag,
    listingUtils_getIconType,
    listingUtils_isApartmentBldg,
    listingUtils_makeThumbUrl
} from 'app/shared/utils/listingUtils';
import dateUtils from 'app/shared/utils/dateUtils';
import map from 'lodash/map';

import Address from 'app/shared/models/Address';
import Amenities from 'app/shared/models/Amenities';
import Broker from 'app/shared/models/Broker';
import Geo from 'app/shared/models/Geo';
import noPhoto from 'images/noPhoto.png';
import Photo from 'app/shared/models/Photo';
import RecencyTime from 'app/shared/models/RecencyTime';

const logger = getLogger('models');

function determineListingType(listings) {
    if (listings.length === 1) {
        return String(listings[0].listingType) || '';
    }

    let isRoom = true;
    let nonRoomType = '';

    listings.forEach((listing) => {
        if (String(listing.listingType).toLowerCase() !== 'room') {
            nonRoomType = listing.listingType;
            isRoom = false;
        }
    });

    if (isRoom) {
        return 'room';
    } else {
        return String(nonRoomType).toLowerCase() || '';
    }
}

const SummaryV2 = function(
    {
        lotIdEncoded = '',
        geo = {},
        uri = '',
        listings = [
            {
                activated: 0,
                active: false,
                address: {},
                aliasEncoded: '',
                amenities: {},
                companyName: null,
                companyPhoneNumber: null,
                fullDescription: '',
                hasSpecialOffers: false,
                incomeRestricted: false,
                isBonusListingCustomer: false,
                isClaimedFreeListing: false,
                isBrokerExclusiveListing: false,
                listingType: '',
                medPhotoUrl: '',
                medPhotoUrls: [], // HPWEB-6082: URLs for new listing cards
                militaryHousing: false,
                modelTypeUnitCount: 0, // for multifamily listings
                name: '',
                photoCount: 0,
                priority: 0,
                propertyType: '',
                rentalApplicationStatus: '',
                reviewsSummary: {},
                score: 0,
                searchKeyword: '',
                seniorHousing: false,
                studentHousing: false,
                title: '',
                trusted: false,
                unit: '',
                updated: 0,
                uriMalone: '',
                userItemTypes: [],
                zillow3dTourUrl: false
            }
        ],
        neighborhoods = []
    } = {},
    filter = {}
) {
    try {
        const { isActive, buildingUserItemTypes, buildingActivated, buildingUpdated, listingMinMaxPriceBeds } =
            buildingUtils.processApiDataForSummaryV2(listings, filter);
        const [listing] = listings;
        const isBuilding = listings.length > 1;
        const hideStreet = listing.address && listing.address.hideStreet;
        const neighborhood = (listing.neighborhoods && listing.neighborhoods[0]) || '';
        this.active = Boolean(isActive);
        this.address = new Address(assign(listing.address, { neighborhood }));
        this.aliasEncoded = String(listing.aliasEncoded || '');
        this.amenities = new Amenities(listing && listing.amenities);
        this.building = Boolean(isBuilding);
        this.created = Number(buildingActivated); // TO DO: rename to activated.
        this.displayName = String(isBuilding && !hideStreet ? listing.address.street : listing.title);
        this.geo = new Geo(geo);
        this.iconType = String(listingUtils_getIconType(listing.propertyType.toLowerCase()));
        this.isApartmentBldg = Boolean(
            listingUtils_isApartmentBldg(listing.listingType.toLowerCase(), listing.propertyType.toLowerCase())
        );
        this.listingMinMaxPriceBeds = listingMinMaxPriceBeds;
        this.isBonusListing = Boolean(listing.isBonusListingCustomer);
        this.isClaimedFreeListing = Boolean(listing.isClaimedFreeListing);

        // for rooms, we only want to put listingType === 'room' if all the listings in the array
        // are rooms.
        this.listingType = determineListingType(listings); // 'rental'
        this.maloneLotIdEncoded = String(lotIdEncoded || '');
        this.trusted = Boolean(listing.trusted);
        this.averageStarLevel = listing.reviewsSummary && Number(listing.reviewsSummary.averageStarLevel);
        this.photoCount = Number(listing.photoCount || 0);
        this.previewPhoto = new Photo({
            url: listing.medPhotoUrl ? listing.medPhotoUrl : noPhoto
        });
        this.previewPhotoMed = new Photo({
            url: listing.medPhotoUrl ? listing.medPhotoUrl : noPhoto
        });
        this.thumbPhoto = new Photo({
            url: listing.medPhotoUrl ? listingUtils_makeThumbUrl(listing.medPhotoUrl) : noPhoto
        });
        this.medPhotoUrls = [].concat(listing.medPhotoUrls);
        this.priority = Number(listing.priority || 0);
        this.score = Number(listing.score || 0);
        this.propertyType = String(listing.propertyType.toLowerCase() || ''); // 'house'
        this.recencyTime = new RecencyTime(dateUtils.recencyTime(buildingActivated, new Date()));
        this.unitCount = Number(listings.length);
        this.searchKeyword = isBuilding
            ? String(listings.length + ' units available')
            : String(listing.searchKeyword || ''); // 'Apartments for Rent'
        this.unit = String(listing.unit || ''); // Unit A
        this.updatedTime = new RecencyTime(dateUtils.recencyTime(buildingUpdated, new Date()));
        this.uriBuilding = String(decodeURI(uri));
        this.uriMalone = String(decodeURI(listing.uriMalone || ''));
        this.urlMaloneUnit = String(listing.unit || '')
            .toLowerCase()
            .replace(' ', '-'); // 'unit-a'
        this.uriV2 = String(isBuilding ? decodeURI(uri || '') : decodeURI(listing.uriMalone || ''));
        this.userItemTypes = map(buildingUserItemTypes || [], (userItem) => {
            return String(userItem);
        });
        this.studentHousing = Boolean(listing.studentHousing);
        this.seniorHousing = Boolean(listing.seniorHousing);
        this.militaryHousing = Boolean(listing.militaryHousing);
        this.incomeRestricted = Boolean(listing.incomeRestricted);
        this.hasSpecialOffers = Boolean(listing.hasSpecialOffers);
        // TODO: ADD DATA MODEL + TESTS
        this.models = listing.models;
        this.neighborhood = String(neighborhoods[0] || '');
        this.has3dTour = get3dTourUrlFlag(listing);
        this.broker = new Broker(listing);
        this.fullDescription = String(listing.fullDescription || '');
        this.rentalApplicationStatus = String(listing.rentalApplicationStatus || ''); // HPWEB-6082
        this.modelTypeUnitCount = Number(listing.modelTypeUnitCount || 0);
    } catch (err) {
        logger.error(
            {
                dataError: 'dataModel',
                type: 'SummaryV2',
                error: err
            },
            'SummaryV2'
        );
        return false;
    }
};

export default SummaryV2;
