import Review from 'app/shared/models/Review';
import ReviewsBreakdown from 'app/shared/models/ReviewsBreakdown';
import ReviewsSummary from 'app/shared/models/ReviewsSummary';

interface ListingReviewsProps {
    averageStarLevel?: number;
    totalNumberOfTextReviews?: number;
    totalNumberOfReviews?: number;
    totalOneStarReviews?: number;
    totalTwoStarReviews?: number;
    totalThreeStarReviews?: number;
    totalFourStarReviews?: number;
    totalFiveStarReviews?: number;
    reviewType?: string;
    reviews?: any[]; // Assuming Review is a custom class
}

const logger = getLogger('models');

class ListingReviews {
    reviewsSummary: ReviewsSummary = new ReviewsSummary({});
    reviewsBreakdown: ReviewsBreakdown = new ReviewsBreakdown({});
    reviews: Review[] = [];

    constructor({
        averageStarLevel = 0,
        totalNumberOfTextReviews = 0,
        totalNumberOfReviews = 0,
        totalOneStarReviews = 0,
        totalTwoStarReviews = 0,
        totalThreeStarReviews = 0,
        totalFourStarReviews = 0,
        totalFiveStarReviews = 0,
        reviewType = '',
        reviews = []
    }: ListingReviewsProps = {}) {
        try {
            this.reviewsSummary = new ReviewsSummary({
                totalNumberOfTextReviews,
                totalNumberOfReviews,
                averageStarLevel,
                reviewType
            });
            this.reviewsBreakdown = new ReviewsBreakdown({
                totalNumberOfReviews,
                totalOneStarReviews,
                totalTwoStarReviews,
                totalThreeStarReviews,
                totalFourStarReviews,
                totalFiveStarReviews
            });
            this.reviews = reviews.map((review) => new Review(review));
        } catch (err) {
            logger.error({
                type: 'ListingReviews',
                dataError: 'dataModel',
                err
            });
        }
    }
}

export default ListingReviews;
