import React, { FC } from 'react';
import omit from 'lodash/omit';
import * as S from './styles';

interface TextProps {
    className?: string;
    htmlTag?: any;
    responsive?: Record<string, any>;
    size?: S.TextSizeKeys;
    children?: React.ReactNode;
}

const Text: FC<TextProps> = ({ size, htmlTag = 'span', className = '', children, responsive = {}, ...rest }) => {
    const CustomTag = htmlTag || 'span';

    // Omitting specific props to pass the rest to the styled component
    const omittedProps = omit(rest, ['children', 'className', 'htmlTag', 'responsive', 'size']);

    return (
        <S.Text as={CustomTag} className={className} size={size} responsive={responsive} {...omittedProps}>
            {children}
        </S.Text>
    );
};

export default Text;