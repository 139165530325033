// @ts-nocheck
/* eslint-enable */
import isNil from 'lodash/isNil';
import merge from 'lodash/merge';

import constants from 'app/shared/constants/ConstantsBundle';
import queryUtils from 'app/shared/utils/queryUtils';
import { listingUtils_getResourceIdAndSlug } from 'app/shared/utils/listingUtils';

const routeUtils = {
    buildAreaPath({ areaResourceId = 'new-york-ny', searchSlug = 'apartments-for-rent' }) {
        const url = '/' + areaResourceId + '/' + searchSlug;
        return url;
    },
    buildFreepassPath({ pathname = '/', query = {} } = {}) {
        if (query.redirect) {
            query = merge({}, query);
            pathname = decodeURIComponent(query.redirect);
            delete query.redirect;
        }

        let currentUrl = routeUtils.buildPathWithQuery(pathname, query);

        if (currentUrl === '/login') {
            currentUrl = '/user/account';
        }

        // add prefix for Comet freepass login
        return '/fp' + currentUrl;
    },
    buildPathWithQuery(pathname, queryObj) {
        return pathname + queryUtils.stringify(queryObj);
    },
    getMaloneLotIdEncodedFromUrl(url = '') {
        var maloneLotIdEncoded;
        var urlArray;

        url = url.split('/')[1];

        urlArray = (url || '').split('-');
        if (urlArray[urlArray.length - 1]) {
            maloneLotIdEncoded = urlArray[urlArray.length - 1];
        }
        return maloneLotIdEncoded;
    },
    getMaloneUnitFromUrl(url = '') {
        var maloneUnit;
        var tempUrl;

        tempUrl = url.split('/');

        if (tempUrl[3]) {
            // there are 3 directories in the url, like address/unit/pad
            maloneUnit = tempUrl[2];
        }
        return maloneUnit;
    },
    getResourceIdFromUrl(url) {
        if (routeUtils.isAreaUrl(url)) {
            return url.split('/')[1];
        } else {
            return false;
        }
    },
    guessAreaInfoFromUrl({ url = '/' }) {
        let zip = url.match(constants.ZIP_PATTERN);
        let state = url.match(constants.STATE_PATTERN);

        return {
            zip: zip ? zip[1] : null,
            state: state ? state[1] : null
        };
    },
    hasLatAndLon(query = {}) {
        return Boolean(query.lat && query.lon);
    },
    hasValidMinMaxLatLon(area = {}) {
        if (isNil(area.minLon) || isNil(area.minLat) || isNil(area.maxLon) || isNil(area.maxLat)) {
            return false;
        }
        if (isNaN(area.minLon) || isNaN(area.minLat) || isNaN(area.maxLon) || isNaN(area.maxLat)) {
            return false;
        }
        if (area.minLon > 180 || area.minLon < -180 || area.maxLon > 180 || area.maxLon < -180) {
            return false;
        }
        if (area.minLat > 90 || area.minLat < -90 || area.maxLat > 90 || area.maxLat < -90) {
            return false;
        }
        return true;
    },
    isAreaUrl(url = '') {
        const rentFragment = 'for-rent';

        url = url.split('?')[0];
        url = url.split('/');

        if (url.length !== 3) {
            // valid area url format: `/{area}/{searchSlug}`
            return false;
        }

        const lastSplit = url[url.length - 1];

        if (lastSplit.indexOf(rentFragment) > -1) {
            return true;
        }

        return false;
    },
    isBuildingUrl(url = '') {
        var lastSplit;

        url = url.split('/');
        lastSplit = url[url.length - 1];

        return lastSplit === 'building';
    },
    isNearMeUrl(url = '') {
        url = url.split('?')[0];
        url = url.split('/');
        const lastSplit = url[url.length - 1];
        const containsNearMeFragment = Boolean(constants.NEAR_ME_ROUTES[`/${lastSplit}`]);

        if (containsNearMeFragment) {
            return true;
        }

        return false;
    },
    isPadUrl(url = '') {
        var lastSplit;

        url = url.split('/');
        lastSplit = url[url.length - 1];
        return lastSplit.indexOf('pad') === 0;
    },
    isPadOrBuildingUrl(url = '') {
        return this.isPadUrl(url) || this.isBuildingUrl(url);
    },
    parseFailedListingAddress(path) {
        // Pathname formatted like: /1234-main-street-san-francisco-ca-94105-abcdef/building
        // Split path between any '/' characters, which generates this array:
        // ["", "1234-main-street-san-francisco-ca-94105-abcdef", "building"]
        let getAddressString = path.split('/')[1];

        // These pathnames contain unique 6 character maloneLot Id's at the end that will
        // screw up the getLatLonFromString method if it's included with the address.
        // So, let's cut off the last element. I thought I could do all this on one line, but I guess not.
        let getAddressArray = getAddressString.split('-');
        getAddressArray.pop();
        getAddressString = getAddressArray.join(' ');

        return getAddressString;
    },
    checkSchoolZoneFromPathname(id) {
        const isInSchoolZone = /-(SCHOOL|ELEMENTARY)/i.test(id);
        return isInSchoolZone;
    },
    isMapDataDifferent({ data, q }) {
        if (Number(data.zoom) !== Number(q.z)) {
            return true;
        } else if (Number(data.lat) !== Number(q.lat)) {
            return true;
        } else if (Number(data.lon) !== Number(q.lon)) {
            return true;
        } else if (data.border !== q.border) {
            return true;
        } else {
            return false;
        }
    },
    mergeMapDataIntoQuery({ data, q }) {
        const newQueryObj = Object.assign({}, q);

        // HPWEB-2850: Map can unexpectedly revert to original zoom / location
        // if min/max lat/ on from query params are present in URL. After getting
        // new map data, delete them from newQueryObj as they aren't needed.
        delete newQueryObj.minLat;
        delete newQueryObj.minLon;
        delete newQueryObj.maxLat;
        delete newQueryObj.maxLon;

        // should use either MapData or MapDataQueryObj, or better consolidate those so we just have 1.....
        newQueryObj.z = Number(data.zoom);
        newQueryObj.lat = Number(data.lat).toFixed(4);
        newQueryObj.lon = Number(data.lon).toFixed(4);
        if (data.border === false) {
            newQueryObj.border = false;
        }
        return newQueryObj;
    }
};

export default routeUtils;
