interface CrimeScoreParams {
    violentCrimes?: string;
    allCrimes?: string;
    areaName?: string;
    crimeScore?: string;
}

class CrimeScore {
    violentCrimes: string;
    allCrimes: string;
    areaName: string;
    crimeScore: string;

    constructor({ violentCrimes = '', allCrimes = '', areaName = '', crimeScore = '' }: CrimeScoreParams = {}) {
        this.violentCrimes = String(violentCrimes);
        this.allCrimes = String(allCrimes);
        this.areaName = String(areaName);
        this.crimeScore = String(crimeScore);
    }
}

export default CrimeScore;