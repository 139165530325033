// @ts-nocheck
/* eslint-enable */
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import extend from 'lodash/extend';

// Actions
import AuthActions from 'app/shared/flux/actions/AuthActions';

// Misc / Utils
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import { incrementPis } from 'app/client/utils/browserCookieUtils';
import Area from 'app/shared/models/Area';
import analyticsUtils from 'app/shared/utils/analyticsUtils';
import api from 'app/shared/utils/api';
// todo: move this to a shared util
import clusterSettingCache from 'app/server/utils/clusterSettingCache';
import constants from 'app/shared/constants/ConstantsBundle';
import mapConstants from 'app/shared/constants/MapConstants';
import { TrackGoogleLogin } from 'app/shared/models/Clickstream/LoginClickstreamEvents';

const logger = getLogger('actions/app');

const SIGN_IN_WITH_GOOGLE_ERROR_MESSAGES = {
    ACCOUNT_DEACTIVATED: constants.SIGN_IN_WITH_GOOGLE_ACCOUNT_DEACTIVATED,
    default: constants.SIGN_IN_WITH_GOOGLE_DEFAULT,
    EMAIL_NOT_SUPPLIED: constants.SIGN_IN_WITH_GOOGLE_EMAIL_NOT_SUPPLIED,
    INTERNAL_ERROR: constants.SIGN_IN_WITH_GOOGLE_INTERNAL_ERROR,
    INVALID_API_CALL: constants.SIGN_IN_WITH_GOOGLE_INVALID_API_CALL,
    INVALID_CREDENTIALS: constants.SIGN_IN_WITH_GOOGLE_INVALID_CREDENTIALS,
    USER_ALREADY_EXISTS: constants.SIGN_IN_WITH_GOOGLE_USER_ALREADY_EXISTS
};

const AppActions = {
    sendEventToApi(type, id) {
        return (dispatch, getState) => {
            let state = getState();

            let apiArgs = {
                type,
                id
            };

            const requiresId =
                type === 'previewed' ||
                type === 'addedToAccountFavorites' ||
                type === constants.LISTING_VIEWED ||
                type === constants.EXPIRED_LISTING_VIEWED;

            if (requiresId && !id) {
                logger.warn(`AppActions#sendEventToApi called with type ${type} requires an id, but no id was passed`);
                return false;
            }
            if (state.analytics.ref) {
                extend(apiArgs, {
                    ref: state.analytics.ref
                });
            }
            return dispatch(api.event.trigger(apiArgs));
        };
    },
    refTrack(ref) {
        return function(dispatch) {
            if (ref) {
                if (isArray(ref)) {
                    ref = ref[0];
                }

                dispatch(api.event.refTrack(ref));
            }
        };
    },
    pageOnLoad(pathname, query) {
        return function(dispatch, getState) {
            // happens on ever page change / client-side navigation page load
            if (window.COMSCORE) {
                const CS_FPID = getState()?.user?.userToken;
                const CS_FPIT = getState()?.user?.loggedIn === true ? 'li' : 'lo';
                window.COMSCORE.beacon({
                    c1: '2',
                    c2: '6036206',
                    cs_fpid: `${CS_FPID}`,
                    cs_fpit: `${CS_FPIT}`,
                    cs_fpdm: '*null',
                    cs_fpdt: '*null'
                });
            }
            if (!getState().app.isInitialSsrPage && window.COMSCORE) {
                dispatch(api.comscore.pageview());
            }
            dispatch(AppActions.updatePageDetails(pathname, query));
        };
    },
    updatePageDetails(pathname, query) {
        return function(dispatch, getState) {
            var areaType = getState().area.area.type;
            const type = constants.UPDATE_CURRENT_PAGE_DETAILS;
            const name = analyticsUtils.getPageName({ pathname, areaType });
            var currentPage = {
                name,
                pathname,
                query
            };

            dispatch({
                type,
                currentPage
            });

            incrementPis();
        };
    },
    loadFacebookApi(callback) {
        return function(dispatch) {
            if (typeof window !== 'undefined' && !window.FB) {
                const facebookLoginCallback = () => {
                    // Loading hotpads.com/login from IE10 causes window.FB to be undefined on SSR.
                    // This causes all third-party social login APIs to fail on this page in IE10.
                    // Checking if window.FB exists handles that case and still works for other browsers.
                    if (window.FB) {
                        window.FB.init({
                            version: 'v3.2',
                            appId: '60854893843',
                            channelUrl: '/fbchannel.html',
                            status: false,
                            cookie: true,
                            xfbml: true,
                            logging: true,
                            oath: true
                        });

                        dispatch({
                            type: constants.FACEBOOK_INIT_SUCCESS
                        });
                    }

                    if (callback) {
                        callback();
                    }
                };

                logger.info('loadFacebookApi');

                try {
                    //eslint-disable-next-line no-undef
                    Optanon.InsertScript(
                        'https://connect.facebook.net/en_US/sdk.js',
                        'head',
                        facebookLoginCallback,
                        null,
                        4
                    );
                } catch (err) {
                    logger.error(err, 'Error loading Facebook auth api');
                }
            }
        };
    },
    loadSignInWithGoogleApi({ onError }) {
        return function(dispatch) {
            if (typeof window !== 'undefined' && !window.google) {
                logger.info('loadSignInWithGoogleApi');

                window.onGoogleLibraryLoad = () => {
                    dispatch({
                        type: constants.SIGN_IN_WITH_GOOGLE_INIT_SUCCESS
                    });
                };

                // called when a user sucessfully authenticates via button or one-tap
                const handleCredentialResponse = (res) => {
                    const { credential } = res;
                    dispatch(AuthActions.login.signInWithGoogle(credential))
                        .then(() => {
                            dispatch(
                                analyticsEvent(gaEvents.SIGN_IN_WITH_GOOGLE_SUCCESS, {
                                    newLaneEvent: TrackGoogleLogin()
                                })
                            );
                            dispatch(AuthActions.login.checkLoginPageRedirect());
                        })
                        .catch((err) => {
                            if (isString(err)) {
                                const errorMsg =
                                    SIGN_IN_WITH_GOOGLE_ERROR_MESSAGES[err] ||
                                    SIGN_IN_WITH_GOOGLE_ERROR_MESSAGES.default;
                                onError(errorMsg);
                            }
                            logger.error(err, 'Error signing in with Sign In With Google api');
                        });
                };

                const googleLoginCallback = () => {
                    window.google.accounts.id.initialize({
                        /* eslint-disable camelcase */
                        client_id: '353588935388-gtdkbktgahlhkisd7nas72b5p6vrno4u.apps.googleusercontent.com',
                        callback: handleCredentialResponse
                        // native_callback: handleNativeResponse // TODO: implement handleNativeResponse to handle chrome login
                    });

                    // opens one-touch modal
                    window.google.accounts.id.prompt((PromptMomentNotification) => {
                        if (PromptMomentNotification.isNotDisplayed()) {
                            logger.info(
                                'googleOneTapNotDisplayed reason:',
                                PromptMomentNotification.getNotDisplayedReason()
                            );
                        } else {
                            logger.info('googleOneTapDisplayed');
                        }
                    });

                    // render button
                    const button = document.getElementById('g_id_signin');
                    window.google.accounts.id.renderButton(button, {
                        size: 'large',
                        text: 'signin_with',
                        theme: 'filled_blue',
                        width: '224'
                    });
                };

                try {
                    //eslint-disable-next-line no-undef
                    Optanon.InsertScript(
                        'https://accounts.google.com/gsi/client',
                        'head',
                        googleLoginCallback,
                        null,
                        4
                    );
                } catch (err) {
                    logger.error(err, 'Error loading Sign In With Google api');
                }
            }
        };
    },
    gmapApiSuccess() {
        return function(dispatch) {
            dispatch({
                type: constants.GMAP_API_READY
            });
        };
    },
    toggleNotificationMessage(payload) {
        return function(dispatch) {
            dispatch({
                type: constants.NOTIFICATION_DISPLAY_BOOL,
                payload
            });
        };
    },
    showPopupModal(component, payload) {
        return function(dispatch) {
            var componentWithData = {
                component,
                data: payload
            };

            dispatch({
                type: constants.SHOW_POPUP_MODAL,
                payload: componentWithData
            });
        };
    },
    hidePopupModal(payload) {
        return function(dispatch) {
            dispatch({
                type: constants.HIDE_POPUP_MODAL,
                payload
            });
        };
    },
    activateHamburger() {
        return function(dispatch) {
            dispatch({
                type: constants.HAMBURGER_ACTIVE
            });
        };
    },
    deactivateHamburger() {
        return function(dispatch) {
            dispatch({
                type: constants.HAMBURGER_INACTIVE
            });
        };
    },
    toggleOverlay(type, bool) {
        return function(dispatch) {
            dispatch({
                type: constants.TOGGLE_OVERLAY,
                payload: {
                    type,
                    bool
                }
            });
        };
    },
    changeMapOverlay(mapOverlayKey) {
        // revert map overlay if specified overlay key doesn't exist
        const finalOverlayKey = mapConstants.OVERLAYS.includes(mapOverlayKey) ? mapOverlayKey : mapConstants.NO_OVERLAY;

        return function(dispatch) {
            dispatch({
                type: constants.MAP_OVERLAY_CHANGED,
                payload: finalOverlayKey
            });
        };
    },
    changeMapType(mapType) {
        const finalMapType = mapConstants.MAP_TYPES.includes(mapType) ? mapType : mapConstants.DEFAULT;

        return function(dispatch) {
            dispatch({
                type: constants.MAP_TYPE_CHANGED,
                payload: finalMapType
            });
        };
    },
    resetMap() {
        return function(dispatch) {
            dispatch({
                type: constants.MAP_TYPE_CHANGED,
                payload: mapConstants.DEFAULT
            });
            dispatch({
                type: constants.MAP_OVERLAY_CHANGED,
                payload: mapConstants.NO_OVERLAY
            });
        };
    },
    updateMapBounds(mapBounds) {
        return function(dispatch) {
            dispatch({
                type: constants.UPDATE_MAP_BOUNDS,
                payload: {
                    mapBounds
                }
            });
        };
    },
    reportReview({ reviewId, comment, reviewType }) {
        return function(dispatch, getState) {
            const userToken = getState().user.userToken;
            return dispatch(api.review.report({ reviewId, comment, userToken, reviewType }));
        };
    },
    voteReview({ reviewId, vote, reviewType }) {
        return function(dispatch, getState) {
            const userToken = getState().user.userToken;
            return dispatch(api.review.vote({ reviewId, vote, userToken, reviewType }));
        };
    },
    saveReviewResponse(reviewId, comment) {
        return function(dispatch, getState) {
            const userEmail = getState().user.info.email;
            const date = new Date(Date.now());
            const dateISOString = date.toISOString();
            return dispatch(api.review.saveResponse(reviewId, comment, userEmail, dateISOString));
        };
    },
    setAppStoreBool(name, bool) {
        return function(dispatch) {
            return dispatch({
                type: constants.SET_APP_STORE_BOOL,
                payload: {
                    name,
                    bool
                }
            });
        };
    },
    // HPWEB-5795 experimental sort order
    setBBTestGroup(bbTestGroup) {
        return function(dispatch, getState) {
            if (bbTestGroup) {
                dispatch({
                    type: constants.SET_BOUNTY_BRAIN_TEST_GROUP,
                    payload: {
                        bbTestGroup
                    }
                });
            }
        };
    },
    resetBBTestGroup() {
        return function(dispatch) {
            dispatch({
                type: constants.SET_BOUNTY_BRAIN_TEST_GROUP,
                payload: {
                    bbTestGroup: null
                }
            });
            return Promise.resolve(true);
        };
    },
    setForceAdsRefresh(bool) {
        return function(dispatch) {
            dispatch({
                type: constants.SET_FORCE_ADS_REFRESH,
                payload: { bool }
            });
        };
    },
    setDevice(device = {}) {
        return (dispatch) => {
            dispatch({
                type: constants.SET_DEVICE,
                payload: device
            });
        };
    },
    setAreaPageScrollPosition(maloneLotId) {
        return (dispatch) => {
            dispatch({
                type: constants.SET_AREA_PAGE_SCROLL,
                payload: maloneLotId
            });
        };
    },
    getClusterSettings() {
        return (dispatch) => {
            dispatch(api.clusterSettings()).then((response) => {
                if (response.success && response.data) {
                    clusterSettingCache.reload(response.data);

                    return dispatch({
                        type: constants.UPDATE_CLUSTER_SETTINGS,
                        payload: clusterSettingCache.clusterSettingDict
                    });
                }
            });
        };
    },
    getIp(skipClientProxy) {
        return (dispatch) => dispatch(api.user.ip(skipClientProxy));
    },
    setIpFromJava(response) {
        return (dispatch) =>
            dispatch({
                type: constants.UPDATE_IP_FROM_JAVA,
                payload: response
            });
    },
    getGeoIp({ ip, trace = null }) {
        return (dispatch) => {
            trace?.startAsyncSpan({ marker: 'geoIp' });
            return dispatch(api.area.byIp(ip)).then((byIp) => {
                const { status } = byIp;
                const areaData = byIp.data;
                if (status !== 'OK') {
                    logger.warn({ byIp }, 'No geocode for IP %s', ip);
                    trace?.finishAsyncSpan({ marker: 'geoIp' });
                    return false;
                }
                const { type } = byIp.data;
                if (type) {
                    trace?.finishAsyncSpan({ marker: 'geoIp' });
                    return dispatch({
                        type: constants.GEOLOCATION_USER_AREA,
                        area: new Area(areaData)
                    });
                } else {
                    logger.warn({ byIp }, 'No area for geocoded IP %s', ip);
                    trace?.finishAsyncSpan({ marker: 'geoIp' });
                }
            });
        };
    }
};

export default AppActions;
