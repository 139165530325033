import React, { Ref, forwardRef } from 'react';
import omit from 'lodash/omit';
import * as S from './styles';

interface LinkerProps {
    className?: string;
    disabled?: boolean;
    escape?: boolean; // escape react router
    linkType?: S.LinkType;
    useButton?: boolean;
    to?: string;
    [key: string]: any; // To allow any other props passed to the component
}

const defaultProps: Partial<LinkerProps> = {
    disabled: false,
    escape: false // escape react router
};

type RefType<T> = T extends { useButton: true } ? HTMLButtonElement : HTMLAnchorElement;

// in order to get proper name in dev tools instead of forwardRef
const Linker = forwardRef<RefType<LinkerProps>, LinkerProps>((props, ref) => {
    const { className, linkType, disabled, useButton } = props;
    const rest = omit(props, ['className', 'linkType', 'disabled', 'useButton', 'isActive', 'isBold']);

    return useButton ? (
        <S.TextButton
            {...rest}
            ref={ref as Ref<HTMLButtonElement>}
            className={className}
            linkType={linkType}
            disabled={disabled}
            aria-disabled={disabled}
        />
    ) : (
        <S.Linker
            {...rest}
            ref={ref}
            className={className}
            linkType={linkType}
            disabled={disabled}
            aria-disabled={disabled}
        />
    );
});

Linker.defaultProps = defaultProps;

export default Linker;
