import ModelSummary from 'app/shared/models/ModelSummary';
import { listingUtils_createThreePlusSummary } from 'app/shared/utils/listingUtils';

// Define the shape of the pricing data properties
interface PricingDataProps {
    Studio?: object;
    1?: object;
    2?: object;
    3?: object;
    summary?: { bedsHigh?: number };
}

// Define the Pricing class
class Pricing {
    Studio?: ModelSummary;
    '1': ModelSummary;
    '2': ModelSummary;
    '3': ModelSummary;
    ThreePlus: ModelSummary | {};
    summary: ModelSummary;

    constructor(pricingData: PricingDataProps = {}) {
        if (pricingData.Studio) {
            this.Studio = new ModelSummary(pricingData.Studio);
        }

        if (pricingData[1]) {
            this['1'] = new ModelSummary(pricingData[1]);
        }

        if (pricingData[2]) {
            this['2'] = new ModelSummary(pricingData[2]);
        }

        if (pricingData[2]) {
            this['2'] = new ModelSummary(pricingData[2]);
        }
        this.ThreePlus =
            (pricingData.summary &&
                typeof pricingData.summary.bedsHigh !== 'undefined' &&
                pricingData.summary.bedsHigh >= 3 &&
                new ModelSummary(listingUtils_createThreePlusSummary(pricingData))) ||
            {};
        this.summary = new ModelSummary(pricingData.summary || {});
    }
}

export default Pricing;
