// @ts-nocheck
/* eslint-enable */
import routeUtils from 'app/shared/utils/routeUtils';
import pathsManifest from 'app/shared/utils/pathsManifest';

const analyticsUtils = {
    getPageName({ pathname = '', areaType = '' }) {
        let pageName = pathname;

        if (routeUtils.isPadUrl(pathname)) {
            pageName = 'MapHdpCombo';
        } else if (routeUtils.isBuildingUrl(pathname)) {
            pageName = 'MapHdpCombo';
        } else if (routeUtils.isAreaUrl(pathname)) {
            pageName = 'RentMapSearchPage-' + areaType;
        }

        for (let key in pathsManifest) {
            if (pathsManifest[key] && pathsManifest[key] === pathname) {
                pageName = key;
                break;
            }
        }

        return pageName;
    }
};

export default analyticsUtils;
