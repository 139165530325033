import styled from 'styled-components';

interface RowProps {
    size?: 'zero' | 'sm' | 'md' | 'lg' | 'xl';
    flex?: boolean;
}

export const Row = styled.div`
    clear: both;
    padding: 8px 0px;

    ${(props: RowProps) =>
        props.size &&
        props.size === 'zero' &&
        `

        padding: 0px;
    `}

    ${(props: RowProps) =>
        props.size &&
        props.size === 'sm' &&
        `
        clear: both;
        padding: 4px 0px;
    `}

    ${(props: RowProps) =>
        props.size &&
        props.size === 'md' &&
        `
        padding: 16px 0px;
    `}

    ${(props: RowProps) =>
        props.size &&
        props.size === 'lg' &&
        `
        padding: 24px 0px;
    `}

    ${(props: RowProps) =>
        props.size &&
        props.size === 'xl' &&
        `
        padding: 48px 0px;
    `}

    ${(props: RowProps) =>
        props.flex &&
        `
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    `}
`;
