import styled from 'styled-components';
import { viewports } from 'app/shared/styles/_breakpoints';
import { fontSize } from 'app/shared/styles/_fontsize';

import Animation from 'app/shared/modules/Animation';

export const NotFoundPage = styled.div`
    text-align: center;
    margin-top: 42px;

    @media ${viewports['lg-and-up']} {
        margin-top: 0;
        text-align: left;
        min-height: 650px;
    }
`;

export const NotFoundPageLeft = styled.div`
    width: 100%;
    display: inline-block;
    text-align: center;
    margin-top: 0;

    @media ${viewports['lg-and-up']} {
        text-align: left;
        width: 60%;
        margin-top: 60px;
    }
`;

export const NotFoundPageAutocompleteWrapper = styled.div`
    max-width: 500px;
    margin: 0 auto;
    height: 40px;

    @media ${viewports['lg-and-up']} {
        max-width: inherit;
        margin: auto;
        height: 44px;
    }
`;

export const NotFoundPageSubColumn = styled.div`
    text-align: center;
    width: 100%;
    vertical-align: top;
    line-height: ${fontSize['$line-height-md']};

    @media ${viewports['lg-and-up']} {
        display: inline-block;
        width: 50%;
        text-align: left;
    }
`;

export const NotFoundPageHouse = styled(Animation)`
    width: 288px;
    height: 246px;
    margin: 0 auto;
    left: 0;
    right: 0;

    @media ${viewports['lg-and-up']} {
        height: 440px;
        left: auto;
        position: absolute;
        right: -60px;
        top: 0;
        width: 517px;
    }
`;
