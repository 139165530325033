import Coordinates from 'app/shared/models/Coordinates';
import ListingCounts, { IListingCounts } from 'app/shared/models/ListingCounts';
import isString from 'lodash/isString';
import { areaUtils_getFullNameFromArea, areaUtils_getNameFromArea } from 'app/shared/utils/areaUtils';
import seoUtils from 'app/shared/utils/seoUtils';
import numberUtils from 'app/shared/utils/numberUtils';

export type AreaType =
    | 'borough'
    | 'city'
    | 'county'
    | 'elemschdist'
    | 'highschool'
    | 'middleschool'
    | 'mixedschool'
    | 'neighborhood'
    | 'primaryschool'
    | 'secschdist'
    | 'state'
    | 'unifschdist'
    | 'university'
    | 'zip';

export interface AreaResponse {
    id: string;
    resourceId: string;
    name: string;
    fullName?: string;
    type: AreaType;
    uriV2: string;
    state: string;
    city: string;
    zip: string;
    neighborhood: string;
    county: string;
    minLat: number;
    maxLat: number;
    minLon: number;
    maxLon: number;
    coordinates: {
        lat: number;
        lon: number;
    };
    breadcrumbs: AreaResponse[];
    listingCounts?: IListingCounts;
    countryCode?: string;
}

class Area {
    id?: string;
    resourceId?: string;
    name?: string;
    type: AreaType | null;
    fullName: string | null;
    uriV2: string;
    state?: string;
    city?: string;
    neighborhood: string | null;
    zip: string | null;
    county: string | null;
    minLat?: number;
    maxLat?: number;
    minLon?: number;
    maxLon?: number;
    breadcrumbs: any[];
    listingCounts?: ListingCounts;
    coordinates: Coordinates;
    countryCode?: string;

    constructor(area: AreaResponse) {
        if (isString(area.type)) {
            area.type = area.type.toLowerCase() as AreaType;
            area.fullName = areaUtils_getFullNameFromArea(area);
            area.name = areaUtils_getNameFromArea(area);
        }

        const { coordinates = {} as Coordinates } = area;

        this.id = area.id;
        this.resourceId = area.resourceId;
        this.name = area.name;
        this.type = area.type || null;
        this.fullName = area.fullName || null;

        // TODO: Remove uri. uri should always be generated. URI's should not belong to an area..
        // but they do belong to breadcrumb's; need a condensedArea obj and a helper method to create uri,
        // something like getUri(area, searchSlug)?
        this.uriV2 = seoUtils.getConsolidatedUri(area.uriV2 || '');
        this.state = area.state;
        this.city = area.city;
        this.neighborhood = area.neighborhood || null;
        this.zip = area.zip || null;
        this.county = area.county || null;
        this.minLat = numberUtils.trimDecimal(area.minLat);
        this.maxLat = numberUtils.trimDecimal(area.maxLat);
        this.minLon = numberUtils.trimDecimal(area.minLon);
        this.maxLon = numberUtils.trimDecimal(area.maxLon);
        this.breadcrumbs = area.breadcrumbs || [];

        if (area.listingCounts) {
            this.listingCounts = new ListingCounts(area.listingCounts);
        }

        this.coordinates = new Coordinates({
            lon: coordinates.lon,
            lat: coordinates.lat
        });
        this.countryCode = area.countryCode;
    }
}

export default Area;
