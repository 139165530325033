// @ts-nocheck
/* eslint-enable */
// TODO: implement location so we have all the location data in 1 store.
import constants from 'app/shared/constants/ConstantsBundle';
import isPlainObject from 'lodash/isPlainObject';
import forEach from 'lodash/forEach';
import reduxUtils from 'app/shared/utils/reduxUtils';
import assign from 'lodash/assign';

const initState = () => ({
    pageCount: 0,
    ssrEntry: {
        pathname: '',
        query: {},
        name: '',
        traceId: '',
        requests: []
    },
    // DEPRECATED
    current: {
        pathname: '',
        query: {},
        name: ''
    }
});

const mapActionsToReducer = {
    [constants.SERVER_SIDE_APP_STORE_INIT_STATE]: (state, action) => {
        const initData = action.payload;

        if (!isPlainObject(initData)) {
            return state;
        }

        forEach(initData.query, (v, k) => {
            initData.query[k] = decodeURIComponent(v);
            if (initData.query[k] === 'true') {
                initData.query[k] = true;
            }
            if (initData.query[k] === 'false') {
                initData.query[k] = false;
            }
        });

        const ssrLocation = {
            pathname: initData.pathname,
            query: initData.query,
            name: initData.name,
            traceId: initData.traceId,
            requests: []
        };

        const location = {
            pathname: initData.pathname,
            query: initData.query,
            name: initData.name
        };

        return assign({}, state, {
            ssrEntry: assign({}, state.current, ssrLocation),
            current: assign({}, state.current, location)
        });
    },
    [constants.ADD_REQUEST_TO_SSR_TRACE]: (state, action) => {
        const newEntry = action.payload;
        let newEntryArray = state.ssrEntry.requests.slice(0, state.ssrEntry.requests.length);
        newEntryArray.push(newEntry);

        return assign({}, state, {
            ssrEntry: assign({}, state.ssrEntry, {
                requests: newEntryArray
            })
        });
    },
    [constants.UPDATE_CURRENT_PAGE_DETAILS]: (state, action) => {
        const currentPage = assign({}, action.currentPage);

        forEach(currentPage.query, (v, k) => {
            if (currentPage.query[k] === 'true') {
                currentPage.query[k] = true;
            }
            if (currentPage.query[k] === 'false') {
                currentPage.query[k] = false;
            }
        });

        return assign({}, state, {
            pageCount: state.pageCount + 1,
            current: assign({}, state.current, {
                pathname: currentPage.pathname,
                query: currentPage.query,
                name: currentPage.name
            })
        });
    }
};

const location = reduxUtils.createReducer(mapActionsToReducer, initState());

export default location;
