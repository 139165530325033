import Area from 'app/shared/models/Area';
import MapData from 'app/shared/models/MapData';
import { variables } from 'app/shared/styles/_variables';
import { LatLon } from 'app/types';

/**
 * NOTE: May need to move these types into a common types file at some point if they are referenced
 * in more than one file.
 */
export interface LatLonFn {
    lat(): number;
    lng(): number;
}

export interface LatLngBounds {
    getNorthEast(): LatLonFn;
    getSouthWest(): LatLonFn;
}

interface Point {
    x: number;
    y: number;
}

interface MapProjection {
    // eslint-disable-next-line no-unused-vars
    fromLatLngToPoint(latLng: LatLonFn): Point;
    // eslint-disable-next-line no-unused-vars
    fromPointToLatLng(point: Point): LatLonFn;
}

export interface IMap {
    zoom: number;
    getCenter(): LatLonFn;
    getBounds(): LatLngBounds | null;
    getDiv(): HTMLElement;
    getProjection(): MapProjection;
    getZoom(): number;
}

interface MapDimensions {
    width: number;
    height: number;
}

interface BoundingBoxParams {
    mapClassName?: string;
    minLat: number;
    maxLat: number;
    minLon: number;
    maxLon: number;
    mapDimensions?: MapDimensions;
}

interface IMapData {
    zoom: number;
    lat: number;
    lon: number;
    maxLat: number;
    minLat: number;
    minLon: number;
    maxLon: number;
    mapContainerId?: string;
}

const gmapUtils = {
    /**
    * Decodes to a [latitude, longitude] coordinates array.
    *
    * This is adapted from the implementation in Project-OSRM.
    * Further adapted from: https://github.com/mapbox/polyline/blob/master/src/polyline.js
    *
    * @param {string} str
    * @param {number} [precision=5]
    * @returns {[number, number][]}
    */
    decodePolyline(str: string, precision: number = 5): [number, number][] {
        const factor = Math.pow(10, precision);

        let index = 0,
            lat = 0,
            lng = 0,
            coordinates: [number, number][] = [],
            shift = 0,
            result = 0,
            byte = null,
            latitudeChange,
            longitudeChange;

        while (index < str.length) {
            byte = null;
            shift = 0;
            result = 0;

            do {
                byte = str.charCodeAt(index++) - 63;
                result |= (byte & 0x1f) << shift;
                shift += 5;
            } while (byte >= 0x20);

            latitudeChange = result & 1 ? ~(result >> 1) : result >> 1;

            shift = result = 0;

            do {
                byte = str.charCodeAt(index++) - 63;
                result |= (byte & 0x1f) << shift;
                shift += 5;
            } while (byte >= 0x20);

            longitudeChange = result & 1 ? ~(result >> 1) : result >> 1;

            lat += latitudeChange;
            lng += longitudeChange;

            coordinates.push([lat / factor, lng / factor]);
        }

        return coordinates;
    },

    getCenterLatLonFromPoints(loc1: LatLon, loc2: LatLon): LatLon {
        if (!loc1) {
            throw new Error('Missing data. Unable to get center lat / lon.');
        } else if (!loc2) {
            return {
                lat: loc1.lat,
                lon: loc1.lon
            };
        } else {
            return {
                lat: (loc1.lat + loc2.lat) / 2,
                lon: (loc1.lon + loc2.lon) / 2
            };
        }
    },

    getMaxLatLonExtent(loc1: LatLon, loc2: LatLon) {
        if (!loc1) {
            throw new Error('Missing data. Unable to get maximum lat / lon extent.');
        } else if (!loc2) {
            return {
                maxLat: loc1.lat,
                maxLon: loc1.lon,
                minLat: loc1.lat,
                minLon: loc1.lon
            };
        } else {
            return {
                maxLat: loc1.lat >= loc2.lat ? loc1.lat : loc2.lat,
                maxLon: loc1.lon >= loc2.lon ? loc1.lon : loc2.lon,
                minLat: loc1.lat <= loc2.lat ? loc1.lat : loc2.lat,
                minLon: loc1.lon <= loc2.lon ? loc1.lon : loc2.lon
            };
        }
    },

    getMapData(map: IMap): MapData | {} {
        try {
            if (!map) {
                return {};
            }

            const zoom = map.zoom;
            const center = map.getCenter();
            const bounds = map.getBounds();
            let ne = center;
            let sw = center;

            if (bounds) {
                ne = bounds.getNorthEast();
                sw = bounds.getSouthWest();
            }

            const data: MapData = {
                zoom: Number(zoom),
                lat: Number(center.lat().toFixed(4)),
                lon: Number(center.lng().toFixed(4)),
                minLat: sw.lat(), // bottom
                maxLat: ne.lat(), // top
                minLon: sw.lng(), // left
                maxLon: ne.lng() // right
            };
            return new MapData(data);
        } catch (err) {
            return {};
        }
    },

    streetViewHelpers: {
        findYaw(viewLat: number, viewLon: number, targetLat: number, targetLon: number) {
            var latDif = targetLat - viewLat;
            var lonDif = targetLon - viewLon;

            if (latDif > 0) {
                return Math.atan(lonDif / latDif);
            }
            if (latDif < 0) {
                return Math.atan(lonDif / latDif) + Math.PI;
            }
            if (lonDif === 0) {
                return 0;
            }
            return ((Math.abs(lonDif) / lonDif) * Math.PI) / 2;
        },
        radToDeg(rads: number) {
            return (rads * 360) / (2 * Math.PI);
        }
    },
    getTargetMapDimensions(mapIdName: string = 'MapWrapper'): MapDimensions {
        let width = 500;
        let height = 500;

        if (typeof document !== 'undefined') {
            const getListCardColumnWidth = (): number => {
                if (window.innerWidth > 1199) {
                    return variables['sidebar-width-xl-value-only']; // Double column, old cards
                } else if (window.innerWidth > 767) {
                    return variables['sidebar-width-md-value-only']; // Single column, old cards
                }
                return 0;
            };

            const mapElement = document.getElementById(mapIdName);
            if (mapElement) {
                const calculatedWidth = mapElement.offsetWidth - getListCardColumnWidth();
                width = calculatedWidth > 0 ? calculatedWidth : 500;
                height = mapElement.offsetHeight || 500;
            }
        }

        return { width, height };
    },

    getZoomForBoundingBox({ mapClassName, minLat, maxLat, minLon, maxLon, mapDimensions }: BoundingBoxParams): number {
        if (!mapDimensions) {
            mapDimensions = this.getTargetMapDimensions(mapClassName);
        }

        if (minLat === maxLat || minLon === maxLon) {
            return 14;
        }

        const mapWidth = mapDimensions.width;

        const GLOBE_WIDTH = 256; // a constant in Google's map projection
        let west = minLon;
        let east = maxLon;
        let angle = east - west;
        if (angle < 0) {
            angle += 360;
        }
        let zoom = Math.floor(Math.log((mapWidth * 360) / angle / GLOBE_WIDTH) / Math.LN2);

        // if the bounding box is much longer than wide, zoom out to fit on screen
        const lonDifference = maxLon - minLon;
        const latDifference = maxLat - minLat;

        if (lonDifference / latDifference < 1) {
            zoom--;
        }

        return Number(zoom);
    },

    // only works on the client (still need a map!)
    getBoundingBoxFromCenterPoint({ lat, lon, map }: {
        lat: number
        lon: number
        map: IMap
    }) {
        var w = map.getDiv().offsetWidth;
        var h = map.getDiv().offsetHeight;
        var latLng = new window.google.maps.LatLng(lat, lon);
        var centerPixel = map.getProjection().fromLatLngToPoint(latLng);
        var pixelSize = Math.pow(2, -map.getZoom());

        var nePoint = new window.google.maps.Point(centerPixel.x + w * pixelSize, centerPixel.y - h * pixelSize);
        var swPoint = new window.google.maps.Point(centerPixel.x - w * pixelSize, centerPixel.y + h * pixelSize);

        var ne = map.getProjection().fromPointToLatLng(nePoint);
        var sw = map.getProjection().fromPointToLatLng(swPoint);

        return {
            minLat: sw.lat(),
            maxLat: ne.lat(),
            minLon: sw.lng(),
            maxLon: ne.lng()
        };
    },
    getXForLongitudeAtLevel(lon: number, level: number) {
        var circumference = 1 << level;
        var degreesFromLeft = 180 + lon;
        var percentRight = degreesFromLeft / 360;
        var x = Math.round(circumference * percentRight);

        return x;
    },
    getYForLatitudeAtLevel(lat: number, level: number) {
        var circumference = 1 << level;
        var latRadians = (lat * Math.PI) / 180;
        var radius = circumference / (2 * Math.PI);
        var y = (radius / 2.0) * Math.log((1.0 + Math.sin(latRadians)) / (1.0 - Math.sin(latRadians)));

        return Math.round(circumference / 2 - y);
    },
    getAccessibleLabel({
        isAreaUrl = false,
        isNearMeUrl = false,
        isPadOrBuildingUrl = false,
        area = null,
        listing = null
    }: {
        area: Area | null
        isAreaUrl: boolean
        isNearMeUrl: boolean
        isPadOrBuildingUrl: boolean
        listing: {
            displayName: string
        } | null
    }) {
        const hasAreaName = area && area.fullName;
        const hasListingName = listing && listing.displayName;
        if (isAreaUrl) {
            if (hasAreaName) {
                return `Interactive Google Map showing listings in ${area.fullName}`;
            }
            return 'Interactive Google Map showing listings.';
        } else if (isNearMeUrl) {
            if (hasAreaName) {
                return `Interactive Google Map showing listings near you, in ${area.fullName}.`;
            }
            return 'Interactive GoogleMap showing listings near you.';
        } else if (isPadOrBuildingUrl) {
            if (hasListingName) {
                return `Interactive Google Map showing ${listing.displayName}.`;
            } else if (hasAreaName) {
                return `Interactive Google Map showing a listing in ${area.fullName}`;
            }
            return 'Interactive Google Map showing a listing.';
        }
        return null;
    }
};
/**
 * Credit goes to Zillow.com: Function takes in lat/lon and figure out the best fit bounding box and zoom level
 * @param {Object} mapData - An object containing an area min/max lat/lon info as well as an optional element id
 * @param {number} mapData.maxLat - An area's maximum latitude
 * @param {number} mapData.minLat - An area's minimum latitude
 * @param {number} mapData.minLon - An area's minimum longitude
 * @param {number} mapData.maxLon - An area's maximim longitude
 * @param {string} [mapData.mapContainerId = 'google-map-container'] - id of element that contains the Map
 * @returns {Object} zoomAndsBounds object
 * @returns {number} zoomAndsBounds.zoom - The derived zoom level
 * @returns {Object} zoomAndsBounds.bounds object
 * @returns {number} zoomAndsBounds.bounds.northLat - Best fit northern latitude
 * @returns {number} zoomAndsBounds.bounds.southLat - Best fit southern latitude
 * @returns {number} zoomAndsBounds.bounds.eastLng - Best fit eastern longitude
 * @returns {number} zoomAndsBounds.bounds.westLng - Best fit western longitude
 */
export const zillowGetMapZoomAndBoundingBox = ({ maxLat: northLat, minLat: southLat, minLon: westLng, maxLon: eastLng, mapContainerId = 'google-map-container' }: IMapData) => {
    let spaceHeight = 500;
    let spaceWidth = 500;

    if (typeof document !== 'undefined') {
        const width = (document.getElementById(mapContainerId) || {}).offsetWidth || 500;
        const height = (document.getElementById(mapContainerId) || {}).offsetHeight || 500;

        spaceHeight = height;
        spaceWidth = width;
    }

    const degreesToRadians = (deg: number) => {
        return deg * (Math.PI / 180);
    };

    const radiansToDegrees = (rad: number) => {
        return rad / (Math.PI / 180);
    };

    const WORLD_DIM = { height: 500, width: 500 };
    const ZOOM_MAX = 19;

    const latRad = (lat: number) => {
        const sin = Math.sin(degreesToRadians(lat));
        const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    };

    const fracToZoom = (mapPx: number, worldPx: number, fraction: number) => {
        const v = Math.log(mapPx / worldPx / fraction) / Math.LN2;
        // Adjust for floating error on exact fit
        return Math.floor(v + 0.00001);
    };

    const zoomToFrac = (mapPx: number, worldPx: number, zoom: number) => {
        return (mapPx / worldPx) / Math.exp(zoom * Math.LN2);
    };

    // ====================================================================
    // Calculate the zoom level to fit these bounds into the given viewport
    // ====================================================================

    // Sphere math to turn lat and long into degrees out of 360
    const latRadNorth = latRad(northLat);
    const latRadSouth = latRad(southLat);
    const latRadDiff = latRadNorth - latRadSouth;
    const latFraction = latRadDiff / Math.PI;

    const lngDiff = eastLng - westLng;
    const lngFraction = ((!(lngDiff >= 0)) ? (lngDiff + 360) : lngDiff) / 360;

    const latZoom = fracToZoom(spaceHeight, WORLD_DIM.height, latFraction);
    const lngZoom = fracToZoom(spaceWidth, WORLD_DIM.width, lngFraction);

    const finalZoom = Math.min(latZoom, lngZoom, ZOOM_MAX);

    // ========================================================
    // Calculate the viewport-exact bounds for this zoom level
    // ========================================================

    const fittedLngFraction = zoomToFrac(spaceWidth, WORLD_DIM.width, finalZoom);
    const lngDegreeCenter = (eastLng + westLng) / 2;
    const fittedLngDiff = lngDiff * (fittedLngFraction / lngFraction);
    const fittedWestLng = lngDegreeCenter - (fittedLngDiff / 2);
    const fittedEastLng = lngDegreeCenter + (fittedLngDiff / 2);

    const fittedLatRadFraction = zoomToFrac(spaceHeight, WORLD_DIM.height, finalZoom);
    const latRadCenter = (latRadNorth + latRadSouth) / 2;
    const fittedLatRadDiff = latRadDiff * (fittedLatRadFraction / latFraction);
    const fittedNorthLat = radiansToDegrees(Math.asin(Math.tanh(2 * (latRadCenter + (fittedLatRadDiff / 2)))));
    const fittedSouthLat = radiansToDegrees(Math.asin(Math.tanh(2 * (latRadCenter - (fittedLatRadDiff / 2)))));

    return {
        zoom: Number(finalZoom + 1),
        bounds: {
            northLat: fittedNorthLat,
            southLat: fittedSouthLat,
            eastLng: fittedEastLng,
            westLng: fittedWestLng
        }
    };
};

export default gmapUtils;
