import numberUtils from 'app/shared/utils/numberUtils';

// Define the shape of the input object
interface GeoProps {
    lat?: number;
    lon?: number;
}

interface SchoolProps {
    areaSid?: string;
    name?: string;
    resourceId?: string;
    type?: string;
    isPrivate?: boolean;
    lowGrade?: string;
    highGrade?: string;
    rating?: number;
    geo?: GeoProps;
    searchSlug?: string;
}

// Define the School class
class School {
    id: string;
    name: string;
    resourceId: string;
    type: string;
    isPrivate: boolean;
    lowGrade: string;
    highGrade: string;
    rating: number;
    geo: {
        lat: number | null;
        lon: number | null;
    };
    uriV2: string;

    constructor({
        areaSid = '',
        name = '',
        resourceId = '',
        type = '',
        isPrivate = false,
        lowGrade = '',
        highGrade = '',
        rating = 0,
        geo = {},
        searchSlug = 'apartments-for-rent'
    }: SchoolProps = {}) {
        this.id = String(areaSid);
        this.name = String(name);
        this.resourceId = String(resourceId);
        this.type = String(type);
        this.isPrivate = Boolean(isPrivate);
        this.lowGrade = String(lowGrade);
        this.highGrade = String(highGrade);
        this.rating = Number(rating);
        this.geo = {
            lat: typeof geo.lat !== 'undefined' ? numberUtils.trimDecimal(geo.lat) : null,
            lon: typeof geo.lon !== 'undefined' ? numberUtils.trimDecimal(geo.lon) : null
        };
        this.uriV2 = '/' + String(resourceId) + '/' + String(searchSlug);
    }
}

export default School;