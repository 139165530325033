interface DateAgoProps {
    date?: number;
    ago?: string;
}

class DateAgo {
    date: number;
    ago: string;

    constructor({ date = 0, ago = '' }: DateAgoProps = {} as DateAgoProps) {
        this.date = Number(date || 0);
        this.ago = String(ago || '');
    }
}

export default DateAgo;
