interface HdpVisitedUserEventOutput {
    type: string;
    value: string;
}

const HdpVisitedUserEvent = (
    alias: string = '',
    timeSpentInMillis: number = -1
): HdpVisitedUserEventOutput => {
    const newValue = {
        alias,
        timeSpentInMillis: Number(timeSpentInMillis),
        platform: 'web'
    };

    return {
        type: 'visited',
        value: JSON.stringify(newValue)
    };
};

export default HdpVisitedUserEvent;