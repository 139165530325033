interface OpenHouseParams {
    date?: string;
    starts?: string;
    ends?: string;
    description?: string;
    appointmentRequired?: boolean;
}

class OpenHouse {
    date: string;
    starts: string;
    ends: string;
    description: string;
    appointmentRequired: boolean;

    constructor({
        date = '',
        starts = '',
        ends = '',
        description = '',
        appointmentRequired = false
    }: OpenHouseParams = {}) {
        this.date = String(date);
        this.starts = String(starts);
        this.ends = String(ends);
        this.description = String(description);
        this.appointmentRequired = Boolean(appointmentRequired);
    }
}

export default OpenHouse;