const NearMeConstants = {
    NEAR_ME_ROUTES: {
        '/apartments-near-me': {
            slug: 'apartments-for-rent',
            title: 'Apartments for Rent Near Me | HotPads',
            description:
                'Search for nearby apartments with the largest and most trusted rental site. View detailed property information, photos, and receive real-time updates.'
        },
        '/houses-near-me': {
            slug: 'houses-for-rent',
            title: 'Houses for Rent Near Me | HotPads',
            description:
                'Search for nearby houses with the largest and most trusted rental site. View detailed property information, photos, and receive real-time updates.'
        },
        '/apartments-near-me-with-laundry': {
            slug: 'apartments-for-rent-with-laundry',
            title: 'Apartments for Rent with Laundry Near Me | HotPads',
            description:
                'Search for nearby apartments with in-unit laundry with the largest and most trusted rental site. View detailed property information, photos, and receive real-time updates.'
        },
        '/apartments-near-me-with-parking': {
            slug: 'apartments-for-rent-with-parking',
            title: 'Apartments for Rent with Parking Near Me | HotPads',
            description:
                'Search for nearby apartments with parking with the largest and most trusted rental site. View detailed property information, photos, and receive real-time updates.'
        },
        '/pet-friendly-apartments-near-me': {
            slug: 'pet-friendly-apartments-for-rent',
            title: 'Pet Friendly Apartments for Rent Near Me | HotPads',
            description:
                'Search for nearby pet friendly apartments with the largest and most trusted rental site. View detailed property information, photos, and receive real-time updates.'
        },
        '/furnished-apartments-near-me': {
            slug: 'furnished-apartments-for-rent',
            title: 'Furnished Apartments for Rent Near Me | HotPads',
            description:
                'Search for nearby furnished apartments with the largest and most trusted rental site. View detailed property information, photos, and receive real-time updates.'
        },
        '/apartments-near-me-with-gym': {
            slug: 'apartments-for-rent-with-gym',
            title: 'Apartments for Rent with a Gym Near Me | HotPads',
            description:
                'Search for nearby apartments with an on-site gym with the largest and most trusted rental site. View detailed property information, photos, and receive real-time updates.'
        }
    }
};

export default NearMeConstants;
