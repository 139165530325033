// @ts-nocheck
/* eslint-enable */
const ListingDetailsCache = new Map();

const cacheActions = {
    add(listing = {}) {
        const { maloneLotIdEncoded } = listing;

        if (!ListingDetailsCache.get(maloneLotIdEncoded)) {
            ListingDetailsCache.set(maloneLotIdEncoded, listing);
        }
    },

    get(maloneLotIdEncoded) {
        return ListingDetailsCache.get(maloneLotIdEncoded);
    },

    size() {
        return ListingDetailsCache.size;
    },

    updateUserItemType(maloneLotIdEncoded, type) {
        const listing = ListingDetailsCache.get(maloneLotIdEncoded);

        if (!listing) {
            return;
        }

        if (listing.userItemTypes.indexOf(type) === -1) {
            listing.userItemTypes.push(type);
            ListingDetailsCache.set(maloneLotIdEncoded, listing);
        }
    }
};

export default cacheActions;
