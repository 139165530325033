// @ts-nocheck
/* eslint-enable */
// App
import React, { useCallback, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactDOM from 'react-dom';
import FocusLock from 'react-focus-lock';
import PropTypes from 'prop-types';

// Lodash
import omit from 'lodash/omit';

// Misc / utils
import ValueConstants from 'app/shared/constants/ValueConstants';
import appUtils from 'app/shared/utils/appUtils';
import IconX from 'images/icons/x.svg';
import IconXWhiteBold from 'images/icons/x-white-bold.svg';
import * as S from './styles';

const { bool, func, node, oneOf, string } = PropTypes;

/**
 * PopupModal - the one modal to rule them all
 * This component is used to wrap components to be displayed over content in a modal window.
 *
 * @param {Object} props - Component props
 * @param {ReactNode} props.children - modal component
 * @param {node} props.customCloseIcon - img element (default is dark X)
 * @param {boolean} props.darkOverlay - uses dark gray transparent overlay instead
 * @param {boolean} props.fullHeight - specifies that modal should be full height of screen
 * @param {boolean} props.floatingCloseButton - floats close button on overlay instead of modal
 * @param {boolean} props.isFullScreen - flag to remove overlay
 * @param {string} props.modalId - id of html element to append portal to
 * @param {function} props.onHidePopup - required handler to hide modal
 * @param {string} props.paddingBottom - string to set padding-bottom
 * @param {string} props.paddingInline - string to set padding-inline
 * @param {string} props.paddingTop - string to set padding-top
 * @param {string} props.position - string to set position of modal (default middle of screen)
 * @param {boolean} props.scrollContainerOverride - override for components that have their own scroll container
 * @param {string} props.size - specifies size of modal - xs, sm, md, lg, xl, xxl
 * @param {boolean} props.slideUp - flag to animate modal to slide up from bottom
 * @param {boolean} props.useCloseButton - flag to show close button
 */

const PopupModal = ({
    modalId = 'modal-root',
    paddingBottom = '4x',
    paddingTop = '6x',
    slideUp = false,
    useCloseButton = true,
    children,
    customCloseIcon,
    className,
    darkOverlay = false,
    fullHeight = false,
    floatingCloseButton = false,
    isFullScreen,
    onHidePopup,
    paddingInline,
    position,
    scrollContainerOverride,
    size = 'auto',
    ...additionalProps
}) => {
    // Redux
    const isMobile = useSelector((state) => state.app.device.screenWidth === 'sm');

    // React refs
    const buttonRef = useRef(null);

    const handleKeydown = useCallback(
        (event) => {
            if (event.key === ValueConstants.ESC_KEY) {
                onHidePopup();
            }
        },
        [onHidePopup]
    );

    useEffect(() => {
        window.addEventListener('keydown', handleKeydown);

        return () => {
            document.removeEventListener('keydown', handleKeydown);
        };
    }, [handleKeydown]);

    useEffect(() => {
        if (buttonRef.current) {
            buttonRef.current.focus();
        }

        if (isMobile) {
            appUtils.lockVerticalScrolling({ isForPortal: true });
        }

        return () => {
            if (isMobile) {
                appUtils.unlockVerticalScrolling();
            }
        };
    }, [isMobile]);

    useEffect(() => {
        if (buttonRef.current) {
            buttonRef.current.focus();
        }
    }, [buttonRef]);

    const handleModalClick = (e) => {
        // stop overlay's onClick from closing modal
        e.stopPropagation();
    };

    const rest = omit(additionalProps, [
        'children',
        'customCloseIcon',
        'className',
        'dispatch',
        'isMobile',
        'modalId',
        'onHidePopup',
        'paddingBottom',
        'paddingInline',
        'paddingTop',
        'position',
        'slideUp',
        'useCloseButton'
    ]);

    const Content = (
        // FocusLock locks tab focus to the modal and prevents events behind the modal from being triggered
        <FocusLock autoFocus={false}>
            <S.PopupModalOverlay
                tag="aside"
                role="dialog"
                aria-modal="true"
                onClick={onHidePopup}
                darkOverlay={darkOverlay}
            >
                <S.PopupModalContent
                    className={className}
                    position={position}
                    slideUp={slideUp}
                    isFullScreen={isFullScreen}
                    paddingTop={paddingTop}
                    paddingBottom={paddingBottom}
                    paddingInline={paddingInline}
                    fullHeight={fullHeight}
                    size={size}
                    floatingCloseButton={floatingCloseButton}
                    onClick={handleModalClick}
                    {...rest}
                >
                    {useCloseButton && (
                        <>
                            <S.PopupModalCloseButton
                                isFullScreen={isFullScreen}
                                floatingCloseButton={floatingCloseButton}
                                onClick={onHidePopup}
                                aria-label="Close modal"
                                ref={buttonRef}
                            >
                                {customCloseIcon ? (
                                    customCloseIcon
                                ) : (
                                    <img
                                        src={darkOverlay && floatingCloseButton ? IconXWhiteBold : IconX}
                                        height={darkOverlay ? '14px' : '16px'}
                                        width={darkOverlay ? '14px' : '16px'}
                                        alt=""
                                    />
                                )}
                            </S.PopupModalCloseButton>
                        </>
                    )}
                    {scrollContainerOverride ? (
                        <>{children}</>
                    ) : (
                        <S.ScrollableContent id="modal-scroll-container">{children}</S.ScrollableContent>
                    )}
                </S.PopupModalContent>
            </S.PopupModalOverlay>
        </FocusLock>
    );

    /**
     * Renders content inline on SSR, then renders content in a portal `modal-root` once the client
     * and document is loaded. This will allow us to have content still appear in HTML on SSR, which
     * is important if we use modals for SSR critical content, like HDPs
     */
    if (typeof document !== 'undefined') {
        return ReactDOM.createPortal(Content, document.getElementById(modalId));
    } else {
        return Content;
    }
};

PopupModal.propTypes = {
    children: node.isRequired,
    customCloseIcon: node,
    isFullScreen: bool,
    modalId: string,
    onHidePopup: func.isRequired,
    paddingBottom: oneOf(['0', 'xs', '1x', '2x', '3x', '4x', '6x', '8x']),
    paddingInline: oneOf(['xs', '1x', '2x', '3x', '4x', '6x', '8x']),
    paddingTop: oneOf(['0', 'xs', '1x', '2x', '3x', '4x', '6x', '8x']),
    position: oneOf(['top']),
    slideUp: bool,
    useCloseButton: bool
};

export default PopupModal;
