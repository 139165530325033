import React, { FC, ReactNode } from 'react';
import { parse, ParsedQuery } from 'query-string';

interface QueryParamProviderProps {
    children: ReactNode;
    queryParamsFromRequest?: ParsedQuery<string>;
}

export const QueryParamContext = React.createContext<ParsedQuery<string>>({});

const QueryParamProvider: FC<QueryParamProviderProps> = ({ children, queryParamsFromRequest = {} }) => {
    let paramObj: ParsedQuery<string>;

    if (typeof window !== 'undefined') {
        paramObj = parse(window.location.search);
    } else {
        paramObj = queryParamsFromRequest;
    }

    return (
        <QueryParamContext.Provider value={paramObj}>
            {children}
        </QueryParamContext.Provider>
    );
};

export default QueryParamProvider;