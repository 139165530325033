// @ts-nocheck
/* eslint-enable */
// Misc / utils
import numberUtils from 'app/shared/utils/numberUtils';
import formatter from 'app/shared/utils/formatter';
import dateUtils from 'app/shared/utils/dateUtils';

const LabelMaker = {
    price: ({ min, max }) => {
        const formattedMin = numberUtils.asCurrency(min);
        const formattedMax = numberUtils.asCurrency(max);
        if (min && max) {
            return `${formattedMin}–${formattedMax}`;
        } else if (min && !max) {
            return `> ${formattedMin}`;
        } else if (!min && max) {
            return `< ${formattedMax}`;
        }
    },
    bedrooms: (bedObj) => {
        const isExact = bedObj.isExact;
        const identifiers = Object.keys(bedObj);
        const active = identifiers.filter((option) => {
            return bedObj[option];
        })[0];

        const BED_MAP = {
            anyOrStudio: isExact ? 'Studio' : null,
            oneOrMore: isExact ? '1 bed' : '1+ beds',
            twoOrMore: isExact ? '2 beds' : '2+ beds',
            threeOrMore: isExact ? '3 beds' : '3+ beds',
            fourOrMore: isExact ? '4 beds' : '4+ beds'
        };

        return BED_MAP[active];
    },
    bathrooms: (bathObj) => {
        const identifiers = Object.keys(bathObj);
        const active = identifiers.filter((option) => {
            return bathObj[option];
        })[0];

        const BATH_MAP = {
            any: null,
            oneOrMore: '1+ baths',
            oneHalfOrMore: '1.5+ baths',
            twoOrMore: '2+ baths',
            threeOrMore: '3+ baths',
            fourOrMore: '4+ baths'
        };

        return BATH_MAP[active];
    },
    commute: (commuteObj) => {
        if (commuteObj.commuteTime !== null) {
            return `Commute friendly`;
        }
    },
    pets: (petsObj) => {
        const { cats, dogs } = petsObj;

        if (cats && dogs) {
            return 'Dogs & Cats';
        } else if (!cats && dogs) {
            return 'Dogs';
        } else if (cats && !dogs) {
            return 'Cats';
        }
    },
    availability: (availObj) => {
        const { start, end, hideUnknownAvailabilityDate } = availObj;
        const label = [`Available ${dateUtils.formatDateMmddyyyy(start)} to ${dateUtils.formatDateMmddyyyy(end)}`];

        if (hideUnknownAvailabilityDate) {
            label.push('Hide unknown dates');
        }

        return label.join(', ');
    },
    laundry: (laundryObj) => {
        const { inUnit, shared } = laundryObj;
        const label = ['Laundry •'];

        if (inUnit && shared) {
            label.push('In-unit, Shared');
        } else if (inUnit && !shared) {
            label.push('In-unit');
        } else if (!inUnit && shared) {
            label.push('Shared');
        }

        return label.join(' ');
    },
    amenities: (amenitiesObj) => {
        const AMENITIES_MAP = {
            cooling: 'A/C',
            heating: 'Heating',
            parking: 'Parking',
            gatedEntry: 'Gated entry',
            doorman: 'Doorman',
            fitnessCenter: 'Gym',
            swimmingPool: 'Pool',
            dishwasher: 'Dishwasher'
        };
        const identifiers = Object.keys(amenitiesObj);
        const active = identifiers.filter((option) => {
            return amenitiesObj[option];
        });

        if (active.length > 1) {
            return `Amenities • ${active.length} selected`;
        } else {
            return AMENITIES_MAP[active[0]];
        }
    },
    furnished: () => 'Furnished',
    propertyTypes: (propertyTypesObj) => {
        const PROPERTY_TYPES_MAP = {
            apartment: 'Apartment',
            condo: 'Condo',
            duplex: 'Duplex',
            house: 'House',
            townhouse: 'Townhouse'
        };
        const identifiers = Object.keys(propertyTypesObj);
        const active = identifiers.filter((option) => {
            return propertyTypesObj[option];
        });

        if (active.length > 1) {
            return `Property types • ${active.length} selected`;
        } else {
            return PROPERTY_TYPES_MAP[active[0]];
        }
    },
    sqft: ({ min, max }) => {
        if (min && max) {
            const formattedMin = numberUtils.compact(min);
            const formattedMax = numberUtils.compact(max);
            return `${formattedMin} – ${formattedMax} sqft`;
        } else if (min) {
            const formattedMin = numberUtils.compact(min);
            return `More than ${formattedMin} sqft`;
        } else if (max) {
            const formattedMax = numberUtils.compact(max);
            return `Less than ${formattedMax} sqft`;
        }
    },
    createdWithin: (createdWithinObj) => {
        const CREATED_WITHIN_MAP = {
            hour: 'Last hour',
            day: '24 hours',
            week: '7 days',
            month: '30 days'
        };
        const identifiers = Object.keys(createdWithinObj);
        const active = identifiers.filter((option) => {
            return createdWithinObj[option];
        })[0];

        return `Created within ${CREATED_WITHIN_MAP[active]}`;
    },
    rentalTypes: (rentalTypesObj) => {
        const RENTAL_TYPES_MAP = {
            rental: 'Regular rentals',
            room: 'Rooms for rent',
            sublet: 'Sublet rentals',
            corporate: 'Corporate rentals'
        };
        const identifiers = Object.keys(rentalTypesObj);
        const active = identifiers.filter((option) => {
            return rentalTypesObj[option];
        });

        if (active.length > 1) {
            return `Rental types • ${active.length} selected`;
        } else {
            return RENTAL_TYPES_MAP[active[0]];
        }
    },
    restrictions: (restrictionsObj) => {
        const RESTRICTIONS_MAP = {
            incomeRestricted: 'Income restricted',
            seniorHousing: 'Senior housing',
            studentHousing: 'Student housing',
            militaryHousing: 'Military housing',
            none: 'Hide all restricted housing'
        };
        const identifiers = Object.keys(restrictionsObj);
        const active = identifiers.filter((option) => {
            return restrictionsObj[option];
        })[0];

        return RESTRICTIONS_MAP[active];
    },
    keywords: (keywords) => {
        const splitKeywords = keywords.split(',');
        if (splitKeywords.length > 1) {
            return `Keywords • ${splitKeywords.length} selected`;
        } else {
            return formatter.string.firstCaps(splitKeywords[0]);
        }
    },
    additionalOpts: (additionalOptsObj) => {
        const ADDITIONAL_OPTIONS_MAP = {
            requiresPhotos: 'Photos required',
            requiresPrice: 'Price required',
            forRentByOwner: 'Listed by owner',
            acceptsSection8: 'Accepts section 8',
            hasOffers: 'Has offers',
            acceptingApplications: 'Accepting Zillow applications'
        };
        const identifiers = Object.keys(additionalOptsObj);
        const active = identifiers.filter((option) => {
            return additionalOptsObj[option];
        });
        const labels = active.map((opt) => {
            return ADDITIONAL_OPTIONS_MAP[opt];
        });

        return labels.join(', ');
    }
};

export default LabelMaker;
