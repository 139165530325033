// app.jsx
// This is the entry point to webpack bundling as well as the app router
import { rpBootstrap } from 'app/client/utils/rp-browser';

import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import usertiming from 'app/shared/utils/performanceUtils';
import { loadableReady } from '@loadable/component';

import AppActions from 'app/shared/flux/actions/AppActions';
import AppTemplate from 'app/shared/templates/AppTemplate';
import configureStore from 'app/shared/flux/store/configureStore';
import { FilterActions_fetchFilterFromUrlAndApi } from 'app/shared/flux/actions/FilterActions';
import queryUtils from 'app/shared/utils/queryUtils';
import QueryParamProvider from 'app/shared/contexts/QueryParamContext';
import BotProvider from 'app/shared/contexts/BotContext';
import { initialize as initializeAnalytics } from './universal-analytics';
import routeUtils from 'app/shared/utils/routeUtils';

// performance tracking
usertiming.mark('Client start');

window.browserHistory = createBrowserHistory();
const store = configureStore(window.__PRELOADED_STATE__);
const userAgent = store.getState().app.userAgent;
window.store = store;
delete window.__PRELOADED_STATE__;

const logger = rpBootstrap.getLogger('client/root');

logger.info('HotPads successfully rehydrated.');

window.browserHistory.listen((location) => {
    const appStore = window.store.getState();
    if (appStore.location.pageCount === 1) {
        if (appStore.app.isInitialSsrPage) {
            window.store.dispatch(AppActions.setAppStoreBool('isInitialSsrPage', false));
            window.store.dispatch(AppActions.setAppStoreBool('isClientSideLoadedPage', true));
        }
    }
    const isArea = routeUtils.isAreaUrl(window.location.pathname);
    const isBuilding = routeUtils.isBuildingUrl(window.location.pathname);
    const isPad = routeUtils.isPadUrl(window.location.pathname);
    const shouldCallFetchFilter = isArea || isBuilding || isPad;

    logger.info(`Transitioning to ${location.pathname}`, queryUtils.parse(location.search));
    if (shouldCallFetchFilter) {
        window.store.dispatch(
            FilterActions_fetchFilterFromUrlAndApi(location.pathname, queryUtils.parse(location.search))
        );
    }

    window.store.dispatch(AppActions.pageOnLoad(location.pathname, queryUtils.parse(location.search)));
    // http://stackoverflow.com/questions/9083594/call-settimeout-without-delay
    // https://github.com/reactjs/react-router/issues/2144#issuecomment-150939358
    setTimeout(() => {
        if (location.action === 'POP') {
            return;
        }
    });
    window.scrollTo(0, 0);
});

window.router = {
    redirectTo(t) {
        return window.browserHistory.replace(t);
    },
    transitionTo(t) {
        return window.browserHistory.push(t);
    }
};

const AppRoot = (
    <Provider store={store}>
        <Router history={window.browserHistory}>
            <QueryParamProvider>
                <BotProvider userAgent={userAgent}>
                    <AppTemplate />
                </BotProvider>
            </QueryParamProvider>
        </Router>
    </Provider>
);

setTimeout(
    () =>
        loadableReady(() => {
            hydrateRoot(document.getElementById('app-root'), AppRoot);
        }),
    0
);

initializeAnalytics();
