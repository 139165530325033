// https://developers.google.com/analytics/devguides/collection/analyticsjs/

export default function analyticsSnippet(options: {
    gaAddress?: string;
    propertyName?: string;
    onetrustEnabled?: boolean;
}): void {
    const gaAddress: string = options.gaAddress ?? 'https://www.google-analytics.com/analytics.js';
    const propertyName: string = options.propertyName ?? 'ga';

    (function (i: Window, s: Document, o: string, g: string): void {
        let a: HTMLScriptElement = s.createElement(o) as HTMLScriptElement;
        let m: HTMLScriptElement | null = s.getElementsByTagName(o)[0] as HTMLScriptElement;

        a.async = true;
        a.src = g;

        if (options.onetrustEnabled) {
            a.className = 'optanon-category-3'; // OneTrust cookie category
            a.type = 'text/plain';
        } else {
            a.type = 'text/javascript';
        }

        m.parentNode?.insertBefore(a, m);
    })(window, document, 'script', gaAddress);

    // HP-5991 Required to enable dual tagging for Google Analytics and Clickstream
    window.GoogleAnalyticsObject = propertyName;
}
