import DateAgo from 'app/shared/models/DateAgo';

interface HistoryProps {
    lastUpdated?: object;
    created?: object;
    deactivated?: object;
    activated?: object;
    lastSynced?: object;
    lastPrice?: string;
}

class History {
    lastUpdated: DateAgo;
    created: DateAgo;
    deactivated: DateAgo;
    activated: DateAgo;
    lastSynced: DateAgo;
    lastPrice: string;

    constructor({
        lastUpdated = {},
        created = {},
        deactivated = {},
        activated = {},
        lastSynced = {},
        lastPrice = ''
    }: HistoryProps = {}) {
        this.lastUpdated = new DateAgo(lastUpdated);
        this.created = new DateAgo(created);
        this.activated = new DateAgo(activated);
        this.deactivated = new DateAgo(deactivated);
        this.lastSynced = new DateAgo(lastSynced);
        this.lastPrice = String(lastPrice);
    }
}

export default History;
