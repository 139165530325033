import constants from 'app/shared/constants/ConstantsBundle';
import reduxUtils from 'app/shared/utils/reduxUtils';
import { Action, AdSlot, AdsReduxState } from 'app/types/redux';

const initSlot = (): AdSlot => {
    return {
        shouldRefresh: false,
        lastRefreshTime: Date.now(),
        slot: null,
        id: null // corresponds to <html> id for this ad slot
    };
};

const initState = (): AdsReduxState => ({
    gadsInit: false,
    navAd: initSlot(),
    squareAd: initSlot(),
    srpAdSlot1: initSlot(),
    srpAdSlot2: initSlot(),
    prebidInit: false
});

const shouldUpdateAd = (state: AdsReduxState) => {
    const now = Date.now();

    // only care about refreshing navAd
    if (!state.navAd.lastRefreshTime || now - state.navAd.lastRefreshTime >= 10000) {
        return true;
    }
    if (!state.srpAdSlot1.lastRefreshTime || now - state.srpAdSlot1.lastRefreshTime >= 10000) {
        return true;
    }

    if (!state.srpAdSlot2.lastRefreshTime || now - state.srpAdSlot2.lastRefreshTime >= 10000) {
        return true;
    }

    return false;
};

// Type guard for AdsState keys so that we only update slot if type is not a boolean
function isAdSlotKey(key: keyof AdsReduxState): key is Exclude<keyof AdsReduxState, 'gadsInit' | 'prebidInit'> {
    return key !== 'gadsInit' && key !== 'prebidInit';
}

const mapActionsToReducer = {
    [constants.GADS_INIT_SUCCESS]: (state: AdsReduxState) => {
        return {
            ...state,
            gadsInit: true
        };
    },
    [constants.REFRESH_AD]: (state: AdsReduxState, action: Action<{ force: boolean }>) => {
        if (
            (state.navAd.id || state.srpAdSlot1.id || state.srpAdSlot2.id) &&
            (shouldUpdateAd(state) || action.payload.force)
        ) {
            return {
                ...state,
                navAd: {
                    ...state.navAd,
                    lastRefreshTime: Date.now(),
                    shouldRefresh: true
                },
                srpAdSlot1: {
                    ...state.srpAdSlot1,
                    lastRefreshTime: Date.now(),
                    shouldRefresh: true
                },
                srpAdSlot2: {
                    ...state.srpAdSlot2,
                    lastRefreshTime: Date.now(),
                    shouldRefresh: true
                }
            };
        }

        return state;
    },
    [constants.SET_AD_SLOT]: (state: AdsReduxState, action: Action<{ data: AdSlot; slot: keyof AdsReduxState }>) => {
        const { data, slot } = action?.payload || {};

        if (data && slot && isAdSlotKey(slot)) {
            const newState: AdsReduxState = { ...state };
            const newSlot = {
                ...(state[slot] as AdSlot),
                ...data
            };

            newState[slot] = newSlot as AdSlot;

            return newState;
        }

        return state;
    },
    [constants.PREBID_INIT]: (state: AdsReduxState, action: Action<{ loaded: boolean }>) => {
        const { loaded } = action?.payload || false;
        return {
            ...state,
            prebidInit: loaded
        };
    }
};

const ads = reduxUtils.createReducer(mapActionsToReducer, initState());
export default ads;
