// @ts-nocheck
/* eslint-enable */
import findIndex from 'lodash/findIndex';
import merge from 'lodash/merge';

import api from 'app/shared/utils/api';
import constants from 'app/shared/constants/ConstantsBundle';
import UserPoint from 'app/shared/models/UserPoint';


const logger = getLogger('actions/userpoint');

const UserPointActions = {
    create(userPointObj, options) {
        return function(dispatch) {
            const { active } = options;

            let newUserPointObj = new UserPoint({
                lat: userPointObj.lat,
                lon: userPointObj.lon,
                name: userPointObj.name,
                address: userPointObj.name, // API requires address field. Right now, just duplicate name field.
                active
            });

            return dispatch(api.user.userPoint.create(newUserPointObj))
                .then(() => {
                    return dispatch(UserPointActions.list());
                })
                .then(() => {
                    return dispatch(UserPointActions.select(newUserPointObj.address));
                });
        };
    },
    fetchDefaultLocation(resourceId) {
        return function(dispatch) {
            return dispatch(api.user.userPoint.defaultLocation(resourceId)).then((response) => {
                if (response && response.data) {
                    return new UserPoint({
                        lat: response.data.defaultLocation.lat,
                        lon: response.data.defaultLocation.lon,
                        name: response.data.locationName
                    });
                } else if (response && response.status === 'AREA_NOT_FOUND') {
                    return false;
                }
            });
        };
    },
    list() {
        return function(dispatch) {
            return dispatch(api.user.userPoint.list()).then((response) => {
                if (response && response.data) {
                    let userPointsArray = [];

                    response.data.forEach((point) => {
                        userPointsArray.push(new UserPoint(point));
                    });

                    // For now, only return 5 most recent locations
                    // reverse array so we take the most recently added addresses:
                    let trimLocations = userPointsArray.reverse().slice(0, 5);

                    dispatch({
                        type: constants.USER_LOAD_POINTS,
                        payload: trimLocations
                    });

                    return trimLocations;
                }
            });
        };
    },
    select(index) {
        return function(dispatch, getState) {
            const userPoints = getState().user.userPoints.destinations;
            let indexOfLocation = findIndex(userPoints, (loc) => {
                return loc.address === index;
            });

            if (indexOfLocation !== -1) {
                let activeUserPoint = userPoints[indexOfLocation];
                // making the assumption that on selection, we want to make the point active
                let updatedUserPoint = merge({}, activeUserPoint, { active: true });
                return dispatch(UserPointActions.update(updatedUserPoint))
                    .then((response) => {
                        if (!response.success) {
                            logger.warn({ response }, 'USER_UPDATE_ACTIVE_POINT_FAILED');
                            return false;
                        }

                        return dispatch({
                            type: constants.USER_UPDATE_ACTIVE_POINT,
                            payload: indexOfLocation
                        });
                    })
                    .then(() => {
                        return dispatch(UserPointActions.list());
                    })
                    .catch((error) => {
                        logger.error({
                            error: {
                                stack: error.stack,
                                message: error.message,
                            }
                        }, 'USER_UPDATE_ACTIVE_POINT_ERROR');
                        return false;
                    });
            } else {
                let activeLocationIndex = findIndex(userPoints, (loc) => {
                    return loc.active === true;
                });
                if (activeLocationIndex !== -1) {
                    let activeUserPoint = userPoints[activeLocationIndex];
                    let updatedUserPoint = merge({}, activeUserPoint, { active: false });
                    return dispatch(UserPointActions.update(updatedUserPoint))
                        .then((response) => {
                            if (!response.success) {
                                logger.warn({ response }, 'USER_UPDATE_ACTIVE_POINT_FAILED');
                                return false;
                            }

                            return dispatch({
                                type: constants.USER_UPDATE_ACTIVE_POINT,
                                payload: null
                            });
                        })
                        .then(() => {
                            return dispatch(UserPointActions.list());
                        })
                        .catch((error) => {
                            logger.error({
                                error: {
                                    stack: error.stack,
                                    message: error.message,
                                }
                            }, 'USER_UPDATE_ACTIVE_POINT_ERROR');
                            return false;
                        });
                } else {
                    return dispatch({
                        type: constants.USER_UPDATE_ACTIVE_POINT,
                        payload: null
                    });
                }
            }
        };
    },
    setActivePoint(userPoints = []) {
        return function(dispatch) {
            let activeUserPoint = {};
            let indexOfLocation = -1;

            userPoints.forEach((userPoint) => {
                if (userPoint.active) {
                    activeUserPoint = userPoint;
                }
            });

            indexOfLocation = findIndex(userPoints, (loc) => {
                return loc.address === activeUserPoint.address;
            });

            dispatch({
                type: constants.USER_UPDATE_ACTIVE_POINT,
                payload: indexOfLocation !== -1 ? indexOfLocation : null
            });

            return userPoints;
        };
    },
    update(updatedUserPoint) {
        return function(dispatch) {
            return dispatch(api.user.userPoint.update(updatedUserPoint));
        };
    }
};

export default UserPointActions;
