interface AreaStatProps {
    areaId?: string;
    areaName?: string;
    areaType?: string;
    areaUriV2?: string;
    averagePrice?: number;
    areaAveragePrice?: number;
    listPrice?: number;
    priceDifference?: number;
    percentageValue?: number;
}

class AreaStat {
    id: string;
    name: string;
    type: string;
    link: string;
    listPrice: number;
    areaAveragePrice: number;
    averagePrice: number;
    difference: string;
    percentageValue: number;

    constructor({
        areaId = '',
        areaName = '',
        areaType = '',
        areaUriV2 = '',
        averagePrice = 0,
        areaAveragePrice = 0,
        listPrice = 0,
        priceDifference = 0,
        percentageValue = 0
    }: AreaStatProps = {}) {
        this.id = areaId;
        this.name = String(areaName);
        this.type = String(areaType);
        this.link = String(areaUriV2);
        this.listPrice = listPrice;
        this.areaAveragePrice = areaAveragePrice;
        this.averagePrice = averagePrice;
        this.difference = `$${priceDifference}`;
        this.percentageValue = percentageValue;
    }
}

export default AreaStat;
