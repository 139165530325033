// @ts-nocheck
/* eslint-enable */
var stringHelper = {
    currentYear() {
        return new Date().getFullYear();
    },
    pluralize(num, singular, plural) {
        return num + ' ' + (num === 1 ? singular : plural);
    },
    getUrlQueryParams() {
        var query = window.location.search.substring(1);

        if (!query) {
            return false;
        }
        var queryString = {};
        var vars = query.split('&');
        var i;
        var pair;

        for (i = 0; i < vars.length; i++) {
            pair = vars[i].split('=');
            if (typeof queryString[pair[0]] === 'undefined') {
                queryString[pair[0]] = decodeURIComponent(pair[1]);
            } else if (typeof queryString[pair[0]] === 'string') {
                queryString[pair[0]] = [queryString[decodeURIComponent(pair[0])], decodeURIComponent(pair[1])];
            } else {
                queryString[pair[0]].push(decodeURIComponent(pair[1]));
            }
        }
        return queryString;
    },
    getUrlPathname(url) {
        var urlParts = url.replace('http://', '').replace('https://', '').split(/[/?#]/);
        return urlParts[0];
    },
    getCookieByName(name) {
        var value = '; ' + document.cookie;
        var parts = value.split('; ' + name + '=');

        if (parts.length === 2) {
            return parts.pop().split(';').shift();
        }
    },
    titleCase(string = '') {
        return string
            .toLowerCase()
            .split('')
            .map((char, i) => {
                if (i === 0) {
                    return char.toUpperCase();
                }

                return char;
            })
            .join('');
    }
};

export default stringHelper;
