import assign from 'lodash/assign';

export interface IListingCounts {
    'corporate-housing'?: number;
    'apartments-for-rent'?: number;
    'houses-for-rent'?: number;
    'condos-for-rent'?: number;
    'sublets-for-rent'?: number;
    'townhomes-for-rent'?: number;
    'duplexes-for-rent'?: number;
}

class ListingCounts {
    'apartments-for-rent': number;
    'houses-for-rent': number;
    'condos-for-rent': number;
    'sublets-for-rent': number;
    'townhomes-for-rent': number;
    'duplexes-for-rent': number;

    constructor(counts: IListingCounts = {}) {
        // Default values for listing counts
        const listingCountsDefaults: IListingCounts = {
            'corporate-housing': 0,
            'apartments-for-rent': 0,
            'houses-for-rent': 0,
            'condos-for-rent': 0,
            'sublets-for-rent': 0,
            'townhomes-for-rent': 0,
            'duplexes-for-rent': 0
        };

        // Merge provided counts with defaults
        const countsWithDefaults = assign(listingCountsDefaults, counts);

        // Assign calculated values to the class properties
        this['apartments-for-rent'] =
            Number(countsWithDefaults['apartments-for-rent']) +
            Number(countsWithDefaults['condos-for-rent']) +
            Number(countsWithDefaults['sublets-for-rent']) +
            Number(countsWithDefaults['townhomes-for-rent']) +
            Number(countsWithDefaults['duplexes-for-rent']) +
            Number(countsWithDefaults['corporate-housing']) +
            Number(countsWithDefaults['houses-for-rent']);
        this['houses-for-rent'] = Number(countsWithDefaults['houses-for-rent']);
        this['condos-for-rent'] = Number(countsWithDefaults['condos-for-rent']);
        this['sublets-for-rent'] = Number(countsWithDefaults['sublets-for-rent']);
        this['townhomes-for-rent'] = Number(countsWithDefaults['townhomes-for-rent']);
        this['duplexes-for-rent'] = Number(countsWithDefaults['duplexes-for-rent']);
    }
}

export default ListingCounts;
