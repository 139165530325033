// @ts-nocheck
/* eslint-enable */
import get from 'lodash/get';
import googleAnalytics from './ga';
import { setListingDimensions, setBaseDimensions, setAreaDimensions } from './dimensions';
import analyticsUtils from 'app/shared/utils/analyticsUtils';
import * as zgAnalytics from '../zg-analytics';

// TODO: Made a reducer function so that we only call ga.set once

// All Page Views, except listing and area pages
export function pageView() {
    return function (dispatch, getState) {
        const state = getState();
        const pathname = get(state, 'location.current.pathname');

        setBaseDimensions(state);
        let page = pathname;
        let title = analyticsUtils.getPageName({ pathname });
        googleAnalytics.pageview(page, title);
        zgAnalytics.pageview({
            name: title,
            path: page
        });
    };
}

// Area Page View
export function areaPageView() {
    return function (dispatch, getState) {
        const state = getState();
        const pathname = get(state, 'location.current.pathname');
        const areaType = get(state, 'area.area.areaType');

        setBaseDimensions(state);
        setAreaDimensions(state);
        let page = pathname;
        let title = analyticsUtils.getPageName({ pathname, areaType });
        googleAnalytics.pageview(page, title);
        zgAnalytics.pageview({
            name: title,
            path: page
        });
    };
}

// Listing Page View
export function listingPageView() {
    return function (dispatch, getState) {
        const state = getState();
        const pathname = get(state, 'location.current.pathname');

        if (!state.currentListingDetails.currentListing) {
            return;
        }

        setBaseDimensions(state);
        setListingDimensions(state);
        let page = pathname;
        let title = analyticsUtils.getPageName({ pathname });
        googleAnalytics.pageview(page, title);
        zgAnalytics.pageview({
            name: title,
            path: page
        });
    };
}
