// @ts-nocheck
/* eslint-enable */
import assign from 'lodash/assign';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import union from 'lodash/union';
import without from 'lodash/without';

import constants from 'app/shared/constants/ConstantsBundle';
import reduxUtils from 'app/shared/utils/reduxUtils';

const initState = () => ({
    lastViewedListing: null,
    expandMySearchName: '',
    expandMySearch: [],
    googleAnalyticsPageView: '',
    userLocation: {},
    collections: []
});

const mapActionsToReducer = {
    [constants.HOMEHUB_COLLECTIONS_LOADED]: (state, action) => {
        const { collections, googleAnalyticsPageView } = action.payload;
        return assign({}, state, {
            collections,
            googleAnalyticsPageView
        });
    },
    [constants.HOMEHUB_CONTINUE_WITH]: (state, action) => {
        let lastViewedListing = action.payload;

        return assign({}, state, {
            googleAnalyticsPageView: `${state.googleAnalyticsPageView} | lastViewed`,
            lastViewedListing
        });
    },
    [constants.HOMEHUB_EXPAND_SEARCH]: (state, action) => {
        const { areas, areaName } = action.payload;
        let expandMySearch = map(areas, (area) => {
            let { resourceId, name } = area;
            return {
                resourceId,
                name
            };
        });

        return assign({}, state, {
            googleAnalyticsPageView: `${state.googleAnalyticsPageView} | expandMySearch`,
            expandMySearch,
            expandMySearchName: areaName
        });
    },

    // This is loaded by AppTemplate
    // We may use this here as a fallback for users without search history
    [constants.GEOLOCATION_USER_AREA]: (state, action) => {
        let { area } = action;

        if (isEmpty(area)) {
            return state;
        }

        return assign({}, state, { userLocation: area });
    },

    // FIXME: Since we don't have a master collection of listings, any time user
    //        changes the state of a listing (e.g. fav/unfav) every listing everywhere has to update
    //        TODO: Maintain a single list, and we won't have to do this.
    [constants.USER_ITEM_OPTIMISTIC_TOGGLE]: (state, { payload }) => {
        const { listing, type, action } = payload;
        const { collections, lastViewedListing } = state;
        const { maloneLotIdEncoded } = listing;
        let newCollections;
        let newLastViewed;

        if (type !== 'favorite') {
            return state;
        }

        const collectionWithListing = collections.filter((collection) => {
            const { listings } = collection;
            return listings[maloneLotIdEncoded];
        });
        const isInCollection = collectionWithListing.length > 0;

        const isLastViewed = lastViewedListing ? maloneLotIdEncoded === lastViewedListing.maloneLotIdEncoded : false;

        if (!isInCollection && !isLastViewed) {
            return state;
        }

        if (isInCollection) {
            newCollections = collections.map((collection) => {
                const { listings } = collection;
                if (listings[maloneLotIdEncoded]) {
                    const newCollection = cloneDeep(collection);
                    const { listings: newListings } = newCollection;
                    const newListing = newListings[maloneLotIdEncoded];
                    if (action === 'add') {
                        newListing.userItemTypes = union(newListing.userItemTypes, ['favorite']);
                    }
                    if (action === 'remove') {
                        newListing.userItemTypes = without(newListing.userItemTypes, 'favorite');
                    }
                    return newCollection;
                } else {
                    return collection;
                }
            });
        }

        if (isLastViewed) {
            newLastViewed = clone(lastViewedListing);
            if (action === 'add') {
                newLastViewed.userItemTypes = union(newLastViewed.userItemTypes, ['favorite']);
            }
            if (action === 'remove') {
                newLastViewed.userItemTypes = without(newLastViewed.userItemTypes, 'favorite');
            }
        }

        return assign({}, state, {
            collections: isInCollection ? newCollections : collections,
            lastViewedListing: isLastViewed ? newLastViewed : lastViewedListing
        });
    },
    [constants.HOMEHUB_RESET]: (state) => {
        return assign({}, state, {
            lastViewedListing: initState().lastViewedListing,
            expandMySearchName: initState().expandMySearchName,
            expandMySearch: initState().expandMySearch,
            googleAnalyticsPageView: initState().googleAnalyticsPageView,
            collections: initState().collections
        });
    }
};

const reducer = reduxUtils.createReducer(mapActionsToReducer, initState());

export default reducer;
