// @ts-nocheck
/* eslint-enable */
export const formatterV2_upperFirst = (str = '') => {
    if (typeof str !== 'string') {
        return false;
    }
    if (str === '') {
        return str;
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatterV2_upperFirstLowerRest = (str = '') => {
    if (typeof str !== 'string') {
        return false;
    }
    if (str === '') {
        return str;
    }

    const lowerCasePortion = str.slice(1).toLowerCase();
    const upperFirstLetter = str[0].toUpperCase();

    return `${upperFirstLetter}${lowerCasePortion}`;
};

export const formatterV2_joinSentences = (listOfSentences, punctuation = '.') => {
    return listOfSentences.reduce((joinedSentences, sentences, idx) => {
        let modifiedSentences = formatterV2_upperFirst(sentences?.trim().replace(/\n/g, ' '));

        if (!modifiedSentences) {
            return joinedSentences;
        }

        if (!modifiedSentences.endsWith('.') && !modifiedSentences.endsWith('?') && !modifiedSentences.endsWith('!')) {
            modifiedSentences = modifiedSentences + `${punctuation}`;
        }

        return joinedSentences + modifiedSentences + (idx !== listOfSentences.length - 1 ? ' ' : '');
    }, '');
};
