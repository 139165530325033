import DateAgo from 'app/shared/models/DateAgo';

interface ListedByProps {
    companyName?: string;
    contactPhone?: string;
    contactName?: string;
    disclaimer?: string;
    landlordPhotoUrl?: string;
    memberPhone?: string;
    memberSince?: DateAgo;
    mlsLogo?: string;
    responseRate?: number;
    responseTime?: number;
    responseRateLevel?: number;
    responseTimeLevel?: number;
    webSite?: string;
    logoUrlCdn?: string;
}

class ListedBy {
    companyName: string | null;
    contactPhone: string | null;
    contactName: string | null;
    disclaimer: string | null;
    landlordPhotoUrl: string | null;
    memberPhone: string | null;
    memberSince: DateAgo | null;
    mlsLogo: string | null;
    responseRate: number | null;
    responseTime: number | null;
    responseRateLevel: number | null;
    responseTimeLevel: number | null;
    webSite: string | null;
    logoUrlCdn: string | null;

    constructor(res: ListedByProps = {}) {
        this.companyName = res.companyName ? String(res.companyName) : null;
        this.contactPhone = res.contactPhone ? String(res.contactPhone) : null;
        this.contactName = res.contactName ? String(res.contactName) : null;
        this.disclaimer = res.disclaimer ? String(res.disclaimer) : null;
        this.landlordPhotoUrl = res.landlordPhotoUrl ? String(res.landlordPhotoUrl) : null;
        this.memberPhone = res.memberPhone ? String(res.memberPhone) : null;
        this.memberSince = res.memberSince ? new DateAgo(res.memberSince) : null;
        this.mlsLogo = res.mlsLogo ? String(res.mlsLogo) : null;
        this.responseRate = typeof res.responseRate !== 'undefined' ? Number(res.responseRate) : null;
        this.responseTime = typeof res.responseTime !== 'undefined' ? Number(res.responseTime) : null;
        this.responseRateLevel = typeof res.responseRateLevel !== 'undefined' ? Number(res.responseRateLevel) : null;
        this.responseTimeLevel = typeof res.responseTimeLevel !== 'undefined' ? Number(res.responseTimeLevel) : null;
        this.webSite = res.webSite ? String(res.webSite) : null;
        this.logoUrlCdn = res.logoUrlCdn ? String(res.logoUrlCdn) : null;
    }
}

export default ListedBy;
