class Envelope {
    event_type_id: string;
    event_type_version_id: string;
    event_template_id: string;
    event_template_version_id: string;
    event_client_start_dtm: string;

    /**
     * Creates an instance of Envelope.
     * @param {Object} data - The data object.
     * @param {string} [data.eventId] - event_type_id from Data Portal
     * @param {string} [data.eventVersion] - event_type_version_id from Data Portal
     * @param {string} [data.templateId] - event_template_version_id from Data Portal
     * @param {string} [data.templateVersion] - event_template_version_id from Data Portal
     */
    constructor(data: { eventId: string; eventVersion: string; templateId?: string; templateVersion?: string }) {
        this.event_type_id = data.eventId || '';
        this.event_type_version_id = data.eventVersion || '';
        this.event_template_id = data.templateId || '';
        this.event_template_version_id = data.templateVersion || '';
        this.event_client_start_dtm = new Date().toISOString();
    }
}

export { Envelope };
