// @ts-nocheck
/* eslint-enable */
import { ALLOWED_CONSTANTS } from '../models/Filter';
// TODO: remove file since searchSlugs are generated on the BE

/****************
    searchSlugObject:
     'search-slug-name': {                      // Name of search slug that will be active.
        filter: {
            listingTypes: [Array],  // Array of listing types related to slug. e.g., ['rental', 'sublet', 'room']
            propertyTypes: [Array]  // Array of property types to fetch for a particular slug. e.g., ['house', 'townhouse']
        },
        excludeAreaTypes: [],   // Exclude this slug from certain area (e.g., University area type is consolidated slugs
        seoApiKeyword: 'house', // keyword used for fetching nearby area links for SEO footer, may be different that filter.propertyTypes above.
    }

    DEPRECATED SLUGS:
    If / when slugs are deprecated from activeSearchSlugs, they should be
    added to the the deprecatedSlugs object at bottom of file.
    where key === deprecated slug and value === redirect slug:
    e.g., { 'old-humble-abodes-for-rent': 'apartments-for-rent' }
*****************/

const activeSearchSlugs = {
    /*
    'cheap-vegan-apartments-for-rent': {
        filter: {
            listingTypes: Filter.allowedConstants.defaultListingTypes,
            propertyTypes: Filter.allowedConstants.defaultPropertyTypes
        },
        excludeAreaTypes: [],
        seoApiKeyword: 'vegan'
    },
    */
    'apartments-for-rent': {
        filter: {
            listingTypes: ALLOWED_CONSTANTS.defaultListingTypes,
            propertyTypes: ALLOWED_CONSTANTS.defaultPropertyTypes
        },
        excludeAreaTypes: [],
        seoApiKeyword: 'rental, sublet, room'
    },
    'affordable-apartments-for-rent': {
        filter: {
            listingTypes: ['rental'],
            propertyTypes: ALLOWED_CONSTANTS.defaultPropertyTypes,
            orderBy: 'lowPrice'
        },
        excludeAreaTypes: [],
        seoApiKeyword: 'affordableApartment'
    },
    'affordable-houses-for-rent': {
        filter: {
            listingTypes: ['rental'],
            propertyTypes: ALLOWED_CONSTANTS.defaultPropertyTypes,
            orderBy: 'lowPrice'
        },
        excludeAreaTypes: [],
        seoApiKeyword: 'affordableHouse'
    },
    'pet-friendly-apartments-for-rent': {
        filter: {
            listingTypes: ['rental'],
            propertyTypes: ALLOWED_CONSTANTS.defaultPropertyTypes,
            pets: ['cats', 'dogs']
        },
        excludeAreaTypes: [],
        seoApiKeyword: 'petFriendlyApartment'
    },
    'condos-for-rent': {
        filter: {
            listingTypes: ALLOWED_CONSTANTS.defaultListingTypes,
            propertyTypes: ['condo']
        },
        excludeAreaTypes: ['university'],
        seoApiKeyword: 'condo'
    },
    'houses-for-rent': {
        filter: {
            listingTypes: ALLOWED_CONSTANTS.defaultListingTypes,
            propertyTypes: ['house', 'townhouse']
        },
        excludeAreaTypes: [],
        seoApiKeyword: 'house'
    },
    'townhomes-for-rent': {
        filter: {
            listingTypes: ALLOWED_CONSTANTS.defaultListingTypes,
            propertyTypes: ['townhouse']
        },
        excludeAreaTypes: [],
        seoApiKeyword: 'townhouse'
    }
};

// Redirect deprecated search slugs.
// Ensure deprecated slug is removed from above.
// [old-slug] : [new-slug]
const deprecatedSlugs = {
    'corporate-housing': 'apartments-for-rent',
    'duplexes-for-rent': 'apartments-for-rent',
    'homes-for-sale': 'apartments-for-rent',
    'condos-for-sale': 'apartments-for-rent',
    'land-for-sale': 'apartments-for-rent',
    'duplexes-for-sale': 'apartments-for-rent',
    'townhomes-for-sale': 'apartments-for-rent',
    'cheap-apartments-for-rent': 'affordable-apartments-for-rent',
    'cheap-houses-for-rent': 'affordable-houses-for-rent'
};

export default {
    activeSearchSlugs,
    deprecatedSlugs
};