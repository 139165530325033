import React, { useEffect } from 'react';
import pathsManifest from 'app/shared/utils/pathsManifest';

const AppleRedirectPage: React.FC = () => {
    useEffect(() => {
        window.router.transitionTo(pathsManifest.FrontPage);
    }, []);

    return null;
};

export default AppleRedirectPage;
