import assign from 'lodash/assign';

import ApiResponseConstants from 'app/shared/constants/ApiResponseConstants';
import ClusterSettingConstants from 'app/shared/constants/ClusterSettingConstants';
import DispatchConstants from 'app/shared/constants/DispatchConstants';
import NearMeConstants from 'app/shared/constants/NearMeConstants';
import SeoConstants from 'app/shared/constants/SeoConstants';
import StringConstants from 'app/shared/constants/StringConstants';
import ValueConstants from 'app/shared/constants/ValueConstants';
import ExperimentConstants from 'app/shared/constants/ExperimentConstants';

const constants = assign(
    {},
    ApiResponseConstants,
    ClusterSettingConstants,
    DispatchConstants,
    NearMeConstants,
    SeoConstants,
    StringConstants,
    ValueConstants,
    ExperimentConstants
);

export default constants;
