// @ts-nocheck
/* eslint-enable */
import merge from 'lodash/merge';
import constants from 'app/shared/constants/ConstantsBundle';
import reduxUtils from 'app/shared/utils/reduxUtils';
import assign from 'lodash/assign';
import forEach from 'lodash/forEach';
import RecentSearch from 'app/shared/models/RecentSearch';
import SavedSearch from 'app/shared/models/SavedSearch';
import ScheduledTour from 'app/shared/models/ScheduledTour';

const logger = getLogger('reducers/user');

const initNotificationTypes = () => ({
    email: true,
    mobile: true
});

const initState = () => ({
    loggedIn: false,
    hasOptedOutAdTracking: false,
    isVerified: false,
    userToken: null,
    csrfToken: null,
    sessionToken: null,
    info: {
        email: null,
        firstName: null,
        lastName: null,
        passwordSet: null,
        passwordStatus: null,
        roles: []
    },
    ctaButtonContext: null, // HPWEB-5993: CTA A/B test
    inquiryDataCache: {
        isPending: true,
        name: null,
        email: null,
        phone: null,
        text: null
    },
    conversations: {},
    unreadMsgCount: 0,
    shareDataCache: {
        emailFields: [{ value: '' }],
        phoneFields: [{ value: '' }]
    },
    search: {
        recent: {},
        saved: []
    },
    savedSearchPreferences: initNotificationTypes(),
    recSearchPreferences: initNotificationTypes(),
    propertyUpdatePreferences: initNotificationTypes(),
    subscriptions: {
        stopListings: null,
        stopOther: null
    },
    userPoints: {
        activeUserPoint: null,
        destinations: []
    },
    renterProfile: {},
    scheduledTours: [],
    currentSearch: null,
    // gets updated for new requests send on the server side
    serverSideCookies: {},
    // gets updated as a set-cookie array
    serverSideSetCookiesArray: []
});

const mapActionsToReducer = {
    [constants.SET_CURRENT_SEARCH]: (state, action) => {
        return assign({}, state, {
            currentSearch: action.payload
        });
    },
    [constants.USER_LOAD_POINTS]: (state, action) => {
        if (!action.payload) {
            logger.warn('payload not passed into USER_LOAD_POINTS');
        }

        const data = action.payload || [];
        const userPoints = merge({}, state.userPoints, {
            destinations: data
        });

        return assign({}, state, {
            userPoints
        });
    },
    [constants.USER_UPDATE_ACTIVE_POINT]: (state, action) => {
        const data = action.payload;
        const userPoints = merge({}, state.userPoints, {
            activeUserPoint: data
        });

        return assign({}, state, {
            userPoints
        });
    },
    [constants.UPDATE_USER_CREDS]: (state, action) => {
        const { loggedIn, userToken } = action.payload;

        return assign({}, state, {
            loggedIn,
            userToken
        });
    },
    [constants.UPDATE_SESSION_TOKEN]: (state, action) => {
        return assign({}, state, {
            sessionToken: action.payload
        });
    },
    [constants.UPDATE_CSRF_TOKEN]: (state, action) => {
        return assign({}, state, {
            csrfToken: action.payload
        });
    },
    [constants.UPDATE_USER_INFO]: (state, action) => {
        if (!action.payload) {
            logger.warn('data not passed into UPDATE_USER_INFO');
        }
        const data = action.payload.data || {};
        const info = merge({}, state.info, {
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            passwordSet: data.passwordSet,
            passwordStatus: data.passwordStatus,
            roles: [].concat(data.roles || [], data.unverifiedRoles || [])
        });

        return assign({}, state, {
            info,
            loggedIn: Boolean(data.email)
        });
    },
    [constants.UPDATE_RENTER_PROFILE]: (state, action) => {
        return assign({}, state, {
            renterProfile: action.payload
        });
    },
    [constants.UPDATE_SCHEDULED_TOURS_FOR_USER]: (state, action) => {
        const scheduledTours = action.payload
            .map((scheduledTour) => {
                return new ScheduledTour(scheduledTour);
            })
            .filter((scheduledTour) => scheduledTour.schedulingStatus !== 'CANCELLED');

        return assign({}, state, {
            scheduledTours
        });
    },
    [constants.UPDATE_UNREAD_CONVERSATION_COUNT]: (state, action) => {
        return assign({}, state, {
            unreadMsgCount: action.payload
        });
    },
    [constants.UPDATE_LISTING_CONVERSATION]: (state, action) => {
        const aliasEncoded = action.payload.aliasEncoded;
        const conversation = action.payload.conversation;

        const conversations = merge({}, state.conversations, {
            [aliasEncoded]: conversation
        });

        return assign({}, state, {
            conversations
        });
    },
    [constants.LOGIN_SUCCESS]: (state, action) => {
        if (!action.payload) {
            logger.warn('data not passed into LOGIN_SUCCESS');
        }
        const data2 = action.payload.data || {};
        let isVerified = action.payload.verified || (action.payload.creds || {}).verified;

        const info2 = merge({}, state.info, {
            email: data2.email,
            firstName: data2.firstName,
            lastName: data2.lastName,
            passwordStatus: data2.passwordStatus,
            passwordSet: data2.passwordSet,

            // isVerified is the determining flag for showing these pages.
            // fine to merge roles?
            roles: [].concat(data2.roles || [], data2.unverifiedRoles || [])
        });

        return assign({}, state, {
            isVerified,
            error: null,
            info: info2,
            loggedIn: true
        });
    },
    [constants.LOGIN_DESTROY]: () => {
        return assign({}, initState());
    },
    [constants.UPDATE_AD_TRACKING]: (state, action) => {
        return assign({}, state, {
            hasOptedOutAdTracking: action.payload.hasOptedOutAdTracking
        });
    },
    [constants.UPDATE_SAVED_SEARCHES]: (state, action) => {
        if (!action.payload) {
            logger.warn('data not passed into UPDATE_SAVED_SEARCHES');
        }

        forEach(action.payload.savedSearch, (search) => {
            if (search instanceof SavedSearch !== true) {
                logger.warn('incorrect data model passed into UPDATE_SAVED_SEARCHES');
            }
        });
        var searches = action.payload.savedSearch;

        return assign({}, state, {
            search: assign({}, state.search, {
                saved: [].concat(searches)
            })
        });
    },
    [constants.UPDATE_RECENT_SEARCHES]: (state, action) => {
        if (!action.payload || action.payload.recentSearch instanceof RecentSearch !== true) {
            logger.warn(
                {
                    payload: JSON.stringify(action)
                },
                'data model incorrect or not passed into UPDATE_RECENT_SEARCHES'
            );
        }
        var searches = action.payload.recentSearch;

        return assign({}, state, {
            search: assign({}, state.search, {
                recent: assign({}, searches)
            })
        });
    },
    [constants.USER_LOAD_SUBSCRIPTIONS]: (state, action) => {
        if (!action.payload) {
            logger.warn('data not passed into USER_LOAD_SUBSCRIPTIONS');

            return state;
        }

        let { stopListings, stopOther } = action.payload;

        return assign({}, state, {
            subscriptions: {
                stopListings,
                stopOther
            }
        });
    },
    [constants.UPDATE_CTA_BUTTON_CONTEXT]: (state, action) => {
        // HPWEB-5993: CTA A/B test
        return assign({}, state, {
            ctaButtonContext: action.payload
        });
    },
    [constants.SET_INQUIRY_DATA_CACHE]: (state, action) => {
        if (!action.payload) {
            logger.warn('data not passed into SET_INQUIRY_DATA_CACHE');
        }
        const inquiryDataCache = action.payload.data;

        return assign({}, state, {
            inquiryDataCache: assign({}, state.inquiryDataCache, inquiryDataCache)
        });
    },
    [constants.SET_SHARE_DATA_CACHE]: (state, action) => {
        if (!action.payload) {
            logger.warn('data not passed into SET_SHARE_DATA_CACHE');
        }
        const shareDataCache = action.payload.data;

        return assign({}, state, {
            shareDataCache: assign({}, state.shareDataCache, shareDataCache)
        });
    },
    [constants.SET_NOTIFICATION_SETTINGS]: (state, action) => {
        const deviceGroups = {
            [constants.NOTIFICATION_DEVICE_EMAIL]: 'email',
            [constants.NOTIFICATION_DEVICE_MOBILE]: 'mobile'
        };
        let newSavedSearchPreferences = initNotificationTypes();
        let newRecSearchPreferences = initNotificationTypes();
        let newPropertyUpdatePreferences = initNotificationTypes();

        if (!action.payload) {
            logger.warn('data not passed into SET_NOTIFICATION_SETTINGS');

            return state;
        }

        // ensure payload cannot contain unsupported values
        const safePayload = action.payload.filter(
            (notificationOption) => notificationOption.deviceGroup !== constants.NOTIFICATION_DEVICE_BROWSER
        );

        safePayload.forEach((notificationOption) => {
            let { deviceGroup, typeGroup, optedOut } = notificationOption;

            if (typeGroup === constants.NOTIFICATION_SAVED_SEARCH) {
                newSavedSearchPreferences[deviceGroups[deviceGroup]] = !optedOut;
            } else if (typeGroup === constants.NOTIFICATION_REC_SEARCH) {
                newRecSearchPreferences[deviceGroups[deviceGroup]] = !optedOut;
            } else if (typeGroup === constants.NOTIFICATION_PROPERTY_UPDATE) {
                newPropertyUpdatePreferences[deviceGroups[deviceGroup]] = !optedOut;
            }
        });

        return assign({}, state, {
            savedSearchPreferences: newSavedSearchPreferences,
            recSearchPreferences: newRecSearchPreferences,
            propertyUpdatePreferences: newPropertyUpdatePreferences
        });
    },
    [constants.SET_SERVER_SIDE_COOKIES]: (state, action) => {
        return assign({}, state, {
            serverSideCookies: action.payload
        });
    },
    [constants.SET_SERVER_SIDE_SET_COOKIE_ARRAY]: (state, action) => {
        return assign({}, state, {
            serverSideSetCookiesArray: action.payload
        });
    }
};

const user = reduxUtils.createReducer(mapActionsToReducer, initState());

export default user;
