interface ModelSummaryProps {
    bedsLow?: number;
    bedsHigh?: number;
    sqFtLow?: number;
    sqFtHigh?: number;
    bathsLow?: number;
    bathsHigh?: number;
    priceLow?: number;
    priceHigh?: number;
}

class ModelSummary {
    bedsLow: number;
    bedsHigh: number;
    sqFtLow: number;
    sqFtHigh: number;
    bathsLow: number;
    bathsHigh: number;
    priceLow: number;
    priceHigh: number;

    constructor({
        bedsLow = 0,
        bedsHigh = 0,
        sqFtLow = 0,
        sqFtHigh = 0,
        bathsLow = 0,
        bathsHigh = 0,
        priceLow = 0,
        priceHigh = 0
    }: ModelSummaryProps = {}) {
        this.bedsLow = bedsLow;
        this.bedsHigh = bedsHigh;
        this.sqFtLow = sqFtLow;
        this.sqFtHigh = sqFtHigh;
        this.bathsLow = bathsLow;
        this.bathsHigh = bathsHigh;
        this.priceLow = priceLow;
        this.priceHigh = priceHigh;
    }
}

export default ModelSummary;