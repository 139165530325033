// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as S from 'app/shared/core/Container/styles';

const { any, string, oneOf, bool } = PropTypes;

class Container extends Component {
    static propTypes = {
        children: any,
        className: string,
        size: oneOf(['sm', 'md', 'lg', 'full']),
        slimPadding: bool
    };

    static defaultProps = {
        children: null,
        className: '',
        size: 'md',
        slimPadding: false
    };

    constructor(props) {
        super(props);
    }

    render() {
        const { className, children, size, margin, slimPadding } = this.props;
        return (
            <S.Container
                size={size}
                className={className}
                style={{
                    margin: margin || margin === 0 ? 0 : 'auto'
                }}
                slimPadding={slimPadding}
            >
                {children}
            </S.Container>
        );
    }
}

export default Container;
