/*
    Ideally, this function should live inside areaUtils.js,
    but since we import areaUtils.js into the Area.js model,
    we would end up having a circular dependency.
*/

import isArray from 'lodash/isArray';
import Area, { AreaResponse } from 'app/shared/models/Area';

const processAreasResponse = (apiResults: AreaResponse[] = []) => {
    if (isArray(apiResults)) {
        return apiResults.map((area) => {
            return new Area(area);
        });
    } else {
        return [];
    }
};

export default processAreasResponse;
