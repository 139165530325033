import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';
import { zindex } from 'app/shared/styles/_zindex';
import { fontSize } from 'app/shared/styles/_fontsize';

import IconElectric from 'images/icons/electric.inline.svg';

export const OfflineNotification = styled.div`
    z-index: ${zindex['$z-index-max']};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: ${colors['$hpx-grey-600']};
    display: flex;
    align-items: center;
    font-size: ${fontSize['$font-size-sm']};
    line-height: ${fontSize['$line-height-tiny']};
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    color: white;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
    overflow: hidden;
`;

export const OfflineNotificationLeft = styled.div`
    flex: 1;
    padding: 10px;
`;

export const OfflineNotificationTitle = styled.p`
    font-weight: bold;
    margin-bottom: 5px;
    color: ${colors['$hpx-grey-100']};
`;

export const OfflineNotificationRight = styled.div`
    width: 50px;
    text-align: right;
    padding: 10px;
`;

export const OfflineNotificationIcon = styled(IconElectric)`
    height: 24px;
    width: 24px;
    fill: ${colors['$hpx-yellow-500']};
`;
