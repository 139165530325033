import styled from 'styled-components';
import { viewports } from 'app/shared/styles/_breakpoints';

export const AppTemplate = styled.div`
    height: 100%;
    width: 100%;
    letter-spacing: 0.5px;
    background-color: white;

    &.AppTemplate-hamburger-active {
        max-width: 100%;
        max-height: 100%;
        overflow: hidden;
        position: absolute;
    }
`;

export const AppTemplateModalContent = styled.div`
    @media ${viewports['md-and-up']} {
        width: 320px;
    }
`;
