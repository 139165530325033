// @ts-nocheck
/* eslint-enable */
/* eslint-disable camelcase */
import Promise from 'promise';
import assign from 'lodash/assign';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';

// Actions
import AdActions from 'app/shared/flux/actions/AdActions';
import AppActions from 'app/shared/flux/actions/AppActions';
import UserSearchActions from 'app/shared/flux/actions/UserSearchActions';

// Models
import DEPRECATED_Filter from 'app/shared/models/DEPRECATED_Filter';
import MapData from 'app/shared/models/MapData';
import MapDataQueryObj from 'app/shared/models/MapDataQueryObj';
import RecentSearch from 'app/shared/models/RecentSearch';
import SavedSearch from 'app/shared/models/SavedSearch';

// Misc / Utils
import adapterUtils from 'app/shared/utils/adapterUtils';
import api from 'app/shared/utils/api';
import { areaUtils_validateMapForUpdatingRecentSearch } from 'app/shared/utils/areaUtils';
import constants from 'app/shared/constants/ConstantsBundle';
import controller from './controller';
import {
    filterUtils_defaultFilter,
    filterUtils_getDefaultFilterVal,
    filterUtils_filterToQueryObj
} from 'app/shared/utils/filterUtils';
import gmapUtils from 'app/client/utils/map/gmapUtils';
import queryUtils from 'app/shared/utils/queryUtils';
import routeUtils from 'app/shared/utils/routeUtils';
import searchSlugUtils from 'app/shared/utils/searchSlugUtils';

const logger = getLogger('actions/filter');

const DEPRECATED_FilterActions = {
    updateFilterObjSoft(partialFilterObj = {}) {
        return function(dispatch, getState) {
            const { DEPRECATED_filter } = getState();

            if (isEqual(partialFilterObj, DEPRECATED_filter)) {
                return false;
            }

            const currentFilter = DEPRECATED_filter;
            dispatch({
                type: constants.UPDATE_LES_FILTER,
                filterObj: dispatch(DEPRECATED_FilterActions.createFilter(assign({}, currentFilter, partialFilterObj)))
            });
        };
    },
    updateFilterObjHard(filterObj) {
        return function(dispatch) {
            dispatch({
                type: constants.UPDATE_LES_FILTER_HARD,
                filterObj: dispatch(DEPRECATED_FilterActions.createFilter(filterObj))
            });
        };
    },
    updateFilterObj(partialFilterObj) {
        return function(dispatch, getState) {
            const { DEPRECATED_filter } = getState();
            const hasFilterChanged = dispatch(
                DEPRECATED_FilterActions.hasFilterChanged(partialFilterObj, DEPRECATED_filter)
            );

            if (!hasFilterChanged) {
                return Promise.reject();
            }

            const forceAdRefresh =
                partialFilterObj.listingTypes &&
                !isEqual(partialFilterObj.listingTypes, DEPRECATED_Filter.listingTypes);
            dispatch(AdActions.refreshAd(Boolean(forceAdRefresh)));

            // HPWEB-3485: Before erasing map listing cache, set flag for loading listings,
            // so we can send proper 'noListingsFound' data to GA.
            dispatch(AppActions.setAppStoreBool('fetchListingsByCoordsComplete', false));
            dispatch({ type: constants.RESET_MAP_LISTING_CACHE });

            return dispatch(DEPRECATED_FilterActions.loadUrlFromFilterObj(partialFilterObj));
        };
    },
    updateUrlWithMapData(mapData) {
        return function(dispatch, getState) {
            const state = getState();
            const pathname = state.location.current.pathname;
            const currentResourceId = state.area.area.resourceId || 'new-york-ny';
            const currentSearchSlug = state.DEPRECATED_filter.searchSlug || 'apartments-near-me';
            const currentArea = `/${currentResourceId}/${currentSearchSlug}`;
            const query = state.location.current.query;
            const isNearMeUrl = routeUtils.isNearMeUrl(pathname);

            delete query.page;

            if (controller.mapDataIsDifferent({ mapData, query })) {
                const newQueryObj = controller.mergeMapDataIntoQuery({ mapData, query });
                const newQueryStr = queryUtils.stringify(newQueryObj);
                const newUrl = isNearMeUrl ? currentArea + newQueryStr : pathname + newQueryStr;
                window.router.transitionTo(newUrl);
                return true;
            } else {
                return false;
            }
        };
    },
    updateUrlWithMapDataAndAreaResourceId({ areaResourceId, mapData }) {
        return function(dispatch, getState) {
            const pathname = getState().location.current.pathname;
            const query = getState().location.current.query;

            delete query.page;

            const currentAreaResourceId = routeUtils.getResourceIdFromUrl(pathname);
            const isAreaUrl = routeUtils.isAreaUrl(pathname);
            const shouldUpdateSearchSlug = areaResourceId && isAreaUrl && currentAreaResourceId !== areaResourceId;
            const shouldUpdateMapData = controller.mapDataIsDifferent({ mapData, query });

            if (shouldUpdateSearchSlug && shouldUpdateMapData) {
                const newPathname = pathname.replace(currentAreaResourceId, areaResourceId);
                const newQueryObj = controller.mergeMapDataIntoQuery({ mapData, query });
                const newQueryStr = queryUtils.stringify(newQueryObj);
                const newUrl = newPathname + newQueryStr;
                window.router.transitionTo(newUrl);
                return true;
            } else if (shouldUpdateSearchSlug) {
                const newPathname = pathname.replace(currentAreaResourceId, areaResourceId);
                const sameQueryStr = queryUtils.stringify(query);
                const newUrl = newPathname + sameQueryStr;
                window.router.transitionTo(newUrl);
                return true;
            } else if (shouldUpdateMapData) {
                return dispatch(DEPRECATED_FilterActions.updateUrlWithMapData(mapData));
            } else {
                return false;
            }
        };
    },
    updateUrlWithAreaResourceId(areaResourceId) {
        return function(dispatch, getState) {
            if (!areaResourceId) {
                return false;
            }
            const pathname = getState().location.current.pathname;
            const query = getState().location.current.query;
            const currentAreaResourceId = routeUtils.getResourceIdFromUrl(pathname);
            const isAreaUrl = routeUtils.isAreaUrl(pathname);
            if (isAreaUrl && currentAreaResourceId !== areaResourceId) {
                const newPathname = pathname.replace(currentAreaResourceId, areaResourceId);
                const queryStr = queryUtils.stringify(query);
                const newUrl = newPathname + queryStr;
                window.router.transitionTo(newUrl);
                return true;
            } else {
                return false;
            }
        };
    },
    updateUrlWithFilterParams(filter) {
        return function(dispatch, getState) {
            const store = getState();
            const border = store.location.current.query.border;
            const currPathname = store.location.current.pathname;
            let pathname;

            if (routeUtils.isAreaUrl(currPathname)) {
                pathname = routeUtils.buildAreaPath({
                    areaResourceId: routeUtils.getResourceIdFromUrl(currPathname),
                    searchSlug: filter.searchSlug
                });
            } else if (routeUtils.isPadOrBuildingUrl(currPathname)) {
                pathname = currPathname;
            } else if (routeUtils.isNearMeUrl(currPathname)) {
                pathname = routeUtils.buildAreaPath({
                    areaResourceId: store.geolocation.area.resourceId,
                    searchSlug: filter.searchSlug
                });
            } else {
                return false;
            }

            const mapData = new MapData(gmapUtils.getMapData(window.map));
            const newQueryObj = dispatch(
                DEPRECATED_FilterActions.getQueryObj({
                    filter,
                    mapData,
                    border
                })
            );
            const newQueryString = queryUtils.stringify(newQueryObj);
            const newUrl = pathname + newQueryString;

            window.router.transitionTo(newUrl);

            return newUrl;
        };
    },
    resetFilterAndNotify() {
        return function(dispatch) {
            dispatch(DEPRECATED_FilterActions.resetFilterObj());
        };
    },
    resetFilterObj() {
        return function(dispatch, getState) {
            const state = getState();
            const { DEPRECATED_filter } = state;
            const isDefaultFilter = dispatch(DEPRECATED_FilterActions.isDefaultFilter(DEPRECATED_filter));
            if (isDefaultFilter) {
                return false;
            }

            dispatch(DEPRECATED_FilterActions.updateFilterObjHard());
            dispatch({
                type: constants.RESET_MAP_LISTING_CACHE
            });
            dispatch(DEPRECATED_FilterActions.updateUrlWithFilterParams(filterUtils_defaultFilter));
            dispatch(DEPRECATED_FilterActions.updateRecentSearch());
            return true;
        };
    },
    // takes a single key or an array of keys (HPWEB-5017)
    partialResetFilter({ key, keys }) {
        return function(dispatch) {
            let partialFilterObj = {};

            if (keys) {
                keys.forEach((k) => {
                    partialFilterObj = assign(partialFilterObj, filterUtils_getDefaultFilterVal(k));
                });
            } else if (key) {
                partialFilterObj = filterUtils_getDefaultFilterVal(key);
            }
            return dispatch(DEPRECATED_FilterActions.updateFilterObj(partialFilterObj));
        };
    },
    loadFilterFromUrlAndApi({ pathname, query }) {
        return function(dispatch, getState) {
            let _query = assign({}, query);
            let searchSlug = 'apartments-for-rent';
            let javaFilter = {};
            const state = getState();
            const isNearMeUrl = routeUtils.isNearMeUrl(pathname);

            if (routeUtils.isAreaUrl(pathname)) {
                searchSlug = searchSlugUtils.getSearchSlugFromUrl(pathname) || 'apartments-for-rent';
            }

            if (isNearMeUrl) {
                searchSlug = searchSlugUtils.nearMeToSearchSlugMap(pathname);
            }

            if (!isEmpty(_query)) {
                javaFilter = dispatch(DEPRECATED_FilterActions.queryObjToJavaFilter(_query));
            }
            javaFilter.searchSlug = searchSlug;

            return dispatch(DEPRECATED_FilterActions.loadFilterFromSearchSlugApi({ queryParams: javaFilter }))
                .then((filter) => dispatch(DEPRECATED_FilterActions.updateFilterObjHard(filter)))
                .catch((error) => {
                    logger.warn({
                        error: {
                            stack: error.stack,
                            message: error.message
                        },
                        msg: 'FilterActions.loadFilterFromUrlAndApi#loadFilterFromSearchSlugApi',
                        pathname,
                        queryParams: javaFilter,
                        requestId: state.app.requestId
                    });
                });
        };
    },
    loadUrlFromFilterObj(filterObj) {
        return function(dispatch, getState) {
            const currFilter = getState().DEPRECATED_filter;
            let searchSlug = currFilter.searchSlug;
            let _filterObj = assign({}, filterObj);
            let _filter = dispatch(DEPRECATED_FilterActions.createFilter(assign({}, currFilter, _filterObj)));

            // js filter -> java filter
            const javaFilter = controller.sanitizeFilter(adapterUtils.createJavaFilter({ filter: _filter }));
            const queryParams = assign(javaFilter, { searchSlug });

            // loadUrlFilterObj is only called when the filter is manually updated
            // when the filter changes, reset to page 1 (HPWEB-5470)
            delete queryParams.currentPage;

            return dispatch(
                DEPRECATED_FilterActions.loadFilterFromSearchSlugApi({ queryParams, hasFilterBeenSet: true })
            ).then((filter) => {
                dispatch(DEPRECATED_FilterActions.updateRecentSearch({ newFilter: filter }));
                dispatch(DEPRECATED_FilterActions.updateUrlWithFilterParams(filter));
            });
        };
    },
    loadFilterFromSearchSlugApi({ queryParams, hasFilterBeenSet = false }) {
        return function(dispatch, getState) {
            const { isValidSearchSlug } = getState().app;

            if (hasFilterBeenSet) {
                /**
                 * When a filter is set, the query will contain all the information needed for the api to respond
                 * with the appropriate searchSlug regardless of which searchSlug is passed in - *unless* you are
                 * resetting a long-tail searchSlug. This is because resetting a filter *can* mean setting it's value
                 * to `null`, which the endpoint will treat as no filter set.
                 */
                queryParams.searchSlug = 'apartments-for-rent';
            }

            return dispatch(api.area.searchSlugToFilter(queryParams)).then((result = {}) => {
                const { data = {}, status } = result;
                const { filter = {} } = data;

                if (status === 'INVALID_DATA') {
                    logger.warn(
                        'FilterActions#loadFilterFromSearchSlugApi invalid search slug: ',
                        queryParams.searchSlug
                    );

                    // show NotFoundPage
                    dispatch(AppActions.setAppStoreBool('isValidSearchSlug', false));

                    return {
                        error: true,
                        changeHttpStatus: 404
                    };
                }

                if (!isValidSearchSlug) {
                    dispatch(AppActions.setAppStoreBool('isValidSearchSlug', true));
                }

                filter.searchSlug = data.searchSlug;

                // TODO: remove after HPWEB-5008
                // hacky fix because the searchSlug api does not treat pagination as a filter param
                if (Number.isInteger(queryParams.currentPage)) {
                    filter.currentPage = queryParams.currentPage;
                }

                return dispatch(DEPRECATED_FilterActions.createFilter(filter));
            });
        };
    },
    /**
     * loadFilterFromUrl is exclusively called in client/index.js right after the browser navigates to a new url.
     * We want the url to be our single source of truth for filters. This means that, whatever query is in the
     * browser's address bar should be reflected in our Redux store's filter object. This ensures that links are
     * correctly shareable and that - if there is a bug with setting filters - we only have to check logic before
     * route changes.
     *
     * Therefore, loadFilterFromUrl should simply check for filter params in the url (if any are present), call
     * the search slug api with the filter, and update the app's filter object.
     */
    loadFilterFromUrl({ pathname, query }) {
        return function(dispatch) {
            let queryObj = assign({}, query);
            let searchSlug;
            let javaFilter = {};

            if (routeUtils.isAreaUrl(pathname)) {
                searchSlug = searchSlugUtils.getSearchSlugFromUrl(pathname) || 'apartments-for-rent';
            } else if (routeUtils.isNearMeUrl(pathname)) {
                searchSlug = searchSlugUtils.nearMeToSearchSlugMap(pathname);
            } else if (routeUtils.isPadOrBuildingUrl(pathname)) {
                /**
                 * This function is only called on client-side render, so we can safely assume that the filter query
                 * is complete, which in turn means that we can use the default search slug (`apartments-for-rent`)
                 * and the api will return the correct filter.
                 */
                searchSlug = 'apartments-for-rent';
            } else {
                // only SRP and HDP urls will contain filter info. No need to call search slug api here
                return false;
            }

            javaFilter = dispatch(DEPRECATED_FilterActions.queryObjToJavaFilter(queryObj));
            javaFilter.searchSlug = searchSlug;

            return dispatch(DEPRECATED_FilterActions.loadFilterFromSearchSlugApi({ queryParams: javaFilter })).then(
                (res) => {
                    if (!res.error) {
                        dispatch(DEPRECATED_FilterActions.updateFilterObjSoft(res));
                    }
                }
            );
        };
    },
    hasFilterChanged(partialFilter, oldFilter) {
        return function(dispatch) {
            const newFilter = dispatch(DEPRECATED_FilterActions.createFilter(assign({}, oldFilter, partialFilter)));
            const hasFilterChanged = !isEqual(newFilter, oldFilter);

            return hasFilterChanged;
        };
    },
    getQueryObj({ filter, mapData, border }) {
        return function() {
            filter = assign({}, filter);
            mapData = assign({}, mapData);
            var filterObj = filterUtils_filterToQueryObj(filter);
            var mapDataObj = new MapDataQueryObj(mapData);

            if (border === false) {
                mapDataObj.border = false;
            }

            return assign({}, filterObj, mapDataObj);
        };
    },
    isDefaultFilter(currFilterObj) {
        return function() {
            return isEqual(filterUtils_defaultFilter, currFilterObj);
        };
    },
    setCurrentSearch({ mapData, waiting, resourceId, isMapFirstIdle = false }) {
        return function(dispatch, getState) {
            const { DEPRECATED_filter, area, location } = getState();
            let currentSearch = getState().user.currentSearch;
            const currentSearchReady = currentSearch && currentSearch.waiting;
            const currAreaMatches = area.area && currentSearch && currentSearch.resourceId === area.area.resourceId;

            if (waiting) {
                // case 1: user searched (no map data)
                return dispatch({
                    type: constants.SET_CURRENT_SEARCH,
                    payload: { waiting: true, resourceId }
                });
            } else if (currentSearchReady && currAreaMatches) {
                // case 2: map data came back - update recent search
                dispatch(DEPRECATED_FilterActions.updateRecentSearch({ mapDataInfo: mapData }));
            } else if (currentSearchReady && !currAreaMatches) {
                // do nothing, wait till area is done loading
            } else if (
                areaUtils_validateMapForUpdatingRecentSearch(mapData, location.current.query.border) &&
                !isMapFirstIdle
            ) {
                // case 3: save api filter info for when user qualifies search to be a recent search
                // ie clicks on a listing
                const shouldUseAreaBoundary = location.current.query.border !== false;
                const areaId = shouldUseAreaBoundary ? area.area.id : null;
                const apiFilter = adapterUtils.createJavaFilter({ filter: DEPRECATED_filter, mapData, areaId });
                return dispatch({
                    type: constants.SET_CURRENT_SEARCH,
                    payload: { apiFilter }
                });
            } else {
                // invalid search (ie too zoomed out)
                // set current search to null to avoid updating recent search
                return dispatch({
                    type: constants.SET_CURRENT_SEARCH,
                    payload: null
                });
            }
        };
    },
    listingOnCurrentSearchClicked() {
        return function(dispatch, getState) {
            const currentSearch = getState().user.currentSearch;

            if (currentSearch && currentSearch.apiFilter) {
                dispatch({
                    type: constants.SET_CURRENT_SEARCH,
                    payload: null
                });

                return dispatch(api.user.search.recent.update({ filter: currentSearch.apiFilter })).then(() => {
                    dispatch(UserSearchActions.getRecentSearches());
                });
            }
        };
    },
    updateRecentSearch({ mapDataInfo, newFilter } = {}) {
        return function(dispatch, getState) {
            if (__CLIENT__) {
                const { DEPRECATED_filter, area, location, app } = getState();
                const isMobile = app.device.isMobile;
                const shouldUseAreaBoundary = location.current.query.border !== false;
                const mapData = (app.gmapLoaded && gmapUtils.getMapData(window.map)) || mapDataInfo;

                if (isEmpty(mapData) && !isMobile) {
                    return Promise.resolve();
                }

                const areaId = shouldUseAreaBoundary && !isEmpty(area.area) ? area.area.id : null;
                const apiFilter = adapterUtils.createJavaFilter({
                    filter: newFilter ? newFilter : DEPRECATED_filter,
                    mapData,
                    areaId
                });
                dispatch({
                    type: constants.SET_CURRENT_SEARCH,
                    payload: null
                });

                return dispatch(api.user.search.recent.update({ filter: apiFilter })).then(() => {
                    dispatch(UserSearchActions.getRecentSearches());
                });
            }
        };
    },
    resetRecentSearchFilter() {
        return function(dispatch, getState) {
            const { user } = getState();
            const recentSearch = assign({}, user.search.recent);
            if (isEmpty(recentSearch)) {
                return false;
            }
            const shouldUseAreaBoundary = !recentSearch.url.includes('border=false');
            const mapData = recentSearch.mapData || {};
            const areaId =
                shouldUseAreaBoundary && user.search.recent && !isEmpty(user.search.recent.areaInfo)
                    ? user.search.recent.areaInfo.id
                    : null;
            const apiFilter = adapterUtils.createJavaFilter({ filter: filterUtils_defaultFilter, mapData, areaId });

            dispatch({
                type: constants.SET_CURRENT_SEARCH,
                payload: null
            });

            return dispatch(api.user.search.recent.update({ filter: apiFilter })).then(() => {
                dispatch(UserSearchActions.getRecentSearches());
            });
        };
    },
    createRecentSearch({ filter = {}, areaInfo = {} } = {}) {
        return function(dispatch) {
            const hasBoundary = Boolean(filter.areas);
            const mapData = new MapData(filter);
            const createdFilter = dispatch(DEPRECATED_FilterActions.createFilter(filter));
            const queryObj = dispatch(
                DEPRECATED_FilterActions.getQueryObj({
                    filter: createdFilter,
                    mapData: hasBoundary ? null : mapData,
                    border: hasBoundary
                })
            );

            return Promise.resolve(
                new RecentSearch({
                    hasBoundary,
                    resourceId: areaInfo.resourceId,
                    filter: createdFilter,
                    queryObj,
                    mapData,
                    areaInfo
                })
            );
        };
    },
    createSavedSearch({
        sid = null,
        filter = {},
        resourceId = 'new-york-ny',
        created,
        name,
        emailFrequency,
        numberOfNewResults
    }) {
        return function(dispatch) {
            const mapData = new MapData(filter);
            const createdFilter = dispatch(DEPRECATED_FilterActions.createFilter(filter));

            const queryObj = dispatch(
                DEPRECATED_FilterActions.getQueryObj({
                    filter: createdFilter,
                    mapData: filter.areas ? null : mapData,
                    border: filter.areas || false
                })
            );

            return new SavedSearch({
                sid,
                filter: createdFilter,
                resourceId,
                created,
                name,
                emailFrequency,
                numberOfNewResults,
                queryObj,
                mapData,
                borderAreaId: filter.areas
            });
        };
    },
    queryObjToJavaFilter(queryObj = {}) {
        return function(dispatch) {
            const filterObj = controller.queryObjToFilterObj(queryObj);
            const filterKeys = Object.keys(filterObj);
            const filter = dispatch(DEPRECATED_FilterActions.createFilter(filterObj));
            const relevantFilter = pick(filter, filterKeys);
            return controller.sanitizeFilter(adapterUtils.createJavaFilter({ filter: relevantFilter }));
        };
    },
    createFilter(_filter = {}) {
        return function() {
            /**
             * FilterActions.createFilter can act as an intermediary that is sandwiched between
             * business logic and the actual creation of a filter. This is helpful for cases
             * when we need to have the application's state, such as A/B testing context,
             * BEFORE we make the filter. Previous scenarios have been: experimenting with the
             * default orderBy and testing between `score` and `experimentScore` (vec2rec).
             */
            const filter = assign({}, _filter);

            if (!filter.orderBy) {
                filter.orderBy = 'score';
            }

            return DEPRECATED_Filter.create(filter);
        };
    }
};

export default DEPRECATED_FilterActions;
