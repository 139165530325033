import SafeString from 'app/shared/utils/SafeString';

interface AddressParams {
    street?: string;
    city?: string;
    state?: string;
    zip?: string;
    neighborhood?: string;
    hideStreet?: boolean;
}

class Address {
    street: string;
    city: string;
    state: string;
    zip: string;
    neighborhood: string;
    hideStreet: boolean;

    constructor({
        street = '',
        city = '',
        state = '',
        zip = '',
        neighborhood = '',
        hideStreet = false
    }: AddressParams = {}) {
        this.street = SafeString(street);
        this.city = SafeString(city);
        this.state = SafeString(state);
        this.zip = SafeString(zip);
        this.neighborhood = SafeString(neighborhood);
        this.hideStreet = Boolean(hideStreet);
    }
}

export default Address;