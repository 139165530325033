import { css, FlattenSimpleInterpolation } from 'styled-components';
import { viewports } from 'app/shared/styles/_breakpoints';

export const smoothTransition = css`
    transition: all 0.15s ease-in-out;
`;

export const transform = (transforms: string): FlattenSimpleInterpolation => css`
    -webkit-transform: ${transforms};
    -o-transform: ${transforms};
    -ms-transform: ${transforms};
    -moz-transform: ${transforms};
    transform: ${transforms};
`;

export const noUserSelect = css`
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome and Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Chrome and Opera */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    /* some androids require this line instead */
    -webkit-tap-highlight-color: transparent;
`;

export const onHover = (arg: FlattenSimpleInterpolation): FlattenSimpleInterpolation => css`
    &:focus {
        ${arg}
    }
    @media ${viewports['md-and-up']} {
        &:focus,
        &:hover {
            ${arg}
        }
    }
`;

export const dropshadow = css`
    -webkit-box-shadow: 10px 11px 26px 0px rgba(0, 0, 0, 0.125);
    -moz-box-shadow: 10px 11px 26px 0px rgba(0, 0, 0, 0.125);
    box-shadow: 10px 11px 26px 0px rgba(0, 0, 0, 0.125);
`;

/** Core mixins that are commonly used across various components */
export const sidePadding16Px = css`
    padding-left: 16px;
    padding-right: 16px;
`;

export const noScrollBar = css`
    &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0) !important;
    }
    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0) !important;
    }
    &::-webkit-scrollbar {
        height: 0;
        width: 0;
        background: rgba(0, 0, 0, 0) !important;
    }
`;

export const arrowPointDown = css`
    transform: rotate(-90deg);
`;
export const arrowPointUp = css`
    transform: rotate(90deg);
`;

export const arrowPointRight = css`
    transform: rotate(180deg);
`;

export const arrowPointLeft = css`
    transform: rotate(0deg);
`;

export const anchorOffset = css`
    display: block;
    position: relative;
    top: -40px;
    visibility: hidden;
    @media (min-width: 768px) {
        top: 0;
    }
`;

export const textOverflow = css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;