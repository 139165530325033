// @ts-nocheck
/* eslint-enable */
import assign from 'lodash/assign';
import map from 'lodash/map';

import buildingUtils from 'app/shared/utils/buildingUtils';
import dateUtils from 'app/shared/utils/dateUtils';

import Geo from 'app/shared/models/Geo';
import Details from 'app/shared/models/Details';
import Address from 'app/shared/models/Address';
import RecencyTime from 'app/shared/models/RecencyTime';
import School from 'app/shared/models/School';


const logger = getLogger('models');

const BuildingV2 = function({
    areas = {},
    availableUnits = {},
    geo = {},
    lotIdEncoded = '',
    listings = [{}],
    lotIdReviews = {},
    schools = []
} = {}) {
    try {
        const {
            units,
            rentalUnits,
            pricingSummary,
            isActive,
            buildingPhotos,
            buildingLargePhotos,
            buildingThumbPhotos,
            buildingActivated,
            buildingUserItemTypes,
            listingMinMaxPriceBeds
        } = buildingUtils.processApiDataForBuildingV2({ areas, availableUnits, geo, listings, lotIdReviews, schools });
        const hasMultipleUnits = units.length > 1;
        const neighborhood = (areas.specific && areas.specific.name) || '';
        this.active = isActive;
        this.address = new Address(assign(units[0].address, { neighborhood }));
        this.aliasEncoded = units[0].aliasEncoded;
        this.areas = units[0].areas;
        this.building = true;
        this.isBuilding = true;
        this.displayName = buildingUtils.getBuildingDisplayName(units);
        this.details = new Details({
            title: buildingUtils.getBuildingPageTitle(units[0].address) // page title
        });
        this.geo = new Geo(geo);
        this.iconType = 'condo';
        this.isApartmentBldg = Boolean(units[0].isApartmentBldg);
        this.listingMinMaxPriceBeds = listingMinMaxPriceBeds;
        this.maloneLotIdEncoded = String(lotIdEncoded);
        this.propertyType = String(units[0].propertyType);
        this.recencyTime = new RecencyTime(dateUtils.recencyTime(buildingActivated, new Date()));
        this.searchKeyword = hasMultipleUnits
            ? String(units.length + ' Units Available')
            : String(units[0].searchKeyword);
        this.previewPhoto = units[0].previewPhoto;
        this.photos = buildingPhotos;
        this.largePhotos = buildingLargePhotos;
        this.thumbPhotos = buildingThumbPhotos;
        this.uriV2 = hasMultipleUnits ? String(units[0].uriBuilding) : String(units[0].uriMalone);
        this.userItemTypes = buildingUserItemTypes;
        this.units = units;
        this.rentalUnits = rentalUnits;
        this.schools = map(schools, (school) => {
            return new School(school);
        });
        this.pricingSummary = pricingSummary;
    } catch (err) {
        logger.error(
            {
                dataError: 'dataModel',
                type: 'BuildingV2',
                error: err
            },
            'BuildingV2'
        );
        return false;
    }
};

export default BuildingV2;
