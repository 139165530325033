// @ts-nocheck
/* eslint-enable */


const logger = getLogger('utils/storage');

export const storageAvailable = (type) => {
    if (__CLIENT__) {
        try {
            var storage = window[type],
                x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        } catch (e) {
            return false;
        }
    }
};

export const userTokenStorage = {
    userToken: null,
    storeOldCred(token) {
        this.userToken = token;
    }
};

export const getNotificationDisplay = (localStorageKey) => {
    try {
        if (storageAvailable('localStorage')) {
            const disabledInfoFromLocalStorage = window.localStorage.getItem(localStorageKey);
            const now = Date.now();

            if (disabledInfoFromLocalStorage) {
                const parsedData = JSON.parse(disabledInfoFromLocalStorage);

                // should reset and show the notification
                if (now - parsedData.time >= parsedData.timeToWait) {
                    const serializedData = JSON.stringify({
                        time: now,
                        timeToWait: 0
                    });

                    window.localStorage.setItem(localStorageKey, serializedData);

                    // enough time has passed since the last dismiss
                    return true;
                } else {
                    // not enough time has passed since the last dismiss
                    return false;
                }
            } else {
                // nothing in localStorage, should display
                return true;
            }
        } else {
            // no localStorage

            return true;
        }
    } catch (e) {
        logger.error(e, 'Error getting NotificationDisplay from localStorage with localStorageKey %s', localStorageKey);

        return false;
    }
};
