// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import * as S from './styles';

class PageLoadingIndicator extends Component {
    constructor(props) {
        super(props);
        const progress = document.getElementById('PageLoadingIndicator-progress');
        let width = '0%';
        let opacity = 0;
        this.isFirstMount = true;

        /**
         * Many pages import multiple components that render PageLoadingIndicator while loading.
         * If this is not the first time PageLoadingIndicator is rendered, initialize the width
         * of the indicator where we left off
         */
        if (progress) {
            this.isFirstMount = false;
            const progressWidth = Number(window.getComputedStyle(progress).width.slice(0, -2)); // ex: '123.45px'
            const windowWidth = window.innerWidth; // ex: 1080
            width = `${(progressWidth / windowWidth) * 100}%`;
            opacity = 1;
        }

        this.state = {
            width,
            opacity
        };
    }

    componentDidMount() {
        this._isMounted = true;

        if (this.isFirstMount) {
            this.loadTimeout1 = setTimeout(() => {
                if (this._isMounted) {
                    this.setState({
                        width: '25%',
                        opacity: 1
                    });
                }
            }, 20);
        }

        this.loadTimeout2 = setTimeout(() => {
            if (this._isMounted) {
                this.setState({
                    width: '95%',
                    opacity: 1
                });
            }
        }, 2000);
    }

    componentWillUnmount() {
        /**
         * Set this._isMounted to false so that the setTimeouts won't call setState
         * on an unmounted component (React)
         */
        this._isMounted = false;
        clearTimeout(this.loadTimeout1);
        clearTimeout(this.loadTimeout2);
    }

    render() {
        const { width, opacity } = this.state;

        return (
            <S.PageLoadingIndicator>
                <S.PageLoadingIndicatorProgress id="PageLoadingIndicator-progress" style={{ width, opacity }} />
            </S.PageLoadingIndicator>
        );
    }
}

export default PageLoadingIndicator;
