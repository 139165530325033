// @ts-nocheck
/* eslint-enable */
export default {
    monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    dateIsValid(time) {
        if (!time) {
            return false;
        }

        const date = new Date(time);

        return date instanceof Date && !isNaN(date);
    },
    formatDateMmddyy(time, separator = '/') {
        const date = new Date(time);

        const formattedDate = [
            ('0' + (date.getMonth() + 1)).slice(-2),
            ('0' + date.getDate()).slice(-2),
            date.getFullYear().toString().substr(-2)
        ].join(separator);

        return formattedDate;
    },
    formatDateYyyyMmDd(time, separator = '/') {
        const date = new Date(time);

        const formattedDate = [
            date.getFullYear(),
            ('0' + (date.getMonth() + 1)).slice(-2),
            ('0' + date.getDate()).slice(-2)
        ].join(separator);

        return formattedDate;
    },
    formatDateMmddyyyy(time, separator = '/') {
        const date = new Date(time);

        const formattedDate = [
            ('0' + (date.getMonth() + 1)).slice(-2),
            ('0' + date.getDate()).slice(-2),
            date.getFullYear()
        ].join(separator);

        return formattedDate;
    },
    formatDateYyyymmdd(time) {
        const date = new Date(time);

        const formattedDate = [
            date.getFullYear(),
            ('0' + (date.getMonth() + 1)).slice(-2),
            ('0' + date.getDate()).slice(-2)
        ].join('-');

        return formattedDate;
    },
    formatDate(d) {
        var dateUpdated = new Date(d);
        var timeDiff = new Date().getTime() - d;
        var timeString = '';

        if (timeDiff < 1000 * 60 * 60 * 24 && timeDiff >= 0) {
            if (timeDiff < 1000 * 60) {
                timeString = 'Just Now!';
            } else if (timeDiff < 1000 * 60 * 60) {
                timeString = parseInt(timeDiff / 1000 / 60) + ' min ago';
            } else if (timeDiff < 1000 * 120 * 60) {
                timeString = parseInt(timeDiff / 1000 / 60 / 60) + ' hour ago';
            } else {
                timeString = parseInt(timeDiff / 1000 / 60 / 60) + ' hours ago';
            }
        } else {
            timeString = this.monthNames[dateUpdated.getMonth()] + ' ' + dateUpdated.getDate();
        }

        return timeString;
    },
    formatDateToString(d, opts) {
        let str = '';
        const date = new Date(d);
        const UTC_DAY = date.getUTCDate();
        const UTC_MONTH = date.getUTCMonth();
        const UTC_YEAR = date.getUTCFullYear();

        opts = opts || {};
        str +=
            this.monthNames[UTC_MONTH] +
            (opts.skipDay ? '' : ' ' + UTC_DAY) +
            (opts.skipDay || opts.skipYear ? '' : ',') +
            (opts.skipYear ? '' : ' ' + UTC_YEAR);

        return str;
    },
    getDayDisplayFromDate(d, abbrev = false, getLocalDate = false) {
        const date = new Date(d);
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const abbrevDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];

        if (getLocalDate) {
            return abbrev ? abbrevDays[date.getDay()] : days[date.getDay()];
        }

        return abbrev ? abbrevDays[date.getUTCDay()] : days[date.getUTCDay()];
    },
    getMonthDisplayFromDate(d) {
        const date = new Date(d);

        return this.monthNames[date.getMonth()];
    },
    formatHourMinuteString(str = '') {
        const timeArr = str.split(':');
        let minutes = timeArr[1];
        let hour = parseInt(timeArr[0]);
        let suffix = 'am';

        if (hour >= 12) {
            suffix = 'pm';
        }

        if (hour > 12) {
            hour = hour - 12;
        } else if (hour === 0) {
            hour = 12;
        }

        if (minutes.length === 1) {
            minutes = '0' + minutes;
        }

        return `${hour}:${minutes}${suffix}`;
    },
    recencyTime(timeInMilliSec, currentTime) {
        var oldTime = new Date(timeInMilliSec);
        var timeDiff = Math.abs(currentTime.getTime() - oldTime.getTime());
        var secondsDiff = Math.ceil(timeDiff / 1000);
        var minutesDiff = Math.ceil(timeDiff / (1000 * 60));
        var hoursDiff = Math.ceil(timeDiff / (1000 * 60 * 60));
        var daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
        var weeksDiff = Math.ceil(timeDiff / (1000 * 3600 * 24 * 7));
        var monthsDiff = Math.ceil(timeDiff / (1000 * 3600 * 24 * 7 * 4));
        var displayDiff;

        if (secondsDiff < 60) {
            displayDiff = secondsDiff + 's';
        } else if (minutesDiff < 60) {
            displayDiff = minutesDiff + 'm';
        } else if (hoursDiff < 18) {
            displayDiff = hoursDiff + 'h';
        } else if (daysDiff < 7) {
            displayDiff = daysDiff + 'd';
        } else if (daysDiff < 52) {
            displayDiff = weeksDiff + 'w';
        } else if (monthsDiff < 24) {
            displayDiff = monthsDiff + ' months';
        } else {
            displayDiff = 'over two years';
        }

        return {
            displayDiff,
            secondsDiff,
            minutesDiff,
            hoursDiff,
            daysDiff,
            weeksDiff,
            monthsDiff
        };
    },
    isDaylightSavings(dateISOString = '') {
        const date = dateISOString
            ? new Date(dateISOString.includes('T') ? dateISOString : dateISOString + 'T12:00:00Z')
            : new Date();
        const winterDate = new Date(date.getFullYear(), 0, 1);
        return date.getTimezoneOffset() !== winterDate.getTimezoneOffset();
    },
    appendTimeISOSubstringIfMissing(dateISOString = '') {
        if (!dateISOString || dateISOString.includes('T')) {
            return dateISOString;
        }

        const highNoonETasUTC = this.isDaylightSavings(dateISOString) ? 'T16:00:00Z' : 'T17:00:00Z';
        return dateISOString + highNoonETasUTC;
    },
    removeTimeISOSubstring(dateISOString = '') {
        return dateISOString.split('T')[0];
    },
    isDateInThePast(date) {
        return new Date() > new Date(date);
    }
};
