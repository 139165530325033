// @ts-nocheck
/* eslint-enable */
const RoomDetails = function({
    roomSqFt = 0,
    utilitiesShareDescription = '',
    roomIsFurnished = false,
    hasPrivateBath = false
} = {}) {
    this.roomSqFt = roomSqFt;
    this.utilitiesShareDescription = utilitiesShareDescription;
    this.roomIsFurnished = roomIsFurnished;
    this.hasPrivateBath = hasPrivateBath;
};

const pluralizeGenderType = (genderType, count) => {
    const genderTypeLowerCase = genderType.toLowerCase();
    if (Number(count) === 1) {
        return genderType;
    } else if (genderTypeLowerCase === 'male' || genderTypeLowerCase === 'female') {
        return genderType + 's';
    } else if (genderTypeLowerCase === 'non-binary') {
        return genderType.slice(0, -1) + 'ies';
    } else {
        return genderType;
    }
};

const getInfo = (roommateDetails) => {
    return roommateDetails
        .filter((detail) => {
            return (detail.genderCount && detail.genderCount !== '0') || (detail.petCount && detail.petCount !== '0');
        })
        .map((detail) => {
            const isRoommateInfo = Boolean(detail.roommateGenderType);
            const count = isRoommateInfo ? detail.genderCount : detail.petCount;
            const type = isRoommateInfo ? pluralizeGenderType(detail.roommateGenderType, count) : detail.petType;

            return `${count} ${type}`;
        })
        .join(', ');
};

const RoommateDetails = function({
    roommateGenderDetails = [],
    totalCount = '',
    description = '',
    roommatePetDetails = []
} = {}) {
    this.roommatesCount = totalCount;
    this.description = description;
    this.roommatesInfo = getInfo(roommateGenderDetails);
    this.petsInfo = getInfo(roommatePetDetails);
};

const RoomForRent = function(roomForRent) {
    const { roomDetails = {}, roommateDetails = {}, roommateRequirements = {} } = roomForRent ?? {};

    this.roomDetails = new RoomDetails(roomDetails);
    this.roommateDetails = new RoommateDetails(roommateDetails);
    this.preferredNewRoommate = (roommateRequirements.genderTypes ?? []).join(', ');
};

export { RoomForRent, RoomDetails };
