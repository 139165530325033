import { Experiment } from '../models/Experiment';

export const getRunningExperimentsInitialState = (experiments: Experiment[]): Record<string, Experiment> => {
    return experiments.reduce<Record<string, Experiment>>((expMap, exp) => {
        expMap[exp.experimentName] = exp;
        return expMap;
    }, {});
};

export const getResolvedExperimentName = (experiment: Experiment) => {
    const { experimentName } = experiment;
    const HPWEB_TEST_REGEXP = /HPWEB/;
    if (HPWEB_TEST_REGEXP.test(experimentName)) {
        return experimentName;
    }

    const { jira } = experiment;

    return `${experimentName}.${jira}`;
};
