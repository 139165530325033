import { isEmpty, toString } from 'lodash';

export interface PropertyInfoBlock {
    listing_alias: string;
    lot_id: string;
    is_paid_ind: boolean;
    property_type_cd: string;
    listing_status_cd: string;
    listing_type_cd: string;
}

export const setPropertyInfoBlock = (listing: any) => {
    if (isEmpty(listing)) {
        return;
    }

    if (listing.units) {
        listing = listing.units[0];
    }

    let {
        active,
        aliasEncoded,
        details = {},
        listingType = '',
        maloneLotIdEncoded: maloneLotId,
        propertyType = ''
    } = listing;

    let listingActive = toString(active);

    const propertyInfoBlock: PropertyInfoBlock = {
        listing_alias: aliasEncoded,
        lot_id: maloneLotId,
        listing_status_cd: listingActive,
        is_paid_ind: details.trusted,
        property_type_cd: propertyType,
        listing_type_cd: listingType
    };

    return propertyInfoBlock;
};
