// @ts-nocheck
/* eslint-enable */
// Lodash
import round from 'lodash/round';
import isNil from 'lodash/isNil';

// Actions
import DEPRECATED_FilterActions from 'app/shared/flux/actions/DEPRECATED_FilterActions';

// Models
import MapDataQueryObj from 'app/shared/models/MapDataQueryObj';

// Utils
import { sanitizingUtils_sanitizeUndefinedAndNullValues } from 'app/shared/utils/sanitizingUtils';

// Allowed Filter Constants
import {
    FILTER_ENUM,
    BEDROOMS,
    BATHROOMS,
    PROPERTY_TYPES,
    COMMUTE_MODE_TYPES_REDUX_TO_JAVA,
    MAX_CREATED_TYPES_REDUX_TO_JAVA
} from 'app/shared/constants/FilterConstants';

/**
 * Adapts query object containing hyphen delimited params into a comma delimited object
 * ready for searchSlugToFilter API consumption
 * @param {Object} _query -  query object containing hyphen delimited params
 *
 * i.e.
 *  {
 *      amenities: 'parking-swimmingPool',
 *      baths: '2-8plus',
 *      etc
 *  }
 */
const adapt_queryToJava = (_query) => {
    const ADAPT = {
        GENERAL: {
            boolQuery: (val) => {
                if (isNil(val)) {
                    return;
                }

                if (typeof val === 'boolean') {
                    return val === true ? true : null;
                }

                return val === 'true' ? true : null;
            },
            truthyChainQuery: (val, ENUM) => {
                if (isNil(val)) {
                    return;
                }

                return val
                    .split('-')
                    .sort()
                    .filter((v) => {
                        const isValid = ENUM[v];
                        return !isNil(isValid);
                    })
                    .join(',');
            },
            truthyQuery: (val, ENUM) => {
                if (isNil(val)) {
                    return;
                }

                const isValid = !isNil(ENUM[val]);
                if (isValid) {
                    return ENUM[val];
                }
                return null;
            }
        },
        SPECIFIC: {
            bedroomsQuery: (val) => {
                // If no arg is passed in
                if (isNil(val)) {
                    return;
                }

                const [min = 0, max = null] = val.split('-');
                const isExact = max === null;

                const bedroomsExact = {
                    0: Number(min) === 0 && '0',
                    1: Number(min) === 1 && '1',
                    2: Number(min) === 2 && '2',
                    3: Number(min) === 3 && '3',
                    4: Number(min) === 4 && '4'
                };
                const bedroomsRange = {
                    0: Number(min) === 0 && BEDROOMS.ANY,
                    1: Number(min) === 1 && BEDROOMS.ONE_BEDROOM_AND_UP,
                    2: Number(min) === 2 && BEDROOMS.TWO_BEDROOMS_AND_UP,
                    3: Number(min) === 3 && BEDROOMS.THREE_BEDROOMS_AND_UP,
                    4: Number(min) === 4 && BEDROOMS.FOUR_BEDROOMS_AND_UP
                };

                const res = isExact ? bedroomsExact[min] : bedroomsRange[min];

                // If arg is passed in
                if (!isNil(res)) {
                    return res;
                }

                // If erroneous args passed in
                return BEDROOMS.ANY;
            },
            bathroomsQuery: (val) => {
                // If no arg is passed in
                if (isNil(val)) {
                    return;
                }

                const [min = 0] = val.split('-');
                const bathroomsRange = {
                    0: Number(min) === 0 && BATHROOMS.ANY,
                    1: Number(min) === 1 && BATHROOMS.ONE_BATHROOM_AND_UP,
                    1.5: Number(min) === 1.5 && BATHROOMS.ONE_HALF_BATHROOMS_AND_UP,
                    2: Number(min) === 2 && BATHROOMS.TWO_BATHROOMS_AND_UP,
                    3: Number(min) === 3 && BATHROOMS.THREE_BATHROOMS_AND_UP,
                    4: Number(min) === 4 && BATHROOMS.FOUR_BATHROOMS_AND_UP
                };
                const res = bathroomsRange[min];

                // If arg is passed in
                if (!isNil(res)) {
                    return res;
                }

                // If erroneous args passed in
                return BATHROOMS.ANY;
            },
            minPhotosQuery: (val) => {
                if (isNil(val)) {
                    return;
                }
                return val === '1' ? 1 : null;
            },
            vaguePricingQuery: (val) => {
                if (isNil(val)) {
                    return;
                }

                if (typeof val === 'boolean') {
                    return val === false ? false : null;
                }

                return val === 'false' ? false : null;
            },
            latLonQuery: (val) => {
                if (isNil(val)) {
                    return;
                }

                const num = Number(val);

                if (!isNaN(num)) {
                    return num;
                }
                return null;
            },
            availabilityQuery: (val) => {
                if (isNil(val)) {
                    return { start: null, end: null };
                }

                const splitDates = val.split('to');

                if (splitDates.length === 2) {
                    const [start, end] = splitDates;
                    return { start, end };
                }

                return [null, null];
            },
            priceOrSqftQuery: (val) => {
                if (isNil(val)) {
                    return { min: null, max: null };
                }

                const [rawMin = 0, rawMax = null] = val.split('-');
                const min = round(Number(rawMin));
                const max = rawMax ? round(Number(rawMax)) : null;

                if (isNaN(min) || isNaN(max)) {
                    return { min: null, max: null };
                }

                if (!isNaN(min)) {
                    return { min, max };
                }
            }
        }
    };

    const javaParams = {
        orderBy: ADAPT.GENERAL.truthyQuery(_query.orderBy, FILTER_ENUM.ORDER_BY),
        lowPrice: ADAPT.SPECIFIC.priceOrSqftQuery(_query.price).min,
        highPrice: ADAPT.SPECIFIC.priceOrSqftQuery(_query.price).max,
        bedrooms: ADAPT.SPECIFIC.bedroomsQuery(_query.beds),
        bathrooms: ADAPT.SPECIFIC.bathroomsQuery(_query.baths),
        commuteTimeMode: ADAPT.GENERAL.truthyQuery(_query.commuteTimeMode, FILTER_ENUM.COMMUTE),
        commuteTime: ADAPT.GENERAL.truthyQuery(_query.commuteTime, FILTER_ENUM.COMMUTE),
        commuteMode: ADAPT.GENERAL.truthyQuery(_query.commuteMode, FILTER_ENUM.COMMUTE),
        commuteLats: ADAPT.SPECIFIC.latLonQuery(_query.commuteLats),
        commuteLons: ADAPT.SPECIFIC.latLonQuery(_query.commuteLons),
        pets: ADAPT.GENERAL.truthyChainQuery(_query.pets, FILTER_ENUM.PETS),
        startOfAvailabilityDate: ADAPT.SPECIFIC.availabilityQuery(_query.avail).start,
        endOfAvailabilityDate: ADAPT.SPECIFIC.availabilityQuery(_query.avail).end,
        hideUnknownAvailabilityDate: ADAPT.GENERAL.boolQuery(_query.hideUnkAvail),
        laundry: ADAPT.GENERAL.truthyChainQuery(_query.laundry, FILTER_ENUM.LAUNDRY),
        amenities: ADAPT.GENERAL.truthyChainQuery(_query.amenities, FILTER_ENUM.AMENITIES),
        furnished: ADAPT.GENERAL.boolQuery(_query.furnished),
        propertyTypes: ADAPT.GENERAL.truthyChainQuery(_query.propertyTypes, FILTER_ENUM.PROPERTY_TYPES),
        minSqft: ADAPT.SPECIFIC.priceOrSqftQuery(_query.sqft).min,
        maxSqft: ADAPT.SPECIFIC.priceOrSqftQuery(_query.sqft).max,
        maxCreated: ADAPT.GENERAL.truthyQuery(_query.maxCreated, FILTER_ENUM.MAX_CREATED),
        listingTypes: ADAPT.GENERAL.truthyChainQuery(_query.listingTypes, FILTER_ENUM.LISTING_TYPES),
        incomeRestricted: _query.incomeRestricted,
        seniorHousing: _query.seniorHousing,
        studentHousing: _query.studentHousing,
        militaryHousing: _query.militaryHousing,
        keywords: _query.keywords,
        minPhotos: ADAPT.SPECIFIC.minPhotosQuery(_query.photos),
        includeVaguePricing: ADAPT.SPECIFIC.vaguePricingQuery(_query.includeVaguePricing),
        isListedByOwner: ADAPT.GENERAL.boolQuery(_query.isListedByOwner),
        acceptsSection8: ADAPT.GENERAL.boolQuery(_query.acceptsSection8),
        hasSpecialOffers: ADAPT.GENERAL.boolQuery(_query.promo),
        isAcceptingRentalApplications: ADAPT.GENERAL.boolQuery(_query.applicationsOk),

        // Non-user facing
        feeds: _query.feeds,
        dupeGrouping: _query.dupeGrouping,
        visible: _query.visible
    };
    const finalParams = sanitizingUtils_sanitizeUndefinedAndNullValues(javaParams);

    return finalParams;
};

/**
 * Adapts parameters containing Redux filter and area data into a comma delimited object
 * ready for ByCoordsV2 API consumption
 *
 * @param {Object} obj
 * @param {Filter Object} obj.filter - Filter from Redux store
 * @param {Object} obj.area - Area object containing id, min/max lat, min/max lon
 * @param {string} obj.area.id - Current area id
 * @param {string} obj.area.minLat - Current area min lat
 * @param {string} obj.area.minLon - Current area min lon
 * @param {string} obj.area.maxLat - Current area max lat
 * @param {string} obj.area.maxLon - Current area max lon
 * @param {number} obj.offset
 * @param {number} obj.limit
 * @param {string} obj.channels
 * @param {array} obj.components
 */
const adapt_reduxToJava = ({
    filter: _filter,
    area: _area,
    offset: _offset = 0,
    limit: _limit = 20,
    channels: _channels,
    components: _components,
    mapData: _mapData
}) => {
    const ADAPT = {
        GENERAL: {
            truthyReduxField: (val) => {
                const keys = Object.keys(val);
                const active = keys.filter((opt) => val[opt]);

                return active.join(',');
            }
        },
        SPECIFIC: {
            bedroomsReduxField: (val) => {
                const keys = Object.keys(val);
                const active = keys.filter((opt) => val[opt]);
                const opt = active[0];
                const isExact = active[1] === 'isExact';
                const res = {
                    anyOrStudio: isExact ? '0' : BEDROOMS.ANY, // Maps to Any or Studio
                    oneOrMore: isExact ? '1' : BEDROOMS.ONE_BEDROOM_AND_UP, // Maps to 1 or 1+
                    twoOrMore: isExact ? '2' : BEDROOMS.TWO_BEDROOMS_AND_UP, // Maps to 2 or 2+
                    threeOrMore: isExact ? '3' : BEDROOMS.THREE_BEDROOMS_AND_UP, // Maps to 3 or 3+
                    fourOrMore: isExact ? '4' : BEDROOMS.FOUR_BEDROOMS_AND_UP // Maps to 4 or 4+
                };

                return res[opt];
            },
            bathroomsReduxField: (val) => {
                const keys = Object.keys(val);
                const active = keys.filter((opt) => val[opt]);
                const opt = active[0];
                const res = {
                    any: BATHROOMS.ANY, // Maps to Any
                    oneOrMore: BATHROOMS.ONE_BATHROOM_AND_UP, // Maps to 1+
                    oneHalfOrMore: BATHROOMS.ONE_HALF_BATHROOMS_AND_UP, // Maps to 1.5+
                    twoOrMore: BATHROOMS.TWO_BATHROOMS_AND_UP, // Maps to 2+
                    threeOrMore: BATHROOMS.THREE_BATHROOMS_AND_UP, // Maps to 3+
                    fourOrMore: BATHROOMS.FOUR_BATHROOMS_AND_UP // Maps to 4+
                };

                return res[opt];
            },
            commuteReduxField: (val) => {
                // 'Driver' => 'DRIVER' or 'Off peak' => 'offPeak
                return COMMUTE_MODE_TYPES_REDUX_TO_JAVA[val];
            },
            propertyTypesReduxField: (val) => {
                const keys = Object.keys(val);
                const active = keys.filter((opt) => val[opt]);
                const isAnyOptionSelected = active[0] === 'any';

                if (isAnyOptionSelected) {
                    return PROPERTY_TYPES.ANY;
                }

                const indexOfApartmentType = active.indexOf('apartment');
                const indexOfDuplexType = active.indexOf('duplex');

                if (indexOfApartmentType > -1) {
                    active[indexOfApartmentType] = PROPERTY_TYPES.APARTMENT;
                }

                if (indexOfDuplexType > -1) {
                    active[indexOfDuplexType] = PROPERTY_TYPES.DUPLEX;
                }

                return active.join(',');
            },
            createdWithinReduxField: (val) => {
                const keys = Object.keys(val);
                const active = keys.filter((opt) => val[opt])[0];

                const res = {
                    any: null,
                    hour: '1',
                    day: '24',
                    week: '168',
                    month: '720'
                };

                return res[active];
            },
            listingTypesReduxField: (val) => {
                const keys = Object.keys(val);
                const active = keys.filter((opt) => val[opt]);

                return active.join(',');
            }
        }
    };

    const queryObj = {
        // Filter based params
        orderBy: _filter.orderBy,
        lowPrice: _filter.price.min,
        highPrice: _filter.price.max,
        bedrooms: ADAPT.SPECIFIC.bedroomsReduxField(_filter.bedrooms),
        bathrooms: ADAPT.SPECIFIC.bathroomsReduxField(_filter.bathrooms),
        commuteTimeMode: ADAPT.SPECIFIC.commuteReduxField(_filter.commute.commuteTimeMode),
        commuteTime: _filter.commute.commuteTime,
        commuteMode: ADAPT.SPECIFIC.commuteReduxField(_filter.commute.commuteMode),
        commuteLats: _filter.commute.commuteLats,
        commuteLons: _filter.commute.commuteLons,
        pets: ADAPT.GENERAL.truthyReduxField(_filter.pets),
        startOfAvailabilityDate: _filter.availability.start,
        endOfAvailabilityDate: _filter.availability.end,
        hideUnknownAvailabilityDate: _filter.availability.hideUnknownAvailabilityDate,
        laundry: ADAPT.GENERAL.truthyReduxField(_filter.laundry),
        amenities: ADAPT.GENERAL.truthyReduxField(_filter.amenities),
        furnished: _filter.furnished ? true : null,
        propertyTypes: ADAPT.SPECIFIC.propertyTypesReduxField(_filter.propertyTypes),
        minSqft: _filter.sqft.min === 0 ? null : _filter.sqft.min,
        maxSqft: _filter.sqft.max === 3800 ? null : _filter.sqft.max,
        maxCreated: ADAPT.SPECIFIC.createdWithinReduxField(_filter.createdWithin),
        listingTypes: ADAPT.SPECIFIC.listingTypesReduxField(_filter.rentalTypes),
        incomeRestricted: _filter.restrictions.incomeRestricted,
        seniorHousing: _filter.restrictions.seniorHousing,
        studentHousing: _filter.restrictions.studentHousing,
        militaryHousing: _filter.restrictions.militaryHousing,
        keywords: _filter.keywords,
        minPhotos: _filter.additionalOpts.requiresPhotos ? 1 : null,
        includePhotosCollection: true, // HPWEB-6082: Request array of medium photo URLs for new listing cards
        includeVaguePricing: _filter.additionalOpts.requiresPrice ? false : null,
        isListedByOwner: _filter.additionalOpts.forRentByOwner ? true : null,
        acceptsSection8: _filter.additionalOpts.acceptsSection8 ? true : null,
        hasSpecialOffers: _filter.additionalOpts.hasOffers ? true : null,
        isAcceptingRentalApplications: _filter.additionalOpts.acceptingApplications ? true : null,

        // Non-user facing params
        feeds: _filter.NON_USER_FACING.feeds,
        dupeGrouping: _filter.NON_USER_FACING.dupeGrouping,
        visible: _filter.NON_USER_FACING.visible,

        // Area based params
        // HPWEB-5750: School SRP not returning listings.
        // Model index does not return listings for university area IDs.
        // Passing null forces byCoords call to search by lat/long instead.
        areas: _area && _area.type !== 'university' ? _area.id : null,
        lat: _area?.lat ?? '',
        lon: _area?.lon ?? '',
        maxLat: (_area?.maxLat || _mapData?.maxLat) ?? '',
        maxLon: (_area?.maxLon || _mapData?.maxLon) ?? '',
        minLat: (_area?.minLat || _mapData?.minLat) ?? '',
        minLon: (_area?.minLon || _mapData?.minLon) ?? '',

        // Other params
        offset: _offset,
        limit: _limit,
        channels: _channels || '',
        components: _components || 'basic,useritem,quality,model,photos'
    };

    const finalParams = sanitizingUtils_sanitizeUndefinedAndNullValues(queryObj);

    return finalParams;
};

/**
 * Adapts Redux filter into a hyphen delimited query string
 * @param {Filter Object} _filter - Filter from Redux store
 */
const adapt_reduxToQuery = ({
    filter: _filter,
    mapData: _mapData,
    border: _border,
    page: _page,
    analytics: _analytics
}) => {
    const queryObj = {};
    const DELIMITER = '-';
    _mapData = Object.assign({}, _mapData);

    if (_filter.orderBy && _filter.orderBy !== 'score' && _filter.orderBy !== 'experimentScore') {
        queryObj.orderBy = _filter.orderBy;
    }

    if (_filter.price) {
        if (_filter.price.min && !_filter.price.max) {
            queryObj.price = [_filter.price.min || 0]?.toString();
        } else if (!_filter.price.min && _filter.price.max) {
            queryObj.price = [0, _filter.price.max].join(DELIMITER)?.toString();
        } else if (_filter.price.min && _filter.price.max) {
            queryObj.price = [_filter.price.min || 0, _filter.price.max].join(DELIMITER)?.toString();
        }
    }

    if (_filter.bedrooms) {
        const bedrooms = _filter.bedrooms;
        const isExact = bedrooms.isExact;
        const identifiers = Object.keys(bedrooms);
        const active = identifiers.filter((beds) => {
            return bedrooms[beds];
        })[0];

        const queryRes = {
            anyOrStudio: isExact ? '0' : null,
            oneOrMore: isExact ? '1' : '1-8plus',
            twoOrMore: isExact ? '2' : '2-8plus',
            threeOrMore: isExact ? '3' : '3-8plus',
            fourOrMore: isExact ? '4' : '4-8plus'
        };

        const bedQuery = queryRes[active];

        if (bedQuery !== null) {
            queryObj.beds = bedQuery?.toString();
        }
    }

    if (_filter.bathrooms) {
        const bathrooms = _filter.bathrooms;
        const identifiers = Object.keys(bathrooms);
        const active = identifiers.filter((beds) => {
            return bathrooms[beds];
        })[0];

        const queryRes = {
            any: null,
            oneOrMore: '1-8plus',
            oneHalfOrMore: '1.5-8plus',
            twoOrMore: '2-8plus',
            threeOrMore: '3-8plus',
            fourOrMore: '4-8plus'
        };

        const bathQuery = queryRes[active];

        if (bathQuery) {
            queryObj.baths = bathQuery?.toString();
        }
    }

    if (_filter.commute) {
        queryObj.commuteLats = _filter.commute.commuteLats?.toString();
        queryObj.commuteLons = _filter.commute.commuteLons?.toString();
        queryObj.commuteMode = COMMUTE_MODE_TYPES_REDUX_TO_JAVA[_filter.commute.commuteMode]?.toString();
        if (_filter.commute.commuteTime) {
            queryObj.commuteTime = _filter.commute.commuteTime?.toString();
        }
        queryObj.commuteTimeMode = COMMUTE_MODE_TYPES_REDUX_TO_JAVA[_filter.commute.commuteTimeMode]?.toString();
    }

    if (_filter.pets) {
        const petCategories = Object.keys(_filter.pets);
        const res = petCategories
            .filter((key) => {
                return _filter.pets[key];
            })
            .join(DELIMITER);

        queryObj.pets = res?.toString();
    }

    if (_filter.availability) {
        const availability = _filter.availability;

        if (availability.start && availability.end) {
            queryObj.avail = `${availability.start}to${availability.end}`?.toString();
        }

        if (availability.hideUnknownAvailabilityDate) {
            queryObj.hideUnkAvail = availability.hideUnknownAvailabilityDate?.toString();
        }
    }

    if (_filter.propertyTypes) {
        const identifiers = Object.keys(_filter.propertyTypes);
        const active = identifiers.filter((option) => {
            return _filter.propertyTypes[option];
        });
        if (!active.includes('any')) {
            const aptIdx = active.indexOf('apartment');
            const dupIdx = active.indexOf('duplex');
            const reMappedApartmentType = PROPERTY_TYPES.APARTMENT.split(',').join(DELIMITER);
            const reMappedDuplexType = PROPERTY_TYPES.DUPLEX.split(',').join(DELIMITER);

            if (aptIdx > -1) {
                active.splice(aptIdx, 1, reMappedApartmentType);
            }

            if (dupIdx > -1) {
                active.splice(dupIdx, 1, reMappedDuplexType);
            }

            queryObj.propertyTypes = active.join(DELIMITER)?.toString();
        }
    }

    if (_filter.laundry) {
        const identifiers = Object.keys(_filter.laundry);
        const active = identifiers.filter((option) => {
            return _filter.laundry[option];
        });

        if (active.length > 0) {
            queryObj.laundry = active.join(DELIMITER)?.toString();
        }
    }

    if (_filter.amenities) {
        const identifiers = Object.keys(_filter.amenities);
        const active = identifiers.filter((option) => {
            return _filter.amenities[option];
        });

        if (active.length > 0) {
            queryObj.amenities = active.join(DELIMITER)?.toString();
        }
    }

    if (_filter.furnished) {
        queryObj.furnished = true?.toString();
    }

    if (_filter.sqft) {
        if (_filter.sqft.min && !_filter.sqft.max) {
            queryObj.sqft = _filter.sqft.min?.toString();
        } else if (_filter.sqft.max) {
            queryObj.sqft = [_filter.sqft.min || 0, _filter.sqft.max].join(DELIMITER)?.toString();
        }
    }

    if (_filter.createdWithin) {
        const identifiers = Object.keys(_filter.createdWithin);
        const active = identifiers.filter((option) => {
            return _filter.createdWithin[option];
        })[0];

        if (active !== 'any') {
            queryObj.maxCreated = MAX_CREATED_TYPES_REDUX_TO_JAVA[active]?.toString();
        }
    }

    if (_filter.rentalTypes) {
        const identifiers = Object.keys(_filter.rentalTypes);
        const active = identifiers.filter((option) => {
            return _filter.rentalTypes[option];
        });

        if (active.length < 4) {
            queryObj.listingTypes = active.join(DELIMITER)?.toString();
        }
    }

    if (_filter.restrictions) {
        const restrictions = _filter.restrictions;

        if (restrictions.incomeRestricted || restrictions.incomeRestricted === false) {
            queryObj.incomeRestricted = restrictions.incomeRestricted?.toString();
        }

        if (restrictions.militaryHousing || restrictions.militaryHousing === false) {
            queryObj.militaryHousing = restrictions.militaryHousing?.toString();
        }

        if (restrictions.seniorHousing || restrictions.seniorHousing === false) {
            queryObj.seniorHousing = restrictions.seniorHousing?.toString();
        }

        if (restrictions.studentHousing || restrictions.studentHousing === false) {
            queryObj.studentHousing = restrictions.studentHousing?.toString();
        }
    }

    if (_filter.keywords) {
        queryObj.keywords = _filter.keywords?.toString();
    }

    if (_filter.additionalOpts) {
        const opts = _filter.additionalOpts;

        if (opts.requiresPhotos) {
            queryObj.photos = 1?.toString();
        }

        if (opts.requiresPrice) {
            queryObj.includeVaguePricing = false?.toString();
        }

        if (opts.forRentByOwner) {
            queryObj.isListedByOwner = opts.forRentByOwner?.toString();
        }

        if (opts.acceptsSection8) {
            queryObj.acceptsSection8 = opts.acceptsSection8?.toString();
        }

        if (opts.hasOffers) {
            queryObj.promo = opts.hasOffers?.toString();
        }

        if (opts.acceptingApplications) {
            queryObj.applicationsOk = opts.acceptingApplications?.toString();
        }
    }

    if (_page) {
        queryObj.page = _page?.toString();
    }

    const mapDataObj = new MapDataQueryObj(_mapData);

    if (_border === false) {
        mapDataObj.border = false?.toString();
    }

    if (_analytics) {
        if (_analytics.HPWEB_CONTROL) {
            queryObj.HPWEB_CONTROL = _analytics.HPWEB_CONTROL;
        }

        if (_analytics.HPWEB_EXP) {
            queryObj.HPWEB_EXP = _analytics.HPWEB_EXP;
        }
    }

    Object.assign(queryObj, mapDataObj);

    return queryObj;
};

/**
 * Custom adapter that converts the filter to DEPRECATED_filter in order to play nice with functions
 * that expect our classic DEPRECATED_filter model shape
 *
 * @param {Filter Object} - Filter from Redux store
 * @returns {DEPRECATED_Filter Object}
 */
const adapt_filterToDEPRECATED_filter = (filter, area) => {
    return (dispatch) => {
        const reduxToJavaFilter = adapt_reduxToJava({ filter, area });
        return dispatch(DEPRECATED_FilterActions.createFilter(reduxToJavaFilter));
    };
};

export { adapt_queryToJava, adapt_reduxToJava, adapt_reduxToQuery, adapt_filterToDEPRECATED_filter };
