// @ts-nocheck
/* eslint-enable */
import forOwn from 'lodash/forOwn';


const logger = getLogger('utils/formatter');

var formatter = {
    obj: {
        toCommaDelimitedString(input) {
            var keys = [];

            forOwn(input, (value, key) => {
                if (value === true) {
                    keys.push(key);
                }
            });
            return keys.join(',');
        }
    },
    string: {
        capitalizeFirstLetter(str) {
            return str[0].toUpperCase() + str.slice(1);
        },
        firstCaps(str) {
            var string = str || '';
            var pieces = string.split(' ');
            var i, j;

            for (i = 0; i < pieces.length; i++) {
                j = pieces[i].charAt(0).toUpperCase();
                pieces[i] = j + pieces[i].substr(1).toLowerCase();
            }
            return pieces.join(' ');
        },
        splitCamelCaseToLowerCaseWords(str) {
            var string = str || '';
            var pieces = string.split('');
            var newLetters = [];
            var i;

            for (i = 0; i < pieces.length; i++) {
                if (pieces[i] === pieces[i].toUpperCase() && pieces[i] !== ' ') {
                    if (i === 0) {
                        newLetters.push(pieces[i].toLowerCase());
                    } else {
                        newLetters.push(' ' + pieces[i].toLowerCase());
                    }
                } else {
                    newLetters.push(pieces[i]);
                }
            }
            return newLetters.join('');
        },
        upperFirstLowerRest(str = '') {
            if (typeof str !== 'string') {
                logger.warn('formatter#upperFirstLowerRest requires a string input');
                return false;
            }
            if (str === '') {
                return str;
            }

            const lowerCasePortion = str.slice(1).toLowerCase();
            const upperFirstLetter = str[0].toUpperCase();

            return `${upperFirstLetter}${lowerCasePortion}`;
        },
        removePunctuation(str = '') {
            return str.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '');
        }
    },
    sort(a, b) {
        return a - b;
    }
};

export default formatter;
