// @ts-nocheck
/* eslint-enable */
import searchSlugUtils from 'app/shared/utils/searchSlugUtils';

const logger = getLogger('utils/seo');

const seoUtils = {
    getConsolidatedUri(uri = '') {
        let pathname;

        try {
            let url = new URL(uri);
            pathname = url.pathname;
        } catch (err) {
            pathname = uri;
        }

        const searchSlug = searchSlugUtils.getSearchSlugFromUrl(pathname);

        if (!searchSlug) {
            return uri;
        }

        return uri;
    },
    translateSlugToApiKeyword(searchSlug) {
        if (searchSlugUtils.active()[searchSlug]) {
            return searchSlugUtils.active()[searchSlug].seoApiKeyword;
        } else {
            logger.warn('seoUtils#translateSlugToApiKeyword unhandled search slug', searchSlug);
            return false;
        }
    }
};

export default seoUtils;
